import { Component } from 'react';
import React from 'react';
import { sendLoginData } from '../actions/sendLogin';
import '../ui_pag-Login.css';

export class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handleSubmit(event) {
    var data = { usuario: this.state.email, senha: this.state.password };
    console.log(sendLoginData(data));
    event.preventDefault();
  }

  render() {
    return (
      <div>
        <div className='princpl-header'>
          <nav className='navbar navbar-expand-md navbar-light'>
            <h1>
              <img
                className='logo_TJdocs'
                src='/ui/img/Logo_TJdocs.svg'
                alt=''
              />
            </h1>
          </nav>
        </div>
        <div className='princpl-conteudo' id='princpl_conteudo'>
          <div className='loginbox'>
            <div
              title='T J dócs'
              id='Princpl-TJdocs_Logo'
              className='form-group'
            >
              <img className='p-contd-Logo' src='/ui/img/Logo_TJdocs.svg' />
            </div>
            <div className='busca_Principal mx-auto'>
              <form id='Formulario_LoginSenha' onSubmit={this.handleSubmit}>
                <input
                  name='email'
                  className='busca_P-input w-100 form-group'
                  type='text'
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  placeholder='E-Mail'
                  id='Input_LoginEmail'
                  aria-required='true'
                />
                <input
                  name='password'
                  className='busca_P-input w-100 form-group'
                  type='password'
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  placeholder='Senha'
                  id='Input_LoginSenha'
                  aria-required='true'
                />
                <button
                  type='submit'
                  className='w-100 btn btn-primary form-group'
                >
                  Entrar
                </button>
              </form>
              <div
                id='mensagem-sucesso'
                className='alert alert-success'
                role='alert'
              >
                <span id='mensagem-sucesso-body'></span>
                <button
                  type='button'
                  className='close'
                  data-dismiss='alert'
                  aria-label='Close'
                >
                  {' '}
                  <span aria-hidden='true'>&times;</span>{' '}
                </button>
              </div>
              <div
                id='mensagem-erro'
                className='alert alert-danger'
                role='alert'
              >
                <span id='mensagem-erro-body'></span>
                <button
                  type='button'
                  className='close'
                  data-dismiss='alert'
                  aria-label='Close'
                >
                  {' '}
                  <span aria-hidden='true'>&times;</span>{' '}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
