import React from 'react';
import { RiMenuSearchLine } from 'react-icons/ri';
import { FaFolder } from 'react-icons/fa';

export const ViewOptions = ({
  showFolderIcon,
  setFoldersView,
  setSearchResultsView = () => {},
  exibicaoLista,
  exibicaoGrade,
  listaParaGrade,
  gradeParaLista,
  hideFoldersView = false,
}) => {
  return (
    <div className='p-c-menTp-Opcoes'>
      <span>
        {showFolderIcon ? (
          <div
            className='icones'
            title='Visualizar as pastas'
            onClick={() => setFoldersView()}
            role='button'
          >
            <FaFolder size={20} />
          </div>
        ) : (
          <div
            className='icones'
            title='Resultados da pesquisa'
            onClick={setSearchResultsView}
            role='button'
          >
            <RiMenuSearchLine size={22} />
          </div>
        )}

        {!hideFoldersView && (
          <>
            <div
              className={exibicaoLista}
              onClick={listaParaGrade}
              id='alterar_P-DisplayMiniaturas'
              title='Visualização em miniatura.'
              role='button'
            >
              <i className='fas fa-th-large'></i>
            </div>
            <div
              className={exibicaoGrade}
              onClick={gradeParaLista}
              id='alterar_P-DisplayLista'
              title='Visualização em lista.'
              role='button'
            >
              <i id='alterarGradeParaLista' className='fas fa-list'></i>
            </div>
          </>
        )}
      </span>
    </div>
  );
};
