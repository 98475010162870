import React from 'react';
import { Button } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

const CloseableButton = ({ label, onClose }) => {
  return (
    <div className='closeable-button'>
      <span style={{ fontSize: '12px' }}>{label}</span>
      <Button variant='link' className='close-icon' onClick={onClose}>
        <span style={{ fontSize: '1rem' }}>
          <FaTimes />
        </span>
      </Button>
    </div>
  );
};

export default CloseableButton;
