import ApiService from "../apiService";

export default class DocumentoEtiquetaService extends ApiService {
  constructor() {
    super("v1/");
  }

  listarEtiquetas(documentoId) {
    return this.get(`documentos/${documentoId}/etiquetas`);
  }

  listarDocumentos(etiquetaId) {
    return this.get(`etiquetas/${etiquetaId}/documentos`);
  }

  estahAssociado(etiquetaId, documentoId) {
     return this.get (`etiquetas/${etiquetaId}/documentos/${documentoId}`);
  }
  
}
