import React, { Component } from 'react';
import toast from 'react-hot-toast';
import TjdocsApi from '../api/API';

import Authentication from '../Authentication';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

export class CadastrarRelease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.exibeCadastraRelease,
      numero: this.props.release == null ? '' : this.props.release.numVersao,
      titulo: this.props.release == null ? '' : this.props.release.nomeVersao,
      data: this.props.release == null ? '' : this.props.release.dataRelease,
      descricao: this.props.release == null ? '' : this.props.release.descricao,
      arquivo: '',
      validated: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleNumero = this.handleNumero.bind(this);
    this.handleTitulo = this.handleTitulo.bind(this);
    this.handleData = this.handleData.bind(this);
    this.handleDescricao = this.handleDescricao.bind(this);
    this.usuario = Authentication.getUsuario();
    this.isLogin = Authentication.isLogin();
  }

  loadState() {
    console.log(this.props);
    this.setState({
      numero: this.props.release == null ? '' : this.props.release.numVersao,
    });
    this.setState({
      titulo: this.props.release == null ? '' : this.props.release.nomeVersao,
    });
    this.setState({
      data: this.props.release == null ? '' : this.props.release.dataRelease,
    });
    this.setState({
      descricao: this.props.release == null ? '' : this.props.release.descricao,
    });
  }

  componentDidMount() {
    this.loadState();
  }

  resetState() {
    /*this.state = {modal:  false, numero: "", titulo: "", data: "", descricao: "", arquivo: "", validated: false };

        this.setState({ numero: "" });
        this.setState({ titulo: "" });
        this.setState({ data: "" });
        this.setState({ descricao: "" });
        this.setState({ arquivo: "" });*/
  }

  handleClose() {
    this.props.handleCadastraRelease();
  }

  handleShow(e) {
    this.setState({ validated: false });
    this.resetState();
  }

  handleFile(e) {
    e.preventDefault();
    let file = e.target.files[0];

    this.setState({ arquivo: file });
  }

  handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ validated: true });
    } else {
      TjdocsApi.criaRelease(
        this.state.numero,
        this.state.titulo,
        this.props.idRelease,
        this.state.data,
        this.state.descricao,
        this.state.arquivo,
      )
        .then((response) => {
          if (document.querySelector('.loading'))
            document.querySelector('.loading').style.display = 'none';
          toast.success('Sucesso!');
        })
        .catch((e) => {
          if (document.querySelector('.loading'))
            document.querySelector('.loading').style.display = 'none';
          toast.error('Criando Arquivo: ' + e);
        });
      this.handleClose();
    }
  }

  handleNumero(event) {
    let numero = '';
    numero = event.target.value;

    this.setState({ numero: numero });
  }

  handleTitulo(event) {
    let titulo = '';
    titulo = event.target.value;

    this.setState({ titulo: titulo });
  }

  handleData(event) {
    let data = '';
    data = event.target.value;

    this.setState({ data: data });
  }

  handleDescricao(event) {
    let descricao = '';
    descricao = event.target.value;

    this.setState({ descricao: descricao });
  }

  onChangeFunc = (optionSelected) => {
    if (document.querySelector('.loading'))
      document.querySelector('.loading').style.display = 'block';
    window.location.href = optionSelected.value;
  };

  render() {
    const { validated } = this.state;
    return (
      <>
        <Modal
          show={this.props.exibeCadastraRelease}
          onHide={this.handleClose}
          autoFocus={false}
          dialogClassName={'largeModal'}
        >
          <Modal.Header style={{ backgroundColor: '#004A80' }}>
            <Modal.Title
              style={{ color: '#ffffff', height: '36px', width: '90%' }}
            >
              <div
                style={{ fontWeight: 'bold', fontSize: '20px', float: 'left' }}
                className='span12 centered-text'
              >
                {' '}
                Releases - TJ
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    color: '#0FBDD2',
                  }}
                >
                  DOCS
                </span>{' '}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              id='create-course-form'
              name='addForm'
              noValidate
              validated={validated}
              onSubmit={(e) => this.handleSubmit(e)}
            >
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlTextarea1'
              >
                <div
                  style={{
                    marginBottom: '40px',
                    borderBottom: 'solid 1px #ccc',
                  }}
                >
                  <h3 className='span12 centered-text'>Release</h3>
                </div>

                <Form.Row>
                  <Form.Group as={Col} md='4'>
                    <Form.Label>Número:</Form.Label>
                    <div className='mb-7'>
                      <input
                        className='form-control'
                        type='text'
                        id='numero'
                        onChange={(e) => this.handleNumero(e)}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} md='4'>
                    <Form.Label>Título:</Form.Label>
                    <div className='mb-7'>
                      <input
                        className='form-control'
                        type='text'
                        id='titulo'
                        onChange={(e) => this.handleTitulo(e)}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} md='4'>
                    <Form.Label>Data:</Form.Label>
                    <div className='mb-7'>
                      <input
                        className='form-control'
                        type='date'
                        id='data'
                        onChange={(e) => this.handleData(e)}
                      />
                    </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md='12'>
                    <Form.Label>Descrição:</Form.Label>
                    <textarea
                      className='form-control'
                      id='descricao'
                      onChange={(e) => this.handleDescricao(e)}
                    ></textarea>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md='12'>
                    <div className='mb-3'>
                      <input
                        required
                        className='form-control'
                        type='file'
                        id='formFile'
                        onChange={this.handleFile}
                      />
                    </div>
                  </Form.Group>
                </Form.Row>
              </Form.Group>
              <div></div>
              <Modal.Footer>
                <div>
                  <Button
                    style={{
                      padding: '10px',
                      color: '#fff',
                      backgroundColor: '#1C7A67',
                      border: 'solid 1px #1C7A67',
                      fontWeight: 'bold',
                      width: '80px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      float: 'left',
                      marginRight: '5px',
                    }}
                    type='submit'
                  >
                    Salvar
                  </Button>
                  <div
                    style={{
                      padding: '10px',
                      color: '#ff0000',
                      border: 'solid 1px #ff0000',
                      fontWeight: 'bold',
                      width: '80px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      float: 'left',
                    }}
                    onClick={(e) => {
                      this.handleClose();
                    }}
                  >
                    Cancelar
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
