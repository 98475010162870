import React from 'react';

const PanelNenhumArquivoEncontrado = () => {
  return (
    <div className="align-items-center justify-content-md-center row" style={{height: '200px'}}>
      <h1>Nenhum arquivo encontrado</h1>
    </div>
  );
};

export default PanelNenhumArquivoEncontrado;