import React, {Component} from 'react';
import '../App.css';
import {Header} from "../components/Header";
import {MenuDominio} from "../components/MenuDominio";
import {MenuAcoesDominio} from "../components/MenuAcoesDominio";

import {Footer} from "../components/Footer";
import BoxMenu from "../components/BoxMenu";
import TjdocsApi from '../api/API';
import {MainContainer} from "./MainContainer";
import {Switch, Route} from 'react-router-dom'
import {FolderContainer} from "./FolderContainer";
import {FileContainer} from "./FileContainer";
import SearchContainer from "./SearchContainer";
import {Link} from "react-router-dom";
import {FileIcon} from "../components/FileIcon";
import {FolderIcon} from "../components/FolderIcon";


export class DominioContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: []
        };

    }


    handlePath = (item) => {
        this.setState({
            path: item
        });
    }

    render() {
		
        return (
            <>
                                    

                                    <MenuDominio path={this.props}/>



                                    <div className="row ">

                                        <div className="col-md-4">
                                        </div>

                                        <div className="col-md-4 ">

                                            {/*Inicio barra de pesquisa direita*/}
                                            
                                            <div className="busca_Principal_Dominio " onLoad={this.loadLoginData}>
                                                <input className="w-100 busca_P-input" type="search"
                                                    placeholder="     Digite os termos da sua pesquisa (no mínimo três letras)" onChange={this.handleChange}/>
                                                <Link className="focu-sem-outline" to={{pathname: `/busca/${this.state.tokens}`}} >
                                                    <img className="busca_Dominio icones svg-cinza svg-comHover"
                                                        src="../ui/img/Search-Icon.svg" alt="" />
                                                </Link>
                                            </div>

                                            {/*End barra de pesquisa direita*/}

                                        </div>

                                        <div className="col-md-4">
                                        </div>
                                    </div>




                        </>            
                    );
    }
}

