import React, { Component } from 'react';
import UsuarioPastaService from '../api/service/usuarioPastaService';
import toast from 'react-hot-toast';
import Authentication from '../Authentication';
export class BoxFavoritos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pastas: [],
    };

    this.usuarioPastaService = new UsuarioPastaService();

    this.usuario = Authentication.getUsuario();
    this.isLogin = Authentication.isLogin();
  }

  componentDidMount() {
    var usuarioId = this.usuario.usuario_id_tjdocs;
    if (usuarioId !== undefined) {
      this.usuarioPastaService
        .listar(usuarioId)
        .then((response) => {
          if (typeof response.data._embedded !== 'undefined') {
            const pastas = response.data._embedded.pastas;

            if (pastas.length < 1) {
              toast.error(`Não encontrou pastas do usuário`);
              return false;
            }
            this.setState({ pastas: pastas });

            this.state.pastas.map((pasta, index) => {
              var element = document.getElementById('ElementoFavorito' + index);
              if (element !== undefined) {
                element.style.backgroundColor = pasta.nome;
                element.href = '/pastas/' + pasta.id;
              }
            });
          }
        })
        .catch((err) => {
          toast.error(`Erro ao tentar abrir pastas do usuário: ${err}`);
        });
    }
  }

  render() {
    const checkClick = () => {
      var ul = document.getElementById('ListaFavoritos');
      ul.onclick = function (event) {
        var target = event.target;
        //alert(event.target.innerHTML);

        var text = event.target.innerHTML;
        var regex = /[0-9]+(?=")/;

        var match = regex.exec(text);
        //alert(match);
        //console.log(match[0]);
        //console.log(regex.lastIndex);
      };
    };

    return (
      <div className={this.isLogin ? 'box box-danger' : 'd-none '}>
        <div className='box-header with-border box-successClaro'>
          <h3 className='box-title'>
            <a
              role='button'
              data-toggle='collapse'
              href='#boxFavoritos'
              aria-expanded='true'
              aria-controls='boxFavoritos'
            >
              <i className='fas fa-star' aria-hidden='true'></i>
              &nbsp;Favoritos
            </a>
          </h3>
        </div>
        {/* fim box-header */}

        <div
          id='boxFavoritos'
          className='collapse show'
          data-parent='#boxFavoritos'
        >
          <div className='box-body no-padding'>
            <ul
              className='nav flex-column nav-stacked'
              id={'ListaFavoritos'}
              key={'ListaFavoritos'}
            >
              {this.state.pastas.map((pasta) => {
                return (
                  <li
                    className={'nav-item '}
                    id={'Item' + pasta.id}
                    key={'Item' + pasta.id}
                  >
                    <a
                      className='nav-link d-flex justify-content-between'
                      key={'ElementoFavorito' + pasta.id}
                      id={'ElementoFavorito' + pasta.id}
                      href={'/pastas/' + pasta.id}
                    >
                      {pasta.nome}
                      <span
                        id={'Favorito' + pasta.id}
                        className='favoritos-ico'
                        onClick={checkClick()}
                      ></span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* fim box-body */}
        </div>
        {/* fim menu */}
      </div>
    );
  }
}

export class BoxFavoritosMinifyed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pastas: [],
    };

    this.usuarioPastaService = new UsuarioPastaService();

    this.usuario = Authentication.getUsuario();
    this.isLogin = Authentication.isLogin();
  }

  componentDidMount() {
    var usuarioId = this.usuario.usuario_id_tjdocs;
    if (usuarioId !== undefined) {
      this.usuarioPastaService
        .listar(usuarioId)
        .then((response) => {
          if (typeof response.data._embedded !== 'undefined') {
            const pastas = response.data._embedded.pastas;

            if (pastas.length < 1) {
              toast.error(`Não encontrou pastas do usuário`);
              return false;
            }
            this.setState({ pastas: pastas });

            this.state.pastas.map((pasta, index) => {
              var element = document.getElementById('ElementoFavorito' + index);
              if (element !== undefined) {
                element.style.backgroundColor = pasta.nome;
                element.href = '/pastas/' + pasta.id;
              }
            });
          }
        })
        .catch((err) => {
          toast.error(`Erro ao tentar abrir pastas do usuário: ${err}`);
        });
    }
  }

  render() {
    const checkClick = () => {
      var ul = document.getElementById('ListaFavoritos');
      ul.onclick = function (event) {
        var target = event.target;
        //alert(event.target.innerHTML);

        var text = event.target.innerHTML;
        var regex = /[0-9]+(?=")/;

        var match = regex.exec(text);
        //alert(match);
        //console.log(match[0]);
        //console.log(regex.lastIndex);
      };
    };

    return (
      <div className={this.isLogin ? 'box box-danger' : 'd-none '}>
        <div className='box-header with-border box-successClaro'>
          <h3 className='box-title text-center'>
            <i className='fas fa-star' aria-hidden='true'></i>
          </h3>
        </div>
        {/* fim box-header */}

        <div
          id='boxFavoritos'
          className='collapse show'
          data-parent='#boxFavoritos'
        >
          <div className='box-body no-padding'>
            <ul
              className='nav flex-column nav-stacked'
              id={'ListaFavoritos'}
              key={'ListaFavoritos'}
            >
              {this.state.pastas.map((pasta) => {
                return (
                  <li
                    className={'nav-item '}
                    id={'Item' + pasta.id}
                    key={'Item' + pasta.id}
                  >
                    <a
                      className='nav-link d-flex justify-content-between'
                      key={'ElementoFavorito' + pasta.id}
                      id={'ElementoFavorito' + pasta.id}
                      href={'/pastas/' + pasta.id}
                      title={pasta.nome}
                    >
                      <i className='fas fa-star'></i>
                      <span
                        id={'Favorito' + pasta.id}
                        className='favoritos-ico'
                        onClick={checkClick()}
                      ></span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* fim box-body */}
        </div>
        {/* fim menu */}
      </div>
    );
  }
}
