import {Link} from "react-router-dom";
import React from "react";

export function get_path(path) {
    let p = [(<Link to={{pathname:"/"}} key={0}>
        &nbsp;<i className="fas fa-home"></i>
    </Link>)];
    if(path == null) {
        return p;
    } else {
        let i = 1;
        path.forEach(function(v) {
            if(v.nome != "/"){
                p.push((<Link to={{pathname:'/pastas/' + v.id}} key={i}>
                    {v.nome}
                </Link>))
            i++;
            }
        });
        return p;
    }

}