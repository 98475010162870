import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import { SeachComponent } from '../components/SearchComponent';
import TjdocsApi from '../api/API';
import toast from 'react-hot-toast';
import DocumentoEtiquetaService from '../api/service/documentoEtiquetaService';

export default class EtiquetasContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultado: [],
      paginacao: {},
      activePage: 1,
    };

    this.documentoEtiquetaService = new DocumentoEtiquetaService();
  }

  componentDidMount() {
    if (this.props.match.params.tokens)
      this.loadComponent(this.props.match.params.tokens);
    else if (this.props.match.params.etiqueta_id)
      this.loadComponentEtiquetas(this.props.match.params.etiqueta_id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.tokens !== this.props.match.params.tokens)
      this.loadComponent(this.props.match.params.tokens);
  }

  loadComponent = (tokens) => {
    TjdocsApi.pesquisa(tokens, 0)
      .then((resp) => {
        this.setState({
          resultado: resp,
          //paginacao: resp.paginacao,
        });
      })
      .catch((error) => toast.error(`Erro ao abrir pasta: ${error}`));
  };

  loadComponentEtiquetas = (etiqueta_id) => {
    this.documentoEtiquetaService.listarDocumentos(etiqueta_id).then(
      (res) => {
        //console.log(">>>> documentos: ", res) ; debugger;
        if (res.data._embedded)
          this.setState({ resultado: res.data._embedded.documentos });
        else toast.error(`Sem documentos para a etiqueta`);
      },
      //).catch(err => toast.error(`Erro ao tentar abrir documentos do usuário: ${err}`));
    );
  };

  handlePageChange = (page_number) => {
    let offset = (page_number - 1) * 20;
    TjdocsApi.pesquisa(this.props.match.params.tokens, offset).then((resp) => {
      this.setState({
        resultado: resp.resultado,
        paginacao: resp.paginacao,
        activePage: page_number,
      });
    });
  };

  displayPagination = () => {
    if (this.state.paginacao.total > 20) {
      return (
        <Pagination
          activeClass='active'
          activeLinkClass='disabled'
          activePage={false}
          disabledClass='disabled'
          firstPageText='<<'
          innerClass='pagination p-c-b-paginacao'
          itemClass='page-item p-c-b-pagnc-paginas'
          itemsCountPerPage={this.state.paginacao.max_result}
          lastPageText='>>'
          linkClass='page-link'
          nextPageText='>'
          onChange={this.handlePageChange}
          pageRangeDisplayed={10}
          prevPageText='<'
          totalItemsCount={this.state.paginacao.total}
        />
      );
    }
  };

  render() {
    return (
      <div className='p-contd-busca'>
        <div
          className='p-c-busc-quantificacao'
          style={{
            display:
              this.props.match.params.tokens !== undefined ? 'block' : 'none',
          }}
        >
          Sua busca por '{this.props.match.params.tokens}' obteve{' '}
          {this.state.resultado.length} resultados.
        </div>

        <div
          className='p-c-busc-quantificacao'
          style={{
            display:
              this.props.match.params.etiqueta_id !== undefined
                ? 'block'
                : 'none',
          }}
        >
          Sua busca em '/etiqueta/{this.props.match.params.etiqueta_id}' obteve{' '}
          {this.state.resultado.length} resultados.
        </div>

        <h4 className='sr-only' tabIndex='0' id='P-Inicio_DisplayMiniaturas'>
          Resultados da pesquisa:
        </h4>

        {this.state.resultado.map((arquivo, i) => {
          var highlight = '';
          if (arquivo.highlight)
            highlight = arquivo.highlight.substring(
              11,
              arquivo.highlight.length - 2,
            ); //Remove {conteudo=[ ]}
          if (!arquivo.texto)
            arquivo.texto =
              arquivo.nome !== undefined ? arquivo.nome : 'Nome arquivo';
          if (!arquivo.fonte) arquivo.fonte = 'documento';
          if (arquivo.documentoId !== undefined)
            arquivo.id = arquivo.documentoId;

          return (
            <SeachComponent
              key={i}
              id={arquivo.id}
              nomeArquivo={arquivo.texto}
              path=''
              resumo={highlight}
              data={arquivo.dataCadastro}
              tamanho={arquivo.tamanho}
              tipo={arquivo.fonte + 's'}
              local={arquivo.local}
            />
          );
        })}

        {/*this.displayPagination()*/}
      </div>
    );
  }
}
