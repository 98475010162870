import React, { Component } from 'react';
import toast from 'react-hot-toast';
import TjdocsApi from '../api/API';

import PastaService from '../api/service/pastaService';
import UsuarioPastaService from '../api/service/usuarioPastaService';

import Authentication from '../Authentication';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

export class MenuDominioPrincipal extends Component {
  constructor(props) {
    super(props);
    //this.adicionarFavorito = this.adicionarFavorito.bind(this);
    this.usuarioPastaService = new UsuarioPastaService();
    this.pastaService = new PastaService();

    this.usuario = Authentication.getUsuario();
    this.isLogin = Authentication.isLogin();
  }

  timeOutInputPesquisaNaPasta = setTimeout(() => {}, 100);

  loadOptionsPesquisaNaPasta = (inputPesquisaNav, callback) => {
    if (inputPesquisaNav.length >= 3) {
      clearTimeout(this.timeOutInputPesquisaNaPasta);
      const timeOut = setTimeout(() => {
        TjdocsApi.pesquisaPesquisaTotalNaPasta(
          inputPesquisaNav,
          this.props.idPasta,
        )
          .then((jsonPesquisa) => {
            var retorno = jsonPesquisa.map((i, index) => ({
              label:
                '<span class="optionPesquisaPrincipal">' +
                (i.fonte === 'pasta'
                  ? '<i class="fa fa-folder"></i> '
                  : '<i class="fa fa-file"></i> ') +
                i.texto +
                '</span>' +
                (i.highlight === null
                  ? ''
                  : '<br/>' +
                    i.highlight.substring(11, i.highlight.length - 2)),
              value: '/' + i.fonte + 's/' + i.id,
            }));
            callback(retorno);
          })
          .catch((error) =>
            toast.error(`Erro ao tentar pesquisar termos: ${error}`),
          );
      }, 2000);
      this.timeOutInputPesquisaNaPasta = timeOut;
    }
  };

  onChangeFunc = (optionSelected) => {
    if (document.querySelector('.loading'))
      document.querySelector('.loading').style.display = 'block';
    window.location.href = optionSelected.value;
  };

  /*
    adicionarFavorito = () => {
       alert(this.props.idPasta);
    }
    */

  render() {
    var exibicaoLista = 'icones ';
    var exibicaoGrade = 'icones ';
    if (typeof Storage !== 'undefined') {
      if (typeof localStorage.exibicao !== 'undefined') {
        if (localStorage.exibicao === 'lista') {
          exibicaoGrade += 'd-none';
          exibicaoLista += '';
        } else {
          exibicaoGrade += '';
          exibicaoLista += 'd-none';
        }
      } else {
        localStorage.exibicao = 'miniaturas';
        exibicaoGrade += '';
        exibicaoLista += 'd-none';
      }
    }

    function gradeParaLista() {
      document.getElementById('alterar_P-DisplayLista').classList.add('d-none');
      document
        .getElementById('alterar_P-DisplayMiniaturas')
        .classList.remove('d-none');

      document.getElementById('P-DisplayLista').classList.remove('d-none');
      document.getElementById('P-DisplayMiniaturas').classList.add('d-none');
      if (typeof Storage !== 'undefined') localStorage.exibicao = 'lista';
    }

    function listaParaGrade() {
      document
        .getElementById('alterar_P-DisplayLista')
        .classList.remove('d-none');
      document
        .getElementById('alterar_P-DisplayMiniaturas')
        .classList.add('d-none');

      document.getElementById('P-DisplayLista').classList.add('d-none');
      document.getElementById('P-DisplayMiniaturas').classList.remove('d-none');
      if (typeof Storage !== 'undefined') localStorage.exibicao = 'miniaturas';
    }

    return (
      <div className='p-contd-menuTopo d-flex align-items-center row'>
        <div className={this.isLogin ? 'col-md-12' : 'd-none '}>
          <div className='p-c-menTp-Endereco flex-grow-1'>
            <NovaPasta
              idPasta={0}
              state={this.props.state}
              loadComponent={this.props.loadComponent}
              podeAdicionarRemoverPasta={this.usuario.authorities}
              atualiza={this.props.atualiza}
            />

            {/*Colar*/}
            <nobr style={{ display: 'none' }}>
              <a
                className='btn-primary btn'
                style={{ position: 'relative', top: '-4px' }}
                title='Colar'
                onClick={(e) => console.log('ma ooe')}
              >
                Colar
              </a>
              <a
                className='btn-danger btn'
                style={{
                  position: 'relative',
                  top: '-4px',
                  color: '#fff',
                  borderRadius: '12px',
                  padding: '0px 6px',
                  marginLeft: '2px',
                }}
                title='Cancelar Colar'
                onClick={(e) => console.log('ma ooe')}
              >
                <i className='fas fa-times'></i>
              </a>
            </nobr>
            {/*Colar*/}
          </div>
        </div>
      </div>
    );
  }
}

class NovaPasta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      nomePasta: '',
      publica: false,
      modalArquivoRepetido: false,
      mensagemRepetida: false,
      nomePastaAtual: '',
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModalArquivoRepetido = this.closeModalArquivoRepetido.bind(this);
    this.handleNomePasta = this.handleNomePasta.bind(this);
    this.criarPasta = this.criarPasta.bind(this);
  }

  handleClose() {
    this.setState({ modal: false });
  }

  handleShow(e) {
    e.preventDefault();
    this.setState({ modal: true });
  }

  handleSubmit(e) {
    e.preventDefault();
    TjdocsApi.existeDuplicidadePasta(
      this.state.nomePasta,
      this.props.idPasta,
    ).then((duplicado) => {
      console.log(duplicado);
      if (duplicado) {
        this.setState({ nomePastaAtual: this.state.nomePasta });
        this.setState({ modalArquivoRepetido: true });

        return false;
      } else {
        TjdocsApi.criaPasta(
          this.props.idPasta,
          this.state.nomePasta,
          this.state.publica,
        ).then((response) => {
          console.log('teste');
          this.props.atualiza();
        });
        this.setState({ modal: false });
        toast.success('Sucesso!');
      }
    });
  }

  handleChange(event) {
    if (event.target.id === 'nome_pasta') {
      let nome = event.target.value;
      this.setState({ nomePasta: nome });
    }

    if (event.target.id === 'pasta_publica') {
      let publica = event.target.checked;
      this.setState({ publica: publica });
    }
  }

  closeModalArquivoRepetido() {
    this.setState({ modalArquivoRepetido: false });
  }

  handleNomePasta(event) {
    let nome = event.target.value;
    console.log(nome);
    this.setState({ nomePasta: nome });
  }

  criarPasta() {
    TjdocsApi.existeDuplicidadePasta(
      this.state.nomePasta,
      this.props.idPasta,
    ).then((duplicado) => {
      console.log(duplicado);
      if (duplicado) {
        this.setState({ modalArquivoRepetido: true });
        this.setState({ mensagemRepetida: true });
        this.setState({ nomePastaAtual: this.state.nomePasta });
        return false;
      } else {
        TjdocsApi.criaPasta(
          this.props.idPasta,
          this.state.nomePasta,
          this.state.publica,
        ).then((response) => {
          this.props.atualiza();
        });
        this.setState({ modal: false });
        this.setState({ modalArquivoRepetido: false });
        this.setState({ mensagemRepetida: false });
        toast.success('Sucesso!');
      }
    });
  }

  render() {
    const { modal } = this.state;
    return (
      <>
        <a
          className={
            this.props.podeAdicionarRemoverPasta &&
            this.props.podeAdicionarRemoverPasta.includes('ADMINISTRADOR')
              ? 'icones'
              : 'd-none '
          }
          title='Nova Pasta'
          onClick={this.handleShow}
        >
          {' '}
          <i className='fas fa-folder-plus'></i>{' '}
        </a>

        <Modal show={modal} onHide={this.handleClose} size='sm'>
          <Modal.Header closeButton>
            <Modal.Title>Nova Pasta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='1'>
              <div key='nome'>
                <Form.Control
                  required
                  type='text'
                  id='nome_pasta'
                  placeholder='Nome Nova pasta'
                  ref='nome'
                  onChange={(e) => this.handleChange(e)}
                />
              </div>

              <Form.Check
                type='checkbox'
                id='pasta_publica'
                label='&nbsp;&nbsp;Pasta Pública'
                onChange={(e) => this.handleChange(e)}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleClose}>
              Fechar
            </Button>
            <Button variant='primary' onClick={this.handleSubmit}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>
        <ValidadorPastaRepetida
          idPasta={this.props.idPasta}
          nomePasta={this.state.nomePastaAtual}
          state={this.props.state}
          nomeArquivo={this.state.arquivo}
          modal={this.state.modalArquivoRepetido}
          close={this.closeModalArquivoRepetido}
          handleNomePasta={this.handleNomePasta}
          editarPasta={this.criarPasta}
          mensagemRepetida={this.state.mensagemRepetida}
        />
      </>
    );
  }
}

class ValidadorPastaRepetida extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      nomePasta: '',
      pastaPai: '',
      publica: false,
      renomearPasta: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose() {
    this.props.close();
  }

  handleShow(e) {
    e.preventDefault();
  }

  loadState(response) {
    this.setState({ nomePasta: response.nome });
  }

  handleSubmit(e) {
    e.preventDefault();

    TjdocsApi.editarPasta(
      this.props.idPasta,
      this.state.nomePasta,
      this.state.pastaPai,
      this.state.publica,
    ).then((response) => this.props.loadComponent(this.props.idPasta));
    this.setState({ modal: false });
    toast.success('Sucesso!');
  }

  handleChange(event) {
    if (event.target.id === 'nome_pasta') {
      let nome = event.target.value;
      this.setState({ nomePasta: nome });
    }

    if (event.target.id === 'pasta_publica') {
      let publica = event.target.checked;
      this.setState({ publica: publica });
    }
  }

  renomearPasta() {
    this.setState({ renomearPasta: !this.state.renomearPasta });
  }

  render() {
    const { modal } = this.props;
    const { nomePasta } = this.props;

    return (
      <>
        <Modal show={modal} onHide={this.handleClose} size='sm'>
          <Modal.Header closeButton>
            <Modal.Title>Pasta Repetida!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.mensagemRepetida && (
              <div style={{ color: '#ff0000' }}>
                O destino {nomePasta} já existe.
              </div>
            )}
            {!this.props.mensagemRepetida &&
              `O destino ${nomePasta} já existe.`}{' '}
            <br />
            Como deseja prosseguir?
            <br />
            <br />
            {/*<Button variant="secondary" style={{width: "280px", marginBottom: "5px"}} onClick={() => {this.props.editarPasta() }} >
                            Substituir pasta no destino
                        </Button><br />*/}
            <Button
              variant='secondary'
              style={{ width: '280px', marginBottom: '5px' }}
            >
              <span
                onClick={() => {
                  this.renomearPasta();
                }}
              >
                Renomear pasta e enviar&nbsp;▼
              </span>
              {this.state.renomearPasta && (
                <div>
                  <Form.Control
                    required
                    type='text'
                    id='nome_pasta'
                    placeholder='Nome Nova Pasta'
                    ref='nome'
                    style={{ width: '230px', float: 'left' }}
                    onChange={this.props.handleNomePasta}
                  />
                  <i
                    className='fas fa-save'
                    onClick={this.props.editarPasta}
                  ></i>
                </div>
              )}
            </Button>
            <br />
            <Button
              variant='secondary'
              style={{ color: 'red', width: '280px', marginBottom: '5px' }}
              onClick={this.handleClose}
            >
              Cancelar operação
            </Button>
          </Modal.Body>

          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    );
  }
}
