import React, { Component } from 'react';
import { MiniDocView } from '../components/MiniDocView';
import { MiniDocViewLista } from '../components/MiniDocViewLista';
import { DominioIconLista } from '../components/DominioIconLista';
import { DominioIcon } from '../components/DominioIcon';
import * as PropTypes from 'prop-types';
import { get_path } from '../helper/Helper';
import { MenuTopo } from '../components/MenuTopo';

export class MainContainer extends Component {
  constructor(props) {
    super(props);
    this.atualizaComponente = this.atualizaComponente.bind(this);
  }

  componentDidMount() {
    let path = get_path(null);
    this.props.handlePath(path);
  }

  atualizaComponente() {
    this.props.atualizaDominios();
  }

  ordenaDadosPastas = (field, order) => {
    this.props.ordenaDadosPastas(field, order);
  };

  salvarOrdenacao = (field, order) => {
    this.props.salvarOrdenacao(field, order);
  };

  filtrarPorNome = (event) => {
    this.props.filtrarPorNome(event.target.value);
  };

  miniDocView() {
    return (
      <div className='p-cntd-pasts-miniaturas d-inline-flex flex-wrap'>
        {this.props.dominios.map((dado, i) => (
          <DominioIcon
            listaGestores={dado.listaGestores}
            id={dado.id}
            nome={dado.nome}
            updatedAt={dado.updatedAt}
            createdAt={dado.createdAt}
            nomeGestor={dado.nomeGestor}
            contatoGestor={dado.contatoGestor}
            tamanho={dado.tamanho}
            totalArquivos={dado.totalArquivos}
            key={i}
            nomeVisibilidade={dado.visibilidadeNome}
          />
        ))}
      </div>
    );
  }

  render() {
    return (
      <div>
        <MenuTopo
          path={this.props.path}
          atualiza={this.atualizaComponente}
          setSearchResultsView={this.props.setSearchResultsView}
          showSearchResults={this.props.showSearchResults}
          setFoldersView={this.props.setFoldersView}
        />
        <MiniDocView children={this.miniDocView()} />
        <MiniDocViewLista
          ordenaDados={this.ordenaDadosPastas}
          salvarOrdenacao={this.salvarOrdenacao}
          colunaOrdenada={this.props.colunaOrdenada}
          ordem={this.props.ordem}
          children={this.props.dominios.map((dado, i) => (
            <DominioIconLista
              listaGestores={dado.listaGestores}
              id={dado.id}
              nome={dado.nome}
              updatedAt={dado.updatedAt}
              createdAt={dado.createdAt}
              nomeGestor={dado.nomeGestor}
              contatoGestor={dado.contatoGestor}
              tamanho={dado.tamanho}
              totalArquivos={dado.totalArquivos}
              key={i}
              nomeVisibilidade={dado.visibilidadeNome}
            />
          ))}
        />
      </div>
    );
  }
}

MainContainer.propTypes = {
  dominios: PropTypes.arrayOf(PropTypes.any),
  handlePath: PropTypes.func,
};
