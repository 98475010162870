import React, { Component } from "react";
import toast from 'react-hot-toast';
import TjdocsApi from "../api/API";
import Authentication from "../Authentication";
import BuscaAvancadaContainer from "../containers/BuscaAvancadaContainer";
import ExcluirArquivo from "./ExcluirArquivo";

import { CKEditor } from 'ckeditor4-react';
import ListarVinculo from "./ListarVinculo";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";


export class FileView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      permissaoUsuario: null, exibeModalLoading: false, adicionarRemoverArquivo: false, editarArquivo: false
    };
    this.usuario = Authentication.getUsuario();
    this.isLogin = Authentication.isLogin();
    this.descDoc = "";
    this.ckeditor = "";
  }

  excluirDocumento(id) {
    TjdocsApi.excluiDocumento(id).then(jsonDocumento => {
      toast.success(`Documento excluído com sucesso!`);
    }
    ).catch((error) => { toast.error(`Erro ao excluir documeto: ${error}`) });
  }

  componentDidMount() {

    this.descDoc = this.props.documento.descricao;
    if (this.ckeditor.setData) {

      this.ckeditor.setData(this.props.documento.descricao);
      this.ckeditor.title = "TJDOCS - " + this.props.documento.nome;

    }

    //window.addEventListener("message", this.receiveMessage, false);

  }

  validaPermissao(idPasta) {

    if (this.state.permissaoUsuario == undefined && this.state.permissaoUsuario == null) {
      console.log(this.props.documento);
      TjdocsApi.listaPermissoesPorDominioPastasPorIdUsuario(idPasta, Authentication.getUsuario().usuario_id_tjdocs).then(response => {
        if (response == null || response._embedded == null) return;
        console.log(response._embedded.dominioUsuarioOutputList);
        this.setState({ permissaoUsuario: response._embedded.dominioUsuarioOutputList[0] })
        this.setState({ adicionarRemoverArquivo: response._embedded.dominioUsuarioOutputList.some(dominio => dominio.adicionarRemoverArquivo) });
        this.setState({ editarArquivo: response._embedded.dominioUsuarioOutputList.some(dominio => dominio.editarArquivo) });

        console.log("permissoes", this.state.permissaoUsuario);
      }
      );
    }

  }

  componentDidUpdate() {
    this.validaPermissao(this.props.documento.idPasta);
    this.descDoc = this.props.documento.descricao;
    if (this.ckeditor.setData) {

      this.ckeditor.setData(this.props.documento.descricao);
      this.ckeditor.title = "TJDOCS - " + this.props.documento.nome;
    }



  }

  apagarCampoDescricao() {
    this.ckeditor.setData("");
  }

  exibeModalLoading() {
    this.setState({ exibeModalLoading: true });
  }

  fecharModalLoading() {
    this.setState({ exibeModalLoading: false });
  }

  ocrFile(id) {
    this.exibeModalLoading();
    TjdocsApi.extrairTextoDocumentoPDF(id).then(jsonDocumento => {
      document.querySelector(".loading").style.display = "block";
      this.props.loadArquivo(this.props.documento.id);
      this.fecharModalLoading();
      toast.success('Sucesso!');
    }
    ).catch((error) => {
      this.fecharModalLoading();
      toast.error(`Erro ao extrair texto: ${error}`)
      if (document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
    });
  }

  urlAutenticacao() {
    return "?token_corporativo=" + sessionStorage.getItem('tokenCorporativo') + "&id_sistema=" + sessionStorage.getItem('idSistema') + "&usr_corporativo=" + sessionStorage.getItem('usrCorporativo');
  }

  render() {
    console.log(this.props.documento);
    return <div>
      <div className="container-fluid">
        <div className="row-fluid">
          <div className="container-fluid">
            <div className="row">
              <div id="right-props" className="col-md-12">

                <h3 className="attr-field" style={{ margin: "10px 0" }}>
                  <i className="fa fa-file"></i> {this.props.documento.nome} &nbsp;

                  {/*Etiquetas*/}
                  {this.props.etiquetas.map((etiqueta, i) => {
                    return (<span><span className="etiquetas-ico" title={etiqueta.titulo} style={{ backgroundColor: etiqueta.codigoCor, fontSize: "14px", padding: "1px 11px", top: "-2px", position: "relative" }}></span>&nbsp;</span>)
                  })}

                </h3>

                <div className="row fileViewInfoBox form-group">
                  <div className="col-md-6">

                    {this.props.vinculadores.length == 0
                      &&
                      <div className="attr-field" style={{ fontSize: "17px", fontWeight: "bold" }}>
                        Não existe documento que altera/revoga esse documento
                      </div>
                    }

                    <div className="attr-field">
                      Enviado por: {this.props.documento.usuarioNome}
                    </div>

                    <div className="attr-field">
                      Data/Hora do envio:&nbsp;{this.props.documento.dataCadastro}

                    </div>

                    <div className="attr-field">
                      Tamanho: {this.props.documento.tamanho / 1000} Kbytes
                    </div>

                  </div>

                  <div className="col-md-6">

                    <div className="attr-field">
                      Visibilidade do Documento: {this.props.documento.nomeVisibilidade}
                    </div>
                    <div className="attr-field">
                      MD5: {this.props.documento.resumo}
                    </div>

                    <div className="attr-field">
                      Quantidade de Visualizações: {this.props.documento.qtdVisualizacoes}
                    </div>

                    <div className="attr-field">
                      Quantidade de Downloads: {this.props.documento.qtdDownloads}
                    </div>
                  </div>

                  <div className="attr-field" style={{ paddingLeft: "15px" }}>
                    <span style={{ fontWeight: "bold" }} >Descrição:</span> {this.props.documento.descricaoDocumento}
                  </div>

                </div>

                <div className="row">
                  {/*Vinculadores*/}
                  {this.props.vinculadores.map((vinculador, i) => {
                    return (<><div className="fileViewInfoBox form-group" style={{ listStyle: "none", padding: "7px 10px" }}>
                      <span className="etiquetas-ico" style={{ backgroundColor: `${vinculador.tipo == "Revoga" ? "#ae301e" : "#7CFC00"}`, padding: "4px 8px" }}>
                        <i className="far fa-file-alt" />
                      </span>&nbsp;
                      <span className="fa" style={{ fontSize: "19px" }} >{vinculador.tipo == null ? "" : vinculador.tipo == "Torna sem Efeito" ? "Tornado sem Efeito por " : vinculador.tipo == "Suspende" ? "Suspenso por" : vinculador.tipo + "do por "} <a style={{ color: "#009abf" }} href={`/documentos/${vinculador.documentoNovoId + this.urlAutenticacao()}`} target="_blank" >{vinculador.documentoNovoNome}</a></span><br />
                      <span className="fa" style={{ fontSize: "19px" }} >Descrição</span>&nbsp;{vinculador.documentoNovoDescricaoDocumento}<br />
                      <span className="fa" style={{ fontSize: "19px" }} >N° Lei / Decreto </span>&nbsp;{vinculador.documentoNovoNumDecreto} {vinculador.documentoNovoNumLei}<br />
                      <span className="fa" style={{ fontSize: "19px" }} >Ano </span>&nbsp;{vinculador.documentoNovoAno}
                    </div>&nbsp;</>)
                  })}
                  {/*Vinculos */}
                  {this.props.vinculos.map((vinculos, i) => {
                    return (<><div className="fileViewInfoBox form-group" style={{ listStyle: "none", padding: "7px 10px" }}>
                      <span className="etiquetas-ico" style={{ backgroundColor: `${vinculos.tipo == "Revoga" ? "#ae301e" : "#7CFC00"}`, padding: "4px 8px" }}>
                        <i className="far fa-file-alt" />
                      </span>&nbsp;
                      <span className="fa" style={{ fontSize: "19px" }} >{vinculos.tipo} <a style={{ color: "#009abf" }} href={`/documentos/${vinculos.documentoVinculadoId + this.urlAutenticacao()}`} target="_blank" >{vinculos.documentoVinculadoNome}</a></span><br />
                      <span className="fa" style={{ fontSize: "19px" }} >Descrição</span>&nbsp;{vinculos.documentoVinculadoDescricaoDocumento}<br />
                      <span className="fa" style={{ fontSize: "19px" }} >N° Lei / Decreto </span>&nbsp;{vinculos.documentoVinculadoNumDecreto} {vinculos.documentoVinculadoNumLei}<br />
                      <span className="fa" style={{ fontSize: "19px" }} >Ano </span>&nbsp;{vinculos.documentoVinculadoAno}
                    </div>&nbsp;</>)
                  })}
                </div>

                <fieldset>
                  <legend></legend>
                  <CKEditor
                    initData={this.props.documento.descricao}
                    onBeforeLoad={(CKEDITOR) => {
                      CKEDITOR.plugins.addExternal("textindent", "/ckeditor4/plugins/textindent/", "plugin.js");
                      CKEDITOR.plugins.addExternal("abnt", "/ckeditor4/plugins/abnt/", "plugin.js");
                      CKEDITOR.plugins.addExternal("brasao", "/ckeditor4/plugins/brasao/", "plugin.js");
                      CKEDITOR.plugins.addExternal("openlink", "/ckeditor4/plugins/openlink/", "plugin.js");
                    }}

                    onDataReady={(CKEDITOR) => {         
                      setTimeout(() => {
                          if(document.querySelector(".cke_notification_close")) document.querySelector(".cke_notification_close").click();
                      }, 500);                                                               
                    }}

                    config={{
                      toolbar: [
                        ['Print', 'textindent', 'abnt', 'lineheight', 'PasteFromWord'
                          , 'Format', 'Font', 'FontSize', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting', 'TextColor', 'BGColor'
                          , 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'
                          , 'Link', 'Unlink'
                          , 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'
                          , 'Image', 'Table'
                        ]],
                      extraPlugins: ['textindent', 'abnt', 'print', 'justify', 'brasao', 'font', 'openlink'],
                      readOnly: true,
                    }}

                    onInstanceReady={evt => {

                      this.ckeditor = evt.editor;
                      document.querySelectorAll("a.cke_combo_button").forEach(element => {
                        element.style.display = 'none';
                      });

                      document.querySelectorAll("a.cke_button").forEach(element => {

                        if (!element.classList.contains('cke_button__print')) {
                          element.style.display = 'none';
                        }
                      }
                      );
                      this.ckeditor.setData(this.descDoc);


                    }}

                    onContentDom={evt => {
                      evt.editor.editable().attachListener(evt.editor.editable(), 'click', function (e) {
                        let target = e.data.getTarget();
                        if (typeof target.$.href != 'undefined') {
                          window.open(target.$.href, 'new' + e.screenX);
                        }

                      });
                    }}

                  />
                </fieldset>

                <fieldset>
                  <legend></legend>
                  {(this.props.documento.id && this.props.documento.extensao != "pdf" && this.props.documento.extensao != null) &&
                    <iframe id="collabora-online-viewer" name="collabora-online-viewer" style={{ height: "500px", width: "100%" }} class="vbox" src={`${TjdocsApi.urlLibreOffice()}/browser/fc0de5b/cool.html?WOPISrc=${TjdocsApi.urlWOPI()}/v1/wopi/files/${this.props.documento.id}&permission=readonly&lang=pt-br`} />
                  }
                </fieldset>


                <div className="col-md-12">
                </div>

                <div className="attr-field" style={{ margin: "5px -15px" }}>
                  {this.props.documento.download !== 'null' && <span>
                    <a href={this.props.documento.urlUpload} className="btn btn-primary btn-lg">
                      <i className="fa fa-download"></i> Download Original
                    </a>
                    &nbsp;
                  </span>}

                  {((this.state.permissaoUsuario != null && (this.state.adicionarRemoverArquivo || this.state.editarArquivo) && this.props.documento.extensao != "pdf") || (this.usuario.authorities)) &&

                    <EditarArquivo documento={this.props.documento} loadArquivo={this.props.loadArquivo} logado={this.isLogin} vinculos={this.props.vinculos} />

                  }

                  {((this.state.permissaoUsuario != null && this.state.adicionarRemoverArquivo) || (this.usuario.authorities && this.usuario.authorities.includes('ADMINISTRADOR'))) &&

                    <ExcluirArquivo documento={this.props.documento} logado={this.isLogin} />


                  }

                  <ModalLoading exibeModal={this.state.exibeModalLoading} />

                  <span>
                    <a href="#!" className="btn btn-success btn-lg" onClick={() => window.history.back()}>
                      <i className="fa fa-arrow-left"></i> Voltar
                    </a>
                  </span>

                  {(this.props.documento.download !== 'null' && this.props.documento.extensao == "pdf" && ((this.state.permissaoUsuario != null && (this.state.adicionarRemoverArquivo || this.state.editarArquivo)) || (this.usuario.authorities && this.usuario.authorities.includes('ADMINISTRADOR')))) && <span>&nbsp;
                    <a href="#" className="btn btn-primary btn-lg" onClick={() => this.ocrFile(this.props.documento.id)} >
                      <i className="fa fa-file"></i> OCR
                    </a>

                  </span>}

                  {/*this.props.documento.download !== 'null' && <span>&nbsp;
                      <a href="#" className="btn btn-primary btn-lg" onClick={() => this.apagarCampoDescricao()} >
                        <i className="fa fa-eraser"></i> Apagar
                      </a>
                    
                </span>*/}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row-fluid">
          <div className="container-fluid">
            <div className="row">
              <embed src={this.props.documento.urlUploadPdf} width="100%" height="1200" type="application/pdf" />
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}


class EditarArquivo extends React.Component {

  constructor(props) {
    super(props);
    this.state = { modal: false, nomeArquivo: "", ano_filtro: "", descricaoDocumento: "", numDecreto: "", numLei: "", descricao: "", idVisibilidade: "", arquivosVinculados: [], tipoVinculo: "", dados: [] };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitVinculos = this.handleSubmitVinculos.bind(this);
    this.usuario = Authentication.getUsuario();
    this.isLogin = Authentication.isLogin();

  }

  handleClose() {
    this.setState({ modal: false });
  }

  handleShow(e) {
    e.preventDefault();
    this.setState({ modal: true });

    this.loadState(this.props.documento)



  }

  loadState(documento) {

    this.setState({ nomeArquivo: documento.nome })
    this.setState({ descricao: documento.descricao })
    this.setState({ idVisibilidade: documento.idVisibilidade })
    this.setState({ numDecreto: documento.numDecreto })
    this.setState({ numLei: documento.numLei })
    this.setState({ descricaoDocumento: documento.descricaoDocumento })
    this.setState({ ano_filtro: documento.ano })
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.state.nomeArquivo != null && this.state.nomeArquivo != "") {
      TjdocsApi.editarArquivo(this.props.documento.id, this.state.nomeArquivo, this.state.descricao, this.state.idVisibilidade, this.state.numDecreto, this.state.numLei, this.state.descricaoDocumento, this.state.ano_filtro).then(response => {
        this.props.loadArquivo(this.props.documento.id);
        this.editarDescricao();
        toast.success('Sucesso!');
        this.setState({ modal: false });
      }
      ).catch((error) => {
        if (document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
        toast.error(`Erro ao editar documeto: ${error}`)
      });
    }
    else {
      toast.error(`O nome do arquivo deve ser preenchido!`)
    }

  }

  handleSubmitVinculos(e) {
    e.preventDefault();
    if (this.state.tipoVinculo == "" || this.state.tipoVinculo == "Escolha...") {
      this.setState({ dados: [], arquivosVinculados: [] });
      toast.error(`Selecione o tipo de vinculo antes de prosseguir!`);
      return;
    }

    var arquivos = this.state.arquivosVinculados.map((i) => ({ id: null, documentoVinculado: i.documentoVinculado, tipo: this.state.tipoVinculo }))
    if (this.state.arquivosVinculados != [] && this.state.arquivosVinculados.length != 0) {

      TjdocsApi.inserirVinculoDocumento(this.props.documento.id, arquivos).then(response => {
        this.setState({ dados: [], arquivosVinculados: [] });
        this.props.loadArquivo(this.props.documento.id);
        toast.success('Sucesso!');
      }
      ).catch((error) => {
        if (document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
        toast.error(`Erro ao inserir vinculo: ${error}`)
      });
    }
    else {
      toast.error(`Selecione um arquivo para vincular`)
    }
  }

  handleChange(event) {

    this.setState({ [event.target.name]: event.target.value });

  }

  handleChangeVinculos(event) {
    this.setState({ tipoVinculo: event.target.value });
  }

  handleChangeDescricao(event) {
    let descricaoArquivo = "";

    if (event.editor) {
      descricaoArquivo = event.editor.getData();

    } else {

      descricaoArquivo = event.target.value;
    }

    this.setState({ descricao: descricaoArquivo });
  }

  handleSelectChangeVisibilidade(event) {

    let visibilidade = event.target.value;
    this.setState({ idVisibilidade: visibilidade });
  }

  handleSelectChangeArquivos = (data) => {
    if (data[0] != null && data[0] != "undefined") {
      var arquivos = [...this.state.arquivosVinculados, { documentoVinculado: { id: data[0].value.split('/')[2] } }];
      this.setState({ arquivosVinculados: arquivos });

      if (data[0].value.split('/')[2] == this.props.documento.id) {
        toast.error(`Não é possivel vincular o mesmo arquivo`);
        this.removeItemSelecionado(this.props.documento.id);
      }
    }

  }

  removeItemSelecionado = (id) => {
    const dadosAux = [...this.state.dados];
    dadosAux.splice(id, 1);
    this.setState({ dados: dadosAux });

    const arquivosVinculadosAux = [...this.state.arquivosVinculados];
    arquivosVinculadosAux.splice(id, 1);
    this.setState({ arquivosVinculados: arquivosVinculadosAux });
  }

  handleItensSelecionados = (data) => {
    if (document.querySelector(".loading")) document.querySelector(".loading").style.display = "block";
    if (data.length > 0) {
      document.getElementById('buscaAvancadaButtonUpload').click()
    }
    const dadosAux = [...this.state.dados, data];
    this.setState({ dados: dadosAux });
    this.handleSelectChangeArquivos(data);
  }

  post(msg) {
    console.log(msg);
    document.getElementById('edicaoTextoDescricao').contentWindow.postMessage(JSON.stringify(msg), '*');
  }

  editarDescricao() {
    this.post({
      "MessageId": "Action_Save",
      "Values": {
        "DontTerminateEdit": true,
        "DontSaveIfUnmodified": false,
        "Notify": false
      }
    });
  }

  handleNumLei(event) {
    let numLei = "";
    numLei = event.target.value;

    this.setState({ numLei: numLei });
  }

  handleNumDecreto(event) {
    let numDecreto = "";
    numDecreto = event.target.value;

    this.setState({ numDecreto: numDecreto });
  }

  handleDescricaoDocumento(event) {
    let descricaoDocumento = "";
    descricaoDocumento = event.target.value;

    this.setState({ descricaoDocumento: descricaoDocumento });
  }

  handleFiltroAno(event) {

    let ano = event.target.value;
    this.setState({ ano_filtro: ano });
  }


  render() {
    const { modal } = this.state;
    const { nomeArquivo } = this.state;
    const { descricao } = this.state;
    const { idVisibilidade } = this.state;
    const { numDecreto } = this.state;
    const { numLei } = this.state;
    const { descricaoDocumento } = this.state;
    const { ano_filtro } = this.state;
    const unidade_corporativo = this.usuario.corporativo_unidade_nome;

    return (
      <>
        <span>


          <a className={this.props.logado ? "btn btn-warning btn-lg" : "d-none"} onClick={this.handleShow}>
            <i className="fa fa-edit"></i> Editar
          </a>
          &nbsp;
        </span>


        <Modal show={modal} onHide={this.handleClose} dialogClassName={"largeModal"} enforceFocus={false} >
          <Modal.Header closeButton>
            <Modal.Title >Editar Arquivo</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Group controlId="1">
              <div key="nome">
                <Form.Label>Nome</Form.Label>

                <Form.Control required type="text" value={nomeArquivo} placeholder="Nome Arquivo" name="nomeArquivo" onChange={e => this.handleChange(e)} />
              </div>
            </Form.Group>

            <Form.Group controlId="1">
              <div key="descricao">
                <Form.Label>Descrição</Form.Label>
                <CKEditor
                  onBeforeLoad={(CKEDITOR) => {
                    CKEDITOR.plugins.addExternal("textindent", "/ckeditor4/plugins/textindent/", "plugin.js");
                    CKEDITOR.plugins.addExternal("abnt", "/ckeditor4/plugins/abnt/", "plugin.js");
                    CKEDITOR.plugins.addExternal("brasao", "/ckeditor4/plugins/brasao/", "plugin.js");
                    CKEDITOR.plugins.addExternal("openlink", "/ckeditor4/plugins/brasao/", "plugin.js");
                  }}

                  onDataReady={(CKEDITOR) => {         
                    setTimeout(() => {
                        if(document.querySelector(".cke_notification_close")) document.querySelector(".cke_notification_close").click();
                    }, 500);                                                               
                  }}
                  
                  onDialogShow={(CKEDITOR) => {

                    let modalBootstrap = document.querySelector("div.fade.modal.show");
                    if (modalBootstrap) {
                      modalBootstrap.setAttribute("tabindex", "");
                    }
                  }}

                  initData={descricao}
                  config={{
                    toolbar: [
                      { name: 'document', items: ['textindent', 'abnt', 'brasao', 'lineheight', 'PasteFromWord', 'Print'] },
                      { name: 'clipboard', items: ['Undo', 'Redo'] },
                      { name: 'styles', items: ['Format', 'Font', 'FontSize'] },
                      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting'] },
                      { name: 'colors', items: ['TextColor', 'BGColor'] },
                      { name: 'align', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
                      { name: 'links', items: ['Link', 'Unlink'] },
                      { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'] },
                      { name: 'insert', items: ['Image', 'Table'] },
                      { name: 'tools', items: ['Maximize'] }
                    ],
                    extraPlugins: ['textindent', 'abnt', 'print', 'justify', 'brasao', 'font', 'openlink'],
                    customValues: { unidade: unidade_corporativo }
                  }}

                  onChange={e => this.handleChangeDescricao(e)}

                />
              </div>
            </Form.Group>

            <Form.Group controlId="1">
              <div key="descricao">
                <Form.Label>Conteúdo do Arquivo
                  {(this.props.documento.extensao == "pdf") &&
                    <span style={{ color: "#ff0000" }}>(Este arquivo está em formato pdf, caso queira editá-lo é necessário fazer o OCR antes)</span>

                  }
                </Form.Label>





                {this.props.documento.id &&
                  <iframe id="edicaoTextoDescricao" name="edicaoTextoDescricao" style={{ height: "500px", width: "100%" }} class="vbox" src={`${TjdocsApi.urlLibreOffice()}/browser/fc0de5b/cool.html?WOPISrc=${TjdocsApi.urlWOPI()}/v1/wopi/files/${this.props.documento.id}edt&NotWOPIButIframe=true&lang=pt-br`} />
                }
              </div>

            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} md="2">
                <Form.Label>Ano</Form.Label>
                <div className="mb-7">
                  <input className="form-control" value={ano_filtro} type="text" id="ano" placeholder="Ano" onChange={e => this.handleFiltroAno(e)} />
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label>N° Lei</Form.Label>
                <div className="mb-7">
                  <input maxLength={"15"} value={numLei} className="form-control" type="text" id="leiDecreto" placeholder="N° da lei" onChange={e => this.handleNumLei(e)} />
                </div>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>N° decreto</Form.Label>
                <div className="mb-7">
                  <input maxLength={"15"} value={numDecreto} className="form-control" type="text" id="leiDecreto" placeholder="N° do decreto" onChange={e => this.handleNumDecreto(e)} />
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label>Descrição do Arquivo</Form.Label>
                <div className="mb-12">
                  <textarea maxLength={"255"} value={descricaoDocumento} className="form-control" id="descricaoarquivo" onChange={e => this.handleDescricaoDocumento(e)} />
                </div>
              </Form.Group>
            </Form.Row>
            <fieldset>
              <legend>Vínculo com outros documentos</legend>
              <Form.Row>
                <ListarVinculo documento={this.props.documento} loadArquivo={this.props.loadArquivo} logado={this.logado} vinculos={this.props.vinculos} />
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="12" >

                  <BuscaAvancadaContainer state={this.props.state} idPasta={this.props.idPasta} loadComponent={this.props.loadComponent} adicionaArquivo={this.handleSelectChangeArquivos} removeItemSelecionado={this.removeItemSelecionado} handleItensSelecionados={this.handleItensSelecionados} dados={this.state.dados} />

                </Form.Group>

              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6" controlId="3">
                  <Form.Label>Escolha o tipo de vínculo</Form.Label>
                  <div key="natureza">
                    <select
                      onChange={(data) => {
                        this.handleChangeVinculos(data)
                      }
                      }

                      className="form-control"
                      aria-label="Natureza do upload de arquivo">
                      <option>Escolha...</option>
                      <option>Altera</option>
                      <option>Retifica</option>
                      <option>Revoga</option>
                      <option>Altera e Revoga</option>
                      <option>Torna sem Efeito</option>
                      <option>Suspende</option>
                    </select>

                  </div>
                </Form.Group>
              </Form.Row>
            </fieldset>

            <Form.Row>
              <Form.Group>
                <Button variant="primary" onClick={this.handleSubmitVinculos}>
                  Vincular
                </Button>
              </Form.Group>
            </Form.Row>



            <Form.Group>
              <Form.Label>Visibilidade</Form.Label>
              <div key="visibilidade">
                <select className="form-control mb-3" aria-label="Visibilidade" onChange={e => this.handleSelectChangeVisibilidade(e)}>
                  <option selected={idVisibilidade == 1 ? 'selected' : ''} value="1">Público</option>
                  <option selected={idVisibilidade == 2 ? 'selected' : ''} value="2">Intranet</option>
                  <option selected={idVisibilidade == 3 ? 'selected' : ''} value="3">Domínio</option>
                  <option selected={idVisibilidade == 4 ? 'selected' : ''} value="4">Pasta</option>
                </select>
              </div>
            </Form.Group>




          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Fechar
            </Button>
            <Button variant="primary" onClick={this.handleSubmit}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>


      </>
    )
  }
};

class ModalLoading extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: false };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleClose() {
    this.setState({ modal: false });
  }

  handleShow(e) {
    e.preventDefault();
    this.setState({ modal: true });
  }

  render() {
    const { modal } = this.state;

    return (
      <>

        <Modal show={this.props.exibeModal} onHide={this.handleClose} size="sm">
          <Modal.Header >
            <Modal.Title>Aguarde..</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading-ico"></div>
          </Modal.Body>

          <Modal.Footer>

          </Modal.Footer>
        </Modal>

      </>
    )
  }
};






