import React, {Component} from "react";


export class GradeArquivos extends Component {
  render() {
    
	var exibicao = "d-none";
	if (typeof(Storage) !== "undefined") {
		if (typeof(localStorage.exibicao) !== "undefined"){
			if(localStorage.exibicao === "miniaturas") exibicao = "";
		} else {
			exibicao = "";
		}
	}
	
	return <>
        <div className="row">
            <div className="col-md-12">
                <p className="h3 labelPastasArquivos">  &nbsp;&nbsp;Arquivos </p>
            </div>
        </div>

        <div className="row margin-0">
            <div className="p-contd-pastas">
                    <div className="p-cntd-pasts-miniaturas flex-wrap">
                        <div className="row"> 
                            {this.props.arquivos}
                        </div>                                            
                    </div>
            </div>

        </div>
    </>;
  }
}
