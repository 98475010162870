import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import TjdocsApi from '../api/API';
import Authentication from '../Authentication';
import { EditarVisibilidade } from '../components/EditarVisibilidade';
import ColunaDataItem from './DataColuna';
import Form from 'react-bootstrap/Form';

export class FolderIconLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisibilidade: false,
      visibilidadeId: 0,
    };
    this.showLoading = this.showLoading.bind(this);
    this.usuario = Authentication.getUsuario();
  }

  isLogin() {
    return Authentication.getUsuario();
  }

  showLoading() {
    if (document.querySelector('.loading'))
      document.querySelector('.loading').style.display = 'inline-block';
  }

  setNodo = () => {
    window.sessionStorage.setItem('nodo', this.props.id);
    window.sessionStorage.setItem('tipo_nodo', 'pasta');
  };

  copiar = () => {
    if (document.querySelector('div[role="tooltip"]') !== undefined)
      //Fecha o menu aberto
      document.querySelector('div[role="tooltip"]').style.display = 'none';
    this.setNodo();
    toast.success('Copiado!');
  };

  alterarVisibilidade = (valor) => {
    TjdocsApi.buscarPasta(this.props.id).then((response) => {
      this.setState({ visibilidadeId: response.visibilidadeId });
      if (document.querySelector('.loading'))
        document.querySelector('.loading').style.display = 'none';
    });
    this.setState({ modalVisibilidade: valor });
  };

  setModalEditarArquivo = (valor) => {
    this.setState({ modalEditarArquivo: valor });
  };

  colar = () => {
    document.body.click();
    if (document.querySelector('.loading')) {
      document.querySelector('.loading').style.display = 'block';
      document.querySelector('.loading').classList = 'loadingImportant';
    }
    this.moverNodo();
  };

  moverNodo = () => {
    //e.preventDefault();

    var tipoNodo = window.sessionStorage.getItem('tipo_nodo');
    var nodoCopiado = window.sessionStorage.getItem('nodo');
    var nodoColado = this.props.id;

    if (nodoCopiado !== nodoColado) {
      // nao colar nele mesmo
      if (tipoNodo === 'pasta') {
        //tratar as demais questoes escondendo o botao colar
        TjdocsApi.moverPasta(nodoCopiado, nodoColado)
          .then((response) => {
            toast.success('Movido!');
            window.history.go(0);
          })
          .catch((e) => {
            toast.error('Movendo Pasta: ' + e);
            if (document.querySelector('.loadingImportant')) {
              document.querySelector('.loadingImportant').style.display =
                'none';
            }
          });
      } else {
        TjdocsApi.moverDocumento(nodoCopiado, nodoColado)
          .then((response) => {
            toast.success('Movido!');
            window.history.go(0);
          })
          .catch((e) => {
            toast.error('Movendo Arquivo: ' + e);
            if (document.querySelector('.loadingImportant')) {
              document.querySelector('.loadingImportant').style.display =
                'none';
            }
          });
      }
    }
  };

  render() {
    const tooltipOptionsFileIcon = (props) => (
      <Tooltip {...props}>
        <div
          className='box box-primary'
          style={{ margin: 0, minWidth: '135px' }}
        >
          <div
            className='box-body no-padding'
            style={{
              color: '#333',
              fontSize: '180%',
            }}
          >
            <ul className='padding-0 ' style={{ textAlign: 'left' }}>
              {/* Copiar Pasta */}
              <li
                className={
                  this.props.podeAdicionarRemoverPasta ||
                  this.isLogin().authorities === 'ADMINISTRADOR'
                    ? 'box-body no-padding'
                    : 'd-none '
                }
                style={{ listStyle: 'none', padding: '7px 10px' }}
              >
                <a className='nav-link' href='#' onClick={(e) => this.copiar()}>
                  <i className='fas fa-cut'></i> Recortar
                </a>
              </li>

              {/* Colar */}
              <li
                className={
                  this.props.podeAdicionarRemoverPasta ||
                  this.isLogin().authorities === 'ADMINISTRADOR'
                    ? 'box-body no-padding'
                    : 'd-none '
                }
                style={{ listStyle: 'none', padding: '7px 10px' }}
              >
                <a className='nav-link' href='#' onClick={(e) => this.colar()}>
                  <i className='fas fa-paste'></i> Colar
                </a>
              </li>

              {/* Alterar visibilidade */}
              <li
                className={
                  this.props.podeAdicionarRemoverPasta ||
                  this.isLogin().authorities === 'ADMINISTRADOR'
                    ? 'box-body no-padding'
                    : 'd-none '
                }
                style={{ listStyle: 'none', padding: '7px 10px' }}
              >
                <a
                  className='nav-link'
                  href='#'
                  onClick={(e) => {
                    document.body.click();
                    this.alterarVisibilidade(true);
                  }}
                >
                  <i className='fas fa-eye'></i> Visibilidade
                </a>
              </li>

              {/* Cancelar */}
              <li
                className={
                  this.props.podeAdicionarRemoverPasta ||
                  this.isLogin().authorities === 'ADMINISTRADOR'
                    ? 'box-body no-padding'
                    : 'd-none '
                }
                style={{
                  listStyle: 'none',
                  padding: '7px 10px',
                  display: 'none',
                }}
              >
                <a
                  className='nav-link'
                  href='#'
                  onClick={(e) => {
                    window.sessionStorage.setItem('nodo', '-1');
                    toast.success('Cancelado!');
                  }}
                >
                  <i className='fas fa-share'></i> Cancelar
                </a>
              </li>

              {/* Excluir Pasta */}
              <ExcluirPasta
                state={this.props.state}
                idPasta={this.props.id}
                loadComponent={this.props.loadComponent}
                podeAdicionarRemoverPasta={this.props.podeAdicionarRemoverPasta}
              />
            </ul>
            {/*A pasta id é: {this.props.id}
					o tipo é: {window.sessionStorage.getItem("tipo_nodo")}*/}
          </div>
        </div>
      </Tooltip>
    );

    const tooltipArquivosVinculados = (props) => (
      <Tooltip {...props}>
        <div className='box box-primary' style={{ margin: 0 }}>
          <div className='box-header with-border box-defaultClaro'>
            <h3 className='box-title'>
              <a role='button' href='#' onClick={(e) => e.preventDefault()}>
                Arquivos Vinculados{' '}
                <i
                  style={{ float: 'right' }}
                  className='fas fa-times'
                  onClick={() => document.body.click()}
                />
              </a>
            </h3>
          </div>
          <div
            className='box-body no-padding'
            style={{ color: '#333', fontSize: '180%' }}
          >
            <ul className='padding-0' style={{ textAlign: 'left' }}>
              <li style={{ listStyle: 'none', padding: '2px 10px 0' }}>
                <span className='fas'>Nome do Gestor: </span>
                <br />
                <div style={{ height: '40px', overflow: 'auto' }}>
                  {this.props.listaGestores && (
                    <>
                      {this.props.listaGestores._embedded.path.map(
                        (gestor, index) => (
                          <div key={`gestor-${index}`}>
                            {gestor.username}
                            <br />
                          </div>
                        ),
                      )}
                    </>
                  )}
                </div>
              </li>
              <li style={{ listStyle: 'none', padding: '2px 10px 0' }}>
                <span className='fas'>Contato do Gestor: </span>
                <br />
                <div style={{ height: '40px', overflow: 'auto' }}>
                  {this.props.listaGestoresl && (
                    <>
                      {this.props.listaGestores._embedded.path.map(
                        (gestor, index) => (
                          <div key={`gestor-${index}`}>
                            {gestor.email}
                            <br />
                          </div>
                        ),
                      )}
                    </>
                  )}
                </div>
              </li>
              <li style={{ listStyle: 'none', padding: '2px 10px 0' }}>
                <span className='fas'>Visibilidade: </span>&nbsp;
                {this.props.nomeVisibilidade}
              </li>
              <li style={{ listStyle: 'none', padding: '2px 10px 0' }}>
                <span className='fas'>Data de criação: </span>&nbsp;
                {this.props.createdAt}
              </li>
              <li style={{ listStyle: 'none', padding: '2px 10px 0' }}>
                <span className='fas'>Data de modificação: </span>&nbsp;
                {this.props.updatedAt}
              </li>
            </ul>

            <ul className='padding-0' style={{ textAlign: 'left' }}>
              {this.usuario.nome_completo && (
                <li style={{ listStyle: 'none', padding: '2px 10px 0' }}>
                  <span className='fas'>Usuário: </span> &nbsp;
                  {this.usuario.nome_completo}
                </li>
              )}
              {this.props.permissoesUsuarios && (
                <li style={{ listStyle: 'none', padding: '2px 10px 0' }}>
                  <span className='fas'>Permissões: </span>
                </li>
              )}
              {this.props.permissoesUsuarios &&
                this.props.permissoesUsuarios.adicionarRemoverPasta && (
                  <li
                    style={{
                      listStyle: 'none',
                      padding: '2px 30px 0',
                      fontSize: '12px',
                    }}
                  >
                    Adicionar/Remover pastas
                  </li>
                )}
              {this.props.permissoesUsuarios &&
                this.props.permissoesUsuarios.adicionarRemoverArquivo && (
                  <li
                    style={{
                      listStyle: 'none',
                      padding: '2px 30px 0',
                      fontSize: '12px',
                    }}
                  >
                    Adicionar/Remover arquivos
                  </li>
                )}
              {this.props.permissoesUsuarios &&
                this.props.permissoesUsuarios.adicionarRemoverUsuarios && (
                  <li
                    style={{
                      listStyle: 'none',
                      padding: '2px 30px 0',
                      fontSize: '12px',
                    }}
                  >
                    Adicionar/Remover usuários
                  </li>
                )}
              {this.props.permissoesUsuarios &&
                this.props.permissoesUsuarios.apagarTodosArquivos && (
                  <li
                    style={{
                      listStyle: 'none',
                      padding: '2px 30px 0',
                      fontSize: '12px',
                    }}
                  >
                    Remover qualquer arquivo
                  </li>
                )}
              {this.props.permissoesUsuarios &&
                this.props.permissoesUsuarios.apagarTodasPastas && (
                  <li
                    style={{
                      listStyle: 'none',
                      padding: '2px 30px 0',
                      fontSize: '12px',
                    }}
                  >
                    Remover qualquer pasta
                  </li>
                )}
            </ul>
          </div>
        </div>
      </Tooltip>
    );

    return (
      <tr className='tabByArrow' tabIndex='0'>
        <td>
          <Link
            to={{ pathname: `/pastas/${this.props.id}` }}
            onClick={this.showLoading}
          >
            <svg
              className='imagem_Cards icones-arquivo svg-azul-escuro'
              x='0px'
              y='0px'
              viewBox='0 0 60 60'
              width='35px'
              height='35px'
            >
              <g>
                <path
                  d='M14,23.5c-0.254,0-0.479,0.172-0.545,0.417L2,52.5v1c0,0.734-0.047,1,0.565,1h44.759c1.156,0,2.174-0.779,2.45-1.813   L60,24.5c0,0,0-0.625,0-1H14z'
                  fill='#333333'
                />
                <path
                  d='M12.731,21.5H53h1v-6.268c0-1.507-1.226-2.732-2.732-2.732H26.515l-5-7H2.732C1.226,5.5,0,6.726,0,8.232v41.796   l10.282-26.717C10.557,22.279,11.575,21.5,12.731,21.5z'
                  fill='#333333'
                />
              </g>
            </svg>
            <span className='sr-only'>Dominio:</span> &nbsp; {this.props.nome}
          </Link>
          <EditarVisibilidade
            state={this.props.state}
            idPasta={this.props.id}
            loadComponent={this.props.loadComponent}
            visibilidadeId={this.state.visibilidadeId}
            modalVisibilidade={this.state.modalVisibilidade}
            close={() => this.alterarVisibilidade(false)}
          />
          {this.isLogin() &&
          ((this.props.permissoesUsuarios &&
            this.props.permissoesUsuarios.adicionarRemoverPasta) ||
            this.isLogin().authorities === 'ADMINISTRADOR') ? (
            <OverlayTrigger
              trigger='click'
              placement='bottom'
              overlay={tooltipOptionsFileIcon}
              rootClose
            >
              <div
                style={{
                  width: '30px',
                  height: '25px',
                  cursor: 'help',
                  right: 0,
                  display: 'inline',
                  marginLeft: '5px',
                }}
              >
                <span
                  className='etiquetas-ico'
                  title='Mais opções'
                  style={{
                    backgroundColor: '#6dcda0',
                    padding: '1px 7px',
                    color: 'var(--paleta_branco)',
                  }}
                >
                  <i className='fas fa-ellipsis-v' />
                </span>
              </div>
            </OverlayTrigger>
          ) : (
            ''
          )}

          <OverlayTrigger
            trigger='click'
            placement='top'
            overlay={tooltipArquivosVinculados}
            rootClose={true}
          >
            <div
              style={{
                width: '30px',
                height: '25px',
                cursor: 'help',
                display: 'inline',
                right: '25px',
                marginLeft: '5px',
              }}
            >
              <span
                className='etiquetas-ico'
                title='Arquivos vinculados'
                style={{ backgroundColor: '#009abf', padding: '1px 7px' }}
              >
                <i className='fas fa-info'></i>
              </span>
            </div>
          </OverlayTrigger>
        </td>
        <td />
        {this.isLogin() ? <td /> : null}
        <td />
        {this.isLogin() ? null : <td />}
        {this.isLogin() ? (
          <>
            <td>{this.props.nomeVisibilidade}</td>
            <td>
              <div style={{ height: '40px', overflow: 'auto' }}>
                {this.props.listaGestores !== null && (
                  <>
                    {this.props.listaGestores._embedded.path.map(
                      (gestor, index) => (
                        <div key={`gestor-${index}`}>
                          {gestor.username} / {gestor.email} <br />
                        </div>
                      ),
                    )}
                  </>
                )}
              </div>
            </td>
          </>
        ) : null}
        <ColunaDataItem data={this.props.dataCadastro ?? this.props.createdAt} />
      </tr>
    );
  }
}

FolderIconLista.propTypes = {
  nome: PropTypes.string,
  openFolder: PropTypes.func,
};

class ExcluirPasta extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: false, pastaPai: '' };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose() {
    this.setState({ modal: false });
  }

  handleShow(e) {
    e.preventDefault();
    this.setState({ modal: true });

    TjdocsApi.buscarPasta(this.props.idPasta).then((response) => {
      document.getElementById('nome_pasta_excluir').innerHTML =
        '<strong>' + response.nome + '</strong>';
      this.loadState(response);
      if (document.querySelector('.loading'))
        document.querySelector('.loading').style.display = 'none';
    });

    TjdocsApi.buscarQuantidadeItem(this.props.idPasta).then((response) => {
      document.getElementById('quatidade_itens_selecionado_excluir').innerHTML =
        'Quantidade de itens que serão excluídos:<ul><li> <strong>' +
        response.quantidadePastas +
        '</strong> pasta(s)</li><li><strong>' +
        response.quantidadeDocumentos +
        '</strong> documento(s)</li></ul>';
      this.loadState(response);
      if (document.querySelector('.loading'))
        document.querySelector('.loading').style.display = 'none';
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    TjdocsApi.excluiPasta(this.props.idPasta).then((response) => {
      //console.log(response.id)
      this.props.loadComponent(this.state.pastaPai);
    });
    this.setState({ modal: false });
    toast.success('Sucesso!');
  }

  loadState(response) {
    this.setState({ pastaPai: response.pastaPaiId });
  }

  render() {
    const { modal } = this.state;

    return (
      <>
        <li
          className={
            this.props.podeAdicionarRemoverPasta
              ? 'box-body no-padding'
              : 'd-none '
          }
          style={{
            listStyle: 'none',
            padding: '7px 10px',
          }}
        >
          <a className='nav-link' href='#' onClick={this.handleShow}>
            <i className='fas fa-trash-alt'></i> Excluir
          </a>
        </li>
        {/*<a  className={this.props.podeAdicionarRemoverPasta  ? "icones" : "d-none "} title="Excluir Pasta" onClick={this.handleShow} > <i className="fas fa-folder-minus"></i></a>*/}

        <Modal show={modal} onHide={this.handleClose} size='sm'>
          <Modal.Header closeButton>
            <Modal.Title>Excluir Pasta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='1'>
              <div key='nome'>
                Deseja realmente excluir a pasta{' '}
                <span id='nome_pasta_excluir'></span>?
              </div>
              <div key='nome' id='quatidade_itens_selecionado_excluir'></div>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleClose}>
              Não
            </Button>
            <Button variant='primary' onClick={this.handleSubmit}>
              Sim
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
