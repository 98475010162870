import React, {Component} from "react";
import {MiniDocView} from "../components/MiniDocView";
import {MiniDocViewLista} from "../components/MiniDocViewLista";
import TjdocsApi from "../api/API";
import {FolderIcon} from "../components/FolderIcon";
import {FileIcon} from "../components/FileIcon";
import {FolderIconLista} from "../components/FolderIconLista";
import {FileIconLista} from "../components/FileIconLista";
import {MainContainer} from "./MainContainer";
import Authentication from "../Authentication";
import * as PropTypes from "prop-types";
import {get_path} from "../helper/Helper";
import Pagination from "react-js-pagination";
import {MenuDominio} from "../components/MenuDominio";
import {GradePastas} from "../components/GradePastas";
import {GradeArquivos} from "../components/GradeArquivos";
import toast from 'react-hot-toast';
import {Accordion, Card, Dropdown, Button, Spinner, Modal} from "react-bootstrap";


export class FolderContainer extends Component {
    constructor(props) {
        super(props);
		this.podeAdicionarRemoverPasta = false;
		this.podeSerPublica = false;
       	this.podeAdicionarRemoverArquivo = false;		
       	this.podeAdicionarRemoverUsuario = false;
		this.adicionarArquivo = false;
		this.caminhoCompleto = "";
		this.nomePasta = "";
        this.state = {
            pastas: [],
            documentos: [],
            path: [],
            paginacao:{},
			idPastaPai: 0,
            activePage: 1,
			dadosPastaPai: { },
			colunaOrdenada: "",
			ordem: "",
			pastasFiltradas: [],
			documentosFiltrados: [],
			loading: false
        };
		this.filtros = {
			pastas: true,
			documentos: true,
			tipoArquivo: "",
			nome: "",
			ano: "",
			dataCadastro: "",
			leiOuDecreto: "",
			descricao: ""
		}
		this.loadComponent = this.loadComponent.bind(this);
    }
    
    checkPermissao(pasta_id){

    	TjdocsApi.buscarPasta(pasta_id).then( response =>{        	
            if (response == null) return;
			this.setState({ permissao: response.adicionarRemoverPasta })
			this.podeAdicionarRemoverPasta = response.adicionarRemoverPasta;
       		this.podeAdicionarRemoverArquivo = response.adicionarRemoverArquivo;
       		this.podeAdicionarRemoverUsuario = response.adicionarRemoverUsuarios;
			this.podeAdicionarArquivo = response.adicionarArquivo;
			this.podeSerPublica = response.visibilidadeNome != 'Intranet';
			this.nomePasta = response.nome;
            }
        );
		
		TjdocsApi.listaPermissoesPorPastasPorIdUsuario(pasta_id, Authentication.getUsuario().usuario_id_tjdocs).then( response =>{        	
            	if (response == null || response._embedded ==null) return;
				this.setState({ permissaoUsuario: response._embedded.dominioUsuarioOutputList[0] })
			}
        );
    }

	caminhoPastaCompleto(pasta_id){
        TjdocsApi.caminhoCompleto(pasta_id).then(resp =>{   			
            this.caminhoCompleto = resp;
        }).catch((e) => {console.log("erro")});    
    }

	recuperaDadosPastaPai(pasta_id){
        TjdocsApi.recuperaDadosPastaPai(pasta_id).then(resp =>{
            this.state.dadosPastaPai = resp;
        }).catch((e) => {console.log("erro")});    
    }
    

    componentDidMount() {
		this.loadComponent(this.props.match.params.pasta_id)
		
    }

    handlePageChange = (page_number) => {
		
        let offset = (page_number -1);
        let pasta_id = this.props.match.params.pasta_id;
		
		console.log(page_number,pasta_id,offset);

		TjdocsApi.recuperarOrdenacao(pasta_id, 'documento').then(resp => {
			console.log(resp.id);
			if(resp.id === null) {
				this.setState({loading: true});
				TjdocsApi.abrePasta(pasta_id,offset).then(resp => {
					if(resp._embedded){
						if (resp._embedded.pastas[0].id != pasta_id){
							this.setState({
								pastas: resp._embedded.pastas,
								path: resp._embedded.pastas[0].caminhos._embedded.path
							});
						} else { //Atualizar o conteudo para vazio quando n tem mais subpastas
							this.setState({
								pastas: [],
								path: resp._embedded.pastas[0].caminhos._embedded.path
							});
						}
						/*this.setState({                
							pastas: resp._embedded.pastas,                
							path: resp._embedded.pastas[0].caminhos._embedded.path                
						});*/
					}
				})

				TjdocsApi.abreArquivos(pasta_id, offset).then(resp => {			
					if(resp._embedded){
						console.log(resp);
						this.setState({				
							documentos: resp._embedded.documentos,
							documentosFiltrados: resp._embedded.documentos,
							paginacao: resp.page,
							activePage: page_number,
							loading: false
						});
					}else{

					}
					this.props.handlePath(get_path(this.state.path));
					
				}).catch(error => console.log(`Erro ao abrir pasta: ${error}`));

				this.setState({colunaOrdenada : 'dataCadastro' });
				this.setState({ordem: 'desc' });

			}
			else {
				this.setState({colunaOrdenada : resp.nomeColuna });
				this.setState({ordem: resp.order});
				this.ordenaDadosArquivosComOffset(resp.nomeColuna, resp.order, offset);
			}
		});

    }

	salvarOrdenacao = (field, order) => {

		let nomeColuna = field;
		let pastaId = this.props.match.params.pasta_id;

		field = (field == 'nome' ? 'documento.nome' : field);
		field = (field == 'dataCadastro' ? 'documento.dataCadastro' : field);
		field = (field == 'dataAtualizacao' ? 'documento.dataAlteracao' : field);
		field = (field == 'tipoAcesso' ? 'documento.visibilidade.nome' : field);
		field = (field == 'nomeGestor' ? 'documento.usuario.nome' : field);
		field = (field == 'tamanhoMB' ? 'documento.tamanho' : field);
		field = (field == 'numeroDecreto' ? 'documento.numDecreto' : field);
		field = (field == 'numeroLei' ? 'documento.numLei' : field);

		TjdocsApi.salvarOrdenacao(pastaId, field, order, "documento", nomeColuna).then(resp => {			
			console.log(resp);
        }).catch(error => console.log(`Erro ao salvar ordenação: ${error}`));

		field = nomeColuna;
		field = (field == 'nome' ? 'ps.nome' : field);
		field = (field == 'dataCadastro' ? 'ps.created_at' : field);
		field = (field == 'dataAtualizacao' ? 'ps.updated_at' : field);
		field = (field == 'tipoAcesso' ? 'ps.nome' : field);
		field = (field == 'nomeGestor' ? 'u.username' : field);
		field = (field == 'tamanhoMB' ? 'tamanho' : field);
		field = (field == 'totalArquivos' ? 'totalArquivo' : field);

		TjdocsApi.salvarOrdenacao(pastaId, field, order, "pasta", nomeColuna).then(resp => {			
			console.log(resp);
        }).catch(error => console.log(`Erro ao salvar ordenação: ${error}`));

		toast.success('Sucesso!');
	}

	ordenaDadosArquivos = (fieldBusca, order) => {
		

		let field = fieldBusca; 
		field = (field == 'nome' ? 'documento.nome' : field);
		field = (field == 'dataCadastro' ? 'documento.dataCadastro' : field);
		field = (field == 'dataAtualizacao' ? 'documento.dataAlteracao' : field);
		field = (field == 'tipoAcesso' ? 'documento.visibilidade.nome' : field);
		field = (field == 'nomeGestor' ? 'documento.usuario.nome' : field);
		field = (field == 'tamanhoMB' ? 'documento.tamanho' : field);
		field = (field == 'numeroDecreto' ? 'documento.numDecreto' : field);
		field = (field == 'numeroLei' ? 'documento.numLei' : field);
		field = (field == 'totalArquivos' ? 'documento.dataCadastro' : field);

        let offset = (this.state.paginacao.number);
        let pasta_id = this.props.match.params.pasta_id;

		TjdocsApi.ordenaDadosArquivos(pasta_id, offset, field, order).then(resp => {			
            if(resp._embedded){
				console.log(resp);
				this.setState({				
					documentos: resp._embedded.documentos,
					documentosFiltrados: resp._embedded.documentos,
					paginacao: resp.page,
					activePage: offset
				});
			}else{

			}
            this.props.handlePath(get_path(this.state.path));
			
        }).catch(error => console.log(`Erro ao abrir pasta: ${error}`));

		field = fieldBusca; 
		field = (field == 'nome' ? 'ps.nome' : field);
		field = (field == 'dataCadastro' ? 'ps.created_at' : field);
		field = (field == 'dataAtualizacao' ? 'ps.updated_at' : field);
		field = (field == 'tipoAcesso' ? 'ps.nome' : field);
		field = (field == 'nomeGestor' ? 'u.username' : field);
		field = (field == 'tamanhoMB' ? 'tamanho' : field);
		field = (field == 'numeroDecreto' ? 'ps.created_at' : field);
		field = (field == 'numeroLei' ? 'ps.created_at' : field);
		field = (field == 'totalArquivos' ? 'totalArquivo' : field);

		TjdocsApi.ordenaPasta(pasta_id, offset, field, order).then(resp => {
			if(resp._embedded){
				if (resp._embedded.pastas[0].id != pasta_id){
					this.setState({
						pastas: resp._embedded.pastas,
						path: resp._embedded.pastas[0].caminhos._embedded.path
					});

					this.filtrar();

				} else { //Atualizar o conteudo para vazio quando n tem mais subpastas
					this.setState({
						pastas: [],
						path: resp._embedded.pastas[0].caminhos._embedded.path
					});
				}
			}
			this.props.handlePath(get_path(this.state.path));
			
			{/* Hide no loading */}
			document.querySelector(".loading").style.display = "none";
        }).catch(error => console.log(`Erro ao abrir pasta: ${error}`));

    }

	ordenaDadosArquivosComOffset = (fieldBusca, order, offset) => {
		

		let field = fieldBusca; 
		field = (field == 'nome' ? 'documento.nome' : field);
		field = (field == 'dataCadastro' ? 'documento.dataCadastro' : field);
		field = (field == 'dataAtualizacao' ? 'documento.dataAlteracao' : field);
		field = (field == 'tipoAcesso' ? 'documento.visibilidade.nome' : field);
		field = (field == 'nomeGestor' ? 'documento.usuario.nome' : field);
		field = (field == 'tamanhoMB' ? 'documento.tamanho' : field);
		field = (field == 'numeroDecreto' ? 'documento.numDecreto' : field);
		field = (field == 'numeroLei' ? 'documento.numLei' : field);
		field = (field == 'totalArquivos' ? 'documento.dataCadastro' : field);

        //let offset = (this.state.paginacao.number);
        let pasta_id = this.props.match.params.pasta_id;

		TjdocsApi.ordenaDadosArquivos(pasta_id, offset, field, order).then(resp => {			
            if(resp._embedded){
				console.log(resp);
				this.setState({				
					documentos: resp._embedded.documentos,
					documentosFiltrados: resp._embedded.documentos,
					paginacao: resp.page,
					activePage: offset
				});
			}else{

			}
            this.props.handlePath(get_path(this.state.path));
			
        }).catch(error => console.log(`Erro ao abrir pasta: ${error}`));

		field = fieldBusca; 
		field = (field == 'nome' ? 'ps.nome' : field);
		field = (field == 'dataCadastro' ? 'ps.created_at' : field);
		field = (field == 'dataAtualizacao' ? 'ps.updated_at' : field);
		field = (field == 'tipoAcesso' ? 'ps.nome' : field);
		field = (field == 'nomeGestor' ? 'u.username' : field);
		field = (field == 'tamanhoMB' ? 'tamanho' : field);
		field = (field == 'numeroDecreto' ? 'ps.created_at' : field);
		field = (field == 'numeroLei' ? 'ps.created_at' : field);
		field = (field == 'totalArquivos' ? 'totalArquivo' : field);

		TjdocsApi.ordenaPasta(pasta_id, offset, field, order).then(resp => {
			if(resp._embedded){
				if (resp._embedded.pastas[0].id != pasta_id){
					this.setState({
						pastas: resp._embedded.pastas,
						path: resp._embedded.pastas[0].caminhos._embedded.path
					});
				} else { //Atualizar o conteudo para vazio quando n tem mais subpastas
					this.setState({
						pastas: [],
						path: resp._embedded.pastas[0].caminhos._embedded.path
					});
				}
			}
			this.props.handlePath(get_path(this.state.path));
			
			{/* Hide no loading */}
			document.querySelector(".loading").style.display = "none";
        }).catch(error => console.log(`Erro ao abrir pasta: ${error}`));

    }


    componentDidUpdate(prevProps) {
        if(prevProps.match.params.pasta_id !== this.props.match.params.pasta_id )
			this.loadComponent(this.props.match.params.pasta_id);
		
		
    }
    
	loadComponent(pasta_id){		
		
		
		let offset = 0;	
		this.checkPermissao(pasta_id);
		this.caminhoPastaCompleto(pasta_id);
		this.recuperaDadosPastaPai(this.props.match.params.pasta_id);

		TjdocsApi.recuperarOrdenacao(pasta_id, 'documento').then(resp => {
			if(resp != null && resp != 'undefined') {
				if(resp.id === null) {
					this.setState({loading: true});
					TjdocsApi.abrePasta(pasta_id, offset).then(resp => {
						if(resp._embedded){
							if (resp._embedded.pastas[0].id != pasta_id){
								this.setState({
									//documentos: resp._embedded.documentos,
									pastas: resp._embedded.pastas,
									pastasFiltradas: resp._embedded.pastas,
									path: resp._embedded.pastas[0].caminhos._embedded.path
								});
							} else { //Atualizar o conteudo para vazio quando n tem mais subpastas
								this.setState({
									pastas: [],
									pastasFiltradas: [],
									path: resp._embedded.pastas[0].caminhos._embedded.path
								});
							}
						}
						this.props.handlePath(get_path(this.state.path));
						
						{/* Hide no loading */}
						document.querySelector(".loading").style.display = "none";
					}).catch(error => console.log(`Erro ao abrir pasta: ${error}`));
					
					if(pasta_id !== 0 ){
						this.props.history.push(`/pastas/${pasta_id}`);
						{/*Abre arquivos*/}
						TjdocsApi.abreArquivos(pasta_id, offset).then(resp => {			
							if(resp._embedded){
								this.setState({				
									documentos: resp._embedded.documentos,
									documentosFiltrados: resp._embedded.documentos,
									paginacao: resp.page,
									loading: false
								});
							}else{
								this.setState({				
									documentos: [],
									documentosFiltrados: [],
									paginacao: {},
									loading: false
								});
							}
							this.props.handlePath(get_path(this.state.path));
							
						}).catch(error => console.log(`Erro ao abrir pasta: ${error}`));
					}else{			
						this.props.history.push('/');
						this.setState({
							pastas: [],
							path: null				
						});
						this.props.handlePath(get_path(this.state.path));
						this.props.atualizaDominios();		
			
					}

					this.setState({colunaOrdenada : 'dataCadastro' });
					this.setState({ordem: 'desc' });
				}
				else {
					console.log("ordenar dados", resp);
					this.setState({colunaOrdenada : resp.nomeColuna });
					this.setState({ordem: resp.order});
					this.ordenaDadosArquivos(resp.nomeColuna, resp.order);
				}
			}
		});

        

	}

    renderChildren = (opcao) => {
		if (opcao === "grade"){ //Grade
			let pastas = this.state.pastasFiltradas.map(
				(pasta, i) => 
				<FolderIcon permissoesUsuarios={this.state.permissaoUsuario} listaGestores={pasta.listaGestores} key={i} nome={pasta.nome} id={pasta.id} podeAdicionarRemoverArquivo={pasta.adicionarRemoverArquivo} podeAdicionarArquivo={pasta.adicionarArquivo} podeAdicionarRemoverPasta={pasta.adicionarRemoverPasta} loadComponent={this.loadComponent}  updatedAt={pasta.updatedAt} createdAt={pasta.createdAt} nomeGestor={pasta.nomeGestor} contatoGestor={pasta.contatoGestor} nomeVisibilidade={pasta.visibilidadeNome} />
			);

			let arquivos = this.state.documentosFiltrados.map((documento, i) => {
					var idPastaPai = 0;
					if (documento.caminhos._embedded.path[documento.caminhos._embedded.path.length-2])
						idPastaPai = documento.caminhos._embedded.path[documento.caminhos._embedded.path.length-2].id
					return (<FileIcon loadComponent={this.loadComponent} listaGestores={this.state.dadosPastaPai.listaGestores}  permissoesUsuarios={this.state.permissaoUsuario} documento={documento} key={i + pastas.length}  nome={documento.nome} idPastaAtual={this.props.match.params.pasta_id} id={documento.id} dataCadastro={documento.dataCadastro} idPastaPai={idPastaPai} etiquetas={documento.etiquetas}  vinculadores={documento.vinculadores}/>)
				}
			);


			return [<GradePastas pastas={pastas} /> , <GradeArquivos arquivos={arquivos} /> ] ;
		} else { //Lista
			let pastas = this.state.pastasFiltradas.map(
				(pasta, i) => <FolderIconLista listaGestores={pasta.listaGestores} podeAdicionarRemoverArquivo={pasta.adicionarRemoverArquivo} podeAdicionarArquivo={pasta.adicionarArquivo}  podeAdicionarRemoverPasta={pasta.adicionarRemoverPasta}  permissoesUsuarios={this.state.permissaoUsuario} loadComponent={this.loadComponent}  key={i} nome={pasta.nome} id={pasta.id} updatedAt={pasta.updatedAt} createdAt={pasta.createdAt} nomeGestor={pasta.nomeGestor} contatoGestor={pasta.contatoGestor} totalArquivos={pasta.totalArquivos} tamanho={pasta.tamanho} nomeVisibilidade={pasta.visibilidadeNome}  />
			);
			let arquivos = this.state.documentosFiltrados.map((documento, i) => {
				let idPastaPai = 0;
				if (documento.caminhos._embedded.path[documento.caminhos._embedded.path.length-2])
					idPastaPai = documento.caminhos._embedded.path[documento.caminhos._embedded.path.length-2].id

				return (<FileIconLista listaGestores={this.state.dadosPastaPai.listaGestores} loadComponent={this.loadComponent}  permissoesUsuarios={this.props.permissoes} key={i + pastas.length} nome={documento.nome} documento={documento} id={documento.id} idPastaAtual={this.props.match.params.pasta_id} dataCadastro={documento.dataCadastro} idPastaPai={idPastaPai} etiquetas={documento.etiquetas} vinculadores={documento.vinculadores}/>)
			}
			);
			return pastas.concat(arquivos);
		}
		
        
    }

    displayPagination = () => {
	  //console.log(this.state)
	  if (document.querySelectorAll(".loading").length > 0)
		document.querySelector(".loading").style.display = "none";
	
	  {if (this.state.paginacao && this.state.paginacao.totalElements > 20) {
        return (<div className="col-md-12">
		{
			<Pagination
			  activeClass="active"
			  activeLinkClass="disabled"
			  activePage={this.state.paginacao.number+1}
			  disabledClass="disabled"
			  firstPageText="<<"
			  innerClass="pagination p-c-b-paginacao"
			  itemClass="page-item p-c-b-pagnc-paginas"
			  itemsCountPerPage={this.state.paginacao.size}
			  lastPageText=">>"
			  linkClass="page-link"
			  nextPageText="&raquo;"
			  onChange={this.handlePageChange}
			  pageRangeDisplayed={14}
			  prevPageText="&laquo;"
			  totalItemsCount={this.state.paginacao.totalElements}			  
			/>
			
		
		}</div>);
      }}
    }

	filtrar = () => {

		let filteredArrayPastas = this.state.pastas;
		let filteredArrayDocumentos = this.state.documentos;

		if(this.filtros.tipoArquivo == "Pastas") {
			if (this.filtros.nome != "") {
				filteredArrayPastas = this.state.pastas.filter(pasta =>
					pasta.nome.toLowerCase().includes(this.filtros.nome.toLowerCase())
				)
			}
			filteredArrayDocumentos = [];
		} else {
			if (this.filtros.tipoArquivo != "") {
				filteredArrayPastas = [];

				if(this.filtros.tipoArquivo != "Documentos") {
					filteredArrayDocumentos = filteredArrayDocumentos.filter(documento =>
						documento.extensao.toLowerCase().includes(this.filtros.tipoArquivo.toLowerCase())
					)
				}
			}

			if (this.filtros.nome != "") {
				if (this.filtros.tipoArquivo == "") {
					filteredArrayPastas = this.state.pastas.filter(pasta =>
						pasta.nome.toLowerCase().includes(this.filtros.nome.toLowerCase())
					)
				}

				filteredArrayDocumentos = filteredArrayDocumentos.filter(documento =>
					documento.nome.toLowerCase().includes(this.filtros.nome.toLowerCase())
				)
			}

			if (this.filtros.ano != "") {
				filteredArrayPastas = [];
				filteredArrayDocumentos = filteredArrayDocumentos.filter(documento =>
					documento.ano.toLowerCase().includes(this.filtros.ano.toLowerCase())
				)
			}

			if (this.filtros.leiOuDecreto != "") {
				filteredArrayPastas = [];
				filteredArrayDocumentos = filteredArrayDocumentos.filter(documento =>
					documento.numLei.toLowerCase().includes(this.filtros.leiOuDecreto.toLowerCase()) ||
					documento.numDecreto.toLowerCase().includes(this.filtros.leiOuDecreto.toLowerCase())
				)
			}

			if (this.filtros.descricao != "") {
				filteredArrayPastas = [];
				filteredArrayDocumentos = filteredArrayDocumentos.filter(documento =>
					documento.descricaoDocumento.toLowerCase().includes(this.filtros.descricao.toLowerCase())
				)
			}

			if (this.filtros.dataCadastro != "") {
				filteredArrayPastas = [];
				filteredArrayDocumentos = filteredArrayDocumentos.filter(documento =>
					documento.dataCadastro.toLowerCase().includes(this.filtros.dataCadastro.toLowerCase())
				)
			}
		}

		this.setState({
			pastasFiltradas: filteredArrayPastas,
			documentosFiltrados: filteredArrayDocumentos
		});

	}

	filtrarPorTipoDoArquivo = (tipoArquivo) => {
		this.filtros.tipoArquivo = tipoArquivo;
		this.filtrar();
	};


	filtrarPorNome = (event) => {
		this.filtros.nome = event.target.value;
		this.filtrar();
	};

	filtrarPorAno = (event) => {
		this.filtros.ano = event.target.value;
		this.filtrar();
	};

	filtrarPorDataCadastro = (event) => {
		if(event.target.value != "") {
			const [year, month, day] = event.target.value.split('-');
			const newFormattedDate = `${day}/${month}/${year}`;
			this.filtros.dataCadastro = newFormattedDate;
		} else {
			this.filtros.dataCadastro = "";
		}

		this.filtrar();

	};

	filtrarPorLeiOuDecreto = (event) => {
		this.filtros.leiOuDecreto = event.target.value;
		this.filtrar();
	};

	filtrarPorDescricao = (event) => {
		this.filtros.descricao = event.target.value;
		this.filtrar();
	};

    render() {
        return <div>

			<Modal show={this.state.loading}>
				<Modal.Header closeButton>
					<Modal.Title>
						Carregando...
						<Spinner animation="border" style={{marginLeft: '5px'}}/>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>Por favor, aguarde.</Modal.Body>
			</Modal>

			<MenuDominio path={this.state.path} idPasta={this.props.match.params.pasta_id}
						 podeAdicionarRemoverPasta={this.podeAdicionarRemoverPasta}
						 podeAdicionarRemoverArquivo={this.podeAdicionarRemoverArquivo}
						 podeAdicionarRemoverUsuario={this.podeAdicionarRemoverUsuario}
						 podeAdicionarArquivo={this.podeAdicionarArquivo}
						 podeSerPublica={this.podeSerPublica}
						 caminhoCompleto={this.caminhoCompleto}
						 loadComponent={this.loadComponent}/>

			<Accordion hidden={true}>
				<Card>
					<Card.Header>
						<p style={{fontSize: '12px'}}>Você está dentro da pasta <u><b>{this.nomePasta}</b></u>.</p>
						<Accordion.Toggle as={Button} variant="link" eventKey="0" style={{paddingLeft: '0px'}}>
							Pesquisar dentro da pasta <b>{this.nomePasta}</b>? <i className="fas fa-search"></i>
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey="0">
						<Card.Body style={{padding: 0}}>
							<div className="box box-primary" style={{marginBottom: '0px', marginTop: '0px'}}>
								<div className="box-header with-border box-defaultClaro input-group">
									<Dropdown>
										<Dropdown.Toggle variant="secondary" id="dropdown-basic"
														 style={{
															 backgroundColor: 'white',
															 height: '37px',
															 color: '#c9c9c9'
														 }}>
											{this.filtros.tipoArquivo == "" ? 'Tipo do Arquivo' : this.filtros.tipoArquivo}
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item
												onClick={() => this.filtrarPorTipoDoArquivo("doc")}>.doc</Dropdown.Item>
											<Dropdown.Item
												onClick={() => this.filtrarPorTipoDoArquivo("odt")}>.odt</Dropdown.Item>
											<Dropdown.Item
												onClick={() => this.filtrarPorTipoDoArquivo("pdf")}>.pdf</Dropdown.Item>
											<Dropdown.Item onClick={() => this.filtrarPorTipoDoArquivo("Documentos")}>Apenas
												documentos</Dropdown.Item>
											<Dropdown.Item onClick={() => this.filtrarPorTipoDoArquivo("Pastas")}>Apenas
												pastas</Dropdown.Item>
											<Dropdown.Item onClick={() => this.filtrarPorTipoDoArquivo("")}>Exibir
												tudo</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>

									<input
										className="busca_P-input input-barra-de-filtragem"
										type="search"
										placeholder="Nome"
										onChange={this.filtrarPorNome}
									/>

									{/*<input*/}
									{/*	className={`busca_P-input input-barra-de-filtragem ${this.filtros.tipoArquivo === "Pastas" ? 'disabled-input' : ''}`}*/}
									{/*	type="number"*/}
									{/*	placeholder="Ano"*/}
									{/*	onChange={this.filtrarPorAno}*/}
									{/*	disabled={this.filtros.tipoArquivo == "Pastas"}*/}
									{/*/>*/}

									{/*<input*/}
									{/*	className={`busca_P-input input-barra-de-filtragem ${this.filtros.tipoArquivo === "Pastas" ? 'disabled-input' : ''}`}*/}
									{/*	type="date"*/}
									{/*	title="Data de Cadastro"*/}
									{/*	onChange={this.filtrarPorDataCadastro}*/}
									{/*	style={{color: 'rgb(201, 201, 201)'}}*/}
									{/*	disabled={this.filtros.tipoArquivo == "Pastas"}*/}
									{/*/>*/}

									<input
										className={`busca_P-input input-barra-de-filtragem ${this.filtros.tipoArquivo === "Pastas" ? 'disabled-input' : ''}`}
										type="search"
										placeholder="Nº Lei ou Decreto"
										onChange={this.filtrarPorLeiOuDecreto}
										disabled={this.filtros.tipoArquivo == "Pastas"}
									/>

									{/*<input*/}
									{/*	className={`busca_P-input input-barra-de-filtragem ${this.filtros.tipoArquivo === "Pastas" ? 'disabled-input' : ''}`}*/}
									{/*	type="search"*/}
									{/*	placeholder="Descrição"*/}
									{/*	onChange={this.filtrarPorDescricao}*/}
									{/*	disabled={this.filtros.tipoArquivo == "Pastas"}*/}
									{/*/>*/}

								</div>
							</div>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>

			<MiniDocView children={this.renderChildren("grade")}/>
			<MiniDocViewLista children={this.renderChildren("lista")} ordenaDados={this.ordenaDadosArquivos}
							  salvarOrdenacao={this.salvarOrdenacao} colunaOrdenada={this.state.colunaOrdenada}
							  ordem={this.state.ordem}/>


			{this.displayPagination()}
		</div>;
	}
}
MainContainer.propTypes = {
     handlePath: PropTypes.func
};
