import ApiService from "../apiService";

export default class pastaService extends ApiService {
  constructor() {
    super("/v1/pastas");
  }

  obterPorId(pastaId) {
    return this.get(`/${pastaId}`);
  }

}
