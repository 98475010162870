import React, {useParams} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {LoginContainer} from "./containers/LoginContainer";
import {DominioContainer} from "./containers/DominioContainer";
import Authentication from './Authentication';

Authentication.login(window.location.search).then((resp) => {

ReactDOM.render((
    <BrowserRouter>
        <Switch>
            <Route path="/login" component={LoginContainer} />

            <App/>
        </Switch>
    </BrowserRouter>
), document.getElementById('root'));

});
registerServiceWorker();
