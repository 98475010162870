import React from "react";
import toast from 'react-hot-toast';
import { withRouter } from 'react-router-dom';
import TjdocsApi from "../api/API";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

  class ExcluirArquivoIcone extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {modal:  false, nomeArquivo: ""  ,descricao: ""};
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
     }
  
     handleClose(){
         this.setState({ modal: false });
     }
  
     handleShow(e){
        e.preventDefault();
        this.setState({ modal: true });

        this.loadState(this.props.documento)

       

     }

     loadState (documento){

            this.setState({ nomeArquivo: documento.nome })
            this.setState({ descricao: documento.descricao })

            console.log(documento)
     }
 
     handleSubmit() {
                 
        TjdocsApi.excluiDocumento(this.props.documento.id)
        .then( response =>

          toast.success('Sucesso!')
        )
        .then( response =>

            this.props.loadComponent(this.props.pastaPai)

        ).catch((error) => {
        	if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
        	toast.error(`Erro ao excluir documeto: ${error}`)
        });
 		this.setState({ modal: false });
     }
 
     handleChange (event){
  
        this.setState({[event.target.name]: event.target.value});
         console.log(event.target.value);
     }
  


     render() {
        const { modal } = this.state;
        const { nomeArquivo } = this.state;
        const { descricao } = this.state;

        return (
            <>

                  <li className="box-body no-padding"
						  style={{listStyle: "none", padding: "7px 10px"}}
					  >
						  <a className="nav-link" href="#" onClick={this.handleShow}>
							  <i className="fas fa-trash-alt"></i> Excluir
						  </a>
					  </li>

  
  
                     <Modal show={modal} onHide={this.handleClose}  size="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>Excluir Arquivo</Modal.Title>
                        </Modal.Header>
                         <Modal.Body>
                          
                            <Form.Group controlId="1">
                                <div key="nome">
                                    {this.props.podeAdicionarRemoverArquivo ? "Deseja realmente excluir o arquivo?" : "Usuário não possui permissão para excluir este arquivo."}
                                    
                                </div>
                            </Form.Group>
                                      
                         </Modal.Body>
  
                        <Modal.Footer>
                        {this.props.podeAdicionarRemoverArquivo && <>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Não
                            </Button>
                            <Button variant="primary" onClick={this.handleSubmit}>
                                Sim
                            </Button></>}
                            {!this.props.podeAdicionarRemoverArquivo && <>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Fechar
                            </Button>
                            </>}    

                        </Modal.Footer>
                    </Modal>
  
                              
             </>
        )
    }
  };
 

 export default withRouter(ExcluirArquivoIcone);