import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";

export class SeachComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="p-c-busc-resultado">
            <div>
                <Link to={{pathname: `/${this.props.tipo}/${this.props.id}`}}>
                    <p className="p-c-b-resltd-titulo" id={this.props.id}>
                        <i class="fa fa-file" style={{display: `${this.props.tipo == 'documentos'?'inline':'none'}`}}></i>
                        <i class="fa fa-folder" style={{display: `${this.props.tipo == 'pastas'?'inline':'none'}`}}></i>&nbsp;
                        {this.props.nomeArquivo}
                    </p>
                </Link>

                <p className="p-c-b-resltd-data">
                    Enviado em:
                    <span className="p-c-b-resltd-endereco p-c-b-resltd-data">
                       {this.props.data}
                  </span> &nbsp;
                </p>


                {this.props.resumo !== null &&
                    <span
                        dangerouslySetInnerHTML={{
                            __html: this.props.resumo.slice(11, this.props.resumo.length - 2)
                        }}
                    />
                }

                {this.props.tipo === 'documentos'  &&
                    <Link to={{pathname: `/pastas/${this.props.local}`}}>
                        <p className="p-c-b-resltd-data">
                            <span className="p-c-b-resltd-endereco p-c-b-resltd-data-only" style={{ fontSize: '12px' }}> <i class="fa fa-folder-open"></i> Clique para abrir pasta pai</span>
                        </p>
                    </Link>
                }
            </div>
        </div>;
    }
}

SeachComponent.propTypes = {
    nomeArquivo: PropTypes.string,
    path: PropTypes.string,
    resumo: PropTypes.string,
    data: PropTypes.string,
    tamanho: PropTypes.number
};