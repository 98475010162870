import React, { useState } from 'react';

import { Button, Col, Form } from 'react-bootstrap';
import TjdocsApi from '../../../api/API';
import { BuscarPastas } from '../../BuscarPastas';
import SearchResultsTable from '../../Tables/SearchResultsTable';
import toast from 'react-hot-toast';

const VinculosTab = ({
  handleTipoVinculoChange,
  handleSelectChangeArquivos,
  removeItemSelecionado,
  arquivosVinculados,
}) => {
  const [loading, setLoading] = useState(false);
  const [mostrarResultados, setMostrarResultados] = useState(false);
  const [data, setData] = useState({
    resultadoPaginado: '',
    resultado: [],
    total: 0,
  });

  const handleSubmitBusca = async (etiquetas) => {
    try {
      setLoading(true);
      const resp = await TjdocsApi.buscaAvancadaDocumento(etiquetas);
      if (!resp || resp.length === 0) {
        setData((prev) => ({
          ...prev,
          resultadoPaginado: [],
        }));
        return;
      }

      setData({
        resultadoPaginado: resp[0].slice(0, 10),
        resultado: resp[0],
      });
      setMostrarResultados(true);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao realizar a busca. Tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setData((prev) => ({
      ...prev,
      resultadoPaginado: prev.resultado.slice(
        (pageNumber - 1) * 10,
        pageNumber * 10,
      ),
    }));
  };

  const limparResultados = () => {
    setMostrarResultados(false);
  };

  return (
    <fieldset>
      <legend>Vínculos de arquivos</legend>

      <Form.Row>
        <Form.Group as={Col} md='6' controlId='3'>
          <Form.Label>Escolha o tipo de vínculo</Form.Label>
          <div key='natureza'>
            <select
              onChange={(data) => {
                handleTipoVinculoChange(data);
              }}
              className='form-control'
              aria-label='Natureza do upload de arquivo'
            >
              <option>Escolha...</option>
              <option>Altera</option>
              <option>Retifica</option>
              <option>Revoga</option>
              <option>Revoga Parcialmente</option>
              <option>Altera e Revoga</option>
              <option>Torna sem Efeito</option>
              <option>Suspende</option>
              <option>Prorroga</option>
            </select>
          </div>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} md='12'>
          <BuscarPastas
            onSubmit={handleSubmitBusca}
            onClean={limparResultados}
          />
          {mostrarResultados && (
            <SearchResultsTable
              results={data.resultadoPaginado}
              loading={loading}
              onSelect={(selectedItem) => {
                handleSelectChangeArquivos(selectedItem);
                setMostrarResultados(false);
              }}
              total={data.resultado.length}
              handlePageChange={handlePageChange}
            />
          )}
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md='12'>
          <div>
            {arquivosVinculados.length > 0 ? (
              <ul>
                {arquivosVinculados.map((arquivo, index) => (
                  <li key={index} style={{ marginBottom: '10px' }}>
                    Documento: {arquivo.documentoVinculado.nome}
                    <Button
                      variant='danger'
                      size='sm'
                      style={{ marginLeft: '10px' }}
                      onClick={() => removeItemSelecionado(index)}
                    >
                      Remover
                    </Button>
                  </li>
                ))}
              </ul>
            ) : (
              <p></p>
            )}
          </div>
        </Form.Group>
      </Form.Row>
    </fieldset>
  );
};

export default VinculosTab;
