import React, { Component } from 'react';

export class MiniDocView extends Component {
  render() {
    var exibicao = 'd-none';
    if (typeof Storage !== 'undefined') {
      if (typeof localStorage.exibicao !== 'undefined') {
        if (localStorage.exibicao === 'miniaturas') exibicao = '';
      } else {
        exibicao = '';
      }
    }

    return (
      <span id='P-DisplayMiniaturas' className={exibicao}>
        <h4 className='sr-only' tabIndex='0' id='P-Inicio_DisplayMiniaturas'>
          Documentos listados em grade.
        </h4>
        {this.props.children}
      </span>
    );
  }
}
