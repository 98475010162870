import React from 'react';
import { Toast, InputGroup, FormControl, Button } from 'react-bootstrap';
import CloseableButton from '../CloseableButton';

export const SearchField = ({
  title,
  placeholder,
  inputType = 'text',
  minValue,
  value,
  onChange,
  onAdd,
  tags,
  onRemoveTag,
  preventSpaces = false,
}) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onAdd();
    }
    if (preventSpaces && e.key === ' ') {
      e.preventDefault();
    }
  };

  return (
    <Toast className='toast-busca-avancada'>
      <Toast.Header className='toast-header-criterio-busca' closeButton={false}>
        <strong className='mr-auto font-card-criterio-busca'>{title}</strong>
      </Toast.Header>
      <Toast.Body>
        <InputGroup className='mb-3'>
          <FormControl
            placeholder={placeholder}
            aria-label={placeholder}
            aria-describedby='basic-addon2'
            type={inputType}
            min={minValue}
            value={value}
            onChange={onChange}
            onKeyDown={handleKeyDown}
          />
          <InputGroup.Append>
            <Button
              variant='outline-secondary'
              onClick={onAdd}
              disabled={value === ''}
            >
              Adicionar
            </Button>
          </InputGroup.Append>
        </InputGroup>
        {tags.map((tag, index) => (
          <CloseableButton
            key={index}
            label={tag}
            onClose={() => onRemoveTag(tag)}
          />
        ))}
      </Toast.Body>
    </Toast>
  );
};
