import React from "react";
import toast from 'react-hot-toast';
import { withRouter } from 'react-router-dom';
import TjdocsApi from "../api/API";


import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";



  class ListarVinculo extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {modal:  false, idVinculo : 0};
        this.handleClose = this.handleClose.bind(this);
     }
  
     handleClose(){
         this.setState({ modal: false });
     }
  
     handleShow(id){
        this.setState({ modal: true });
        this.setState({idVinculo : id});
     }

     loadState (documento){

        console.log(documento)
     }
 
     handleChange (event){
  
        this.setState({[event.target.name]: event.target.value});
         console.log(event.target.value);
     }

     removeVinculoSelecionado = () => {
        TjdocsApi.removerVinculoDocumento(this.state.idVinculo).then( response =>{
            this.props.loadArquivo(this.props.documento.id);
            toast.success('Sucesso!');
        }
        ).catch((error) => {
            if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
                toast.error(`Erro ao remover vinculo: ${error}`)
        });

        this.setState({ modal: false });
      }
  


     render() {
        const { modal } = this.state;

      
        return (
            <>
  
                <div className="p-contd-busca">
                    <div id="itens-selecionados" >  
                    {this.props.vinculos.map((vinculos, index) => {
                        return (<><div class="css-1rhbuit-multiValue" style={{"float" : "left" }} key={index} >
                            <div class="css-12jo7m5"><span style={{"fontSize" : "14px", "color" : "var(--paleta_azul_escuro)"}} >&nbsp;&nbsp;<i class="fa fa-file"></i>&nbsp;{vinculos.documentoVinculadoNome} - {vinculos.tipo}&nbsp;&nbsp;</span></div>
                            <div role="button" class="css-xb97g8" aria-label="Remover" onClick={ () => this.handleShow(vinculos.id) } >
                                <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                            </div>
                            </div>&nbsp;
                        </>)
                    })}  
                    </div>  
                </div>

  
  
                <Modal show={modal} onHide={this.handleClose}  size="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>Excluir Vínculo</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                        
                        <Form.Group controlId="1">
                            <div key="nome">
                                Deseja realmente excluir o vínculo?
                            </div>
                        </Form.Group>
                                    
                        </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Não
                        </Button>
                        <Button variant="primary" onClick={this.removeVinculoSelecionado}>
                            Sim
                        </Button>
                    </Modal.Footer>
                </Modal>
  
                              
             </>
        )
    }
  };
 

 export default withRouter(ListarVinculo);