import React, {Component} from 'react';
import TjdocsApi from "../api/API";
import toast from 'react-hot-toast';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import AsyncSelect from 'react-select/async';

export default class BuscaAvancadaContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ano_filtro: "",
            //dados: [],
            showTooltipUpload: false
          };

        this.handleAnoChange = this.handleAnoChange.bind(this);
     }

     handleAnoChange(e) {
        this.setState({ ano_filtro: e.target.value });
      }

     loadOptionsPesquisaNaPasta = (inputPesquisaNav, callback) => {
        console.log(this.props);
        if (inputPesquisaNav.length >= 3){
            clearTimeout(this.timeOutInputPesquisaNaPasta);
            const timeOut = setTimeout(() => {

                TjdocsApi.pesquisaArquivos(inputPesquisaNav, this.state.ano_filtro).then(jsonPesquisa => {
                    var retorno = jsonPesquisa.map((i, index) => ({
            label: '<span class="optionPesquisaPrincipal">'+
            (i.fonte == 'pasta'?'<i class="fa fa-folder"></i> ':'<i class="fa fa-file"></i> ') +i.texto+' (Criado em: '+i.dataCadastro+')</span>' +
            (i.highlight == null?'':'<br/>' + i.highlight.substring(11, (i.highlight.length-2))),
            value: "/"+i.fonte+"s/"+i.id}));
        callback(retorno);
                }).catch(error => toast.error(`Erro ao tentar pesquisar termos: ${error}`));

            }, 2000);
            this.timeOutInputPesquisaNaPasta = timeOut;
        }
    };

    loadOptionsPesquisaNaPastaPorDecretoLei = (inputPesquisaNav, callback) => {
      console.log(this.props);
           clearTimeout(this.timeOutInputPesquisaNaPasta);
           const timeOut = setTimeout(() => {
    
               TjdocsApi.pesquisaArquivosPorDecretoLei(inputPesquisaNav, this.state.ano_filtro).then(jsonPesquisa => {
                   var retorno = jsonPesquisa.map((i, index) => ({
           label: '<span class="optionPesquisaPrincipal">'+
             (i.fonte == 'pasta'?'<i class="fa fa-folder"></i> ':'<i class="fa fa-file"></i> ') +i.texto+' (Criado em: '+i.dataCadastro+')</span>' +
             (i.highlight == null?'':'<br/>' + i.highlight.substring(11, (i.highlight.length-2))),
           value: "/"+i.fonte+"s/"+i.id}));
         callback(retorno);
               }).catch(error => toast.error(`Erro ao tentar pesquisar termos: ${error}`));
    
           }, 2000);
           this.timeOutInputPesquisaNaPasta = timeOut;
    };
    
    loadOptionsPesquisaNaPastaPorDescricao = (inputPesquisaNav, callback) => {
      console.log(this.props);
      if (inputPesquisaNav.length >= 3){
           clearTimeout(this.timeOutInputPesquisaNaPasta);
           const timeOut = setTimeout(() => {
    
               TjdocsApi.pesquisaArquivosPorDescricao(inputPesquisaNav, this.state.ano_filtro).then(jsonPesquisa => {
                   var retorno = jsonPesquisa.map((i, index) => ({
           label: '<span class="optionPesquisaPrincipal">'+
             (i.fonte == 'pasta'?'<i class="fa fa-folder"></i> ':'<i class="fa fa-file"></i> ') +i.texto+' (Criado em: '+i.dataCadastro+')</span>' +
             (i.highlight == null?'':'<br/>' + i.highlight.substring(11, (i.highlight.length-2))),
           value: "/"+i.fonte+"s/"+i.id}));
         callback(retorno);
               }).catch(error => toast.error(`Erro ao tentar pesquisar termos: ${error}`));
    
           }, 2000);
           this.timeOutInputPesquisaNaPasta = timeOut;
      }
    };

    removeItemSelecionado = (id) => {
        const dadosAux = [...this.props.dados];
        dadosAux.splice(id, 1);
        this.setState({dados : dadosAux});
    }

    onChangeFunc = (optionSelected) => {
        if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "block";
        window.location.href = optionSelected.value;
    }

    handleItensSelecionados = (data) => {
        if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "block";
        if(data.length > 0) { 
          document.getElementById('buscaAvancadaButtonUpload').click()
        }
        const dadosAux = [...this.props.dados, data];
        this.setState({dados : dadosAux});
        this.props.adicionaArquivo(data);
    }

    loadOptions = (inputPesquisaNav, callback) => {
        if (inputPesquisaNav.length >= 3) {
          clearTimeout(this.timeOutInputPesquisaNav);
          const timeOut = setTimeout(() => {
            TjdocsApi.pesquisaConteudo(inputPesquisaNav)
              .then((jsonPesquisa) => {
                var retorno = jsonPesquisa.map((i, index) => ({
                  label:
                    '<span class="optionPesquisaPrincipal">' +
                    (i.fonte == "pasta"
                      ? '<i class="fa fa-folder"></i> '
                      : '<i class="fa fa-file"></i> ') +
                    i.texto + ' - (Enviado em: ' + i.dataCadastro  +  ')' +
                    "</span>" +
                    (i.highlight == null
                      ? ""
                      : "<br/>" +
                        i.highlight.substring(11, i.highlight.length - 2)),
                  value: "/" + i.fonte + "s/" + i.id,
                }));
                retorno.push({
                  label: "+ Mais Resultados",
                  value: "/busca/" + inputPesquisaNav,
                });
    
                if (inputPesquisaNav == this.state.textoDigitadoPesquisa)
                  callback(retorno);
              })
              .catch((error) =>
                toast.error(`Erro ao tentar pesquisar termos: ${error}`)
              );
          }, 2000);
          this.timeOutInputPesquisaNav = timeOut;
        } else {
          this.timeOutInputPesquisaNav = setTimeout(() => {}, 100);
          callback([]);
        }
      };

      textoDigitadoPesquisa = (inputValue) => {
        if (inputValue.length != 0)
          this.setState({ textoDigitadoPesquisa: inputValue });
      };

      processarHTML = (html) => {
        console.log(html);
        html = html <= 120 ? html : html.substring(0, 120);
        console.log(html);
        return { __html: html };
      };
    
      renderizarHTML = (html) => {       
        return <div dangerouslySetInnerHTML={this.processarHTML(html)} />;
      };

    criarDivsDinamicas = () => {
        console.log(this.props.dados);
        return this.props.dados.map((i, index) => ( 
          i[0] != null && 
            <div class="css-1rhbuit-multiValue" style={{"float" : "left"}} key={index} >
                        <div class="css-12jo7m5"><span style={{"fontSize" : "14px"}} >{this.renderizarHTML(i[0].label)}</span></div>
                        <div role="button" class="css-xb97g8" aria-label="Remover" onClick={ () => this.props.removeItemSelecionado(index) } >
                        <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                    </div>
                </div>
        ));
    } 

    openTooltip = () => {
      console.log(this.state.showTooltipUpload)
      this.setState({ showTooltipUpload : true});
    };
  
    closeTooltip = () => {
      console.log(this.state.showTooltipUpload)
      this.setState({ showTooltipUpload : false});
    };
  
    handleTooltip = () => {
      this.setState({ showTooltipUpload : !this.state.showTooltipUpload});
    }


render() {

  const { showTooltipUpload } = this.state;



        return (
          <div className="p-contd-busca">
            <OverlayTrigger
                    trigger="click"
                    placement="top"
                    overlay={<Tooltip > 
        
                    <div className="box-pesquisa box-primary" style={{margin: 0, width: "1200px" }}>
                            <div className="box-header-pesquisa with-border box-defaultClaro">
                                <h3 className="box-title">
                                    <a role="button" href="#" onClick={(e) => e.preventDefault()}>
                                        Pesquisar <i style={{"float": "right"}} className="fas fa-times" onClick={() => document.getElementById('buscaAvancadaButtonUpload').click() }/>
                                    </a>
                                </h3>
                            </div>
                            <div className="box-body no-padding" style={{color: "#333", fontSize: "180%", padding: "10px" }}>
                                
                                <div className="mb-7">
                                    <input  className="form-control" type="text" id="anoFiltroUpload" onChange={this.handleAnoChange}   maxLength="4" placeholder="Por Ano" style={{"textAlign" : "center"}}  />
                                </div><div style={{marginBottom: "5px"}} />
            
                                <AsyncSelect id="inputPesquisaTituloUpload" className="w-100" placeholder="Por parte do título do documento" style={{textAlign: "left" }}
                                    isMulti
                                    loadOptions={this.loadOptionsPesquisaNaPasta}
                                    onChange={this.props.handleItensSelecionados}
                                    onInputChange={(inputPesquisaNav) => {
                                      if(inputPesquisaNav == "") return false;
                                    }}
                                    formatOptionLabel={function(data) {
                                            
                                            return (
                                                <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                            );
                                        }}
                                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                                /><div style={{marginBottom: "5px"}} />
            
            
                                <AsyncSelect id="inputPesquisaConteudo" className="w-100" placeholder="No conteúdo do documento" style={{textAlign: "left" }}
                                                        isMulti
                                                        loadOptions={this.loadOptions}
                                                        onChange={this.props.handleItensSelecionados}
                                                        onInputChange={this.textoDigitadoPesquisa}
                                                        formatOptionLabel={function(data) {
                                                            return (
                                                                <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                                            );
                                                        }}
                                    noOptionsMessage={() => 'Nenhum registro encontrado'}
                                                    /><div style={{marginBottom: "5px"}} />
            
                            <AsyncSelect id="inputPesquisaLeiDecreto" className="w-100" placeholder="N° da lei ou decreto" style={{textAlign: "left" }}
                                    isMulti
                                    loadOptions={this.loadOptionsPesquisaNaPastaPorDecretoLei}
                                    onChange={this.props.handleItensSelecionados}
                                    formatOptionLabel={function(data) {
                                            return (
                                                <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                            );
                                        }}
                                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                                /><div style={{marginBottom: "5px"}} />
            
                                <AsyncSelect id="descricao" className="w-100" placeholder="Descrição" style={{textAlign: "left" }}
                                    isMulti
                                    loadOptions={this.loadOptionsPesquisaNaPastaPorDescricao}
                                    onChange={this.props.handleItensSelecionados}
                                    formatOptionLabel={function(data) {
                                            return (
                                                <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                            );
                                        }}
                                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                                    /><div style={{marginBottom: "5px"}} /> 
            
                            </div>
                        </div>
                    </Tooltip> }
                    rootClose={false}
                    show={showTooltipUpload}
                    onEnter={() => {this.openTooltip() }}
                >
                    <input type="button" value="Adicionar Vínculo" id="buscaAvancadaButtonUpload" onClick={() => {this.handleTooltip()}} />
                </OverlayTrigger> <br /><br />
              <div id="itens-selecionados" >{this.criarDivsDinamicas()}</div>    
                                   
                


          </div>
        );
     }
}
