import React, { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { useHistory } from 'react-router-dom';
import { IoHomeOutline } from 'react-icons/io5';
import { Spinner } from 'react-bootstrap';

const ROW_TYPES = {
  DOCUMENTO: 'documento',
  PASTA: 'pasta',
};

const IconName = ({ name, type, highlight, path }) => {
  const normalizedLocal = path ? `/${path.replace(/^\/+|\/+$/g, '')}` : '';
  return (
    <div className='d-flex flex-row' style={{ gap: '10px' }}>
      <div style={{ minWidth: '35px', minHeight: '35px' }}>
        {type === ROW_TYPES.DOCUMENTO ? (
          <svg
            className='imagem_Cards icones-arquivo svg-cinza-claro'
            x='0px'
            y='0px'
            viewBox='0 0 60 60'
          >
            <path
              d='M38.914,0H6.5v60h47V14.586L38.914,0z M17.5,14h10c0.552,0,1,0.447,1,1s-0.448,1-1,1h-10c-0.552,0-1-0.447-1-1  S16.948,14,17.5,14z M42.5,48h-25c-0.552,0-1-0.447-1-1s0.448-1,1-1h25c0.552,0,1,0.447,1,1S43.052,48,42.5,48z M42.5,40h-25  c-0.552,0-1-0.447-1-1s0.448-1,1-1h25c0.552,0,1,0.447,1,1S43.052,40,42.5,40z M42.5,32h-25c-0.552,0-1-0.447-1-1s0.448-1,1-1h25  c0.552,0,1,0.447,1,1S43.052,32,42.5,32z M42.5,24h-25c-0.552,0-1-0.447-1-1s0.448-1,1-1h25c0.552,0,1,0.447,1,1S43.052,24,42.5,24z   M37.5,16V2l14,14H37.5z'
              fill='#333333'
            />
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
        ) : (
          <svg
            className='imagem_Cards icones-arquivo svg-azul-escuro'
            x='0px'
            y='0px'
            viewBox='0 0 60 60'
          >
            <g>
              <path
                d='M14,23.5c-0.254,0-0.479,0.172-0.545,0.417L2,52.5v1c0,0.734-0.047,1,0.565,1h44.759c1.156,0,2.174-0.779,2.45-1.813   L60,24.5c0,0,0-0.625,0-1H14z'
                fill='#333333'
              />
              <path
                d='M12.731,21.5H53h1v-6.268c0-1.507-1.226-2.732-2.732-2.732H26.515l-5-7H2.732C1.226,5.5,0,6.726,0,8.232v41.796   l10.282-26.717C10.557,22.279,11.575,21.5,12.731,21.5z'
                fill='#333333'
              />
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
        )}
      </div>
      <div className='d-flex justify-content-end flex-column'>
        <span>{name}</span>
        <small className='mt-2'>
          {normalizedLocal ? (
            <>
              <IoHomeOutline className='mb-1' />
              {normalizedLocal}{' '}
            </>
          ) : (
            ''
          )}
        </small>
        {highlight && (
          <>
            <hr style={{ width: '50%', height: '1px', margin: '5px 0px' }} />
            <div className='d-flex flex-column' style={{ fontSize: '13px' }}>
              <span style={{ fontWeight: 'bold' }}>Conteúdo</span>
              <span
                style={{ fontStyle: 'italic' }}
                dangerouslySetInnerHTML={{
                  __html: highlight.slice(11, highlight.length - 2),
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const TableRow = React.memo(({ row, onSelect }) => {
  const history = useHistory();
  const decreto = row.numero;
  let status = '';
  if (row.fonte === ROW_TYPES.DOCUMENTO) {
    status = row.exaurido ? 'Exaurido' : row.situacao;
  }

  function handleClick() {
    console.log('handleClick', row);
    if (onSelect) {
      onSelect(row);
    } else {
      const path =
        row.fonte === ROW_TYPES.DOCUMENTO
          ? `/documentos/${row.id}`
          : `/pastas/${row.id}`;
      history.push(path);
    }
  }

  return (
    <tr>
      <td className={row.highlight ? 'column-50' : ''}>
        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
          <IconName
            name={row.texto || 'Nome arquivo'}
            type={row.fonte}
            highlight={row.highlight}
            path={row.local}
          />
        </div>
      </td>
      <td className={row.highlight ? 'column-30' : ''}>{row.ementa}</td>
      <td>{decreto}</td>
      <td>{status}</td>
      <td>{row.dataCadastro}</td>
    </tr>
  );
});

const SearchResultsTable = ({
  results,
  qtdPorPagina = 10,
  total,
  onSelect,
  loading = false,
  handlePageChange,
}) => {
  const [elementos, setElementos] = useState({
    inicial: 1,
    final: 10,
  });
  const [paginaAtual, setPaginaAtual] = useState(1);

  useEffect(() => {
    setElementos({
      inicial: (paginaAtual - 1) * qtdPorPagina + 1,
      final: paginaAtual * qtdPorPagina,
    });
  }, [paginaAtual, qtdPorPagina]);

  const handlePaginationChange = (pageNumber) => {
    setPaginaAtual(pageNumber);
    handlePageChange(pageNumber);
  };

  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <Spinner
          animation='border'
          variant='primary'
          className='mt-5 mb-5 mx-auto'
        />
      </div>
    );
  }

  if (!results) {
    return <></>;
  }

  return (
    <>
      <table className='table table-sm table-hover' tabIndex='0'>
        <thead tabIndex='0'>
          <tr>
            <th>Pasta/Documento</th>
            <th>Ementa</th>
            <th>Nr. Ato</th>
            <th>Situação</th>
            <th>Data da Criação</th>
          </tr>
        </thead>
        <tbody>
          {results && results.length === 0 ? (
            <tr>
              <td colSpan='5' className='text-center'>
                Nenhum resultado encontrado
              </td>
            </tr>
          ) : (
            results.map((row) => (
              <TableRow key={row.id} row={row} onSelect={onSelect} />
            ))
          )}
        </tbody>
      </table>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          className='p-c-busc-quantificacao'
          style={{
            display: 'block',
            color: 'var(--paleta_cinza_escuro)',
          }}
        >
          Exibindo {elementos.inicial} até {elementos.final} de {total}{' '}
          resultados
        </div>

        <Pagination
          activeClass='active'
          activeLinkClass='disabled'
          activePage={paginaAtual}
          disabledClass='disabled'
          innerClass='pagination p-c-b-paginacao'
          itemClass='page-item p-c-b-pagnc-paginas'
          itemsCountPerPage={qtdPorPagina}
          linkClass='page-link'
          onChange={handlePaginationChange}
          pageRangeDisplayed={5}
          firstPageText='Primeira'
          lastPageText='Última'
          nextPageText='Próximo'
          prevPageText='Anterior'
          totalItemsCount={total}
        />
      </div>
    </>
  );
};

export default SearchResultsTable;
