import React, { Component, useState } from "react";
import * as PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { sendLoginData } from "../actions/sendLogin";
import { sendLogout } from "../actions/sendLogout";
import JWT from "jwt-decode";
import { Toaster } from "react-hot-toast";
import AsyncSelect from "react-select/async";
import TjdocsApi from "../api/API";
import toast from "react-hot-toast";
import Authentication from "../Authentication";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import { ListaRelease } from "./ListaRelease";


export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokens: ["teste", "teste1"],
      email: "",
      password: "",
      textoDigitadoPesquisa: "",
      inputValue: '',
      ano_filtro: "",
      dadoTitulo: "",
      dadoConteudo: "",
      dadoLeiDecreto: "",
      dadoDescricao: "",
      loadedOptionsDescricao: [],
      exibeListaRelease: false,
      exibeEditarRelease : false,
      exibeCadastraRelease: false,
      releaseSelecionado : null,
      listaRelease: [],
      showTooltip: false,
      buscarPorConteudo: true
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleAnoChange = this.handleAnoChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.bodyLoad = this.bodyLoad.bind(this);
    this.loadLoginData = this.loadLoginData.bind(this);
    this.logout = this.logout.bind(this);
    this.usuario = Authentication.getUsuario();
    this.handleExibeListaRelease = this.handleExibeListaRelease.bind(this);
    this.handleCadastraRelease = this.handleCadastraRelease.bind(this);
    this.handleEditarRelease = this.handleEditarRelease.bind(this);
    this.permissoes = "";

  }

  handleExibeListaRelease = () => {
    
    TjdocsApi.listaRelease().then(releases => {
      console.log(releases);
      this.setState({listaRelease : releases._embedded.documentos});
      }).catch(error => toast.error(`Erro ao tentar consultar os domínios: ${error}`));

      if(this.state.exibeListaRelease) {
        this.setState({exibeListaRelease: false});
        return;
      }

      this.setState({exibeListaRelease: true});

  }

  componentWillMount() {
      const params = this.props.variavelPath.split("/");
      
      this.setState({ ano_filtro: "" });
      this.setState({ dadoTitulo : "" });
      this.setState({ dadoConteudo : "" });
      this.setState({ dadoLeiDecreto : "" });
      this.setState({ dadoDescricao : "" });

      if(params[1] == 'documentos') {
        this.setState({ ano_filtro: params[3] === 'undefined' ? '' : params[3] });
        this.setState({ dadoTitulo : params[4] === 'undefined' ? '' : params[4] });
        this.setState({ dadoConteudo : params[5] === 'undefined' ? '' : params[5] });
        this.setState({ dadoLeiDecreto : params[6] === 'undefined' ? '' : params[6] });
        this.setState({ dadoDescricao : params[7] === 'undefined' ? '' : params[7] });
      }
  }

  limparState() {
      this.setState({ dadoTitulo : "" });
      this.setState({ dadoConteudo : "" });
      this.setState({ dadoLeiDecreto : "" });
      this.setState({ dadoDescricao : "" });
  }
 

  handleChange = (event) => {
    this.setState({
      tokens: event.target.value,
    });
  };

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleAnoChange(e) {
    this.setState({ ano_filtro: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handleSubmit(event) {
    var data = { usuario: this.state.email, senha: this.state.password };
    sendLoginData(data);
    event.preventDefault();
  }

  PesquisaNaPastaPorAno = (inputPesquisaNav, callback) => {
    console.log(inputPesquisaNav + "Junior teste")
    if(inputPesquisaNav.length === 4){
      
      clearTimeout(this.timeOutInputPesquisaNaPasta);
      const timeOut = setTimeout(() => {
  
          TjdocsApi.pesquisaTudo(this.state.dadoTitulo, this.state.dadoDescricao, this.state.dadoLeiDecreto, this.state.ano_filtro, this.state.dadoConteudo).then(jsonPesquisa => {
              var retorno = jsonPesquisa.map((i, index) => ({
        label: '<span class="optionPesquisaPrincipal">'+
        (i.fonte == 'pasta'?'<i class="fa fa-folder"></i> ':'<i class="fa fa-file"></i> ') +i.texto+'</span>' +
        (i.highlight == null?'':'<br/>' + i.highlight.substring(11, (i.highlight.length-2))),
        value: "/"+i.fonte+"s/"+i.id}));
          callback(retorno);
          }).catch(error => toast.error(`Erro ao tentar pesquisar termos: ${error}`));
  
      }, 2000);
      this.timeOutInputPesquisaNaPasta = timeOut;
    }
  }

  loadOptionsPesquisaNaPasta = (inputPesquisaNav, callback) => {
    
    if (inputPesquisaNav.length >= 3){
         clearTimeout(this.timeOutInputPesquisaNaPasta);
         const timeOut = setTimeout(() => {

             TjdocsApi.pesquisaTudo(this.state.dadoTitulo, this.state.dadoDescricao, this.state.dadoLeiDecreto, this.state.ano_filtro, this.state.dadoConteudo).then(jsonPesquisa => {
                 var retorno = jsonPesquisa.map((i, index) => ({
         label: '<span class="optionPesquisaPrincipal">'+
           (i.fonte == 'pasta'?'<i class="fa fa-folder"></i> ':'<i class="fa fa-file"></i> ') +i.texto+'</span>' +
           (i.highlight == null?'':'<br/>' + i.highlight.substring(11, (i.highlight.length-2))),
         value: "/"+i.fonte+"s/"+i.id}));
       callback(retorno);
             }).catch(error => toast.error(`Erro ao tentar pesquisar termos: ${error}`));

         }, 2000);
         this.timeOutInputPesquisaNaPasta = timeOut;
    }
 };

 loadOptionsPesquisaNaPastaPorDecretoLei = (inputPesquisaNav, callback) => {
       
       clearTimeout(this.timeOutInputPesquisaNaPasta);
       const timeOut = setTimeout(() => {

           TjdocsApi.pesquisaTudo(this.state.dadoTitulo, this.state.dadoDescricao, this.state.dadoLeiDecreto, this.state.ano_filtro, this.state.dadoConteudo).then(jsonPesquisa => {
               var retorno = jsonPesquisa.map((i, index) => ({
       label: '<span class="optionPesquisaPrincipal">'+
         (i.fonte == 'pasta'?'<i class="fa fa-folder"></i> ':'<i class="fa fa-file"></i> ') +i.texto+'</span>' +
         (i.highlight == null?'':'<br/>' + i.highlight.substring(11, (i.highlight.length-2))),
       value: "/"+i.fonte+"s/"+i.id}));
     callback(retorno);
           }).catch(error => toast.error(`Erro ao tentar pesquisar termos: ${error}`));

       }, 2000);
       this.timeOutInputPesquisaNaPasta = timeOut;
};

loadOptionsPesquisaNaPastaPorDescricao = (inputPesquisaNav, callback) => {
  
  if (inputPesquisaNav.length >= 3){
       clearTimeout(this.timeOutInputPesquisaNaPasta);
       const timeOut = setTimeout(() => {

           TjdocsApi.pesquisaTudo(this.state.dadoTitulo, this.state.dadoDescricao, this.state.dadoLeiDecreto, this.state.ano_filtro, this.state.dadoConteudo).then(jsonPesquisa => {
               var retorno = jsonPesquisa.map((i, index) => ({
       label: '<span class="optionPesquisaPrincipal">'+
         (i.fonte == 'pasta'?'<i class="fa fa-folder"></i> ':'<i class="fa fa-file"></i> ') +i.texto+' (Criado em: '+i.dataCadastro+')</span>' +
         (i.highlight == null?'':'<br/>' + i.highlight.substring(11, (i.highlight.length-2))),
       value: "/"+i.fonte+"s/"+i.id}));
     callback(retorno);
           }).catch(error => toast.error(`Erro ao tentar pesquisar termos: ${error}`));

       }, 2000);
       this.timeOutInputPesquisaNaPasta = timeOut;
  }
};

  bodyLoad() {
    document.querySelector(".dropdown-menu").onClick = function (e) {
      e.stopPropagation();
    };

    if (document.querySelector("#alterar_P-DisplayLista") != undefined)
      document.querySelector("#alterar_P-DisplayLista").onClick = function () {
        if (typeof Storage !== "undefined") localStorage.exibicao = "lista";
        document
          .querySelector("#alterar_P-DisplayLista")
          .classList.toggle("d-none");
        document.querySelector("#P-DisplayLista").classList.toggle("d-none");
        document
          .querySelector("#alterar_P-DisplayMiniaturas")
          .classList.toggle("d-none");
        document
          .querySelector("#P-DisplayMiniaturas")
          .classList.toggle("d-none");
        //#P-Inicio_DisplayLista focus
      };

    if (document.querySelector("#alterar_P-DisplayMiniaturas") != undefined)
      document.querySelector("#alterar_P-DisplayMiniaturas").onClick =
        function () {
          if (typeof Storage !== "undefined")
            localStorage.exibicao = "miniaturas";
          document
            .querySelector("#alterar_P-DisplayLista")
            .classList.toggle("d-none");
          document.querySelector("#P-DisplayLista").classList.toggle("d-none");
          document
            .querySelector("#alterar_P-DisplayMiniaturas")
            .classList.toggle("d-none");
          document
            .querySelector("#P-DisplayMiniaturas")
            .classList.toggle("d-none");
        };
  }

  loadLoginData() {
    if (typeof Storage !== "undefined") {
      if (typeof sessionStorage.Bearer !== "undefined") {
        var decoded = JWT(sessionStorage.token);
        //Atualizações visuais
        document.querySelector(".btn-login-right").style.display = "none";
        document.querySelector(".busca_Principal_search").style.width = "100%";
        document.querySelector(".user-name-header span").innerHTML =
          "<i>" + decoded.sub + "</i>";
        document.querySelector(".user-name-header").style.display = "block";
      }
    }
  }

  logout() {
    sendLogout();
  }

  timeOutInputPesquisaNav = setTimeout(() => {}, 100);

  loadOptions = (inputPesquisaNav, callback) => {
    
    if (inputPesquisaNav.length >= 3) {
      clearTimeout(this.timeOutInputPesquisaNav);
      const timeOut = setTimeout(() => {
        TjdocsApi.pesquisaTudo(this.state.dadoTitulo, this.state.dadoDescricao, this.state.dadoLeiDecreto, this.state.ano_filtro, this.state.dadoConteudo)
          .then((jsonPesquisa) => {
            var retorno = jsonPesquisa.map((i, index) => ({
              label:
                '<span class="optionPesquisaPrincipal">' +
                (i.fonte == "pasta"
                  ? '<i class="fa fa-folder"></i> '
                  : '<i class="fa fa-file"></i> ') +
                i.texto + ' - (Enviado em: ' + i.dataCadastro  +  ')' +
                "</span>" +
                (i.highlight == null
                  ? ""
                  : "<br/>" +
                    i.highlight.substring(11, i.highlight.length - 2)),
              value: "/" + i.fonte + "s/" + i.id,
            }));
            retorno.push({
              label: "+ Mais Resultados",
              value: "/busca/" + inputPesquisaNav,
            });

            if (inputPesquisaNav == this.state.textoDigitadoPesquisa)
              callback(retorno);
          })
          .catch((error) =>
            toast.error(`Erro ao tentar pesquisar termos: ${error}`)
          );
      }, 2000);
      this.timeOutInputPesquisaNav = timeOut;
    } else {
      this.timeOutInputPesquisaNav = setTimeout(() => {}, 100);
      callback([]);
    }
  };

  loadOptionsGeral = (inputPesquisaNav, callback) => {
    if (inputPesquisaNav.length >= 3) {
      clearTimeout(this.timeOutInputPesquisaNav);
      const timeOut = setTimeout(() => {
        TjdocsApi.pesquisaComFiltroDeConteudo(inputPesquisaNav, this.state.buscarPorConteudo)
          .then((jsonPesquisa) => {
            var retorno = jsonPesquisa.map((i, index) => ({
              label:
                '<span class="optionPesquisaPrincipal">' +
                (i.fonte === 'pasta'
                  ? '<i class="fa fa-folder"></i> '
                  : '<i class="fa fa-file"></i> ') +
                i.texto + ' - (Enviado em: ' + i.dataCadastro + ')' +
                "</span>" +
                (i.highlight == null
                  ? ""
                  : "<br/>" +
                    i.highlight.substring(11, i.highlight.length - 2)),
              value: "/" + i.fonte + "s/" + i.id,
            }));
            retorno.push({
              label: "+ Mais Resultados",
              value: "/busca/" + inputPesquisaNav,
            });

            if (inputPesquisaNav === this.state.textoDigitadoPesquisa)
              callback(retorno);
          })
          .catch((error) =>
            toast.error(`Erro ao tentar pesquisar termos: ${error}`)
          );
      }, 2000);
      this.timeOutInputPesquisaNav = timeOut;
    } else {
      this.timeOutInputPesquisaNav = setTimeout(() => { }, 100);
      callback([]);
    }
  };

  onChangeFunc = (optionSelected) => {
    console.log(optionSelected);
    if (document.querySelector(".loading"))
      document.querySelector(".loading").style.display = "block";
    window.location.href = optionSelected.value + `/${this.state.ano_filtro}/${this.state.dadoTitulo}/${this.state.dadoConteudo}/${this.state.dadoLeiDecreto}/${this.state.dadoDescricao}`;
  };

  textoDigitadoPesquisa = (inputValue, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      this.setState({ inputValue, textoDigitadoPesquisa: inputValue });
    }
  };

  buscaPrincipalRedirectTela = () => {
    if (this.state.textoDigitadoPesquisa.length >= 3) {
      if (document.querySelector(".loading"))
        document.querySelector(".loading").style.display = "block";
      window.location.href = `/busca/${this.state.textoDigitadoPesquisa}`;
    }
  };
 
  LoginButton = () => {
    return (
      <div >
        <a href="https://portaltj.tjgo.jus.br/sistemas/index.php?s=corporativo" style={{ color: "#fff" }}>Usu&aacute;rio n&atilde;o logado - clique aqui para LOGIN</a>
      </div>
     );
         
  }

  handleCadastraRelease = () => {
    console.log(this.state.exibeCadastraRelease);
    this.setState({exibeCadastraRelease : this.state.exibeCadastraRelease ? false : true});
  }

  handleEditarRelease = (e, release) => {
    this.setState({releaseSelecionado : release})
    this.setState({exibeEditarRelease : this.state.exibeEditarRelease ? false : true });
  }

  openTooltip = () => {
    console.log(this.state.showTooltip)
    this.setState({ showTooltip : true});
  };

  closeTooltip = () => {
    console.log(this.state.showTooltip)
    this.setState({ showTooltip : false});
  };

  alterarBuscarPorConteudo = () => {
    this.setState({ buscarPorConteudo : !this.state.buscarPorConteudo});
    console.log(this.state.buscarPorConteudo);
  };

  handleTooltip = () => {
    this.setState({ showTooltip : !this.state.showTooltip});
  }

  handleInputBlur = () => {
    // Não faz nada ao perder o foco para evitar reverter o valor digitado
  };

  handleConteudoInputChange = (inputPesquisaNav, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      this.setState({ dadoConteudo: inputPesquisaNav });
      this.textoDigitadoPesquisa(inputPesquisaNav, actionMeta);
    }
  };

  handleDecretoLeiInputChange = (inputPesquisaNav, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      this.setState({ dadoLeiDecreto: inputPesquisaNav });
    }
  };

  handleDescricaoInputChange = (inputPesquisaNav, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      this.setState({ dadoDescricao: inputPesquisaNav });
    }
  };
  
    render() {
      const { showTooltip } = this.state;
      /*const tooltipPesquisa = () => (
         
      );*/

		
        return <div className="princpl-header" onLoad={this.bodyLoad}>
					
					<div><Toaster/></div>{/* notificações - react-hot-toast */}
					
					<div className="row" id="barraAcessibilidade">{/* barra de acessibilidade */}

						<div className="">
							<div className="atalhos">
								<ul> 
									<li><a href="#conteudo" tabIndex="0" accessKey="1" id="entrada">Ir para o conteúdo <span className="access-content">1</span></a></li> &nbsp;&nbsp;
									<li><a href="#mainmenu" accessKey="2">Ir para o menu <span className="access-content">2</span></a></li> &nbsp;&nbsp;
									<li><a href="#pesquisa" accessKey="3">Ir para a pesquisa <span className="access-content">3</span></a></li>
								</ul>
							</div>{/* atalhos */}
						</div>

						<div className="">
							<div className="ajustes"> 
								<ul>
									<li><a href="#" className="itemDeMenu d-none" accessKey="4" title="Instruções de Acessibilidade">Acessibilidade</a></li>
									<li><a href="#altocontraste" id="altocontraste" aria-label="Alto Contraste" title="Alto Contraste" accessKey="5" className="theme-link"><div className="circuloContrastePreto"></div></a></li> &nbsp;
									<li><a href="#" aria-label="Aumentar Fonte" title="Aumentar Fonte" accessKey="6" /* onClick="fonte('a')"*/>A +</a></li>
									<li><a href="#" aria-label="Aumentar Fonte" title="Aumentar Fonte" accessKey="6" /* onClick="fonte('a')"*/>A</a></li> &nbsp;
									<li><a href="#" aria-label="Diminuir Fonte" title="Diminuir Fonte" accessKey="7" /*onClick="fonte('d')"*/>A -</a></li>
								</ul>
							</div>{/* ajustes */}
						</div>
						
					</div>
		

            <nav className="navbar navbar-expand-md navbar-light">
                <h1>
					<Link to="/">
						<img className="logo_TJdocs" src="/ui/img/logo_tjdocs.png" title="Logotipo do TJDOCS"/>

                        <span style={{color : "#0FBDD2"}}>
                            <a onClick={this.handleExibeListaRelease}
                               style={{
                                   color: "#FFF",
                                   fontSize: "20px",
                                   position: "relative",
                                   top: "10px"
                               }}>
                                2.2
                            </a>
                        </span>
                    </Link>
          <ListaRelease exibeListaRelease={this.state.exibeListaRelease} closeListaRelease={this.handleExibeListaRelease} listaRelease={this.state.listaRelease} handleCadastraRelease={this.handleCadastraRelease} handleEditarRelease={this.handleEditarRelease} exibeCadastraRelease={this.state.exibeCadastraRelease} exibeEditarRelease={this.state.exibeEditarRelease}  releaseSelecionado={this.state.releaseSelecionado}  />
					<div className="loading">
						<div className="loading-ico"></div>
					</div>

                </h1>

                <button className="n-collps-button icones" type="button" data-toggle="collapse"
                        data-target="#navbarPrincipal" title="Expandir menu">
                    <img className="icones" src="/ui/img/Menu_icon.svg" alt=""/>
                </button>

                <div className="collapse navbar-collapse" id="navbarPrincipal">

                    <div className="offset-md-5 p-hedr-opceos p-c-menTp-Opcoes col-md-7 texto_BreakAll">
						<div className="row margin-none">
							<div className="col-md-10 offset-md-2 col-sm-12 padding-header-bars">
                                <div className="row">
                                    <div style={{textAlign: "left", color: "white", width: "30%", marginBottom: "1px", marginTop: "5px"}}>
                                        <input type="checkbox" id="checkboxBuscarPorConteudo"
                                               value={this.state.buscarPorConteudo}
                                               checked={this.state.buscarPorConteudo}
                                               onChange={() => this.alterarBuscarPorConteudo()}/>
                                        <label htmlFor="checkboxBuscarPorConteudo" style={{paddingLeft: "5px"}}>
                                            Buscar por conteúdo
                                        </label>
                                    </div>
                                    <div style={{textAlign: "right", width: "70%", marginBottom: "1px"}}>
										<span style={{color: "#fff"}}>
											{this.usuario.nome_completo == undefined
											? <this.LoginButton/>
											: "Olá " + this.usuario.nome_completo + " - "}{" "}
											<i>
                                                {this.usuario.authorities && this.usuario.authorities.length > 1
                                                ? this.usuario.authorities.join(' / ')
                                                : this.usuario.authorities}
                                            </i>

                                            {this.usuario.nome_completo == undefined ? "" : <span  onClick={this.logout} dangerouslySetInnerHTML={{ __html: "&nbsp; <a  href='#' title='Sair do sistema'class='btnprincipal_sair'><i class='fas fa-sign-out-alt'></i></a>"}}/> }
										</span>  
                                      
									</div>

								<div onLoad={this.loadLoginData} className="busca_Principal_search w-100"  >
                <div style={{float: "right", marginLeft: "5px" }} >
                    <OverlayTrigger
                          trigger="click"
                          placement="top"
                          overlay={<Tooltip> 
                            <div className="box-pesquisa box-primary" style={{margin: 0, width: "1200px" }}>
                              <div className="box-header-pesquisa with-border box-defaultClaro">
                                <h3 className="box-title">
                                <a role="button" href="#" onClick={(e) => e.preventDefault()}>
                                  Pesquisar <i style={{"float": "right"}} className="fas fa-times" onClick={() => { document.getElementById('buscaAvancadaButton').click() }} />
                                </a>
                                </h3>
                              </div>            
                              <div className="box-body no-padding" style={{color: "#333", fontSize: "180%", padding: "10px" }}>
                                  <div className="mb-7">
                                      <AsyncSelect  
                                        id="anoFiltro"   
                                        className="w-100" 
                                        placeholder="Por Ano" 
                                        style={{ textAlign: "center" }}
                                        loadOptions={this.PesquisaNaPastaPorAno}
                                        inputValue={this.state.ano_filtro} 
                                        onInputChange={(inputPesquisaNav, actionMeta) => {
                                          if (actionMeta.action === 'input-change') {
                                            this.setState({ ano_filtro: inputPesquisaNav });
                                          }
                                        }}
                                        onBlur={() => this.setState({ ano_filtro: this.state.ano_filtro })}
                                        maxLength="4" 
                                        onChange={this.onChangeFunc} 
                                        formatOptionLabel={(data) => (
                                          <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                        )}
                                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                                      />
                                  </div><div style={{marginBottom: "5px"}} />
                  
                                  <AsyncSelect
                                    id="inputPesquisaTitulo"
                                    className="w-100"
                                    placeholder="Por parte do título do documento"
                                    style={{ textAlign: "center" }}
                                    loadOptions={this.loadOptionsPesquisaNaPasta}
                                    inputValue={this.state.dadoTitulo}
                                    onInputChange={(inputPesquisaNav, actionMeta) => {
                                      if (actionMeta.action === 'input-change') {
                                        this.setState({ dadoTitulo: inputPesquisaNav });
                                      }
                                    }}
                                    onBlur={() => this.setState({ dadoTitulo: this.state.dadoTitulo })}
                                    onChange={this.onChangeFunc}
                                    formatOptionLabel={(data) => (
                                      <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                    )}
                                    noOptionsMessage={() => 'Nenhum registro encontrado'}
                                  />
                                    <div style={{marginBottom: "5px"}} />
                  
                  
                                  <AsyncSelect id="inputPesquisaConteudo" className="w-100" placeholder="No conteúdo do documento"  style={{textAlign: "center" }}
                                      loadOptions={this.loadOptions}
                                      inputValue={ this.state.dadoConteudo }
                                      onChange={this.onChangeFunc}
                                      onInputChange={this.handleConteudoInputChange}
                                      onBlur={() => this.setState({ dadoConteudo: this.state.dadoConteudo })}
                                      formatOptionLabel={function(data) {
                                        return (
                                          <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                        );
                                      }}
                                      noOptionsMessage={() => 'Nenhum registro encontrado'}
                                    /><div style={{marginBottom: "5px"}} />
                  
                                    <AsyncSelect id="inputPesquisaLeiDecreto" className="w-100" placeholder="N° da lei ou decreto" style={{textAlign: "center" }}
                                         loadOptions={this.loadOptionsPesquisaNaPastaPorDecretoLei}
                                         inputValue={ this.state.dadoLeiDecreto }
                                         onChange={this.onChangeFunc}
                                         onInputChange={this.handleDecretoLeiInputChange }
                                        onBlur={() => this.setState({ dadoLeiDecreto: this.state.dadoLeiDecreto })}
                                         formatOptionLabel={function(data) {
                                              return (
                                                  <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                              );
                                          }}
                                          noOptionsMessage={() => 'Nenhum registro encontrado'}
                                     /><div style={{marginBottom: "5px"}} />
                   
                                    <AsyncSelect id="descricao" className="w-100" placeholder="Descrição" style={{textAlign: "center" }}
                                         loadOptions={this.loadOptionsPesquisaNaPastaPorDescricao}
                                         onChange={this.onChangeFunc}
                                         inputValue={ this.state.dadoDescricao }
                                         options={this.state.loadedOptionsDescricao}
                                         onBlur={() => this.setState({ dadoDescricao: this.state.dadoDescricao })}
                                         onInputChange={this.handleDescricaoInputChange}
                                         formatOptionLabel={function(data) {
                                              return (
                                                  <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                              );
                                          }}
                                          noOptionsMessage={() => 'Nenhum registro encontrado'}
                                     /><div style={{marginBottom: "5px"}} />
                  
                              </div>
                            </div>
                          </Tooltip> }
                          rootClose={false}
                          show={showTooltip}
                          onEnter={() => {this.openTooltip() }}
                        >
                          <a href="#" className="btn btn-header-search" id="buscaAvancadaButton" style={{padding: "7px"}} onClick={() => {this.handleTooltip()}} >
                          Busca Avan&ccedil;ada <i className="fas fa-search"></i>
                          </a>
                    </OverlayTrigger>
                  </div>
                  <div style={{width: "86%" }} >      
                    <AsyncSelect
                      id="inputPesquisaNav" 
                      placeholder="Digite os termos da sua pesquisa (no mínimo três letras)" 
                      cacheOptions
                      loadingMessage={() => "Pesquisando, aguarde..."}
                      loadOptions={this.loadOptionsGeral}
                      onChange={this.onChangeFunc}
                      onInputChange={this.textoDigitadoPesquisa}
                      inputValue={this.state.inputValue}
                      formatOptionLabel={function(data) {
                        return (
                          <span dangerouslySetInnerHTML={{ __html: data.label }} />
                        );
                      }}
                      noOptionsMessage={() => 'Nenhum registro encontrado'}
                      cacheOptions={false}
                    />
          
                  </div>
                  
                </div>
                    

                    {/*End barra de pesquisa direita*/}

                    {/* Login Box */}
                    <div
                      className="btn-group btn-login-right"
                      style={{ display: "none" }}
                    >
                      <button
                        type="button"
                        className="btn btn-secondary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {this.usuario.nome_completo == undefined
                          ? "Login"
                          : "Olá " + this.usuario.nome_completo}
                      </button>
                      <div className="dropdown-menu dropdown-menu-right dropdown-login">
                        <div className="busca_Principal mx-auto col-md-12">
                          {this.usuario.authorities}
                          

                          <div
                            id="mensagem-sucesso"
                            className="alert alert-success"
                            role="alert"
                          >
                            <span id="mensagem-sucesso-body"></span>
                            {/*
											<button type="button" className="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
											*/}
                          </div>
                          <div
                            id="mensagem-erro"
                            className="alert alert-danger"
                            role="alert"
                          >
                            <span id="mensagem-erro-body"></span>
                            {/*
											<button type="button" className="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
											*/}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Login Box */}
                  </div>
                </div>
              </div>

              <div className="user-name-header">
                <span>
                  <i> - </i>
                </span>
                &nbsp;
                <a href="#" onClick={this.logout}>
                  <i className="fas fa-sign-out-alt"></i>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
	};
}

Header.propTypes = {
  handleChange: PropTypes.func,
};
