import React, { Component } from 'react';
import { MenuDominioPrincipal } from './MenuDominioPrincipal';
import { ViewOptions } from './Menu/ViewOptions';

export class MenuTopo extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}
  render() {
    var exibicaoLista = 'icones ';
    var exibicaoGrade = 'icones ';
    if (typeof Storage !== 'undefined') {
      if (typeof localStorage.exibicao !== 'undefined') {
        if (localStorage.exibicao === 'lista') {
          exibicaoGrade += 'd-none';
          exibicaoLista += '';
        } else {
          exibicaoGrade += '';
          exibicaoLista += 'd-none';
        }
      } else {
        localStorage.exibicao = 'miniaturas';
        exibicaoGrade += '';
        exibicaoLista += 'd-none';
      }
    }

    function gradeParaLista() {
      document.getElementById('alterar_P-DisplayLista').classList.add('d-none');
      document
        .getElementById('alterar_P-DisplayMiniaturas')
        .classList.remove('d-none');

      document.getElementById('P-DisplayLista').classList.remove('d-none');
      document.getElementById('P-DisplayMiniaturas').classList.add('d-none');
      if (typeof Storage !== 'undefined') localStorage.exibicao = 'lista';
    }

    function listaParaGrade() {
      document
        .getElementById('alterar_P-DisplayLista')
        .classList.remove('d-none');
      document
        .getElementById('alterar_P-DisplayMiniaturas')
        .classList.add('d-none');

      document.getElementById('P-DisplayLista').classList.add('d-none');
      document.getElementById('P-DisplayMiniaturas').classList.remove('d-none');
      if (typeof Storage !== 'undefined') localStorage.exibicao = 'miniaturas';
    }

    return (
      <div className='p-contd-menuTopo d-flex align-items-center'>
        <div className='p-c-menTp-Endereco flex-grow-1'>
          <MenuDominioPrincipal atualiza={this.props.atualiza} />
        </div>

        <ViewOptions
          showFolderIcon={this.props.showSearchResults}
          setFoldersView={this.props.setFoldersView}
          setSearchResultsView={this.props.setSearchResultsView}
          exibicaoLista={exibicaoLista}
          exibicaoGrade={exibicaoGrade}
          listaParaGrade={listaParaGrade}
          gradeParaLista={gradeParaLista}
        />
      </div>
    );
  }
}
