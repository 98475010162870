import { Link } from 'react-router-dom';
import React from 'react';

export function get_path(path) {
  let p = [
    <Link to={{ pathname: '/' }} onClick={handleRootRouteReload} key={0}>
      &nbsp;<i className='fas fa-home'></i>
    </Link>,
  ];
  if (path === null) {
    return p;
  } else {
    let i = 1;
    path.forEach(function (v) {
      if (v.nome !== '/') {
        p.push(
          <Link to={{ pathname: '/pastas/' + v.id }} key={i}>
            {v.nome}
          </Link>,
        );
        i++;
      }
    });
    return p;
  }
}

export function ocultarLoading() {
  const loadingElement = document.querySelector('.loading');
  if (loadingElement) {
    loadingElement.style.display = 'none';
  }
}

export function mostrarLoading(ehImportante = false) {
  const loadingElement = document.querySelector('.loading');
  if (loadingElement) {
    if (ehImportante) {
      loadingElement.classList = 'loadingImportant';
    }
    loadingElement.style.display = 'block';
  }
}

export function handleRootRouteReload() {
  if (window.location.pathname === '/') {
    window.location.reload();
  }
}
