import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";

export class SeachComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="p-c-busc-resultado">
            <div>
                <Link to={{pathname: `/${this.props.tipo}/${this.props.id}`}}>
                    <p className="p-c-b-resltd-titulo">
                        <i class="fa fa-folder" style={{display: `${this.props.tipo == 'pasta'?'inline':'none'}`}}></i>
                        <i class="fa fa-file" style={{display: `${this.props.tipo !== 'pasta'?'inline':'none'}`}}></i>&nbsp;
                        {this.props.nomeArquivo}
                    </p>
                </Link>
                <p className="p-c-b-resltd-endereco">
                    <span className="sr-only">Local do arquivo:</span>
                    {this.props.path}
                </p>
                <div className="p-c-b-resltd-descricao">
                    <span className="sr-only">Descrição:</span>
                    <div dangerouslySetInnerHTML={{__html: this.props.resumo}}/>
                </div>
                <p className="p-c-b-resltd-data">
                    Enviado em:
                    <span className="p-c-b-resltd-endereco p-c-b-resltd-data">
                      {this.props.data}
                  </span> &nbsp;
                    Local:
                    <span className="p-c-b-resltd-endereco p-c-b-resltd-data">
                      {this.props.local}
                  </span>
                </p>
            </div>
        </div>;
    }
}

SeachComponent.propTypes = {
    nomeArquivo: PropTypes.string,
    path: PropTypes.string,
    resumo: PropTypes.string,
    data: PropTypes.string,
    tamanho: PropTypes.number
};