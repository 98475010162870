import * as PropTypes from 'prop-types';
import React from 'react';
import { getDataFromDataHoraString } from '../utils/datas';

export default function ColunaDataItem({ data }) {
  return (
    <td className={'tr-col-nr-data-criacao'}>
      {getDataFromDataHoraString(data)}
    </td>
  );
}

ColunaDataItem.propTypes = {
  data: PropTypes.string.isRequired,
};
