import React, { useMemo, useState } from 'react';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { SearchField } from './SearchField';

const ETIQUETAS_KEYS_ENUM = {
  ANO: 'ano',
  CONTEUDO: 'conteudo',
  DESCRICAO: 'descricao', // ementa
  NUMERO: 'numero', // decreto
  LEI: 'lei',
  TITULO: 'titulo',
};

const DEFAULT_MAX_ETIQUETAS = 3;

const MAX_ETIQUETAS = {
  [ETIQUETAS_KEYS_ENUM.CONTEUDO]: 1,
};

export const BuscarPastas = ({ onSubmit, onClean = () => {} }) => {
  const [etiquetas, setEtiquetas] = useState({
    [ETIQUETAS_KEYS_ENUM.ANO]: [],
    [ETIQUETAS_KEYS_ENUM.CONTEUDO]: [],
    [ETIQUETAS_KEYS_ENUM.DESCRICAO]: [],
    [ETIQUETAS_KEYS_ENUM.NUMERO]: [],
    [ETIQUETAS_KEYS_ENUM.LEI]: [],
    [ETIQUETAS_KEYS_ENUM.TITULO]: [],
  });
  const [values, setValues] = useState({
    [ETIQUETAS_KEYS_ENUM.ANO]: '',
    [ETIQUETAS_KEYS_ENUM.CONTEUDO]: '',
    [ETIQUETAS_KEYS_ENUM.DESCRICAO]: '',
    [ETIQUETAS_KEYS_ENUM.NUMERO]: '',
    [ETIQUETAS_KEYS_ENUM.LEI]: '',
    [ETIQUETAS_KEYS_ENUM.TITULO]: '',
  });

  const adicionarEtiqueta = (key, etiqueta) => {
    if (etiqueta === '') {
      return;
    }

    const isDuplicated = etiquetas[key].includes(etiqueta);
    if (isDuplicated) {
      toast.error('O filtro já foi inserido');
      return;
    }

    const maxEtiquetas = MAX_ETIQUETAS[key] || DEFAULT_MAX_ETIQUETAS;
    const currentEtiquetas = etiquetas[key];
    const updatedEtiquetas =
      currentEtiquetas.length >= maxEtiquetas
        ? [...currentEtiquetas.slice(1), etiqueta]
        : [...currentEtiquetas, etiqueta];

    setEtiquetas({
      ...etiquetas,
      [key]: updatedEtiquetas,
    });
    setValues({
      ...values,
      [key]: '',
    });
  };

  const removerEtiqueta = (key, etiqueta) => {
    setEtiquetas({
      ...etiquetas,
      [key]: etiquetas[key].filter((e) => e !== etiqueta),
    });
  };

  const limparEtiquetas = () => {
    setEtiquetas({
      ...etiquetas,
      [ETIQUETAS_KEYS_ENUM.ANO]: [],
      [ETIQUETAS_KEYS_ENUM.CONTEUDO]: [],
      [ETIQUETAS_KEYS_ENUM.DESCRICAO]: [],
      [ETIQUETAS_KEYS_ENUM.LEI]: [],
      [ETIQUETAS_KEYS_ENUM.NUMERO]: [],
      [ETIQUETAS_KEYS_ENUM.TITULO]: [],
    });
    setValues({
      [ETIQUETAS_KEYS_ENUM.ANO]: '',
      [ETIQUETAS_KEYS_ENUM.CONTEUDO]: '',
      [ETIQUETAS_KEYS_ENUM.DESCRICAO]: '',
      [ETIQUETAS_KEYS_ENUM.LEI]: '',
      [ETIQUETAS_KEYS_ENUM.NUMERO]: '',
      [ETIQUETAS_KEYS_ENUM.TITULO]: '',
    });
    onClean();
  };

  const botaoHabilitado = useMemo(() => {
    const hasEtiqueta = Object.values(etiquetas).some(
      (array) => array.length !== 0,
    );
    const hasInput = Object.values(values).some((value) => value.trim() !== '');
    return hasEtiqueta || hasInput;
  }, [etiquetas, values]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const combinedValues = {
      ...etiquetas,
      [ETIQUETAS_KEYS_ENUM.ANO]: [
        ...etiquetas[ETIQUETAS_KEYS_ENUM.ANO],
        values[ETIQUETAS_KEYS_ENUM.ANO],
      ].filter(Boolean),
      [ETIQUETAS_KEYS_ENUM.CONTEUDO]: [
        ...etiquetas[ETIQUETAS_KEYS_ENUM.CONTEUDO],
        values[ETIQUETAS_KEYS_ENUM.CONTEUDO],
      ].filter(Boolean),
      [ETIQUETAS_KEYS_ENUM.DESCRICAO]: [
        ...etiquetas[ETIQUETAS_KEYS_ENUM.DESCRICAO],
        values[ETIQUETAS_KEYS_ENUM.DESCRICAO],
      ].filter(Boolean),
      [ETIQUETAS_KEYS_ENUM.NUMERO]: [
        ...etiquetas[ETIQUETAS_KEYS_ENUM.NUMERO],
        values[ETIQUETAS_KEYS_ENUM.NUMERO],
      ].filter(Boolean),
      [ETIQUETAS_KEYS_ENUM.LEI]: [
        ...etiquetas[ETIQUETAS_KEYS_ENUM.LEI],
        values[ETIQUETAS_KEYS_ENUM.LEI],
      ].filter(Boolean),
      [ETIQUETAS_KEYS_ENUM.TITULO]: [
        ...etiquetas[ETIQUETAS_KEYS_ENUM.TITULO],
        values[ETIQUETAS_KEYS_ENUM.TITULO],
      ].filter(Boolean),
    };

    onSubmit(combinedValues);
  };

  const [activeKey, setActiveKey] = useState('0');

  return (
    <Accordion
      defaultActiveKey='0'
      activeKey={activeKey}
      onSelect={(key) => setActiveKey(key)}
    >
      <Card>
        <Card.Header className='d-flex'>
          <Accordion.Toggle
            as={Button}
            variant='link'
            eventKey='0'
            style={{
              color: 'black',
              fontSize: '1.5rem',
              textDecoration: 'none',
              width: '100%',
              textAlign: 'left',
            }}
          >
            <i className='fas fa-search' /> Pesquisar
          </Accordion.Toggle>
          {activeKey === '0' ? (
            <FaChevronUp className='mt-2' />
          ) : (
            <FaChevronDown className='mt-2' />
          )}
        </Card.Header>
        <Accordion.Collapse eventKey='0' style={{ margin: '20px 10px' }}>
          <Row
            style={{
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              padding: '10px',
            }}
          >
            <Row
              style={{
                rowGap: '20px',
                marginLeft: '10px',
              }}
            >
              <Col sm={12} md={6} lg={8}>
                <SearchField
                  title='Palavra-chave'
                  placeholder='Digite a palavra-chave'
                  value={values[ETIQUETAS_KEYS_ENUM.CONTEUDO]}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      [ETIQUETAS_KEYS_ENUM.CONTEUDO]: e.target.value,
                    })
                  }
                  onAdd={() =>
                    adicionarEtiqueta(
                      ETIQUETAS_KEYS_ENUM.CONTEUDO,
                      values[ETIQUETAS_KEYS_ENUM.CONTEUDO],
                    )
                  }
                  tags={etiquetas[ETIQUETAS_KEYS_ENUM.CONTEUDO]}
                  onRemoveTag={(tag) =>
                    removerEtiqueta(ETIQUETAS_KEYS_ENUM.CONTEUDO, tag)
                  }
                  preventSpaces={false}
                />
              </Col>
              <Col sm={12} md={6} lg={4}>
                <SearchField
                  title='Ementa'
                  placeholder='Digite a ementa'
                  value={values[ETIQUETAS_KEYS_ENUM.DESCRICAO]}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      [ETIQUETAS_KEYS_ENUM.DESCRICAO]: e.target.value,
                    })
                  }
                  onAdd={() =>
                    adicionarEtiqueta(
                      ETIQUETAS_KEYS_ENUM.DESCRICAO,
                      values[ETIQUETAS_KEYS_ENUM.DESCRICAO],
                    )
                  }
                  tags={etiquetas[ETIQUETAS_KEYS_ENUM.DESCRICAO]}
                  onRemoveTag={(tag) =>
                    removerEtiqueta(ETIQUETAS_KEYS_ENUM.DESCRICAO, tag)
                  }
                  preventSpaces={false}
                />
              </Col>
              </Row>
              <Row
                style={{
                rowGap: '20px',
                marginLeft: '10px',
              }}>
              {/* <Col sm={12} md={6} lg={4}>
                <SearchField
                  title='Nº Lei'
                  placeholder='Digite o número da lei'
                  inputType='number'
                  minValue={1900}
                  value={values[ETIQUETAS_KEYS_ENUM.LEI]}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      [ETIQUETAS_KEYS_ENUM.LEI]: e.target.value,
                    })
                  }
                  onAdd={() =>
                    adicionarEtiqueta(
                      ETIQUETAS_KEYS_ENUM.LEI,
                      values[ETIQUETAS_KEYS_ENUM.LEI],
                    )
                  }
                  tags={etiquetas[ETIQUETAS_KEYS_ENUM.LEI]}
                  onRemoveTag={(tag) =>
                    removerEtiqueta(ETIQUETAS_KEYS_ENUM.LEI, tag)
                  }
                />
              </Col> */}
              <Col sm={12} md={6} lg={4}>
                <SearchField
                  title='Nº Ato'
                  placeholder='Digite o número do ato'
                  inputType='number'
                  minValue={1900}
                  value={values[ETIQUETAS_KEYS_ENUM.NUMERO]}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      [ETIQUETAS_KEYS_ENUM.NUMERO]: e.target.value,
                    })
                  }
                  onAdd={() =>
                    adicionarEtiqueta(
                      ETIQUETAS_KEYS_ENUM.NUMERO,
                      values[ETIQUETAS_KEYS_ENUM.NUMERO],
                    )
                  }
                  tags={etiquetas[ETIQUETAS_KEYS_ENUM.NUMERO]}
                  onRemoveTag={(tag) =>
                    removerEtiqueta(ETIQUETAS_KEYS_ENUM.NUMERO, tag)
                  }
                />
              </Col>
              <Col sm={12} md={6} lg={4}>
                <SearchField
                  title='Ano'
                  placeholder='Digite o ano'
                  inputType='number'
                  minValue={1900}
                  value={values[ETIQUETAS_KEYS_ENUM.ANO]}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      [ETIQUETAS_KEYS_ENUM.ANO]: e.target.value,
                    })
                  }
                  onAdd={() =>
                    adicionarEtiqueta(
                      ETIQUETAS_KEYS_ENUM.ANO,
                      values[ETIQUETAS_KEYS_ENUM.ANO],
                    )
                  }
                  tags={etiquetas[ETIQUETAS_KEYS_ENUM.ANO]}
                  onRemoveTag={(tag) =>
                    removerEtiqueta(ETIQUETAS_KEYS_ENUM.ANO, tag)
                  }
                />
              </Col>
              <Col sm={12} md={6} lg={4}>
                <SearchField
                  title='Nome do Documento / Pasta'
                  placeholder='Digite o nome do documento ou pasta'
                  value={values[ETIQUETAS_KEYS_ENUM.TITULO]}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      [ETIQUETAS_KEYS_ENUM.TITULO]: e.target.value,
                    })
                  }
                  onAdd={() =>
                    adicionarEtiqueta(
                      ETIQUETAS_KEYS_ENUM.TITULO,
                      values[ETIQUETAS_KEYS_ENUM.TITULO],
                    )
                  }
                  tags={etiquetas[ETIQUETAS_KEYS_ENUM.TITULO]}
                  onRemoveTag={(tag) =>
                    removerEtiqueta(ETIQUETAS_KEYS_ENUM.TITULO, tag)
                  }
                  preventSpaces={false}
                />
              </Col>
             
              
            </Row>
            <Row>
              <Col
                style={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'flex-end',
                }}
              >
                <Button variant='secondary' onClick={limparEtiquetas}>
                  Limpar
                </Button>
                <Button
                  variant='primary'
                  onClick={handleSubmit}
                  disabled={!botaoHabilitado}
                  title={
                    botaoHabilitado
                      ? 'Buscar'
                      : 'Adicione ao menos 1 etiqueta ou 1 palavra em um dos campos de pesquisa'
                  }
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Row>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
