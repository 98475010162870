import React, { Component } from 'react';
import toast from 'react-hot-toast';
import { Link, Route, Switch } from 'react-router-dom';
import TjdocsApi from './api/API';
import './App.css';
import Authentication from './Authentication';
import { BoxEtiquetas, BoxEtiquetasMinifyed } from './components/BoxEtiquetas';
import { BoxFavoritos, BoxFavoritosMinifyed } from './components/BoxFavoritos';
import { BoxGestao, BoxGestaoMinifyed } from './components/BoxGestao';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { FileContainer } from './containers/FileContainer';
import { FolderContainer } from './containers/FolderContainer';
import { MainContainer } from './containers/MainContainer';
import SearchContainer from './containers/SearchContainer';
import { handleRootRouteReload, ocultarLoading } from './helper/Helper';

import { Button } from 'react-bootstrap';
import SearchWrapper from './components/SearchWrapper';
import EtiquetasContainer from './containers/EtiquetasContainer';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dominios: [],
      dominiosSemFiltrar: [],
      path: [],
      infoUsuario: [],
      colunaOrdenada: '',
      ordem: '',
      isMenuExpanded: false,
    };

    this.usuario = Authentication.getUsuario();
    this.isLogin = Authentication.isLogin();
    this.atualizaDominios = this.atualizaDominios.bind(this);
    this.handlePath = this.handlePath.bind(this);

    this.filtros = {
      nome: '',
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    this.setState({ isMenuExpanded: this.isLogin });
    this.recuperarOrdenacao();
  }

  async listarDominios() {
    TjdocsApi.listaDominios()
      .then((jsonDominios) => {
        this.setState({
          // dominios: jsonDominios._embedded.pastas,
          dominiosSemFiltrar: jsonDominios._embedded.pastas,
        });

        this.filtrar();
        ocultarLoading();
      })
      .catch((error) =>
        console.log(`Erro ao tentar consultar os domínios: ${error}`),
      );
  }

  recuperarOrdenacao() {
    TjdocsApi.recuperarOrdenacao(0, 'dominio').then((resp) => {
      if (resp) {
        if (resp.id === null) {
          this.listarDominios();
        } else {
          let nomeColuna =
            resp.nomeColuna !== null ? resp.nomeColuna : 'p.nome';
          let order = resp.order !== null ? resp.order : 'asc';

          this.setState({ colunaOrdenada: nomeColuna });
          this.setState({ ordem: order });
          this.ordenaDadosPastas(nomeColuna, order);
        }
      }
    });
  }

  toggleMenu() {
    this.setState((prevState) => ({
      isMenuExpanded: !prevState.isMenuExpanded,
    }));
  }

  handlePath = (item) => {
    this.setState({
      path: item,
    });
  };

  atualizaDominios() {
    TjdocsApi.recuperarOrdenacao(0, 'dominio').then((resp) => {
      if (!resp.id) {
        TjdocsApi.listaDominios()
          .then((jsonDominios) => {
            this.setState({
              dominios: jsonDominios._embedded.pastas,
              dominiosSemFiltrar: jsonDominios._embedded.pastas,
            });
            ocultarLoading();
          })
          .catch((error) =>
            console.log(`Erro ao tentar consultar os domínios: ${error}`),
          );
      } else {
        let nomeColuna = resp.nomeColuna !== null ? resp.nomeColuna : 'p.nome';
        let order = resp.order !== null ? resp.order : 'asc';

        console.log('ordenar dados', resp);
        this.setState({ colunaOrdenada: nomeColuna });
        this.setState({ ordem: order });
        this.ordenaDadosPastas(nomeColuna, order);
      }
    });
  }

  ordenaDadosPastas = (field, order) => {
    field = field === 'nome' ? 'p.nome' : field;
    field = field === 'dataCadastro' ? 'p.created_at' : field;
    field = field === 'dataAtualizacao' ? 'p.updated_at' : field;
    field = field === 'tipoAcesso' ? 'p.nome' : field;
    field = field === 'nomeGestor' ? 'u.username' : field;
    field = field === 'tamanhoMB' ? 'tamanho' : field;
    field = field === 'numeroDecreto' ? 'p.created_at' : field;
    field = field === 'numeroLei' ? 'p.created_at' : field;
    field = field === 'totalArquivos' ? 'totalArquivo' : field;

    TjdocsApi.listaDominiosOrdenados(field, order)
      .then((jsonDominios) => {
        this.setState({
          // dominios: jsonDominios._embedded.pastas,
          dominiosSemFiltrar: jsonDominios._embedded.pastas,
        });

        this.filtrar();

        {
          /* Hide no loading */
        }
        ocultarLoading();
      })
      .catch((error) =>
        console.log(`Erro ao tentar consultar os domínios: ${error}`),
      );
  };

  salvarOrdenacao = (field, order) => {
    let nomeColuna = field;

    field = field === 'nome' ? 'p.nome' : field;
    field = field === 'dataCadastro' ? 'p.created_at' : field;
    field = field === 'dataAtualizacao' ? 'p.updated_at' : field;
    field = field === 'tipoAcesso' ? 'p.nome' : field;
    field = field === 'nomeGestor' ? 'u.username' : field;
    field = field === 'tamanhoMB' ? 'tamanho' : field;
    field = field === 'totalArquivos' ? 'totalArquivo' : field;

    TjdocsApi.salvarOrdenacao(0, field, order, 'dominio', nomeColuna)
      .then((resp) => {
        console.log(resp);
      })
      .catch((error) => console.log(`Erro ao salvar ordenação: ${error}`));

    toast.success('Sucesso!');
  };

  filtrarPorNome = (nome) => {
    this.filtros.nome = nome;
    this.filtrar();
  };

  filtrar = () => {
    let filteredArrayPastas = this.state.dominiosSemFiltrar;

    if (this.filtros.nome !== '') {
      filteredArrayPastas = this.state.dominiosSemFiltrar.filter((pasta) =>
        pasta.nome.toLowerCase().includes(this.filtros.nome.toLowerCase()),
      );
    }

    this.setState({
      dominios: filteredArrayPastas,
    });
  };

  render() {
    const { isMenuExpanded } = this.state;
    return (
      <div className='principal'>
        <Header
          handlePath={this.handlePath}
          infoUsuario={this.state.infoUsuario}
          variavelPath={this.props.location.pathname}
        />
        <div className={ this.isLogin ? 'row margin-0' : 'mx-4 my-5' }>
          {/* Menu lateral */}
          <div className={ this.isLogin ? `col-${isMenuExpanded ? 'md-3' : 'auto pl-5'}` : 'd-none'}>
            {/* <div className={this.isLogin ? '' : 'd-none '}> */}
              {/* Box Favoritos */}
              {/*<BoxFavoritos />
               End Box Favoritos */}

              {/* Box Etiquetas */}
              {/*<BoxEtiquetas />
               End Box Etiquetas */}
              {/* Box Etiquetas 
              <BoxGestao />*/}
           {/*  </div> */}
            {/* End Box Etiquetas */}

            {isMenuExpanded ? (
              <>
                <BoxFavoritos />
                <BoxEtiquetas />
                {/* <BoxGestao /> */}
              </>
            ) : (
              <>
                <BoxFavoritosMinifyed />
                <BoxEtiquetasMinifyed />
                {/* <BoxGestaoMinifyed /> */}
              </>
            )}
            <div className='d-flex flex-row-reverse'>
              <Button
                onClick={this.toggleMenu}
                title={
                  isMenuExpanded
                    ? 'Recolher o menu lateral'
                    : 'Expandir o menu lateral'
                }
              >
                {isMenuExpanded ? (
                  <i className='fas fa-angle-double-left'></i>
                ) : (
                  <i
                    className='fas fa-angle-double-right'
                    style={{ padding: '0 11px' }}
                  ></i>
                )}
              </Button>
            </div>
          </div>
          {/* fim col-md-3 */}

          {/* Conteudo Principal */}
          <div
            className={`princpl-conteudo col${isMenuExpanded ? '-md-9' : ''}`}
            id='princpl-conteudo'
          >
            <div>
              <div className='box box-primary'>
                <div className='box-header with-border box-defaultClaro'>
                  <h3 className='box-title'>
                    {/* BREADCRUMB */}
                    <div className='p-c-menTp-Endereco flex-grow-1'>
                      <div className='row rowBreadCrumbs'>
                        {this.state.path.map((diretorio, index, statepath) => {
                          if (index + 1 === statepath.length)
                            //ultimo elemento não aparece o >
                            return (
                              <span
                                key={index}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {diretorio}
                              </span>
                            );
                          else
                            return (
                              <span
                                key={index}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {diretorio} &nbsp;{' '}
                                <i className='fas fa-angle-right'></i> &nbsp;
                              </span>
                            );
                        })}

                        {this.state.path.length === 0 && (
                          <Link
                            to={{ pathname: '/' }}
                            onClick={handleRootRouteReload}
                            key={0}
                          >
                            &nbsp;<i className='fas fa-home'></i>
                          </Link>
                        )}
                      </div>
                    </div>
                  </h3>
                </div>
                {/* fim box-header */}
                <div className='collapse show min-400'>
                  <div className='box-body padding-none'>
                    <div className='p-contd-pastas' id='folder-file'>
                      <Switch>
                        <Route
                          exact
                          path='/'
                          render={(props) => (
                            <SearchWrapper
                              component={MainContainer}
                              {...props}
                              path={this.state.path}
                              dominios={this.state.dominios}
                              handlePath={this.handlePath}
                              atualizaDominios={this.atualizaDominios}
                              ordenaDadosPastas={this.ordenaDadosPastas}
                              salvarOrdenacao={this.salvarOrdenacao}
                              colunaOrdenada={this.state.colunaOrdenada}
                              ordem={this.state.ordem}
                              filtrarPorNome={this.filtrarPorNome}
                            />
                          )}
                        />
                        <Route
                          path='/pastas/:pasta_id'
                          render={(props) => (
                            <SearchWrapper
                              component={FolderContainer}
                              {...props}
                              handlePath={this.handlePath}
                              atualizaDominios={this.atualizaDominios}
                              permissoes={this.usuario}
                            />
                          )}
                        />
                        <Route
                          path='/busca/:tokens'
                          render={(props) => (
                            <SearchWrapper
                              component={SearchContainer}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          path='/documentos/:arquivo_id'
                          render={(props) => (
                            <FileContainer
                              handlePath={this.handlePath}
                              {...props}
                            />
                          )}
                        />
                        {/* <Route path="/buscaAvancada"   render={ props => <SearchContainer {...props}/>} /> */}
                        <Route
                          path='/etiqueta/:etiqueta_id'
                          render={(props) => <EtiquetasContainer {...props} />}
                        />
                      </Switch>
                    </div>
                  </div>
                  {/* fim box-body */}
                </div>
                {/* fim menu */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
