import React, { Component } from 'react';

export class GradePastas extends Component {
  render() {
    return (
      <>
        <div className='row'>
          <div className='col-md-12'>
            <p className='h3 labelPastasArquivos'> &nbsp;&nbsp;Pastas </p>
          </div>
        </div>

        <div className='row margin-0'>
          <div className='p-contd-pastas'>
            <div className='p-cntd-pasts-miniaturas flex-wrap'>
              <div className='row'>{this.props.pastas}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
