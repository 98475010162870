import React, { Component } from 'react';
import Authentication from '../Authentication';

import Button from 'react-bootstrap/Button';

export class MiniDocViewLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colunaOrdenada: '',
      ordem: '',
      colunaOrdenadaPropAnt: '',
      ordemPropAnt: '',
    };
  }

  componentDidUpdate(prevProps) {
    // Guard to prevent infinite renders - only update state if props actually changed
    if (
      (this.props.colunaOrdenada !== prevProps.colunaOrdenada ||
        this.props.ordem !== prevProps.ordem) &&
      (this.state.colunaOrdenada === '' ||
        this.state.colunaOrdenada === undefined ||
        this.props.colunaOrdenada !== this.state.colunaOrdenadaPropAnt ||
        this.props.ordem !== this.state.ordemPropAnt)
    ) {
      if (
        this.props.colunaOrdenada !== '' &&
        this.props.colunaOrdenada !== undefined &&
        this.props.ordem !== '' &&
        this.props.ordem !== undefined
      ) {
        this.setState({
          colunaOrdenada: this.props.colunaOrdenada,
          ordem: this.props.ordem,
          colunaOrdenadaPropAnt: this.props.colunaOrdenada,
          ordemPropAnt: this.props.ordem,
        });
      }
    }
  }

  ordenarPorColuna(coluna) {
    let { colunaOrdenada, ordem } = this.state;

    if (coluna === colunaOrdenada) {
      // Se a mesma coluna for clicada novamente, alterne a ordem
      this.setState({
        colunaOrdenada: coluna,
        ordem: ordem === 'asc' ? 'desc' : 'asc',
      });
      ordem = ordem === 'asc' ? 'desc' : 'asc';
    } else {
      // Caso contrário, defina a nova coluna e ordem como padrão (ascendente)
      this.setState({
        colunaOrdenada: coluna,
        ordem: 'asc',
      });
      ordem = 'asc';
    }

    this.props.ordenaDados(coluna, ordem);
  }

  salvarOrdenacao() {
    const { colunaOrdenada, ordem } = this.state;
    this.props.salvarOrdenacao(colunaOrdenada, ordem);
  }

  render() {
    let { colunaOrdenada, ordem } = this.state;

    // Use default values if needed without updating state in render
    if (!colunaOrdenada) {
      colunaOrdenada = 'dataCadastro';
      ordem = 'desc';
    }

    /*let {colunaOrdenada, ordem } = this.state.colunaOrdenada === '' ? this.props : this.state;
	colunaOrdenada = colunaOrdenada === '' || colunaOrdenada === undefined ? 'dataCadastro' : colunaOrdenada;
	ordem = ordem === '' || ordem === undefined ? 'desc' : ordem;*/

    var exibicao = 'd-none';
    if (typeof Storage !== 'undefined') {
      if (typeof localStorage.exibicao !== 'undefined') {
        if (localStorage.exibicao === 'lista') exibicao = '';
      }
    }

    return (
      <div id='P-DisplayLista' className={exibicao}>
        {/*<span style={{color: "#0057b5", fontSize: "20px"}}>Para acesso ao sistema, deve-se solicitar o cadastro ao gestor da pasta (decreto N° 2117/2013)</span>*/}
        <Button
          variant='primary'
          style={{ margin: '5px', float: 'right' }}
          className={Authentication.isLogin() ? '' : 'd-none'}
          onClick={() => {
            this.salvarOrdenacao();
          }}
        >
          <i className='fas fa-save'></i>&nbsp; Salvar Ordenação
        </Button>
        <table
          className='table  table-sm table-hover'
          tabIndex='0'
          summary=' :  Documentos'
        >
          <thead tabIndex='0'>
            <tr>
              <th
                id='p-c-p-l-Tabl-Nome'
                onClick={() => this.ordenarPorColuna('nome')}
                style={{ cursor: 'pointer' }}
              >
                Nome{' '}
                {colunaOrdenada === 'nome' ? (
                  <i
                    className={
                      ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'
                    }
                  ></i>
                ) : (
                  <i className='fas fa-sort'></i>
                )}
              </th>
              <th id='p-c-p-l-Tabl-Ementa'>Ementa </th>
              {Authentication.isLogin() ? (
                <>
                  <th
                    id='p-c-p-l-Tabl-Lei'
                    onClick={() => this.ordenarPorColuna('numeroLei')}
                style={{ cursor: 'pointer' }}
              >
                Lei{' '}
                {colunaOrdenada === 'numeroLei' ? (
                  <i
                    className={
                      ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'
                    }
                  ></i>
                ) : (
                  <i className='fas fa-sort'></i>
                    )}
                  </th>
                </>
              ): null}
              <th
                id='p-c-p-l-Tabl-Decreto'
                onClick={() => this.ordenarPorColuna('numeroDecreto')}
                style={{ cursor: 'pointer' }}
              >
                Nr. Ato{' '}
                {colunaOrdenada === 'numeroDecreto' ? (
                  <i
                    className={
                      ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'
                    }
                  ></i>
                ) : (
                  <i className='fas fa-sort'></i>
                )}
              </th>
              {Authentication.isLogin() ? null:  (
                <th
                  id='p-c-p-l-Tabl-Situacao'
                  onClick={() => this.ordenarPorColuna('situacao')}
                  style={{ cursor: 'pointer' }}
                >
                  Situação{' '}
                  {colunaOrdenada === 'situacao' ? (
                    <i
                      className={
                        ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'
                      }
                    ></i>
                  ) : (
                    <i className='fas fa-sort'></i>
                  )}
                </th>
              )}
              {Authentication.isLogin() ? (
                <>
                  <th
                    id='p-c-p-l-Tabl-Tipo-Acesso'
                    onClick={() => this.ordenarPorColuna('tipoAcesso')}
                    style={{ cursor: 'pointer' }}
                  >
                    Tipo de acesso{' '}
                    {colunaOrdenada === 'tipoAcesso' ? (
                      <i
                        className={
                          ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'
                        }
                      ></i>
                    ) : (
                      <i className='fas fa-sort'></i>
                    )}
                  </th>
                  <th
                    id='p-c-p-l-Tabl-Nome-Gestor'
                    onClick={() => this.ordenarPorColuna('nomeGestor')}
                    style={{ cursor: 'pointer' }}
                  >
                    Gestor/Contato{' '}
                    {colunaOrdenada === 'nomeGestor' ? (
                      <i
                        className={
                          ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'
                        }
                      ></i>
                    ) : (
                      <i className='fas fa-sort'></i>
                    )}
                  </th>
                </>
              ): null}
              <th
                id='p-c-p-l-Tabl-Tamanho'
                onClick={() => this.ordenarPorColuna('dataCadastro')}
                style={{ cursor: 'pointer' }}
              >
                Data Criação{' '}
                {colunaOrdenada === 'dataCadastro' ? (
                  <i
                    className={
                      ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'
                    }
                  ></i>
                ) : (
                  <i className='fas fa-sort'></i>
                )}
              </th>
            </tr>
          </thead>
          <tbody>{this.props.children}</tbody>
        </table>
      </div>
    );
  }
}
