import axios from "axios";

export const httpClient = axios.create({
  //baseURL: process.env.REACT_APP_TJDOCS2_SERVICE?process.env.REACT_APP_TJDOCS2_SERVICE:"http://tjdocs2bk-desenvolvimento.apps.tjgo.jus.br/",  
  baseURL: process.env.REACT_APP_TJDOCS2_SERVICE?process.env.REACT_APP_TJDOCS2_SERVICE:"http://localhost:8080/",  
});


class ApiService {
  constructor(apiUrl) {
    this.apiUrl = apiUrl;
  }

  put(url) {
    const requestUrl = `${this.apiUrl}${url}`;
    return httpClient.put(requestUrl);
  }

  delete(url) {
    const requestUrl = `${this.apiUrl}${url}`;
    return httpClient.delete(requestUrl);
  }

  get(url) {
    const requestUrl = `${this.apiUrl}${url}`;
    //console.log(">>>> RequestUrl Get : " + requestUrl);
    return httpClient.get(requestUrl);
  }
}

export default ApiService;
