import React, {Component} from 'react';
import './App.css';
import {Header} from "./components/Header";
import { Footer } from "./components/Footer";
import { BoxFavoritos } from "./components/BoxFavoritos";
import {BoxEtiquetas} from "./components/BoxEtiquetas";
import {BoxGestao} from "./components/BoxGestao";
import TjdocsApi from './api/API';
import {Switch, Route, Link} from 'react-router-dom'
import {FolderContainer} from "./containers/FolderContainer";
import {FileContainer} from "./containers/FileContainer";
import SearchContainer from "./containers/SearchContainer";
import {MainContainer} from "./containers/MainContainer";
import Authentication from './Authentication';
import toast from 'react-hot-toast';

import {DominioContainer} from "./containers/DominioContainer";
import EtiquetasContainer from "./containers/EtiquetasContainer";


class App extends Component {	
    constructor(props) {
        super(props);
		
        this.state = {
            dominios: [],
			dominiosSemFiltrar: [],
            path: [],
			infoUsuario: [],
			colunaOrdenada: "",
			ordem: ""
        };

		this.usuario = Authentication.getUsuario();	
		this.isLogin = Authentication.isLogin();
		this.atualizaDominios = this.atualizaDominios.bind(this);
		this.handlePath = this.handlePath.bind(this);

		this.filtros = {
			nome: ""
		}

    }	
	
    componentWillMount() {
		
		TjdocsApi.recuperarOrdenacao(0, 'dominio').then(resp => {
			
			if(resp != null && resp != 'undefined') {
				if(resp.id === null) {
					TjdocsApi.listaDominios().then(jsonDominios => {
							this.setState({
								// dominios: jsonDominios._embedded.pastas,
								dominiosSemFiltrar: jsonDominios._embedded.pastas
							});

							this.filtrar();
							
							{/* Hide no loading */}
							document.querySelector(".loading").style.display = "none";
						}).catch(error => console.log(`Erro ao tentar consultar os domínios: ${error}`));
				}
				else {
					let nomeColuna = (resp.nomeColuna != null ? resp.nomeColuna : "p.nome");
					let order = (resp.order != null ? resp.order : "asc");

					console.log("ordenar dados", resp);
					this.setState({colunaOrdenada : nomeColuna });
					this.setState({ordem: order});
					this.ordenaDadosPastas(nomeColuna, order);
				}
			}

		});
    }

    handlePath = (item) => {
        this.setState({
            path: item
        });
    }

	atualizaDominios(){
		TjdocsApi.recuperarOrdenacao(0, 'dominio').then(resp => {
			if(resp.id === null) {
				TjdocsApi.listaDominios().then(jsonDominios => {
					this.setState({
						dominios: jsonDominios._embedded.pastas,
						dominiosSemFiltrar: jsonDominios._embedded.pastas
					});
					
					{/* Hide no loading */}
					document.querySelector(".loading").style.display = "none";
				}

				).catch(error => console.log(`Erro ao tentar consultar os domínios: ${error}`));	
			}
			else {
				let nomeColuna = (resp.nomeColuna != null ? resp.nomeColuna : "p.nome");
				let order = (resp.order != null ? resp.order : "asc");

				console.log("ordenar dados", resp);
				this.setState({colunaOrdenada : nomeColuna });
				this.setState({ordem: order});
				this.ordenaDadosPastas(nomeColuna, order);
			}
		});	
	}

	ordenaDadosPastas = (field, order) => {   
		field = (field == 'nome' ? 'p.nome' : field);
		field = (field == 'dataCadastro' ? 'p.created_at' : field);
		field = (field == 'dataAtualizacao' ? 'p.updated_at' : field);
		field = (field == 'tipoAcesso' ? 'p.nome' : field);
		field = (field == 'nomeGestor' ? 'u.username' : field);
		field = (field == 'tamanhoMB' ? 'tamanho' : field);
		field = (field == 'numeroDecreto' ? 'p.created_at' : field);
		field = (field == 'numeroLei' ? 'p.created_at' : field);
		field = (field == 'totalArquivos' ? 'totalArquivo' : field);

        TjdocsApi.listaDominiosOrdenados(field, order).then(jsonDominios => {
			this.setState({
				// dominios: jsonDominios._embedded.pastas,
				dominiosSemFiltrar: jsonDominios._embedded.pastas
			});

			this.filtrar();
			
			{/* Hide no loading */}
			document.querySelector(".loading").style.display = "none";
		}

		).catch(error => console.log(`Erro ao tentar consultar os domínios: ${error}`));
    }

	salvarOrdenacao = (field, order) => {

		let nomeColuna = field;

		field = (field == 'nome' ? 'p.nome' : field);
		field = (field == 'dataCadastro' ? 'p.created_at' : field);
		field = (field == 'dataAtualizacao' ? 'p.updated_at' : field);
		field = (field == 'tipoAcesso' ? 'p.nome' : field);
		field = (field == 'nomeGestor' ? 'u.username' : field);
		field = (field == 'tamanhoMB' ? 'tamanho' : field);
		field = (field == 'totalArquivos' ? 'totalArquivo' : field);

		TjdocsApi.salvarOrdenacao(0, field, order, "dominio", nomeColuna).then(resp => {			
			console.log(resp);
        }).catch(error => console.log(`Erro ao salvar ordenação: ${error}`));

		toast.success('Sucesso!');
	}

	filtrarPorNome = (nome) => {
		this.filtros.nome = nome;
		this.filtrar();
	};

	filtrar = () => {

		let filteredArrayPastas = this.state.dominiosSemFiltrar;

		if(this.filtros.nome != "") {
			filteredArrayPastas = this.state.dominiosSemFiltrar.filter(pasta =>
				pasta.nome.toLowerCase().includes(this.filtros.nome.toLowerCase())
			)
		}

		this.setState({
			dominios: filteredArrayPastas
		})
	}
	

    render() {
        return (<div className="principal">
            <Header handlePath={this.handlePath} infoUsuario={this.state.infoUsuario} variavelPath={this.props.location.pathname} />
            <div className="row margin-0">
				{/* Menu lateral */}
				<div className="col-md-3">
					<div className={this.isLogin  ? "" : "d-none "}>	
						{/* Box Favoritos */}
						<BoxFavoritos />
						{/* End Box Favoritos */}
						
						{/* Box Etiquetas */}
						<BoxEtiquetas />
						{/* End Box Etiquetas */}
					</div>

				{/* Box Etiquetas */}
				<BoxGestao />
				{/* End Box Etiquetas */}
{/* 				
				
				import {DominioContainer} from "./containers/DominioContainer";
<Route  path="/dominio/:pasta_id" render={ props => <DominioContainer  handlePath={this.handlePath} {...props}/>} />
				 */}
				
				</div>{/* fim col-md-3 */}
				
				{/* Conteudo Principal */}
				<div className="princpl-conteudo col-md-9" id="princpl-conteudo">
					
					<div>
						<div className="box box-primary">
							<div className="box-header with-border box-defaultClaro">
							<h3 className="box-title"> 

								{/* BREADCRUMB */}
								<div className="p-c-menTp-Endereco flex-grow-1">
									<div className="row rowBreadCrumbs">
										{this.state.path.map((diretorio, index, statepath) => {
											if (index + 1 === statepath.length) //ultimo elemento não aparece o >
												return (
													<span style={{"display": "flex", "align-items": "center"}}>
													{diretorio}
												</span>
												);
											else
												return (
													<span style={{"display": "flex", "align-items": "center"}}>
													{diretorio} &nbsp; <i className="fas fa-angle-right"></i> &nbsp;
												</span>
												);
										})}

										{this.state.path.length === 0 &&
											<Link to={{pathname: "/"}} key={0}>
												&nbsp;<i className="fas fa-home"></i>
											</Link>
										}

									</div>
								</div>
								
							</h3>
						</div>{/* fim box-header */}

							<div className="collapse show min-400">
								<div className="box-body padding-none">

									<div className="p-contd-pastas" id="folder-file">
										<Switch>

											<Route exact path="/" render={ props => <MainContainer path={this.state.path}  dominios={this.state.dominios} handlePath={this.handlePath} {...props} atualizaDominios={this.atualizaDominios} ordenaDadosPastas={this.ordenaDadosPastas} salvarOrdenacao={this.salvarOrdenacao} colunaOrdenada={this.state.colunaOrdenada} ordem={this.state.ordem} filtrarPorNome={this.filtrarPorNome}/>}/>


											<Route  path="/dominio/:pasta_id" render={ props => <FolderContainer  handlePath={this.handlePath} {...props} atualizaDominios={this.atualizaDominios}/>} />

											<Route path="/pastas/:pasta_id" render={ props => <FolderContainer  handlePath={this.handlePath} {...props}  atualizaDominios={this.atualizaDominios} permissoes={this.usuario} />}  />
											
											<Route path="/documentos/:arquivo_id" render={ props => <FileContainer  handlePath={this.handlePath} {...props}/>} />
											
											<Route path="/busca/:tokens"   render={ props => <SearchContainer {...props}/>} />

											<Route path="/buscaAvancada"   render={ props => <SearchContainer {...props}/>} />

											<Route path="/etiqueta/:etiqueta_id"   render={ props => <EtiquetasContainer {...props}/>} />
										</Switch>
									</div>
									
								</div>{/* fim box-body */} 
							</div>{/* fim menu */} 
						</div>
					</div>
					
				</div>

			</div>
			<Footer/>
		</div>);
    }
}

export default App;
