import React, {Component} from "react";
import TjdocsApi from "../api/API";
import {FileView} from "../components/FileView";
import {get_path} from "../helper/Helper";

export class FileContainer extends Component {

    constructor(props) {
        super(props);
		
        this.state = {
            documento: {},
            capa: '/ui/img/a4_paper.png',
			path: [],
            etiquetas: [],
            vinculos: [],
            vinculadores: []
        };
		this.loadArquivo = this.loadArquivo.bind(this);
    }

    componentWillMount() {
		const arquivo_id = this.props.match.params.arquivo_id;

        this.loadArquivo(arquivo_id);
    }


    loadArquivo(arquivo_id){
        TjdocsApi.abreArquivo(arquivo_id).then(resp => {            
            this.setState({
				documento: resp,
				path: resp.caminhos._embedded.path,
                etiquetas: resp.etiquetas,
                vinculos: resp.vinculos,
                vinculadores: resp.vinculadores
			});
			this.props.handlePath(get_path(this.state.path));
			
			{/* Hide no loading */}
			document.querySelector(".loading").style.display = "none";
        }).catch(error => console.log(`Erro ao abrir arquivo: ${error}`));

        this.setState({capa: TjdocsApi.getImgURL(arquivo_id)});
        
    }
	
    render() {        
        return <div>
            <FileView documento={this.state.documento} capa={this.state.capa} loadArquivo={this.loadArquivo} etiquetas={this.state.etiquetas} vinculos={this.state.vinculos} vinculadores={this.state.vinculadores}/>
        </div>;
    }
}
