import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SearchContainer from '../../containers/SearchContainer';
import { BuscarPastas } from '../BuscarPastas';
import { ViewOptions } from '../Menu/ViewOptions';

const SearchWrapper = ({ component: Component, ...props }) => {
  const [pathname, setPathname] = useState(null);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [etiquetas, setEtiquetas] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (pathname !== location.pathname) {
      setPathname(location.pathname);
      setShowSearchResults(false);
    }
  }, [pathname, setPathname, location.pathname, setShowSearchResults]);

  const handleSubmitBusca = (etiquetas) => {
    setShowSearchResults(true);
    setEtiquetas(etiquetas);
  };

  const handleClean = () => {
    setShowSearchResults(false);
  };

  return (
    <>
      <BuscarPastas onSubmit={handleSubmitBusca} onClean={handleClean} />
      {showSearchResults ? (
        <>
          <div className='p-contd-menuTopo d-flex align-items-center justify-content-sm-end '>
            <ViewOptions
              showFolderIcon={showSearchResults}
              setFoldersView={() => setShowSearchResults(false)}
              setSearchResultsView={() => setShowSearchResults(true)}
              hideFoldersView
            />
          </div>
          <SearchContainer
            showSearchResults={showSearchResults}
            etiquetas={etiquetas}
            {...props}
          />
        </>
      ) : (
        <Component
          showSearchResults={showSearchResults}
          setFoldersView={() => setShowSearchResults(false)}
          setSearchResultsView={() => setShowSearchResults(true)}
          {...props}
        />
      )}
    </>
  );
};

export default SearchWrapper;
