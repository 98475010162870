import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { OverlayTrigger, Button, Tooltip, Modal } from 'react-bootstrap';
import TjdocsApi from '../api/API';
import toast from 'react-hot-toast';
import Authentication from '../Authentication';
import { EditarVisibilidade } from '../components/EditarVisibilidade';
import { mostrarLoading, ocultarLoading } from '../helper/Helper';
export class DominioIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisibilidade: false,
      visibilidadeId: 0,
    };
    this.showLoading = this.showLoading.bind(this);
  }

  isLogin() {
    return Authentication.getUsuario();
  }

  showLoading() {
    mostrarLoading();
  }

  moverNodo = () => {
    //e.preventDefault();
    var tipoNodo = window.sessionStorage.getItem('tipo_nodo');
    var nodoCopiado = window.sessionStorage.getItem('nodo');
    var nodoColado = this.props.id;

    if (nodoCopiado !== nodoColado) {
      // nao colar nele mesmo
      if (tipoNodo === 'pasta') {
        //tratar as demais questoes escondendo o botao colar
        TjdocsApi.moverPasta(nodoCopiado, nodoColado)
          .then((response) => {
            toast.success('Movido!');
          })
          .catch((e) => {
            toast.error('Movendo Pasta: ' + e);
            if (document.querySelector('.loadingImportant')) {
              document.querySelector('.loadingImportant').style.display =
                'none';
            }
          });
      } else {
        TjdocsApi.moverDocumento(nodoCopiado, nodoColado)
          .then((response) => {
            toast.success('Movido!');
          })
          .catch((e) => {
            toast.error('Movendo Arquvio: ' + e);
            if (document.querySelector('.loadingImportant')) {
              document.querySelector('.loadingImportant').style.display =
                'none';
            }
          });
      }
    }
  };

  setNodo = () => {
    window.sessionStorage.setItem('nodo', this.props.id);
    window.sessionStorage.setItem('tipo_nodo', 'pasta');
  };

  copiar = () => {
    document.body.click();
    this.setNodo();
    toast.success('Copiado!');
  };

  colar = () => {
    document.body.click();
    if (document.querySelector('.loading')) {
      document.querySelector('.loading').style.display = 'block';
      document.querySelector('.loading').classList = 'loadingImportant';
    }
    this.moverNodo();
    //toast.success("Movido!"); //-- MENSAGEM DEPENDE DA REQUISIÇÃO
  };

  alterarVisibilidade = (valor) => {
    TjdocsApi.buscarPasta(this.props.id).then((response) => {
      this.setState({ visibilidadeId: response.visibilidadeId });
      if (document.querySelector('.loading'))
        document.querySelector('.loading').style.display = 'none';
    });
    this.setState({ modalVisibilidade: valor });
  };

  setModalEditarArquivo = (valor) => {
    this.setState({ modalEditarArquivo: valor });
  };

  render() {
    const tooltipOptionsFileIcon = (props) => (
      <Tooltip {...props}>
        <div
          className='box box-primary'
          style={{ margin: 0, minWidth: '135px' }}
        >
          <div
            className='box-body no-padding'
            style={{
              color: '#333',
              fontSize: '180%',
            }}
          >
            <ul className='padding-0 ' style={{ textAlign: 'left' }}>
              {/* Copiar Pasta */}
              <li
                className='box-body no-padding'
                style={{ listStyle: 'none', padding: '7px 10px' }}
              >
                <a className='nav-link' href='#' onClick={(e) => this.copiar()}>
                  <i className='fas fa-cut'></i> Recortar
                </a>
              </li>

              {/* Colar */}
              <li
                className='box-body no-padding'
                style={{ listStyle: 'none', padding: '7px 10px' }}
              >
                <a className='nav-link' href='#' onClick={(e) => this.colar()}>
                  <i className='fas fa-paste'></i> Colar
                </a>
              </li>

              {/* Alterar visibilidade */}
              <li
                className='box-body no-padding'
                style={{ listStyle: 'none', padding: '7px 10px' }}
              >
                <a
                  className='nav-link'
                  href='#'
                  onClick={(e) => {
                    document.body.click();
                    this.alterarVisibilidade(true);
                  }}
                >
                  <i className='fas fa-eye'></i> Visibilidade
                </a>
              </li>

              {/* Cancelar */}
              <li
                className='box-body no-padding'
                style={{
                  listStyle: 'none',
                  padding: '7px 10px',
                  display: 'none',
                }}
              >
                <a
                  className='nav-link'
                  href='#'
                  onClick={(e) => {
                    window.sessionStorage.setItem('nodo', '-1');
                    toast.success('Cancelado!');
                  }}
                >
                  <i className='fas fa-share'></i> Cancelar
                </a>
              </li>

              {/* Cancelar */}
              <li
                className='box-body no-padding'
                style={{
                  listStyle: 'none',
                  padding: '7px 10px',
                  display: 'none',
                }}
              >
                <a
                  className='nav-link'
                  href='#'
                  onClick={(e) => {
                    window.sessionStorage.setItem('nodo', '-1');
                    toast.success('Cancelado!');
                  }}
                >
                  <i className='fas fa-share'></i> Cancelar
                </a>
              </li>

              {/* Excluir Pasta */}
              <li
                className='box-body no-padding'
                style={{
                  listStyle: 'none',
                  padding: '7px 10px',
                  display: 'none',
                }}
              >
                <a className='nav-link' href='#'>
                  <i className='fas fa-trash-alt'></i> Excluir
                </a>
              </li>
            </ul>

            {/*A pasta id é: {this.props.id}
				o tipo é: {window.sessionStorage.getItem("tipo_nodo")}*/}
          </div>
        </div>
      </Tooltip>
    );

    const tooltipArquivosVinculados = (props) => (
      <Tooltip {...props}>
        <div className='box box-primary' style={{ margin: 0 }}>
          <div className='box-header with-border box-defaultClaro'>
            <h3 className='box-title'>
              <a role='button' href='#' onClick={(e) => e.preventDefault()}>
                Arquivos Vinculados{' '}
                <i
                  style={{ float: 'right' }}
                  className='fas fa-times'
                  onClick={() => document.body.click()}
                />
              </a>
            </h3>
          </div>
          <div
            className='box-body no-padding'
            style={{ color: '#333', fontSize: '180%' }}
          >
            <ul className='padding-0' style={{ textAlign: 'left' }}>
              <li style={{ listStyle: 'none', padding: '2px 10px 0' }}>
                <span className='fas'>Nome do Gestor: </span>
                <br />
                <div style={{ height: '40px', overflow: 'auto' }}>
                  {this.props.listaGestores !== null && (
                    <>
                      {this.props.listaGestores._embedded.path.map((gestor) => (
                        <>
                          {gestor.username}
                          <br />
                        </>
                      ))}
                    </>
                  )}
                </div>
              </li>
              <li style={{ listStyle: 'none', padding: '2px 10px 0' }}>
                <span className='fas'>Contato do Gestor: </span>
                <br />
                <div style={{ height: '40px', overflow: 'auto' }}>
                  {this.props.listaGestores !== null && (
                    <>
                      {this.props.listaGestores._embedded.path.map((gestor) => (
                        <>
                          {gestor.email}
                          <br />
                        </>
                      ))}
                    </>
                  )}
                </div>
              </li>
              <li style={{ listStyle: 'none', padding: '2px 10px 0' }}>
                <span className='fas'>Visibilidade: </span>&nbsp;
                {this.props.nomeVisibilidade}
              </li>
              <li style={{ listStyle: 'none', padding: '2px 10px 0' }}>
                <span className='fas'>Data de criação: </span>&nbsp;
                {this.props.createdAt}
              </li>
              <li style={{ listStyle: 'none', padding: '2px 10px 0' }}>
                <span className='fas'>Data de modificação: </span>&nbsp;
                {this.props.updatedAt}
              </li>
            </ul>

            {/*<ul className="padding-0" style={{textAlign: "left"}} >
            {this.usuario.nome_completo !== null &&<li style={{listStyle: "none", padding: "2px 10px 0"}} >
                <span class="fas" >Usuário: </span> &nbsp;{this.usuario.nome_completo}
              </li> }
              {this.props.permissoesUsuarios !== null && <li style={{listStyle: "none", padding: "2px 10px 0" }} >
                <span class="fas" >Permissões: </span> 
              </li> }
              {(this.props.permissoesUsuarios !== null && this.props.permissoesUsuarios.adicionarRemoverPasta !== null && this.props.permissoesUsuarios.adicionarRemoverPasta) && <li style={{listStyle: "none", padding: "2px 30px 0", fontSize: "12px" }} >
                Adicionar/Remover pastas
              </li> }
              {(this.props.permissoesUsuarios !== null && this.props.permissoesUsuarios.adicionarRemoverArquivo !== null && this.props.permissoesUsuarios.adicionarRemoverArquivo) && <li style={{listStyle: "none", padding: "2px 30px 0", fontSize: "12px" }} >
                Adicionar/Remover arquivos
              </li> }
              {(this.props.permissoesUsuarios !== null && this.props.permissoesUsuarios.adicionarRemoverUsuarios !== null && this.props.permissoesUsuarios.adicionarRemoverUsuarios) && <li style={{listStyle: "none", padding: "2px 30px 0", fontSize: "12px" }} >
                Adicionar/Remover usuários
              </li>}
              {(this.props.permissoesUsuarios !== null && this.props.permissoesUsuarios.apagarTodosArquivos !== null && this.props.permissoesUsuarios.apagarTodosArquivos) && <li style={{listStyle: "none", padding: "2px 30px 0", fontSize: "12px" }} >
                Remover qualquer arquivos
              </li>}
              {(this.props.permissoesUsuarios !== null && this.props.permissoesUsuarios.apagarTodasPastas !== null && this.props.permissoesUsuarios.apagarTodasPastas) && <li style={{listStyle: "none", padding: "2px 30px 0", fontSize: "12px" }} >
                Remover qualquer pasta
                </li>} 
            </ul>	*/}
          </div>
        </div>
      </Tooltip>
    );

    return (
      <div
        className='p-c-p-cards tabByArrow'
        tabIndex='0'
        data-abrir='link_a_abrir'
      >
        <EditarVisibilidade
          state={this.props.state}
          idPasta={this.props.id}
          loadComponent={this.props.loadComponent}
          visibilidadeId={this.state.visibilidadeId}
          modalVisibilidade={this.state.modalVisibilidade}
          close={() => this.alterarVisibilidade(false)}
        />
        {this.isLogin().nome_completo &&
        this.isLogin().authorities === 'ADMINISTRADOR' ? (
          <OverlayTrigger
            trigger='click'
            placement='bottom'
            overlay={tooltipOptionsFileIcon}
            rootClose={true}
          >
            <div
              style={{
                width: '30px',
                height: '25px',
                cursor: 'help',
                position: 'absolute',
                right: 0,
              }}
            >
              <span
                className='etiquetas-ico'
                style={{
                  backgroundColor: '#6dcda0',
                  padding: '1px 7px',
                  color: 'var(--paleta_branco)',
                }}
                title='Mais opções'
              >
                <i className='fas fa-ellipsis-v' />
              </span>
            </div>
          </OverlayTrigger>
        ) : (
          ''
        )}

        <OverlayTrigger
          trigger='click'
          placement='top'
          overlay={tooltipArquivosVinculados}
          rootClose={true}
        >
          <div
            style={{
              width: '30px',
              height: '25px',
              cursor: 'help',
              position: 'absolute',
              right: '25px',
              display: 'block',
            }}
          >
            <span
              className='etiquetas-ico'
              title='Arquivos vinculados'
              style={{ backgroundColor: '#009abf', padding: '1px 7px' }}
            >
              <i className='fas fa-info'></i>
            </span>
          </div>
        </OverlayTrigger>

        <Link
          to={{ pathname: `/pastas/${this.props.id}` }}
          onClick={this.showLoading}
        >
          <div className='p-c-p-crds-imagem' aria-hidden='true'>
            <svg
              className='imagem_Cards icones-arquivo svg-azul-escuro'
              x='0px'
              y='0px'
              viewBox='0 0 56 56'
              width='50px'
              height='50px'
            >
              <g>
                <path
                  d='M18,34.486v4.028C18,38.782,18.218,39,18.486,39h19.028C37.782,39,38,38.782,38,38.514v-4.028   C38,33.115,39.115,32,40.486,32h15.228L45.713,3H10.287l-10,29h15.227C16.885,32,18,33.115,18,34.486z M14,8h28v6h-2v-4H16v4h-2V8z    M11,16h34v6h-2v-4H13v4h-2V16z M10,30H8v-6h40v6h-2v-4H10V30z'
                  fill='#333333'
                />
                <path
                  d='M40,34.486v4.028C40,39.885,38.885,41,37.514,41H18.486C17.115,41,16,39.885,16,38.514v-4.028   C16,34.218,15.782,34,15.514,34H0v15.375C0,51.374,1.626,53,3.625,53h48.749C54.374,53,56,51.374,56,49.375V34H40.486   C40.218,34,40,34.218,40,34.486z'
                  fill='#333333'
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </div>
          <div className='p-c-p-crds-texto'>
            <div className='p-c-p-c-txt-center'>
              <span className='sr-only'>Domínio:</span>
              {this.props.nome.length >= 20
                ? this.props.nome
                    .substring(0, 17)
                    .concat('...')
                    .concat(
                      this.props.nome.substring(this.props.nome.length - 9),
                    )
                : this.props.nome}
              {/*
		  <Link to={{pathname: `/pastas/${this.props.id}`}} >
            {this.props.nome}
          </Link>
		  */}
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

DominioIcon.propTypes = {
  nome: PropTypes.string,
};
