import React from "react";
import { NavLink } from "react-router-dom";

function BoxMenu({id, routes, boxclasses, titulo}) {
  
  var boxcolor = "box box-primary";
  if (boxclasses != undefined && boxclasses.length > 0) boxcolor = "box " + boxclasses;
  
  var boxid = "menu01";
  if (id != undefined && id.length > 0) boxid = id;
  
  var boxtitulo = "Conteúdo";
  if (titulo != undefined && titulo.length > 0) boxtitulo = titulo;
  
  function toggle(id, evento){
	evento.preventDefault();
	var e = document.getElementById(id);
	if (!e) return;
	e.classList.toggle("show");
	
	if (!evento.target.getAttribute('aria-expanded')) return;
	if (evento.target.getAttribute('aria-expanded') == 'true') {
		evento.target.setAttribute('aria-expanded', 'false');
	} else {
		evento.target.setAttribute('aria-expanded', 'true');
	}
  };
  
  return (
	<div>
	<div className={boxcolor}>
		<div className="box-header with-border box-defaultClaro">
		  <h3 className="box-title"> <a role="button" data-toggle="collapse" href={"#"+boxid} aria-expanded="true" aria-controls={boxid} >{boxtitulo}</a> </h3>
		</div>{/* fim box-header */}
		<div id={boxid} className="collapse show" data-parent={"#"+boxid}>
		  <div className="box-body no-padding">
			<ul className="nav flex-column nav-stacked">
			  
			
			
			{routes.map((prop, index) => {
			if (!prop.submenus)
			  return (
				<li
				  className={"nav-item "}
				>
				  <NavLink
					to={prop.layout + prop.path}
					className="nav-link d-flex justify-content-between"
					activeClassName="active"
				  >
					{prop.name} <i className={prop.icon} />
				  </NavLink>
				</li>
			  );
			else {
			  return (
				<li className="nav-item submenu">
					<a className="nav-link d-flex justify-content-between" role="button" aria-expanded="false" data-toggle="collapse" aria-controls={boxid + "_sub" + index} href={boxid + "_sub" + index} onClick={(e) => toggle(boxid + "_sub" + index, e)}> {prop.name}
					  <div>
						<span className="iconSubmenu"> <i className="fa fa-angle-left" /> </span>
						<span className="label label-default" aria-hidden="true">{prop.submenus.length}</span>
					  </div>
					</a>
					<ul className="nav flex-column nav-stacked collapse" id={boxid + "_sub" + index} data-parent={boxid + "_sub" + index}>
					
					{prop.submenus.map((prop) => {
						  return (
							<li>
							  <NavLink
								to={prop.layout + prop.path}
								className="nav-link d-flex justify-content-between"
								activeClassName="active"
							  >
								{prop.name} <i className={prop.icon} />
							  </NavLink>
							</li>
						  );
						return null;
					})}
					
					</ul>
				</li>
			  );
			}
			return null;
			})}
			
			
			</ul>
		  </div>{/* fim box-body */} 
		</div>{/* fim menu */} 
	</div>
    </div>
  );
}

export default BoxMenu;
