import ApiService from "../apiService";
import ErroValidacao from "./exception/ErroValidacao";

export default class UsuarioPastaService extends ApiService {
  constructor() {
    super("v1/");
  }

  validar(usuario, pasta) {
    const erros = [];

    if (!usuario.id) {
      erros.push("Informe o Usuário.");
    }

    if (!pasta.id) {
      erros.push("Informe a Pasta.");
    }

    if (erros && erros.length > 0) {
      throw new ErroValidacao(erros);
    }
  }

  listar(usuarioId) {
    return this.get(`usuarios/${usuarioId}/pastas`);
  }

  estahAssociado(usuarioId, pastaId) {
     return this.get (`usuarios/${usuarioId}/pastas/${pastaId}`);
  }

  associar(usuarioId, pastaId) {
    return this.put(`usuarios/${usuarioId}/pastas/${pastaId}`);
  }

  desassociar(usuarioId, pastaId) {
    return this.delete(`usuarios/${usuarioId}/pastas/${pastaId}`);
  }
  
}
