export default class AuthenticationArchivematica {
  static url() {
    return 'https://archivematica-dsv-webservice.apps.ocp-prd.tjgo.jus.br'; // URL do segundo backend
  }

  static async getAccessTokenArchivematica() {
    let urlGetAccessToken = `${this.url()}/rfb/oauth2/token`;

    const username = 'archivematicaws';
    const password = 'archivematica123';
    const credentials = btoa(`${username}:${password}`);

    // Configura os headers da requisição
    const headers = {
      Authorization: `Basic ${credentials}`,
      Accept: '*/*',
      Host: 'archivematica-dsv-webservice.apps.ocp-prd.tjgo.jus.br',
      'Accept-Encoding': 'gzip, deflate, br',
      Connection: 'keep-alive',
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    // Configura o corpo da requisição
    const body = 'grant_type=client_credentials&scope=READ';

    try {
      // Faz a requisição POST
      const response = await fetch(urlGetAccessToken, {
        method: 'POST',
        headers: headers,
        body: body,
      });

      // Verifica se a resposta foi bem-sucedida
      if (!response.ok) {
        throw new Error(`Erro na resposta do servidor: ${response.statusText}`);
      }

      // Converte a resposta para JSON
      const data = await response.json();

      this.storeAuthentication(data);

      return data;
    } catch (error) {
      console.error('Erro ao obter o token:', error);
    }
  }

  static storeAuthentication(archivematicaAccessToken) {
    if (typeof Storage !== 'undefined') {
      sessionStorage.ArchivematicaAccessToken =
        archivematicaAccessToken.access_token;
    } else {
      console.error('sessionStorage não está disponível.');
    }
  }

  static logoutArchivematica() {
    sessionStorage.removeItem('ArchivematicaAccessToken');
  }
}
