import React, { Component } from "react";

export class Footer extends Component {
  render() {
	
	{/* posiciona o footer em baixo */}
	if (document.getElementById('princpl-conteudo') != null) document.getElementById('princpl-conteudo').style = "min-height: " + (window.innerHeight - 184) + "px";
	
	return (
      <div className="footer px-0 px-lg-3">
          <div className="row margin-0">
            <div className="col-md-6">
			<img className="social-area" src="/ui/img/Logo_TJGO.svg" width="250px" title="Logotipo do Tribunal de Justiça de Goiás"/>
            </div>
            <div className="col-md-6">
             <div className="social-area text-right">
                <small><strong>Diretoria de Tecnologia da Informação<br /> Coordenadoria de Engenharia de Software</strong></small>
            </div>
            </div>
          </div>
      </div>
    );
  }
}
