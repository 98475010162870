import React, {Component} from "react";
import {FileIcon} from "../components/FileIcon";
import {FolderIcon} from "../components/FolderIcon";
import {GradePastas} from "../components/GradePastas";
import {GradeArquivos} from "../components/GradeArquivos";

export class MiniDocView extends Component {
  render() {
    
	var exibicao = "d-none";
	if (typeof(Storage) !== "undefined") {
		if (typeof(localStorage.exibicao) !== "undefined"){
			if(localStorage.exibicao === "miniaturas") exibicao = "";
		} else {
			exibicao = "";
		}
	}
	
	return <span id="P-DisplayMiniaturas" className={exibicao}>
      
      
      
      <h4 className="sr-only" tabIndex="0" id="P-Inicio_DisplayMiniaturas">
        Documentos listados em grade.
      </h4>
      {this.props.children}

    </span>;
  }
}
