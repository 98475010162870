import React, {Component} from "react";
import {MenuDominio} from "./MenuDominio";
import { MenuDominioPrincipal } from "./MenuDominioPrincipal";

export class MenuTopo extends Component {

	constructor(props) {
        super(props);
    }
	
	componentDidMount() {
		
	}
    render() {
		
		var exibicaoLista = "icones ";
		var exibicaoGrade = "icones ";
		if (typeof(Storage) !== "undefined") {
			if (typeof(localStorage.exibicao) !== "undefined"){
				if(localStorage.exibicao === "lista") {
					exibicaoGrade += "d-none";
					exibicaoLista += "";
				} else {
					exibicaoGrade += "";
					exibicaoLista += "d-none";
				}
			} else {
				localStorage.exibicao = "miniaturas";
				exibicaoGrade += "";
				exibicaoLista += "d-none";
			}
		}
		
		function gradeParaLista(){
			document.getElementById('alterar_P-DisplayLista').classList.add('d-none');
			document.getElementById('alterar_P-DisplayMiniaturas').classList.remove('d-none');
			
			document.getElementById('P-DisplayLista').classList.remove('d-none');
			document.getElementById('P-DisplayMiniaturas').classList.add('d-none');
			if (typeof(Storage) !== "undefined") localStorage.exibicao = "lista";
		}
		
		function listaParaGrade(){
			document.getElementById('alterar_P-DisplayLista').classList.remove('d-none');
			document.getElementById('alterar_P-DisplayMiniaturas').classList.add('d-none');
			
			document.getElementById('P-DisplayLista').classList.add('d-none');
			document.getElementById('P-DisplayMiniaturas').classList.remove('d-none');
			if (typeof(Storage) !== "undefined") localStorage.exibicao = "miniaturas";
		}
		
        return <div className="p-contd-menuTopo d-flex align-items-center">

				<div className="p-c-menTp-Endereco flex-grow-1">
					<MenuDominioPrincipal atualiza={this.props.atualiza}/>
				</div>

				<div className="p-c-menTp-Opcoes">
					<span>
						<a className="icones" title="Ordernado por data">
							<i className="fas fa-sort-amount-down-alt"></i>
							{/*<img className="icones" src="/ui/img/Sort_By-Icon.svg" alt="Aquivos ordernados por data."/>*/}
						</a>
						
						<a className={exibicaoLista} onClick={listaParaGrade} href="#!" id="alterar_P-DisplayMiniaturas" title="Visualização em miniatura."
							role="button">
							<i className="fas fa-th-large"></i>
							{/*<img className="icones svg-comHover" src="/ui/img/Display_Miniatures-Icon.svg" alt=""/>*/}
						</a>
						<a className={exibicaoGrade} onClick={gradeParaLista} href="#!" id="alterar_P-DisplayLista" title="Visualização em lista." role="button">
							<i id="alterarGradeParaLista" className="fas fa-list"></i>
							{/*<img className="icones svg-comHover" src="/ui/img/Display_List-Icon.svg" alt=""/>*/}
						</a>
					</span>
				</div>
			</div>;
    }
}
