import React, {Component} from 'react';
import Pagination from "react-js-pagination";
import {SeachComponent} from "../components/SearchComponent";
import TjdocsApi from "../api/API";
import toast from 'react-hot-toast';
import DocumentoEtiquetaService from "../api/service/documentoEtiquetaService";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {Button, Dropdown, Modal, Spinner} from "react-bootstrap";

const parseDate = (dateString) => {
    const [date, time] = dateString.split(' ');
    const [day, month, year] = date.split('/').map(Number);
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds); // month is 0-indexed
};

export default class SearchContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resultado: [[]],
            tudoPaginado: [],
            pastaPaginado: [],
            documentoPaginado: [],
            conteudoPaginado: [],
            paginacao: {},
            tab: 0,
            paginaAtual: 1,
            qtdPaginas: 1,
            qtdPorPagina: 10,
            elementoInicial: 1,
            elementoFinal: 10,
            qtdTudo: 0,
            qtdPastas: 0,
            qtdDocumentos: 0,
            qtdConteudos: 0,
            fieldOrdering: 'texto',
            orderType: 'asc',
            orderLabel: <span><i className="icon-ordenacao fas fa-sort-alpha-down"></i>Alfabética (crescente)</span>,
            resultadosClicados: [],
            loading: false
        };

        this.documentoEtiquetaService = new DocumentoEtiquetaService();
     }

    componentWillMount() {
        if (this.props.match.params.tokens)
            this.loadComponent(this.props.match.params.tokens);
        else if (this.props.match.params.etiqueta_id)
        this.loadComponentEtiquetas(this.props.match.params.etiqueta_id);
    }

    componentDidMount() {
        if (this.props.match.params.tokens === undefined) {
            this.loadComponentBuscaAvancada();
        } else {
            this.loadComponent(this.props.match.params.tokens);
        }
    }

    ordenarLista = (lista, field, order) => {

        if(lista === undefined || lista.length < 1) {
            return [];
        }

        if (field === 'data') {
            lista.sort((a, b) => {
                if (order === 'asc') {
                    return parseDate(a.dataCadastro) - parseDate(b.dataCadastro);
                } else {
                    return parseDate(b.dataCadastro) - parseDate(a.dataCadastro);
                }
            });
        }

        if (field === 'texto') {
            if (order === 'asc') {
                lista.sort((a, b) => a.texto.localeCompare(b.texto));
            } else {
                lista.sort((a, b) => b.texto.localeCompare(a.texto));
            }
        }

        return lista;

    }
    
    loadComponent = (tokens) => {

        this.setState({loading: true});
        TjdocsApi.consultarComListasSeparadas(tokens,0).then(resp => {

            let tudoOrdenado = this.ordenarLista(resp[0], this.state.fieldOrdering, this.state.orderType);
            let pastaOrdenado = this.ordenarLista(resp[1], this.state.fieldOrdering, this.state.orderType);
            let documentoOrdenado = this.ordenarLista(resp[2], this.state.fieldOrdering, this.state.orderType);
            let conteudoOrdenado = this.ordenarLista(resp[3], this.state.fieldOrdering, this.state.orderType);

            let respOrdenada = [];

            respOrdenada.push(tudoOrdenado);
            respOrdenada.push(pastaOrdenado);
            respOrdenada.push(documentoOrdenado);
            respOrdenada.push(conteudoOrdenado);

            let calcularQtdPaginas = Math.ceil(resp[0].length / 10);

			this.setState({
                resultado: respOrdenada,
                tudoPaginado: tudoOrdenado.slice(0, 10),
                pastaPaginado: pastaOrdenado.slice(0, 10),
                documentoPaginado: documentoOrdenado.slice(0, 10),
                conteudoPaginado: conteudoOrdenado,
                qtdPaginas: calcularQtdPaginas,
                qtdTudo: tudoOrdenado.length,
                qtdPastas: pastaOrdenado.length,
                qtdDocumentos: documentoOrdenado.length,
                qtdConteudos: conteudoOrdenado.length,
                loading: false
            });
        }).catch(error => {
            this.setState({loading: false});
            toast.error(`Erro ao abrir pasta: ${error}`)
        });
    }
    
    loadComponentEtiquetas = (etiqueta_id) => {
        this.documentoEtiquetaService
            .listarDocumentos(etiqueta_id)
            .then(
                (res) => {
                    if (res.data._embedded)
                        this.setState({ resultado: res.data._embedded.documentos });
                    else toast.error(`Sem documentos para a etiqueta`);
                }
            //).catch(err => toast.error(`Erro ao tentar abrir documentos do usuário: ${err}`));
            );
    }

    loadComponentBuscaAvancada = () => {
        this.setState({loading: true});

        TjdocsApi.buscaAvancada().then(resp => {

            let tudoOrdenado = this.ordenarLista(resp[0], this.state.fieldOrdering, this.state.orderType);
            let pastaOrdenado = this.ordenarLista(resp[1], this.state.fieldOrdering, this.state.orderType);
            let documentoOrdenado = this.ordenarLista(resp[2], this.state.fieldOrdering, this.state.orderType);
            let conteudoOrdenado = this.ordenarLista(resp[3], this.state.fieldOrdering, this.state.orderType);

            let respOrdenada = [];

            respOrdenada.push(tudoOrdenado);
            respOrdenada.push(pastaOrdenado);
            respOrdenada.push(documentoOrdenado);
            respOrdenada.push(conteudoOrdenado);

            let calcularQtdPaginas = Math.ceil(resp[0].length / 10);

            this.setState({
                resultado: respOrdenada,
                tudoPaginado: tudoOrdenado.slice(0, 10),
                pastaPaginado: pastaOrdenado.slice(0, 10),
                documentoPaginado: documentoOrdenado.slice(0, 10),
                conteudoPaginado: conteudoOrdenado,
                qtdPaginas: calcularQtdPaginas,
                qtdTudo: tudoOrdenado.length,
                qtdPastas: pastaOrdenado.length,
                qtdDocumentos: documentoOrdenado.length,
                qtdConteudos: conteudoOrdenado.length,
                loading: false
            });
        }).catch(error => {
            this.setState({loading: false});
            toast.error(`Erro ao abrir pasta: ${error}`)
        });
    }

    handlePageChange = (page_number) => {

        let elementoInicial = (page_number - 1) * this.state.qtdPorPagina;
        let elementoFinal = elementoInicial + this.state.qtdPorPagina;

        this.setState({
            tudoPaginado: this.state.resultado[0].slice(elementoInicial, elementoFinal),
            pastaPaginado: this.state.resultado[1].slice(elementoInicial, elementoFinal),
            documentoPaginado: this.state.resultado[2].slice(elementoInicial, elementoFinal),
            conteudoPaginado: this.state.resultado[3].slice(elementoInicial, elementoFinal),
            elementoInicial: elementoInicial + 1,
            elementoFinal: elementoFinal,
            paginaAtual: page_number
        }, () => {
            this.marcarResultadosClicados();
        });

    };

    handleTabChange = (tabIndex) => {
        let calcularQtdPaginas = Math.ceil(this.state.resultado[tabIndex].length / this.state.qtdPorPagina);

        this.setState({
            tudoPaginado: this.state.resultado[0].slice(0, this.state.qtdPorPagina),
            pastaPaginado: this.state.resultado[1].slice(0, this.state.qtdPorPagina),
            documentoPaginado: this.state.resultado[2].slice(0, this.state.qtdPorPagina),
            conteudoPaginado: this.state.resultado[3].slice(0, this.state.qtdPorPagina),
            tab: tabIndex,
            qtdPaginas: calcularQtdPaginas,
            paginaAtual: 1
        }, () => {
            this.marcarResultadosClicados();
        });
    };

    insertTabTitle = (tabTitle) => {
        return <span>{tabTitle}
            <span className="label-qtd-resultados">{this.state.qtdTudo}</span>
            </span>;
    }

    returnTabPastas = (tabTitle) => {
        return <span>
            <i className="fa fa-folder" style={{marginRight: "5px"}}></i>
            {tabTitle}
            <span className="label-qtd-resultados">{this.state.qtdPastas}</span>
        </span>;
    }

    returnTabDocumentos = (tabTitle) => {
        return <span>
            <i className="fa fa-file" style={{marginRight: "5px"}}></i>
            {tabTitle}
            <span className="label-qtd-resultados">{this.state.qtdDocumentos}</span>
        </span>;
    }

    returnTabConteudos = (tabTitle) => {
        return <span>
            <i className="fa fa-spell-check" style={{marginRight: "5px"}}></i>
            {tabTitle}
            <span className="label-qtd-resultados">{this.state.qtdConteudos}</span>
        </span>;
    }

    handlePageQtdChange = (page_qtd) => {
        let elementoInicial = 0;
        let elementoFinal = page_qtd;

        let calcularQtdPaginas = Math.ceil(this.state.resultado[0].length / page_qtd);

        this.setState({
            tudoPaginado: this.state.resultado[0].slice(elementoInicial, elementoFinal),
            pastaPaginado: this.state.resultado[1].slice(elementoInicial, elementoFinal),
            documentoPaginado: this.state.resultado[2].slice(elementoInicial, elementoFinal),
            conteudoPaginado: this.state.resultado[3].slice(elementoInicial, elementoFinal),
            elementoInicial: elementoInicial + 1,
            elementoFinal: elementoFinal,
            paginaAtual: 1,
            qtdPorPagina: page_qtd,
            qtdPaginas: calcularQtdPaginas
        });
    };

    alterarOrdenacao = (field, order) => {

        let newOrderLabel;

        if(field === 'texto') {
            if(order === 'asc') {
                newOrderLabel = <span><i className="icon-ordenacao fas fa-sort-alpha-down"></i>Alfabética (crescente)</span>
            } else {
                newOrderLabel = <span><i className="icon-ordenacao fas fa-sort-alpha-up"></i>Alfabética (decrescente)</span>
            }
        } else if(field === 'data') {
            if(order === 'asc') {
                newOrderLabel = <span><i className="icon-ordenacao fas fa-sort-numeric-down"></i>Data de criação (crescente)</span>
            } else {
                newOrderLabel = <span><i className="icon-ordenacao fas fa-sort-numeric-up"></i>Data de criação (decrescente)</span>
            }
        }

        let tudoOrdenado = this.ordenarLista(this.state.resultado[0], field, order);
        let pastaOrdenado = this.ordenarLista(this.state.resultado[1], field, order);
        let documentoOrdenado = this.ordenarLista(this.state.resultado[2], field, order);
        let conteudoOrdenado = this.ordenarLista(this.state.resultado[3], field, order);

        this.setState({
            tudoPaginado: tudoOrdenado.slice(0, 10),
            pastaPaginado: pastaOrdenado.slice(0, 10),
            documentoPaginado: documentoOrdenado.slice(0, 10),
            conteudoPaginado: conteudoOrdenado.slice(0, 10),
            fieldOrdering: field,
            orderType: order,
            orderLabel: newOrderLabel
        });
    }

    clicarEmResultado = (id) => {
        this.state.resultadosClicados.push(id);

        let paragraphSelector = 'p[id="' + id + '"]';

        let paragraphs = document.querySelectorAll(paragraphSelector);

        paragraphs.forEach(paragraph => {
            paragraph.classList.add('elemento-clicado');
        });
    };

    marcarResultadosClicados = () => {

        let allParagraphs = document.querySelectorAll('p');

        allParagraphs.forEach(paragraph => {
            paragraph.classList.remove('elemento-clicado');
        });

        this.state.resultadosClicados.forEach(id => {
            let paragraphSelector = 'p[id="' + id + '"]';
            let paragraphs = document.querySelectorAll(paragraphSelector);
            paragraphs.forEach(paragraph => {
                paragraph.classList.add('elemento-clicado');
            });
        });

    }

    render() {
        return (
            <div className="p-contd-busca">
            <div className="p-c-busc-quantificacao"
                     style={{display: this.props.match.params.etiqueta_id != undefined ? "block" : "none"}}>
                    Sua busca em '/etiqueta/{this.props.match.params.etiqueta_id}'
                    obteve {this.state.resultado.length} resultados.
                </div>

                <Modal show={this.state.loading}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Pesquisando...
                            <Spinner animation="border" style={{marginLeft: '5px'}}/>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Por favor, aguarde.</Modal.Body>
                </Modal>

                <div className="top-nav-bar-pesquisa" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                   <div style={{ display: 'flex', alignItems: 'center', color: 'var(--paleta_cinza_escuro)' }}>
                        <Dropdown >
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic"
                                             style={{backgroundColor: 'white'}}>
                                {this.state.qtdPorPagina}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => this.handlePageQtdChange(10)}>10</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handlePageQtdChange(20)}>20</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.handlePageQtdChange(50)}>50</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                       <span style={{ marginLeft: '5px', fontSize: '1.3rem' }}>Resultados por página</span>

                   </div>

                    <Dropdown>

                        <Dropdown.Toggle variant="secondary" id="dropdown-basic"
                                         style={{backgroundColor: 'white'}}>
                            {this.state.orderLabel}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Header>Ordenação</Dropdown.Header>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => this.alterarOrdenacao("texto", "asc")}>
                                <i className="icon-ordenacao fas fa-sort-alpha-down"></i>Alfabética (crescente)
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => this.alterarOrdenacao("texto", "desc")}>
                                <i className="icon-ordenacao fas fa-sort-alpha-up"></i>Alfabética (decrescente)
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => this.alterarOrdenacao("data", "asc")}>
                                <i className="icon-ordenacao fas fa-sort-numeric-down"></i>Data de criação (crescente)
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => this.alterarOrdenacao("data", "desc")}>
                                <i className="icon-ordenacao fas fa-sort-numeric-up"></i>Data de criação (decrescente)
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <Tabs
                    id="controlled-tab-example"
                    activeKey={this.state.tab}
                    onSelect={(k) => this.handleTabChange(k)}
                    className="mb-3"
                >
                    <Tab eventKey="0" title={this.insertTabTitle("Tudo")} tabClassName="tabs-resultados-pesquisa" >
                        <div className="box-resultados">
                            {this.state.tudoPaginado.map((arquivo, i) => {
                                    if (arquivo.texto == undefined) arquivo.texto = (arquivo.nome !== undefined ? arquivo.nome : 'Nome arquivo');
                                    if (arquivo.fonte == undefined) arquivo.fonte = 'documento';
                                    if (arquivo.documentoId !== undefined) arquivo.id = arquivo.documentoId;

                                    return (
                                        <div onClick={() => this.clicarEmResultado(arquivo.id)}>
                                            <SeachComponent key={i} id={arquivo.id} nomeArquivo={arquivo.texto} path=""
                                                            resumo={arquivo.highlight} data={arquivo.dataCadastro} tamanho={arquivo.tamanho}
                                                            tipo={arquivo.fonte + "s"} local={arquivo.local}/>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </Tab>
                    <Tab eventKey="1" title={this.returnTabPastas("Pastas")} tabClassName="tabs-resultados-pesquisa">
                        <div className="box-resultados">
                            {this.state.pastaPaginado.map((arquivo, i) => {
                                    var highlight = "";
                                    if (arquivo.highlight) highlight = arquivo.highlight.substring(11, (arquivo.highlight.length - 2)); //Remove {conteudo=[ ]}
                                    if (arquivo.texto == undefined) arquivo.texto = (arquivo.nome !== undefined ? arquivo.nome : 'Nome arquivo');
                                    if (arquivo.fonte == undefined) arquivo.fonte = 'documento';
                                    if (arquivo.documentoId !== undefined) arquivo.id = arquivo.documentoId;

                                    return (
                                        <div onClick={() => this.clicarEmResultado(arquivo.id)}>
                                            <SeachComponent key={i} id={arquivo.id} nomeArquivo={arquivo.texto} path=""
                                                            resumo={highlight} data={arquivo.dataCadastro}
                                                            tamanho={arquivo.tamanho}
                                                            tipo={arquivo.fonte + "s"} local={arquivo.local}/>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </Tab>
                    <Tab eventKey="2" title={this.returnTabDocumentos("Documentos")}
                         tabClassName="tabs-resultados-pesquisa">
                        <div className="box-resultados">
                            {this.state.documentoPaginado.map((arquivo, i) => {
                                var highlight = "";
                                    if (arquivo.highlight) highlight = arquivo.highlight.substring(11, (arquivo.highlight.length - 2)); //Remove {conteudo=[ ]}
                                    if (arquivo.texto == undefined) arquivo.texto = (arquivo.nome !== undefined ? arquivo.nome : 'Nome arquivo');
                                    if (arquivo.fonte == undefined) arquivo.fonte = 'documento';
                                    if (arquivo.documentoId !== undefined) arquivo.id = arquivo.documentoId;

                                    return (
                                        <div onClick={() => this.clicarEmResultado(arquivo.id)}>
                                            <SeachComponent key={i} id={arquivo.id} nomeArquivo={arquivo.texto} path=""
                                                            resumo={highlight} data={arquivo.dataCadastro}
                                                            tamanho={arquivo.tamanho}
                                                            tipo={arquivo.fonte + "s"} local={arquivo.local}/>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </Tab>
                    <Tab eventKey="3" title={this.returnTabConteudos("Conteúdos")}
                         tabClassName="tabs-resultados-pesquisa">
                        <div className="box-resultados">
                            {this.state.conteudoPaginado.map((arquivo, i) => {
                                var highlight = "";
                                    if (arquivo.highlight) highlight = arquivo.highlight.substring(11, (arquivo.highlight.length - 2)); //Remove {conteudo=[ ]}
                                    if (arquivo.texto == undefined) arquivo.texto = (arquivo.nome !== undefined ? arquivo.nome : 'Nome arquivo');
                                    if (arquivo.fonte == undefined) arquivo.fonte = 'documento';
                                    if (arquivo.documentoId !== undefined) arquivo.id = arquivo.documentoId;

                                    return (
                                        <div onClick={() => this.clicarEmResultado(arquivo.id)}>
                                            <SeachComponent key={i} id={arquivo.id} nomeArquivo={arquivo.texto} path=""
                                                            resumo={arquivo.highlight} data={arquivo.dataCadastro}
                                                            tamanho={arquivo.tamanho}
                                                            tipo={arquivo.fonte + "s"} local={arquivo.local}/>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </Tab>
                </Tabs>

                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className="p-c-busc-quantificacao"
                         style={{
                             display: this.props.match.params.tokens != undefined ? "block" : "none",
                             color: 'var(--paleta_cinza_escuro)'
                         }}>
                        Exibindo {this.state.elementoInicial} até {this.state.elementoFinal} de {this.state.resultado[this.state.tab].length} resultados
                        buscando por '{this.props.match.params.tokens}'
                    </div>

                    <div className="p-c-busc-quantificacao"
                         style={{
                             display: this.props.match.params.tokens === undefined ? "block" : "none",
                             color: 'var(--paleta_cinza_escuro)'
                         }}>
                        Exibindo {this.state.elementoInicial} até {this.state.elementoFinal} de {this.state.resultado[this.state.tab].length} resultados
                    </div>

                    <Pagination
                        activeClass="active"
                        activeLinkClass="disabled"
                        activePage={this.state.paginaAtual}
                        disabledClass="disabled"
                        innerClass="pagination p-c-b-paginacao"
                        itemClass="page-item p-c-b-pagnc-paginas"
                        itemsCountPerPage={this.state.qtdPorPagina}
                        linkClass="page-link"
                        onChange={this.handlePageChange}
                        pageRangeDisplayed={5}
                        firstPageText="Primeira"
                        lastPageText="Última"
                        nextPageText="Próximo"
                        prevPageText="Anterior"
                        totalItemsCount={this.state.resultado[this.state.tab].length}
                    />
                </div>

            </div>
        );
    }
}
