import JWT from 'jwt-decode';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { sendLoginData } from '../actions/sendLogin';
import { sendLogout } from '../actions/sendLogout';
import TjdocsApi from '../api/API';
import Authentication from '../Authentication';
import { handleRootRouteReload } from '../helper/Helper';
import { ListaRelease } from './ListaRelease';
export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokens: ['teste', 'teste1'],
      email: '',
      password: '',
      textoDigitadoPesquisa: '',
      inputValue: '',
      ano_filtro: '',
      dadoTitulo: '',
      dadoConteudo: '',
      dadoLeiDecreto: '',
      dadoDescricao: '',
      loadedOptionsDescricao: [],
      exibeListaRelease: false,
      exibeEditarRelease: false,
      exibeCadastraRelease: false,
      releaseSelecionado: null,
      listaRelease: [],
      showTooltip: false,
      buscarPorConteudo: true,
      termos: {
        ano: '',
        conteudo: '',
        dataCriacao: '',
        descricao: '',
        leiOuDecreto: '',
        tipoArquivo: '',
        titulo: '',
      },
      cards: {
        ano: false,
        conteudo: false,
        dataCriacao: false,
        descricao: false,
        leiOuDecreto: false,
        tipoArquivo: false,
        titulo: false,
      },
      etiquetas: {
        pesquisaCom: 'OR',
        ano: [],
        conteudo: [],
        dataCriacao: [],
        descricao: [],
        leiOuDecreto: [],
        tipoArquivo: [],
        titulo: [],
      },
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleAnoChange = this.handleAnoChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.bodyLoad = this.bodyLoad.bind(this);
    this.loadLoginData = this.loadLoginData.bind(this);
    this.logout = this.logout.bind(this);
    this.usuario = Authentication.getUsuario();
    this.handleExibeListaRelease = this.handleExibeListaRelease.bind(this);
    this.handleCadastraRelease = this.handleCadastraRelease.bind(this);
    this.handleEditarRelease = this.handleEditarRelease.bind(this);
    this.permissoes = '';
  }

  handleExibeListaRelease = () => {
    TjdocsApi.listaRelease()
      .then((releases) => {
        console.log(releases);
        this.setState({ listaRelease: releases._embedded.documentos });
      })
      .catch((error) =>
        toast.error(`Erro ao tentar consultar os domínios: ${error}`),
      );

    if (this.state.exibeListaRelease) {
      this.setState({ exibeListaRelease: false });
      return;
    }

    this.setState({ exibeListaRelease: true });
  };

  componentDidMount() {
    const params = this.props.variavelPath.split('/');

    this.setState({ ano_filtro: '' });
    this.setState({ dadoTitulo: '' });
    this.setState({ dadoConteudo: '' });
    this.setState({ dadoLeiDecreto: '' });
    this.setState({ dadoDescricao: '' });

    if (params[1] === 'documentos') {
      this.setState({ ano_filtro: params[3] === 'undefined' ? '' : params[3] });
      this.setState({ dadoTitulo: params[4] === 'undefined' ? '' : params[4] });
      this.setState({
        dadoConteudo: params[5] === 'undefined' ? '' : params[5],
      });
      this.setState({
        dadoLeiDecreto: params[6] === 'undefined' ? '' : params[6],
      });
      this.setState({
        dadoDescricao: params[7] === 'undefined' ? '' : params[7],
      });
    }
  }

  limparState() {
    this.setState({ dadoTitulo: '' });
    this.setState({ dadoConteudo: '' });
    this.setState({ dadoLeiDecreto: '' });
    this.setState({ dadoDescricao: '' });
  }

  handleChange = (event) => {
    this.setState({
      tokens: event.target.value,
    });
  };

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleAnoChange(e) {
    this.setState({ ano_filtro: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handleSubmit(event) {
    var data = { usuario: this.state.email, senha: this.state.password };
    sendLoginData(data);
    event.preventDefault();
  }

  PesquisaNaPastaPorAno = (inputPesquisaNav, callback) => {
    console.log(inputPesquisaNav + 'Junior teste');
    if (inputPesquisaNav.length === 4) {
      clearTimeout(this.timeOutInputPesquisaNaPasta);
      const timeOut = setTimeout(() => {
        TjdocsApi.pesquisaTudo(
          this.state.dadoTitulo,
          this.state.dadoDescricao,
          this.state.dadoLeiDecreto,
          this.state.ano_filtro,
          this.state.dadoConteudo,
        )
          .then((jsonPesquisa) => {
            var retorno = jsonPesquisa.map((i, index) => ({
              label:
                '<span class="optionPesquisaPrincipal">' +
                (i.fonte === 'pasta'
                  ? '<i class="fa fa-folder"></i> '
                  : '<i class="fa fa-file"></i> ') +
                i.texto +
                '</span>' +
                (i.highlight === null
                  ? ''
                  : '<br/>' +
                    i.highlight.substring(11, i.highlight.length - 2)),
              value: '/' + i.fonte + 's/' + i.id,
            }));
            callback(retorno);
          })
          .catch((error) =>
            toast.error(`Erro ao tentar pesquisar termos: ${error}`),
          );
      }, 2000);
      this.timeOutInputPesquisaNaPasta = timeOut;
    }
  };

  loadOptionsPesquisaNaPasta = (inputPesquisaNav, callback) => {
    if (inputPesquisaNav.length >= 3) {
      clearTimeout(this.timeOutInputPesquisaNaPasta);
      const timeOut = setTimeout(() => {
        TjdocsApi.pesquisaTudo(
          this.state.dadoTitulo,
          this.state.dadoDescricao,
          this.state.dadoLeiDecreto,
          this.state.ano_filtro,
          this.state.dadoConteudo,
        )
          .then((jsonPesquisa) => {
            var retorno = jsonPesquisa.map((i, index) => ({
              label:
                '<span class="optionPesquisaPrincipal">' +
                (i.fonte === 'pasta'
                  ? '<i class="fa fa-folder"></i> '
                  : '<i class="fa fa-file"></i> ') +
                i.texto +
                '</span>' +
                (i.highlight === null
                  ? ''
                  : '<br/>' +
                    i.highlight.substring(11, i.highlight.length - 2)),
              value: '/' + i.fonte + 's/' + i.id,
            }));
            callback(retorno);
          })
          .catch((error) =>
            toast.error(`Erro ao tentar pesquisar termos: ${error}`),
          );
      }, 2000);
      this.timeOutInputPesquisaNaPasta = timeOut;
    }
  };

  loadOptionsPesquisaNaPastaPorDecretoLei = (inputPesquisaNav, callback) => {
    clearTimeout(this.timeOutInputPesquisaNaPasta);
    const timeOut = setTimeout(() => {
      TjdocsApi.pesquisaTudo(
        this.state.dadoTitulo,
        this.state.dadoDescricao,
        this.state.dadoLeiDecreto,
        this.state.ano_filtro,
        this.state.dadoConteudo,
      )
        .then((jsonPesquisa) => {
          var retorno = jsonPesquisa.map((i, index) => ({
            label:
              '<span class="optionPesquisaPrincipal">' +
              (i.fonte === 'pasta'
                ? '<i class="fa fa-folder"></i> '
                : '<i class="fa fa-file"></i> ') +
              i.texto +
              '</span>' +
              (i.highlight === null
                ? ''
                : '<br/>' + i.highlight.substring(11, i.highlight.length - 2)),
            value: '/' + i.fonte + 's/' + i.id,
          }));
          callback(retorno);
        })
        .catch((error) =>
          toast.error(`Erro ao tentar pesquisar termos: ${error}`),
        );
    }, 2000);
    this.timeOutInputPesquisaNaPasta = timeOut;
  };

  loadOptionsPesquisaNaPastaPorDescricao = (inputPesquisaNav, callback) => {
    if (inputPesquisaNav.length >= 3) {
      clearTimeout(this.timeOutInputPesquisaNaPasta);
      const timeOut = setTimeout(() => {
        TjdocsApi.pesquisaTudo(
          this.state.dadoTitulo,
          this.state.dadoDescricao,
          this.state.dadoLeiDecreto,
          this.state.ano_filtro,
          this.state.dadoConteudo,
        )
          .then((jsonPesquisa) => {
            var retorno = jsonPesquisa.map((i, index) => ({
              label:
                '<span class="optionPesquisaPrincipal">' +
                (i.fonte === 'pasta'
                  ? '<i class="fa fa-folder"></i> '
                  : '<i class="fa fa-file"></i> ') +
                i.texto +
                ' (Criado em: ' +
                i.dataCadastro +
                ')</span>' +
                (i.highlight === null
                  ? ''
                  : '<br/>' +
                    i.highlight.substring(11, i.highlight.length - 2)),
              value: '/' + i.fonte + 's/' + i.id,
            }));
            callback(retorno);
          })
          .catch((error) =>
            toast.error(`Erro ao tentar pesquisar termos: ${error}`),
          );
      }, 2000);
      this.timeOutInputPesquisaNaPasta = timeOut;
    }
  };

  bodyLoad() {
    // Sempre define para 'lista' ao carregar a página
    if (typeof Storage !== 'undefined') {
      localStorage.exibicao = 'lista';
    }

    document.querySelector('.dropdown-menu').onClick = function (e) {
      e.stopPropagation();
    };

    if (document.querySelector('#alterar_P-DisplayLista'))
      document.querySelector('#alterar_P-DisplayLista').onClick = function () {
        if (typeof Storage !== 'undefined') localStorage.exibicao = 'lista';
        document
          .querySelector('#alterar_P-DisplayLista')
          .classList.toggle('d-none');
        document.querySelector('#P-DisplayLista').classList.toggle('d-none');
        document
          .querySelector('#alterar_P-DisplayMiniaturas')
          .classList.toggle('d-none');
        document
          .querySelector('#P-DisplayMiniaturas')
          .classList.toggle('d-none');
        //#P-Inicio_DisplayLista focus
      };

    if (document.querySelector('#alterar_P-DisplayMiniaturas'))
      document.querySelector('#alterar_P-DisplayMiniaturas').onClick =
        function () {
          if (typeof Storage !== 'undefined')
            localStorage.exibicao = 'miniaturas';
          document
            .querySelector('#alterar_P-DisplayLista')
            .classList.toggle('d-none');
          document.querySelector('#P-DisplayLista').classList.toggle('d-none');
          document
            .querySelector('#alterar_P-DisplayMiniaturas')
            .classList.toggle('d-none');
          document
            .querySelector('#P-DisplayMiniaturas')
            .classList.toggle('d-none');
        };
  }

  loadLoginData() {
    if (typeof Storage !== 'undefined') {
      if (typeof sessionStorage.Bearer !== 'undefined') {
        var decoded = JWT(sessionStorage.token);
        //Atualizações visuais
        document.querySelector('.btn-login-right').style.display = 'none';
        document.querySelector('.busca_Principal_search').style.width = '100%';
        document.querySelector('.user-name-header span').innerHTML =
          '<i>' + decoded.sub + '</i>';
        document.querySelector('.user-name-header').style.display = 'block';
      }
    }
  }

  logout() {
    sendLogout();
  }

  timeOutInputPesquisaNav = setTimeout(() => {}, 100);

  loadOptions = (inputPesquisaNav, callback) => {
    if (inputPesquisaNav.length >= 3) {
      clearTimeout(this.timeOutInputPesquisaNav);
      const timeOut = setTimeout(() => {
        TjdocsApi.pesquisaTudo(
          this.state.dadoTitulo,
          this.state.dadoDescricao,
          this.state.dadoLeiDecreto,
          this.state.ano_filtro,
          this.state.dadoConteudo,
        )
          .then((jsonPesquisa) => {
            var retorno = jsonPesquisa.map((i, index) => ({
              label:
                '<span class="optionPesquisaPrincipal">' +
                (i.fonte === 'pasta'
                  ? '<i class="fa fa-folder"></i> '
                  : '<i class="fa fa-file"></i> ') +
                i.texto +
                ' - (Enviado em: ' +
                i.dataCadastro +
                ')' +
                '</span>' +
                (i.highlight === null
                  ? ''
                  : '<br/>' +
                    i.highlight.substring(11, i.highlight.length - 2)),
              value: '/' + i.fonte + 's/' + i.id,
            }));
            retorno.push({
              label: '+ Mais Resultados',
              value: '/busca/' + inputPesquisaNav,
            });

            if (inputPesquisaNav === this.state.textoDigitadoPesquisa)
              callback(retorno);
          })
          .catch((error) =>
            toast.error(`Erro ao tentar pesquisar termos: ${error}`),
          );
      }, 2000);
      this.timeOutInputPesquisaNav = timeOut;
    } else {
      this.timeOutInputPesquisaNav = setTimeout(() => {}, 100);
      callback([]);
    }
  };

  loadOptionsGeral = (inputPesquisaNav, callback) => {
    if (inputPesquisaNav.length >= 3) {
      clearTimeout(this.timeOutInputPesquisaNav);
      const timeOut = setTimeout(() => {
        TjdocsApi.pesquisaComFiltroDeConteudo(
          inputPesquisaNav,
          this.state.buscarPorConteudo,
        )
          .then((jsonPesquisa) => {
            var retorno = jsonPesquisa.map((i, index) => ({
              label:
                '<span class="optionPesquisaPrincipal">' +
                (i.fonte === 'pasta'
                  ? '<i class="fa fa-folder"></i> '
                  : '<i class="fa fa-file"></i> ') +
                i.texto +
                ' - (Enviado em: ' +
                i.dataCadastro +
                ')' +
                '</span>' +
                (i.highlight === null
                  ? ''
                  : '<br/>' +
                    i.highlight.substring(11, i.highlight.length - 2)),
              value: '/' + i.fonte + 's/' + i.id,
            }));
            retorno.push({
              label: '+ Mais Resultados',
              value: '/busca/' + inputPesquisaNav,
            });

            if (inputPesquisaNav === this.state.textoDigitadoPesquisa)
              callback(retorno);
          })
          .catch((error) =>
            toast.error(`Erro ao tentar pesquisar termos: ${error}`),
          );
      }, 2000);
      this.timeOutInputPesquisaNav = timeOut;
    } else {
      this.timeOutInputPesquisaNav = setTimeout(() => {}, 100);
      callback([]);
    }
  };

  onChangeFunc = (optionSelected) => {
    console.log(optionSelected);
    if (document.querySelector('.loading'))
      document.querySelector('.loading').style.display = 'block';
    window.location.href =
      optionSelected.value +
      `/${this.state.ano_filtro}/${this.state.dadoTitulo}/${this.state.dadoConteudo}/${this.state.dadoLeiDecreto}/${this.state.dadoDescricao}`;
  };

  textoDigitadoPesquisa = (inputValue, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      this.setState({ inputValue, textoDigitadoPesquisa: inputValue });
    }
  };

  buscaPrincipalRedirectTela = () => {
    if (this.state.textoDigitadoPesquisa.length >= 3) {
      if (document.querySelector('.loading'))
        document.querySelector('.loading').style.display = 'block';
      window.location.href = `/busca/${this.state.textoDigitadoPesquisa}`;
    }
  };

  LoginButton = () => {
    return (
      <div className="mr-5">
        <a
          href='https://portaltj.tjgo.jus.br/sistemas/index.php?s=corporativo'
          style={{ color: '#fff' }}
        >
          Efetuar LOGIN
        </a>
        <i className="fas fa-user ml-3"></i>
      </div>
    );
  };

  handleCadastraRelease = () => {
    console.log(this.state.exibeCadastraRelease);
    this.setState({
      exibeCadastraRelease: this.state.exibeCadastraRelease ? false : true,
    });
  };

  handleEditarRelease = (e, release) => {
    this.setState({ releaseSelecionado: release });
    this.setState({
      exibeEditarRelease: this.state.exibeEditarRelease ? false : true,
    });
  };

  openTooltip = () => {
    console.log(this.state.showTooltip);
    this.setState({ showTooltip: true });
  };

  closeTooltip = () => {
    console.log(this.state.showTooltip);
    this.setState({ showTooltip: false });
  };

  alterarBuscarPorConteudo = () => {
    this.setState({ buscarPorConteudo: !this.state.buscarPorConteudo });
    console.log(this.state.buscarPorConteudo);
  };

  handleTooltip = () => {
    this.setState({ showTooltip: !this.state.showTooltip });
  };

  handleInputBlur = () => {
    // Não faz nada ao perder o foco para evitar reverter o valor digitado
  };

  handleConteudoInputChange = (inputPesquisaNav, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      this.setState({ dadoConteudo: inputPesquisaNav });
      this.textoDigitadoPesquisa(inputPesquisaNav, actionMeta);
    }
  };

  handleDecretoLeiInputChange = (inputPesquisaNav, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      this.setState({ dadoLeiDecreto: inputPesquisaNav });
    }
  };

  handleDescricaoInputChange = (inputPesquisaNav, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      this.setState({ dadoDescricao: inputPesquisaNav });
    }
  };

  handleSubmitPesquisaNav = (event) => {
    event.preventDefault();
    window.location.href = `/busca/${this.state.dadoTitulo}`;
  };

  handleInputChange = (event) => {
    this.setState({ dadoTitulo: event.target.value });
  };

  handleCloseMiniButton = (etiqueta, filtro) => {
    this.setState(
      (prevState) => ({
        etiquetas: {
          ...prevState.etiquetas,
          [filtro]: prevState.etiquetas[filtro].filter(
            (item) => item !== etiqueta,
          ),
        },
      }),
      () => {
        console.log('Button closed/excluded!', this.state.etiquetas.titulo);
      },
    );
  };

  changeVisibilidadeCard = (event, card) => {
    let isChecked = event.target.checked;
    this.setState((prevState) => ({
      cards: {
        ...prevState.cards,
        [card]: isChecked,
      },
      etiquetas: {
        ...prevState.etiquetas,
        [card]: [],
      },
    }));
  };

  changePesquisaCom = (event) => {
    this.setState({
      etiquetas: {
        ...this.state.etiquetas,
        pesquisaCom: event.target.value,
      },
    });
  };

  closeCriterioBusca = (criterio) => {
    this.setState((prevState) => ({
      cards: {
        ...prevState.cards,
        [criterio]: false,
      },
      etiquetas: {
        ...prevState.etiquetas,
        [criterio]: [],
      },
    }));
  };

  handleFiltroChange = (event, filtro) => {
    let termo = event.target.value;
    this.setState((prevState) => ({
      termos: {
        ...prevState.termos,
        [filtro]: termo,
      },
    }));
  };

  addTipoArquivo = (tipoArquivo) => {
    this.setState(
      (prevState) => ({
        termos: {
          ...prevState.termos,
          tipoArquivo: tipoArquivo,
        },
      }),
      () => {
        this.addEtiqueta('tipoArquivo');
      },
    );
  };

  addEtiqueta = (filtro) => {
    const newEtiqueta = this.state.termos[filtro];
    const currentEtiquetas = this.state.etiquetas[filtro] || [];

    if (newEtiqueta === '') return;

    if (!currentEtiquetas.includes(newEtiqueta)) {
      this.setState(
        (prevState) => {
          let updatedEtiquetas;
          let limiteMaximoEtiquetas = filtro === 'conteudo' ? 1 : 3;

          if (currentEtiquetas.length >= limiteMaximoEtiquetas) {
            updatedEtiquetas = [...currentEtiquetas.slice(1), newEtiqueta];
          } else {
            updatedEtiquetas = [...currentEtiquetas, newEtiqueta];
          }

          return {
            termos: {
              ...prevState.termos,
              [filtro]: '',
            },
            etiquetas: {
              ...prevState.etiquetas,
              [filtro]: updatedEtiquetas,
            },
          };
        },
        () => {
          console.log('etiquetas: ', this.state.etiquetas[filtro]);
        },
      );
    } else {
      // Clear the input field if etiqueta already exists
      this.setState((prevState) => ({
        termos: {
          ...prevState.termos,
          [filtro]: '',
        },
      }));
    }
  };

  formatarDataCriacao = (data) => {
    let etiqueta = data;
    let formattedEtiqueta = etiqueta
      .split('-')
      .reverse()
      .join('-')
      .replaceAll('-', '/');
    return formattedEtiqueta;
  };

  limparCriteriosBusca = () => {
    this.setState((prevState) => ({
      cards: {
        ano: false,
        conteudo: false,
        dataCriacao: false,
        descricao: false,
        leiOuDecreto: false,
        tipoArquivo: false,
        titulo: false,
      },
      etiquetas: {
        pesquisaCom: 'OR',
        ano: [],
        conteudo: [],
        dataCriacao: [],
        descricao: [],
        leiOuDecreto: [],
        tipoArquivo: [],
        titulo: [],
      },
    }));
  };

  componentDidMount() {
    let storageEtiquetas = localStorage.getItem('etiquetas');
    let storageCards = localStorage.getItem('cards');

    let updatedEtiquetas = {
      pesquisaCom: 'OR',
      ano: [],
      conteudo: [],
      dataCriacao: [],
      descricao: [],
      leiOuDecreto: [],
      tipoArquivo: [],
      titulo: [],
    };

    let updatedCards = {
      ano: false,
      conteudo: false,
      dataCriacao: false,
      descricao: false,
      leiOuDecreto: false,
      tipoArquivo: false,
      titulo: false,
    };

    if (storageEtiquetas && storageEtiquetas !== 'undefined') {
      storageEtiquetas = JSON.parse(storageEtiquetas);

      updatedEtiquetas = {
        pesquisaCom: storageEtiquetas.pesquisaCom || 'OR',
        ano: storageEtiquetas.ano || [],
        conteudo: storageEtiquetas.conteudo || [],
        dataCriacao: storageEtiquetas.dataCriacao || [],
        descricao: storageEtiquetas.descricao || [],
        leiOuDecreto: storageEtiquetas.leiOuDecreto || [],
        tipoArquivo: storageEtiquetas.tipoArquivo || [],
        titulo: storageEtiquetas.titulo || [],
      };
    }

    if (storageCards && storageCards !== 'undefined') {
      storageCards = JSON.parse(storageCards);

      updatedCards = {
        ano: storageCards.ano || false,
        conteudo: storageCards.conteudo || false,
        dataCriacao: storageCards.dataCriacao || false,
        descricao: storageCards.descricao || false,
        leiOuDecreto: storageCards.leiOuDecreto || false,
        tipoArquivo: storageCards.tipoArquivo || false,
        titulo: storageCards.titulo || false,
      };
    }

    this.setState(
      {
        etiquetas: updatedEtiquetas,
        cards: updatedCards,
      },
      () => {
        console.log('Updated etiquetas:', this.state.etiquetas);
      },
    );
  }

  render() {
    const { showTooltip } = this.state;
    /*const tooltipPesquisa = () => (
         
      );*/

    return (
      <div className='princpl-header' onLoad={this.bodyLoad}>
        <div>
          <Toaster />
        </div>
        {/* notificações - react-hot-toast */}

        <div className='row' id='barraAcessibilidade'>
          {/* barra de acessibilidade */}

          <div className=''>
            <div className='atalhos'>
              <ul>
                <li>
                  <a href='#conteudo' tabIndex='0' accessKey='1' id='entrada'>
                    Ir para o conteúdo <span className='access-content'>1</span>
                  </a>
                </li>{' '}
                &nbsp;&nbsp;
                <li>
                  <a href='#mainmenu' accessKey='2'>
                    Ir para o menu <span className='access-content'>2</span>
                  </a>
                </li>{' '}
                &nbsp;&nbsp;
                <li>
                  <a href='#pesquisa' accessKey='3'>
                    Ir para a pesquisa <span className='access-content'>3</span>
                  </a>
                </li>
              </ul>
            </div>
            {/* atalhos */}
          </div>

          <div className=''>
            <div className='ajustes'>
              <ul>
                <li>
                  <a
                    href='#'
                    className='itemDeMenu d-none'
                    accessKey='4'
                    title='Instruções de Acessibilidade'
                  >
                    Acessibilidade
                  </a>
                </li>
                <li>
                  <a
                    href='#altocontraste'
                    id='altocontraste'
                    aria-label='Alto Contraste'
                    title='Alto Contraste'
                    accessKey='5'
                    className='theme-link'
                  >
                    <div className='circuloContrastePreto'></div>
                  </a>
                </li>{' '}
                &nbsp;
                <li>
                  <a
                    href='#'
                    aria-label='Aumentar Fonte'
                    title='Aumentar Fonte'
                    accessKey='6' /* onClick="fonte('a')"*/
                  >
                    A +
                  </a>
                </li>
                <li>
                  <a
                    href='#'
                    aria-label='Aumentar Fonte'
                    title='Aumentar Fonte'
                    accessKey='6' /* onClick="fonte('a')"*/
                  >
                    A
                  </a>
                </li>{' '}
                &nbsp;
                <li>
                  <a
                    href='#'
                    aria-label='Diminuir Fonte'
                    title='Diminuir Fonte'
                    accessKey='7' /*onClick="fonte('d')"*/
                  >
                    A -
                  </a>
                </li>
              </ul>
            </div>
            {/* ajustes */}
          </div>
        </div>

        <nav className='navbar navbar-expand-md navbar-light'>
          <h1>
            <Link to='/' onClick={handleRootRouteReload}>
              <img
                className='logo_TJdocs'
                src='/ui/img/logo_tjdocs.png'
                title='Logotipo do TJDOCS'
              />
            </Link>
            <span style={{ color: '#0FBDD2' }}>
              <a
                onClick={this.handleExibeListaRelease}
                style={{
                  color: '#FFF',
                  fontSize: '20px',
                  position: 'relative',
                  top: '10px',
                  cursor: 'pointer',
                }}
                alt='Versão 2.2 - Clique para ver a lista de releases'
              >
                2.2
              </a>
            </span>

            <ListaRelease
              exibeListaRelease={this.state.exibeListaRelease}
              closeListaRelease={this.handleExibeListaRelease}
              listaRelease={this.state.listaRelease}
              handleCadastraRelease={this.handleCadastraRelease}
              handleEditarRelease={this.handleEditarRelease}
              exibeCadastraRelease={this.state.exibeCadastraRelease}
              exibeEditarRelease={this.state.exibeEditarRelease}
              releaseSelecionado={this.state.releaseSelecionado}
            />
            {/* <div className="loading">
						<div className="loading-ico"></div>
					</div> */}
          </h1>

          <button
            className='n-collps-button icones'
            type='button'
            data-toggle='collapse'
            data-target='#navbarPrincipal'
            title='Expandir menu'
          >
            <img className='icones' src='/ui/img/Menu_icon.svg' alt='' />
          </button>

          <div className='collapse navbar-collapse' id='navbarPrincipal'>
            <div className='offset-md-5 p-hedr-opceos p-c-menTp-Opcoes col-md-7 texto_BreakAll'>
              <div className='row margin-none'>
                <div className='col-md-10 offset-md-2 col-sm-12 padding-header-bars'>
                  <div className='row'>
                    {/*<div style={{textAlign: "left", color: "white", width: "30%", marginBottom: "1px", marginTop: "5px"}}>*/}
                    {/*    <input type="checkbox" id="checkboxBuscarPorConteudo"*/}
                    {/*           value={this.state.buscarPorConteudo}*/}
                    {/*           checked={this.state.buscarPorConteudo}*/}
                    {/*           onChange={() => this.alterarBuscarPorConteudo()}/>*/}
                    {/*    <label htmlFor="checkboxBuscarPorConteudo" style={{paddingLeft: "5px"}}>*/}
                    {/*        Buscar por conteúdo*/}
                    {/*    </label>*/}
                    {/*</div>*/}
                    <div
                      style={{
                        textAlign: 'right',
                        width: '100%',
                        marginBottom: '1px',
                      }}
                    >
                      <span style={{ color: '#fff' }}>
                        {!this.usuario.nome_completo ? (
                          <this.LoginButton />
                        ) : (
                          'Olá ' + this.usuario.nome_completo + ' - '
                        )}{' '}
                        <i>
                          {this.usuario.authorities &&
                          this.usuario.authorities.length > 1
                            ? this.usuario.authorities.join(' / ')
                            : this.usuario.authorities}
                        </i>
                        {!this.usuario.nome_completo ? (
                          ''
                        ) : (
                          <span
                            onClick={this.logout}
                            dangerouslySetInnerHTML={{
                              __html:
                                "&nbsp; <a  href='#' title='Sair do sistema'class='btnprincipal_sair'><i class='fas fa-sign-out-alt'></i></a>",
                            }}
                          />
                        )}
                      </span>
                    </div>

                    {/* Login Box */}
                    <div
                      className='btn-group btn-login-right'
                      style={{ display: 'none' }}
                    >
                      <button
                        type='button'
                        className='btn btn-secondary dropdown-toggle'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        {!this.usuario.nome_completo
                          ? 'Login'
                          : 'Olá ' + this.usuario.nome_completo}
                      </button>
                      <div className='dropdown-menu dropdown-menu-right dropdown-login'>
                        <div className='busca_Principal mx-auto col-md-12'>
                          {this.usuario.authorities}

                          <div
                            id='mensagem-sucesso'
                            className='alert alert-success'
                            role='alert'
                          >
                            <span id='mensagem-sucesso-body'></span>
                            {/*
											<button type="button" className="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
											*/}
                          </div>
                          <div
                            id='mensagem-erro'
                            className='alert alert-danger'
                            role='alert'
                          >
                            <span id='mensagem-erro-body'></span>
                            {/*
											<button type="button" className="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
											*/}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Login Box */}
                  </div>
                </div>
              </div>

              <div className='user-name-header'>
                <span>
                  <i> - </i>
                </span>
                &nbsp;
                <a href='#' onClick={this.logout}>
                  <i className='fas fa-sign-out-alt'></i>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

Header.propTypes = {
  handleChange: PropTypes.func,
};
