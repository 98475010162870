import React, {Component} from "react";
import {MiniDocView} from "../components/MiniDocView";
import {MiniDocViewLista} from "../components/MiniDocViewLista";
import {DominioIconLista} from "../components/DominioIconLista";
import {DominioIcon} from "../components/DominioIcon";
import TjdocsApi from "../api/API";
import * as PropTypes from "prop-types";
import {get_path} from "../helper/Helper";
import {MenuTopo} from "../components/MenuTopo";
import {Accordion, Button, Card, Dropdown} from "react-bootstrap";


export class MainContainer extends Component {

    constructor(props) {
        super(props);                
        this.atualizaComponente = this.atualizaComponente.bind(this);
    }

    componentWillMount() {
        let path = get_path(null);
        this.props.handlePath(path);
    }

    atualizaComponente(){        
        this.props.atualizaDominios();
    }

    ordenaDadosPastas = (field, order) => {   
		this.props.ordenaDadosPastas(field, order);
    }

    salvarOrdenacao = (field, order) => {
        this.props.salvarOrdenacao(field, order);
    }

    filtrarPorNome = (event) => {
        this.props.filtrarPorNome(event.target.value);
    };


    miniDocView(){
return <div className="p-cntd-pasts-miniaturas d-inline-flex flex-wrap">
{this.props.dominios.map((dado, i) => <DominioIcon listaGestores={dado.listaGestores} id={dado.id} nome={dado.nome} updatedAt={dado.updatedAt} createdAt={dado.createdAt} nomeGestor={dado.nomeGestor} contatoGestor={dado.contatoGestor} tamanho={dado.tamanho} totalArquivos={dado.totalArquivos} key={i} nomeVisibilidade={dado.visibilidadeNome} />)}
</div>;

    }

    render() {
        return <div>

            <MenuTopo path={this.props.path} atualiza={this.atualizaComponente}/>

            <Accordion>
                <Card>
                    <Card.Header>
                        <p style={{fontSize: '12px'}}>Você está dentro da pasta <u><b>Inicial</b></u>.</p>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{paddingLeft: '0px'}}>
                            Pesquisar dentro da pasta <b>Inicial</b>? <i className="fas fa-search"></i>
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body style={{padding: 0}}>
                            <div className="box box-primary" style={{marginBottom: '0px', marginTop: '0px', float: 'left'}}>
                                <div className="box-header with-border box-defaultClaro input-group">

                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic"
                                                         style={{
                                                             backgroundColor: 'white',
                                                             height: '37px',
                                                             color: '#c9c9c9'
                                                         }} disabled>
                                            Tipo do Arquivo
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <input
                                        className="busca_P-input input-barra-de-filtragem"
                                        type="search"
                                        placeholder="Nome"
                                        onChange={this.filtrarPorNome}
                                    />

                                    {/*<input*/}
                                    {/*    className="busca_P-input input-barra-de-filtragem disabled-input"*/}
                                    {/*    type="number"*/}
                                    {/*    placeholder="Ano"*/}
                                    {/*    disabled*/}
                                    {/*/>*/}

                                    {/*<input*/}
                                    {/*    className="busca_P-input input-barra-de-filtragem disabled-input"*/}
                                    {/*    type="date"*/}
                                    {/*    title="Data de Cadastro"*/}
                                    {/*    disabled*/}
                                    {/*/>*/}

                                    <input
                                        className="busca_P-input input-barra-de-filtragem disabled-input"
                                        type="search"
                                        placeholder="Nº Lei ou Decreto"
                                        disabled
                                    />

                                    {/*<input*/}
                                    {/*    className="busca_P-input input-barra-de-filtragem disabled-input"*/}
                                    {/*    type="search"*/}
                                    {/*    placeholder="Descrição"*/}
                                    {/*    disabled*/}
                                    {/*/>*/}

                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <MiniDocView children={this.miniDocView()}/>

            <MiniDocViewLista ordenaDados={this.ordenaDadosPastas} salvarOrdenacao={this.salvarOrdenacao}
                              colunaOrdenada={this.props.colunaOrdenada} ordem={this.props.ordem}
                              children={this.props.dominios.map((dado, i) => <DominioIconLista
                                  listaGestores={dado.listaGestores} id={dado.id} nome={dado.nome}
                                  updatedAt={dado.updatedAt} createdAt={dado.createdAt} nomeGestor={dado.nomeGestor}
                                  contatoGestor={dado.contatoGestor} tamanho={dado.tamanho}
                                  totalArquivos={dado.totalArquivos} key={i}
                                  nomeVisibilidade={dado.visibilidadeNome}/>)}/>

        </div>;
    }
}

MainContainer.propTypes = {
    dominios: PropTypes.arrayOf(PropTypes.any),
    handlePath: PropTypes.func
};