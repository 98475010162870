import React, { Component } from "react";




export class MenuAcoesDominio extends Component {
	componentDidMount() {
		console.log(this.props.path.length);
	}
    render() {
		
		var exibicaoLista = "icones ";
		var exibicaoGrade = "icones ";
		if (typeof(Storage) !== "undefined") {
			if (typeof(localStorage.exibicao) !== "undefined"){
				if(localStorage.exibicao === "lista") {
					exibicaoGrade += "d-none";
					exibicaoLista += "";
				} else {
					exibicaoGrade += "";
					exibicaoLista += "d-none";
				}
			} else {
				localStorage.exibicao = "miniaturas";
				exibicaoGrade += "";
				exibicaoLista += "d-none";
			}
		}
		
		function gradeParaLista(){
			document.getElementById('alterar_P-DisplayLista').classList.add('d-none');
			document.getElementById('alterar_P-DisplayMiniaturas').classList.remove('d-none');
			
			document.getElementById('P-DisplayLista').classList.remove('d-none');
			document.getElementById('P-DisplayMiniaturas').classList.add('d-none');
			if (typeof(Storage) !== "undefined") localStorage.exibicao = "lista";
		}
		
		function listaParaGrade(){
			document.getElementById('alterar_P-DisplayLista').classList.remove('d-none');
			document.getElementById('alterar_P-DisplayMiniaturas').classList.add('d-none');
			
			document.getElementById('P-DisplayLista').classList.add('d-none');
			document.getElementById('P-DisplayMiniaturas').classList.remove('d-none');
			if (typeof(Storage) !== "undefined") localStorage.exibicao = "miniaturas";
		}
		
        return  <div className="p-contd-menuTopo d-flex align-items-center row">



                        <div className="p-c-menTp-Opcoes">
                            <span>
                                {/*<a className="icones" title="Ordernado por data">*/}
                                {/*    <i className="fas fa-sort-amount-down-alt fa-2x"></i>*/}
                                {/*</a>*/}
                                
                                <a className={exibicaoLista} onClick={listaParaGrade} href="#!" id="alterar_P-DisplayMiniaturas" title="Visualização em miniatura."
                                    role="button">
                                    <i className="fas fa-th-large fa-2x"></i>
                                </a>
                                <a className={exibicaoGrade} onClick={gradeParaLista} href="#!" id="alterar_P-DisplayLista" title="Visualização em lista." role="button">
                                    <i id="alterarGradeParaLista" className="fas fa-list fa-2x"></i>
                                </a>
                            </span>
                        </div>
                    </div>;
    }
}


