import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import TjdocsApi from '../api/API';
import toast from 'react-hot-toast';
import Authentication from "../Authentication";
import ReactDOM from 'react-dom';
export class BoxEtiquetas extends Component {

	constructor(props) {
		super(props);
		this.state = {
			etiquetas: [],
			documentos: [],
			carregarDocumentos: {
				etiquetaId: '',
			}
		};

		this.usuario = Authentication.getUsuario();
		this.isLogin = Authentication.isLogin();
	}

	componentWillMount() {
		TjdocsApi.listaEtiquetas().then(jsonEtiquetas => {
			if (jsonEtiquetas && jsonEtiquetas._embedded) {
				this.setState({
					etiquetas: jsonEtiquetas._embedded.etiquetas
				});
			}

			this.state.etiquetas.map((etiqueta, index) => {
				if (document.getElementById("Etiqueta" + index) != undefined) {
					document.getElementById("Etiqueta" + index).style.backgroundColor = etiqueta.cor;
				}
			});
		}
		).catch(error => toast.error(`Erro ao tentar consultar etiquetas: ${error}`));
	}

	render() {
		return <div className={this.isLogin ? "box" : "d-none "}>
			<div className="box-header with-border box-warningClaro">
				<h3 className="box-title">
					<a role="button" data-toggle="collapse" href="#boxEtiquetas" aria-expanded="true" aria-controls="boxEtiquetas" >
						<i class="fas fa-tags" aria-hidden="true"></i>	Etiquetas
					</a>
				</h3>
			</div>{/* fim box-header */}

			<div id="boxEtiquetas" className="collapse show" data-parent="#boxEtiquetas">
				<div className="box-body no-padding">

					{/*Barra*/}
					<div className="p-contd-menuTopo d-flex align-items-center">
						<div className="p-c-menTp-Endereco flex-grow-1">
							<IncluirEtiquetas state={{ modal: false }} />
						</div>
						<div className="p-c-menTp-Opcoes">
							<ExcluirEtiquetas state={{ modal: false }} />
						</div>
					</div>
					{/*Barra*/}

					<ul className="nav flex-column nav-stacked" id="ticket-list">

						{this.state.etiquetas.map((etiqueta, index) => {
							return (
								<li className={"nav-item "} key={index}>
									<a className="nav-link d-flex justify-content-between"
										id={"ItemEtiqueta" + etiqueta.id}
										href={`/etiqueta/${etiqueta.id}`}
									>
										<span className=
											{etiqueta.tipo == 'PRIVADA' ? "fas fa-user-circle" : "fas fa-tags"}>
											&nbsp;{etiqueta.titulo}</span>
										<span id={"Etiqueta" + index}
											className="etiquetas-ico"
										>
											<i className="fas" id={"EtiquetaId" + etiqueta.id}></i>
										</span>
									</a>

								</li>
							);
						})}

					</ul>

					<span className="fas fa-tags">&nbsp;&nbsp;etiquetas de sistema</span><br />
					<span className="fas fa-user-circle">&nbsp;&nbsp;etiquetas privadas</span>

				</div>{/* fim box-body */}
			</div>{/* fim menu */}
		</div>
	}
}






class IncluirEtiquetas extends React.Component {

	constructor(props) {
		super(props);
		this.state = { modal: false, tipo: "PRIVADA" };
		this.handleClose = this.handleClose.bind(this);
		this.handleShow = this.handleShow.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.usuario = Authentication.getUsuario();
	}

	handleClose() {
		this.setState({ modal: false });
	}

	handleShow(e) {
		e.preventDefault();
		this.setState({ modal: true });
	}

	onValueChangeTipoEtiqueta(event) {
		this.setState({ tipo: event.target.value });
	}

	handleSubmit(e) {
		var cor = "";
		var valueCor = document.querySelector('input[name="corEtiquetaAdicionar"]:checked');
		if (valueCor != undefined) cor = valueCor.value;
		else {
			e.preventDefault();
			toast.error("Cor é necessária!");
			return;
		}

		var nome = document.getElementById('nomeEtiquetaAdicionar').value.trim();

		if (nome.length > 0) {

			TjdocsApi.criaEtiqueta(nome, cor, this.state.tipo).then(jsonEtiqueta => {
				//alert('Etiqueta Criada! Id: '+jsonEtiqueta.id);
				toast.success('Sucesso!');
				this.handleClose();
				this.atualizaEtiquetas();
			}
			).catch(error => toast.error(`Erro ao tentar criar etiqueta: ${error}`));

		} else {
			e.preventDefault();
			toast.error("Nome é necessário!");
		}
	}

	atualizaEtiquetas() {
		TjdocsApi.listaEtiquetas().then(jsonEtiquetas => {

			var container = document.querySelector('#boxEtiquetas ul');
			if (container != undefined) {
				var conteudo = "";
				let tipo = ""
				if (jsonEtiquetas._embedded) {
					jsonEtiquetas._embedded.etiquetas.map((etiqueta, index) => {
						tipo = etiqueta.tipo == "PRIVADA" ? "fas fa-user-circle" : "fas fa-tags";
						conteudo += '<li class="nav-item "><a class="nav-link d-flex justify-content-between" href="/etiqueta/' + etiqueta.id + '"><span class="' + tipo + '">&nbsp;' + etiqueta.titulo + '</span><span id="Etiqueta' + index + '" style="background-color: ' + etiqueta.cor + '" class="etiquetas-ico"><i class="fas"></i></span></a></li>';
					});
				}
				container.innerHTML = conteudo;
			}
		}
		).catch(error => console.log(`Erro ao tentar atualizar as etiquetas --atualizaEtiquetas(): ${error}`));
	}

	background = (elemento) => {
		document.querySelectorAll('.divEtiquetasIncluir label')
			.forEach(i => { i.style.backgroundColor = "transparent" });
		elemento.querySelector('label').style.backgroundColor = "var(--paleta_cinza_md-escuro)";
	}

	render() {
		const { modal } = this.state;
		return (
			<>
				<div>
					<a title="Adicionar Etiquetas" onClick={this.handleShow}>&nbsp;&nbsp;<i className="fas fa-plus"></i></a>
				</div>

				<Modal show={modal} onHide={this.handleClose} size="md">
					<Modal.Header closeButton>
						<Modal.Title class="modalTitle">Incluir Etiquetas</Modal.Title>
					</Modal.Header>

					<Modal.Body>

						<div className="row">
							<div className="col-md-12">
								<legend>Nome:</legend>
								<div className="row divTipoEtiquetasIncluir col-md-12" style={{ "justify-content": "center" }}>
									<input id="nomeEtiquetaAdicionar" type="text" className="form-control" placeholder="" />
								</div>
								<br></br>
								<legend>Selecione a visibilidade de acordo com os tipos:</legend>
								{/* Tipo da etiqueta */}
								<div className="row divTipoEtiquetasIncluir col-md-7" style={{ "justify-content": "center" }}>
									<label
										className={(this.usuario.authorities && this.usuario.authorities.includes('ADMINISTRADOR')) ? "nav-link d-flex justify-content-between" : "d-none "}
									>
										<input type="radio" value="SISTEMA" name="tipoEtiquetaAdicionar" onChange={e => this.onValueChangeTipoEtiqueta(e)} />&nbsp;Sistema
									</label>

									<label
										className={(this.usuario.authorities && this.usuario.authorities.includes('ADMINISTRADOR')) || this.usuario.authorities == 'ADMINISTRADOR_DOMINIO' || this.usuario.authorities == 'USUARIO' ? "nav-link d-flex justify-content-between" : "d-none "}
									>
										<input type="radio" defaultChecked value="PRIVADA" name="tipoEtiquetaAdicionar" onChange={e => this.onValueChangeTipoEtiqueta(e)} />&nbsp;Privada
									</label>
								</div>

								<legend>Selecione uma cor:</legend>
								{/* Cores */}
								<div className="row divEtiquetasIncluir col-md-7" style={{ "justify-content": "center" }}>
									<a className="padding-0" href="#" onClick={(e) => { this.background(e.currentTarget) }}>
										<label className="nav-link d-flex justify-content-between">
											<input type="radio" value="#FFA817" name="corEtiquetaAdicionar" />
											<span className="etiquetas-ico" style={{ backgroundColor: "#FFA817" }}><i className="fas"></i></span>
										</label>
									</a>

									<a className="padding-0" href="#" onClick={(e) => { this.background(e.currentTarget) }}>
										<label className="nav-link d-flex justify-content-between">
											<input type="radio" value="#008548" name="corEtiquetaAdicionar" />
											<span className="etiquetas-ico" style={{ backgroundColor: "#008548" }}><i className="fas"></i></span>
										</label>
									</a>

									<a className="padding-0" href="#" onClick={(e) => { this.background(e.currentTarget) }}>
										<label className="nav-link d-flex justify-content-between">
											<input type="radio" value="#004a80" name="corEtiquetaAdicionar" />
											<span className="etiquetas-ico" style={{ backgroundColor: "#004a80" }}><i className="fas"></i></span>
										</label>
									</a>

									<a className="padding-0" href="#" onClick={(e) => { this.background(e.currentTarget) }}>
										<label className="nav-link d-flex justify-content-between">
											<input type="radio" value="#009abf" name="corEtiquetaAdicionar" />
											<span className="etiquetas-ico" style={{ backgroundColor: "#009abf" }}><i className="fas"></i></span>
										</label>
									</a>

									<a className="padding-0" href="#" onClick={(e) => { this.background(e.currentTarget) }}>
										<label className="nav-link d-flex justify-content-between">
											<input type="radio" value="#ae301e" name="corEtiquetaAdicionar" />
											<span className="etiquetas-ico" style={{ backgroundColor: "#ae301e" }}><i className="fas"></i></span>
										</label>
									</a>

									<a className="padding-0" href="#" onClick={(e) => { this.background(e.currentTarget) }}>
										<label className="nav-link d-flex justify-content-between">
											<input type="radio" value="#0c5460" name="corEtiquetaAdicionar" />
											<span className="etiquetas-ico" style={{ backgroundColor: "#0c5460" }}><i className="fas"></i></span>
										</label>
									</a>

									<a className="padding-0" href="#" onClick={(e) => { this.background(e.currentTarget) }}>
										<label className="nav-link d-flex justify-content-between">
											<input type="radio" value="#0ec24a" name="corEtiquetaAdicionar" />
											<span className="etiquetas-ico" style={{ backgroundColor: "#0ec24a" }}><i className="fas"></i></span>
										</label>
									</a>

									<a className="padding-0" href="#" onClick={(e) => { this.background(e.currentTarget) }}>
										<label className="nav-link d-flex justify-content-between">
											<input type="radio" value="#85005f" name="corEtiquetaAdicionar" />
											<span className="etiquetas-ico" style={{ backgroundColor: "#85005f" }}><i className="fas"></i></span>
										</label>
									</a>

									<a className="padding-0" href="#" onClick={(e) => { this.background(e.currentTarget) }}>
										<label className="nav-link d-flex justify-content-between">
											<input type="radio" value="#95ab07" name="corEtiquetaAdicionar" />
											<span className="etiquetas-ico" style={{ backgroundColor: "#95ab07" }}><i className="fas"></i></span>
										</label>
									</a>

									<a className="padding-0" href="#" onClick={(e) => { this.background(e.currentTarget) }}>
										<label className="nav-link d-flex justify-content-between">
											<input type="radio" value="#8626d5" name="corEtiquetaAdicionar" />
											<span className="etiquetas-ico" style={{ backgroundColor: "#8626d5" }}><i className="fas"></i></span>
										</label>
									</a>

									<a className="padding-0" href="#" onClick={(e) => { this.background(e.currentTarget) }}>
										<label className="nav-link d-flex justify-content-between">
											<input type="radio" value="#484848" name="corEtiquetaAdicionar" />
											<span className="etiquetas-ico" style={{ backgroundColor: "#484848" }}><i className="fas"></i></span>
										</label>
									</a>

									<a className="padding-0" href="#" onClick={(e) => { this.background(e.currentTarget) }}>
										<label className="nav-link d-flex justify-content-between">
											<input type="radio" value="#b9b9b9" name="corEtiquetaAdicionar" />
											<span className="etiquetas-ico" style={{ backgroundColor: "#b9b9b9" }}><i className="fas"></i></span>
										</label>
									</a>
								</div>
							</div>
						</div>

					</Modal.Body>


					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Fechar
						</Button>
						<Button variant="primary" onClick={this.handleSubmit}>
							Salvar
						</Button>
					</Modal.Footer>
				</Modal>

			</>
		)
	}
};






class ExcluirEtiquetas extends React.Component {

	constructor(props) {
		super(props);
		this.state = { modal: false };
		this.handleClose = this.handleClose.bind(this);
		this.handleShow = this.handleShow.bind(this);
		this.atualizaEtiquetasBoxExcluirEtiquetas = this.atualizaEtiquetasBoxExcluirEtiquetas.bind(this);
		this.atualizaEtiquetas = this.atualizaEtiquetas.bind(this);
	}

	handleClose() {
		this.setState({ modal: false });
	}

	handleShow(e) {
		e.preventDefault();
		this.setState({ modal: true });
		this.atualizaEtiquetasBoxExcluirEtiquetas();
	}

	atualizaEtiquetasBoxExcluirEtiquetas = () => {
		TjdocsApi.listaEtiquetas().then(jsonEtiquetas => {

			var container = document.querySelector('#boxExcluirEtiquetas ul');
			if (container != undefined) {
				var conteudo = "";
				let tipo = "";
				if (jsonEtiquetas._embedded) {
					jsonEtiquetas._embedded.etiquetas.map((etiqueta, index) => {
						tipo = etiqueta.tipo == "PRIVADA" ? "fas fa-user-circle" : "fas fa-tags";
						conteudo += '<li class="nav-item"><a class="nav-link d-flex justify-content-between aExcluirEtiquetas" href="#"><i class="fas fa-trash"></i><span class="' + tipo + '">&nbsp;' + etiqueta.titulo + '</span> <span  style="background-color: ' + etiqueta.cor + '" class="etiquetas-ico"><i class="fas"></i></span></a></li>';
					});
				}
				container.innerHTML = conteudo;


				var aExcluirEtiquetas = document.getElementsByClassName('aExcluirEtiquetas');
				jsonEtiquetas._embedded.etiquetas.map((etiqueta, index) => {
					aExcluirEtiquetas[index].onclick = (e) => {
						e.preventDefault();

						var confirmacao = window.confirm("Deseja realmente excluir esta etiqueta? - " + etiqueta.titulo);
						if (confirmacao == true) {
							//Exclui
							TjdocsApi.excluiEtiqueta(etiqueta.id).then(jsonEtiquetas => {
								toast.success("Etiqueta excluída com sucesso!");
								this.handleClose();
								this.atualizaEtiquetas();
							}
							).catch(error => toast.error(`Erro ao excluir etiquetas --atualizaEtiquetasBoxExcluirEtiquetas(): ${error}`));
						}
					}
				});


			}
		}
		).catch(error => console.log(`Erro ao tentar atualizar as etiquetas --atualizaEtiquetas(): ${error}`));
	}

	atualizaEtiquetas = () => {
		TjdocsApi.listaEtiquetas().then(jsonEtiquetas => {

			var container = document.querySelector('#boxEtiquetas ul');
			if (container != undefined) {
				var conteudo = "";
				let tipo = ""
				if (jsonEtiquetas._embedded) {
					jsonEtiquetas._embedded.etiquetas.map((etiqueta, index) => {
						tipo = etiqueta.tipo == "PRIVADA" ? "fas fa-user-circle" : "fas fa-tags";
						conteudo += '<li class="nav-item "><a class="nav-link d-flex justify-content-between" href="/etiqueta/' + etiqueta.id + '"><span class="' + tipo + '">' + etiqueta.titulo + '</span><span id="Etiqueta' + index + '" style="background-color: ' + etiqueta.cor + '" class="etiquetas-ico"><i class="fas"></i></span></a></li>';
					});
				}
				container.innerHTML = conteudo;
			}
		}
		).catch(error => console.log(`Erro ao tentar atualizar as etiquetas --atualizaEtiquetas(): ${error}`));
	}

	render() {
		const { modal } = this.state;
		return (
			<>
				<div>
					<a title="Excluir Etiquetas" onClick={this.handleShow}><i className="fas fa-trash"></i></a>
				</div>

				<Modal show={modal} onHide={this.handleClose} size="sm">
					<Modal.Header closeButton>
						<Modal.Title class="modalTitle">Excluir Etiquetas</Modal.Title>
					</Modal.Header>

					<Modal.Body>

						<div className="row">
							<div id="boxExcluirEtiquetas" className="col-md-12">
								<ul className="nav flex-column nav-stacked">

								</ul>
							</div>
						</div>

					</Modal.Body>


					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Fechar
						</Button>
					</Modal.Footer>
				</Modal>

			</>
		)
	}
};


