import JWT from "jwt-decode";

export function sendLoginData(data) {
	if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "block";
	return fetch('http://localhost:8080/tjdocs-rest-api/autenticacao', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => {
        if (res.status && res.status === 403){
			document.getElementById('mensagem-erro-body').innerHTML = 'Usuário não encontrado!';
			document.getElementById('mensagem-erro').style.display = "block";
			setTimeout(function(){
				document.getElementById('mensagem-erro').style.display = "none";
			}, 2000);
		} else if (res.status === 200){
			document.getElementById('mensagem-sucesso-body').innerHTML = 'Logado!';
			document.getElementById('mensagem-sucesso').style.display = "block";
			setTimeout(function(){
				document.getElementById('mensagem-sucesso').style.display = "none";
			}, 2000);
		}
		document.querySelector(".loading").style.display = "none";
		return res.text();
    }).then(function(token) {
		var decoded = JWT(token);
		console.log(decoded);
		
		//Armazenamento do token na Web em HTML5
		if (typeof(Storage) !== "undefined") {
			sessionStorage.token = token;
			
			//Atualizações visuais
			document.querySelector(".btn-login-right").style.display = "none";
			document.querySelector(".busca_Principal_search").style.width = "100%";
			document.querySelector(".user-name-header span").innerHTML = '<i>' + decoded.sub + '</i>';
			document.querySelector(".user-name-header").style.display = "block";
		
		} else {
			document.getElementById('mensagem-erro-body').innerHTML = 'Atualize seu navegador!';
			document.getElementById('mensagem-erro').style.display = "block";
			setTimeout(function(){
				document.getElementById('mensagem-erro').style.display = "none";
			}, 2000);
		}
		
		
	}).catch(err => {
        console.log(err);
		return err;
    });
}