import chroma from 'chroma-js';
import React, { Component } from "react";
import toast from 'react-hot-toast';
import AsyncSelect from 'react-select/async';
import TjdocsApi from '../api/API';

import PastaService from "../api/service/pastaService";
import UsuarioPastaService from "../api/service/usuarioPastaService";

import Authentication from "../Authentication";

import { CKEditor } from 'ckeditor4-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EditarVisibilidade } from "../components/EditarVisibilidade";
import BuscaAvancadaContainer from "../containers/BuscaAvancadaContainer";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

export class MenuDominio extends Component {
 
 
   constructor(props) {
       super(props);
	   //this.adicionarFavorito = this.adicionarFavorito.bind(this);
       this.usuarioPastaService = new UsuarioPastaService();
       this.pastaService = new PastaService();

       this.usuario = Authentication.getUsuario();	
       this.isLogin = Authentication.isLogin();	
       
       this.state = {
        ano_filtro: "",
        modalVisibilidade: false,
        visibilidadeId: 0
      };

      this.handleAnoChange = this.handleAnoChange.bind(this);
   }

   timeOutInputPesquisaNaPasta = setTimeout(() => {}, 100);
  
   loadOptionsPesquisaNaPasta = (inputPesquisaNav, callback) => {
        if (inputPesquisaNav.length >= 3){
            clearTimeout(this.timeOutInputPesquisaNaPasta);
            const timeOut = setTimeout(() => {

                TjdocsApi.pesquisaArquivosPorPasta(inputPesquisaNav, this.state.ano_filtro, this.props.idPasta).then(jsonPesquisa => {
                    var retorno = jsonPesquisa.map((i, index) => ({
            label: '<span class="optionPesquisaPrincipal">'+
            (i.fonte == 'pasta'?'<i class="fa fa-folder"></i> ':'<i class="fa fa-file"></i> ') +i.texto+' (Criado em: '+i.dataCadastro+')</span>' +
            (i.highlight == null?'':'<br/>' + i.highlight.substring(11, (i.highlight.length-2))),
            value: "/"+i.fonte+"s/"+i.id}));
        callback(retorno);
                }).catch(error => toast.error(`Erro ao tentar pesquisar termos: ${error}`));

            }, 2000);
            this.timeOutInputPesquisaNaPasta = timeOut;
        }
    };

    loadOptionsPesquisaNaPastaPorDecretoLei = (inputPesquisaNav, callback) => {
        clearTimeout(this.timeOutInputPesquisaNaPasta);
        const timeOut = setTimeout(() => {

            TjdocsApi.pesquisaArquivosPorDecretoLeiPorPasta(inputPesquisaNav, this.state.ano_filtro, this.props.idPasta).then(jsonPesquisa => {
                var retorno = jsonPesquisa.map((i, index) => ({
        label: '<span class="optionPesquisaPrincipal">'+
        (i.fonte == 'pasta'?'<i class="fa fa-folder"></i> ':'<i class="fa fa-file"></i> ') +i.texto+' (Criado em: '+i.dataCadastro+')</span>' +
        (i.highlight == null?'':'<br/>' + i.highlight.substring(11, (i.highlight.length-2))),
        value: "/"+i.fonte+"s/"+i.id}));
        callback(retorno);
            }).catch(error => toast.error(`Erro ao tentar pesquisar termos: ${error}`));

        }, 2000);
        this.timeOutInputPesquisaNaPasta = timeOut;
      };
      
      loadOptionsPesquisaNaPastaPorDescricao = (inputPesquisaNav, callback) => {
        if (inputPesquisaNav.length >= 3){
             clearTimeout(this.timeOutInputPesquisaNaPasta);
             const timeOut = setTimeout(() => {
      
                 TjdocsApi.pesquisaArquivosPorDescricaoPorPasta(inputPesquisaNav, this.state.ano_filtro, this.props.idPasta).then(jsonPesquisa => {
                     var retorno = jsonPesquisa.map((i, index) => ({
             label: '<span class="optionPesquisaPrincipal">'+
               (i.fonte == 'pasta'?'<i class="fa fa-folder"></i> ':'<i class="fa fa-file"></i> ') +i.texto+' (Criado em: '+i.dataCadastro+')</span>' +
               (i.highlight == null?'':'<br/>' + i.highlight.substring(11, (i.highlight.length-2))),
             value: "/"+i.fonte+"s/"+i.id}));
           callback(retorno);
                 }).catch(error => toast.error(`Erro ao tentar pesquisar termos: ${error}`));
      
             }, 2000);
             this.timeOutInputPesquisaNaPasta = timeOut;
        }
      };

    handleAnoChange(e) {
        this.setState({ ano_filtro: e.target.value });
      }

    loadOptions = (inputPesquisaNav, callback) => {
        if (inputPesquisaNav.length >= 3) {
          clearTimeout(this.timeOutInputPesquisaNav);
          const timeOut = setTimeout(() => {
            TjdocsApi.pesquisaConteudoPorPasta(inputPesquisaNav, this.props.idPasta)
              .then((jsonPesquisa) => {
                var retorno = jsonPesquisa.map((i, index) => ({
                  label:
                    '<span class="optionPesquisaPrincipal">' +
                    (i.fonte == "pasta"
                      ? '<i class="fa fa-folder"></i> '
                      : '<i class="fa fa-file"></i> ') +
                    i.texto + ' - (Enviado em: ' + i.dataCadastro  +  ')' +
                    "</span>" +
                    (i.highlight == null
                      ? ""
                      : "<br/>" +
                        i.highlight.substring(11, i.highlight.length - 2)),
                  value: "/" + i.fonte + "s/" + i.id,
                }));
                retorno.push({
                  label: "+ Mais Resultados",
                  value: "/busca/" + inputPesquisaNav,
                });
    
                if (inputPesquisaNav == this.state.textoDigitadoPesquisa)
                  callback(retorno);
              })
              .catch((error) =>
                toast.error(`Erro ao tentar pesquisar termos: ${error}`)
              );
          }, 2000);
          this.timeOutInputPesquisaNav = timeOut;
        } else {
          this.timeOutInputPesquisaNav = setTimeout(() => {}, 100);
          callback([]);
        }
      };

      textoDigitadoPesquisa = (inputValue) => {
        if (inputValue.length != 0)
          this.setState({ textoDigitadoPesquisa: inputValue });
      };

    onChangeFunc = (optionSelected) => {
		if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "block";
        window.location.href = optionSelected.value;
	}

    alterarVisibilidade = (valor) => {
        TjdocsApi.buscarPasta(this.props.idPasta).then( response =>
            {
                
                this.setState({visibilidadeId : response.visibilidadeId });
                if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
            }
         );
        this.setState({modalVisibilidade : valor });
    }
  
    setModalEditarArquivo = (valor) => {
        this.setState({modalEditarArquivo: valor});
    }

    
   /*
   adicionarFavorito = () => {
	  alert(this.props.idPasta);
   }
   */
 
  render() { 
      var exibicaoLista = "icones ";
      var exibicaoGrade = "icones ";
      if (typeof(Storage) !== "undefined") {
          if (typeof(localStorage.exibicao) !== "undefined"){
              if(localStorage.exibicao === "lista") {
                  exibicaoGrade += "d-none";
                  exibicaoLista += "";
              } else {
                  exibicaoGrade += "";
                  exibicaoLista += "d-none";
              }
          } else {
              localStorage.exibicao = "miniaturas";
              exibicaoGrade += "";
              exibicaoLista += "d-none";
          }
      }
    
      function gradeParaLista(){
          document.getElementById('alterar_P-DisplayLista').classList.add('d-none');
          document.getElementById('alterar_P-DisplayMiniaturas').classList.remove('d-none');
        
          document.getElementById('P-DisplayLista').classList.remove('d-none');
          document.getElementById('P-DisplayMiniaturas').classList.add('d-none');
          if (typeof(Storage) !== "undefined") localStorage.exibicao = "lista";
      }
    
      function listaParaGrade(){
          document.getElementById('alterar_P-DisplayLista').classList.remove('d-none');
          document.getElementById('alterar_P-DisplayMiniaturas').classList.add('d-none');
        
          document.getElementById('P-DisplayLista').classList.add('d-none');
          document.getElementById('P-DisplayMiniaturas').classList.remove('d-none');
          if (typeof(Storage) !== "undefined") localStorage.exibicao = "miniaturas";
      }

      

      const tooltipPesquisa = (props) => (
        <Tooltip {...props}> 
          <div className="box-pesquisa box-primary" style={{margin: 0, width: "1200px" }}>
            <div className="box-header-pesquisa with-border box-defaultClaro">
              <h3 className="box-title">
              <a role="button" href="#" onClick={(e) => e.preventDefault()}>
                Pesquisar <i style={{"float": "right"}} className="fas fa-times" onClick={() => document.body.click()}/>
              </a>
              </h3>
            </div>
            <div className="box-body no-padding" style={{color: "#333", fontSize: "180%", padding: "10px" }}>
                <div className="mb-7">
                    <input  className="form-control" type="text" id="anoFiltro" onChange={this.handleAnoChange}   maxLength="4" placeholder="Por Ano" style={{"textAlign" : "center"}}  />
                </div><div style={{marginBottom: "5px"}} />

                <AsyncSelect id="inputPesquisaTitulo" className="w-100" placeholder="Por parte do título do documento" style={{textAlign: "left" }}
                       cacheOptions
                       loadOptions={this.loadOptionsPesquisaNaPasta}
                       onChange={this.onChangeFunc}
                       formatOptionLabel={function(data) {
                            return (
                                <span dangerouslySetInnerHTML={{ __html: data.label }} />
                            );
                        }}
                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                   /><div style={{marginBottom: "5px"}} />


                <AsyncSelect id="inputPesquisaConteudo" className="w-100" placeholder="No conteúdo do documento" style={{textAlign: "left" }}
										cacheOptions
										loadOptions={this.loadOptions}
										onChange={this.onChangeFunc}
										onInputChange={this.textoDigitadoPesquisa}
										formatOptionLabel={function(data) {
											return (
												<span dangerouslySetInnerHTML={{ __html: data.label }} />
											);
										}}
                    noOptionsMessage={() => 'Nenhum registro encontrado'}
									/><div style={{marginBottom: "5px"}} />

                  <AsyncSelect id="inputPesquisaLeiDecreto" className="w-100" placeholder="N° da lei ou decreto" style={{textAlign: "left" }}
                       cacheOptions
                       loadOptions={this.loadOptionsPesquisaNaPastaPorDecretoLei}
                       onChange={this.onChangeFunc}
                       formatOptionLabel={function(data) {
                            return (
                                <span dangerouslySetInnerHTML={{ __html: data.label }} />
                            );
                        }}
                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                   /><div style={{marginBottom: "5px"}} />

                    <AsyncSelect id="descricao" className="w-100" placeholder="Descrição" style={{textAlign: "left" }}
                       cacheOptions
                       loadOptions={this.loadOptionsPesquisaNaPastaPorDescricao}
                       onChange={this.onChangeFunc}
                       formatOptionLabel={function(data) {
                            return (
                                <span dangerouslySetInnerHTML={{ __html: data.label }} />
                            );
                        }}
                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                   /><div style={{marginBottom: "5px"}} />

            </div>
		      </div>
        </Tooltip> 
      );

      const connect = (props) => {
        const handler = () => {

          var usuarioId = this.usuario.usuario_id_tjdocs;
          var pastaId = props.idPasta;

          if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "block";
            
          this.usuarioPastaService
            .estahAssociado(usuarioId, pastaId)
            .then((exist) => {
              //console.log(">>>> Exists: " + exist.data);
              if (!exist.data) {
                //console.log(">>>> Chegou ao associar com:", usuarioId, " - ", pastaId)
                this.pastaService
                  .obterPorId(pastaId)
                  .then((pasta) => {
                      //console.log(">>>> Localizou a pasta: "+pasta.data.toString());
                      this.usuarioPastaService.associar(usuarioId, pastaId);

                      //Lista
                      var lista = document.querySelector('#ListaFavoritos');
                      //alert("Eu sou o nodo: " + lista.nodeName);

                      if (document.querySelectorAll("[id=ElementoFavorito"+ pastaId + "]").length == 0){
                        //Elemento
                        var li = document.createElement("li");
                        li.className = "nav-item";
                        li.id = "Item" + pastaId;
                        li.innerHTML= '<a class="nav-link d-flex justify-content-between" id="ElementoFavorito'+ pastaId + '" href="/pastas/'+ pastaId + '">' + pasta.data.nome + '<span id="Favorito241" class="favoritos-ico"></span></a>';
                        lista.appendChild(li);
                      }
                    
                    })
                  .catch((error) => {
                    //console.log(">>>> Não localizou a pasta de Id: " + pastaId)
                    toast.error(
                      `Não foi possível obter a descrição da pasta: ${error}`
                    );
                    
                  });
                  if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";

                } else {
                  //console.log(">>>> Chegou ao desassociar com:", usuarioId, " - ", pastaId)
                  this.usuarioPastaService.desassociar(usuarioId, pastaId);

                  var elemento = document.querySelector("#Item"+pastaId);
                  elemento.parentNode.removeChild(elemento);
                  if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
                }
              })
            .catch((error) =>
                {toast.error(
                  `Erro ao tentar consultar as pastas do usuário: ${error}`
                );
                if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";}
              );
        };
        return handler;
      };
    
      return  <div className="p-contd-menuTopo d-flex align-items-center row">

              <div className="col-md-4" >

                  <div className={this.isLogin  ? "p-c-menTp-Endereco flex-grow-1" : "d-none "}>
                      <NovaPasta  idPasta={this.props.idPasta} state={this.props.state} loadComponent={this.props.loadComponent} podeAdicionarRemoverPasta={this.props.podeAdicionarRemoverPasta} podeSerPublica={this.props.podeSerPublica}/>
                      <UploadArquivo state={this.props.state} idPasta={this.props.idPasta} loadComponent={this.props.loadComponent} podeAdicionarRemoverArquivo={this.props.podeAdicionarRemoverArquivo} podeAdicionarArquivo={this.props.podeAdicionarArquivo}  />    
                      <ExcluirPasta state={this.props.state} idPasta={this.props.idPasta} loadComponent={this.props.loadComponent} podeAdicionarRemoverPasta={this.props.podeAdicionarRemoverPasta}/>    
                      <EditarPasta state={this.props.state} path={this.props.path} idPasta={this.props.idPasta} loadComponent={this.props.loadComponent} podeAdicionarRemoverPasta={this.props.podeAdicionarRemoverPasta}/>    

                      <a  className={this.props.podeAdicionarRemoverPasta  ? "icones" : "d-none "} title="Visibilidade" onClick={(e) => {document.body.click(); this.alterarVisibilidade(true)}}  style={{marginRight: "4px"}}> <i className="fas fa-eye"></i> </a>  
                      <a className={this.props.podeAdicionarRemoverPasta  ? "icones" : "d-none "} id="Favoritos" title="Favoritos" onClick={connect(this.props)}>
                          <i className="fas fa-star"></i>
                      </a>
                      <EditarVisibilidade state={this.props.state} idPasta={this.props.idPasta} visibilidadeId={this.state.visibilidadeId} loadComponent={this.props.loadComponent} modalVisibilidade={this.state.modalVisibilidade} close={() => this.alterarVisibilidade(false)} podeAdicionarRemoverPasta={this.props.podeAdicionarRemoverPasta} />
                      <Permissoes state={this.props.state} idPasta={this.props.idPasta} loadComponent={this.props.loadComponent} 
                      podeAdicionarRemoverUsuario={this.props.podeAdicionarRemoverUsuario} caminhoCompleto={this.props.caminhoCompleto}/>

                      {/*Colar*/}
                      <nobr style={{display: "none"}}>
                      <a className="btn-primary btn" style={{position: "relative", top: "-4px"}} title="Colar" onClick={(e) => console.log('ma ooe')}>
                          Colar
                      </a>
                      <a className="btn-danger btn" style={{position: "relative", top: "-4px", color: "#fff", borderRadius: "12px", padding: "0px 6px", marginLeft: "2px"}} title="Cancelar Colar" onClick={(e) => console.log('ma ooe')}>
                        <i className="fas fa-times"></i>
                      </a>
                      </nobr>
                      {/*Colar*/}

                  </div>
              </div>
              <div className="col-md-5">
                  {/*Inicio barra de pesquisa direita*/}
                        
                  {/* <AsyncSelect id="inputPesquisaNav" className="w-100" placeholder="Digite os termos da sua pesquisa (no mínimo três letras)"
                       cacheOptions
                       loadOptions={this.loadOptionsPesquisaNaPasta}
                       onChange={this.onChangeFunc}
                       formatOptionLabel={function(data) {
                            return (
                                <span dangerouslySetInnerHTML={{ __html: data.label }} />
                            );
                        }}
                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                    />*/}


                  {/*End barra de pesquisa direita*/}

                  
                  
              </div>
              <div className="col-md-3">
                  <div className="p-c-menTp-Opcoes">
                      <span>
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={tooltipPesquisa }
                        rootClose={true}
                      >
                        <a className="icones" title="Ordernado por data">
                            <i className="fas fa-search"></i>
                        </a>  
                     </OverlayTrigger>

                          <a className="icones" title="Ordernado por data">
                              <i className="fas fa-sort-amount-down-alt"></i>
                              {/*<img className="icones" src="/ui/img/Sort_By-Icon.svg" alt="Aquivos ordernados por data."/>*/}
                          </a>
                        
                          <a className={exibicaoLista} onClick={listaParaGrade} href="#!" id="alterar_P-DisplayMiniaturas" title="Visualização em miniatura."
                              role="button">
                              <i className="fas fa-th-large"></i>
                              {/*<img className="icones svg-comHover" src="/ui/img/Display_Miniatures-Icon.svg" alt=""/>*/}
                          </a>
                          <a className={exibicaoGrade} onClick={gradeParaLista} href="#!" id="alterar_P-DisplayLista" title="Visualização em lista." role="button">
                              <i id="alterarGradeParaLista" className="fas fa-list"></i>
                              {/*<img className="icones svg-comHover" src="/ui/img/Display_List-Icon.svg" alt=""/>*/}
                          </a>
                      </span>
                  </div>
              </div>
            

          </div>;
  }
}

 
class UploadArquivo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {libreofficeURL: '', modal:  false, etiquetas: [], arquivo: "", descricao: "", ano_filtro: "", etiquetasSelecionadas:[], arquivosVinculados:[], tipoVinculo: "", idVisibilidade: "", validated: false, dados: [], modalArquivoRepetido: false, mensagemRepetida: false, nomePasta: "", nomeArquivo: "", idDocumento: 0, urlUpload : "", exibeModalLoading: false };
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFile = this.handleFile.bind(this); 
        this.closeModalArquivoRepetido = this.closeModalArquivoRepetido.bind(this); 
        this.usuario = Authentication.getUsuario();	
        this.isLogin = Authentication.isLogin();
        this.handleNomeArquivo = this.handleNomeArquivo.bind(this);	   
        this.reenviar = this.reenviar.bind(this); 
        this.editarComArquivo = this.editarComArquivo.bind(this); 
        this.fecharModalLoading = this.fecharModalLoading.bind(this);
        this.editarSemArquivo = this.editarSemArquivo.bind(this);

        
        
    }

    componentDidMount(){

        window.addEventListener('message', this.receiveMessage, false);
          
    }

    componentWillMount() {
        
        TjdocsApi.listaEtiquetas().then(jsonEtiquetas => {
                if(jsonEtiquetas && jsonEtiquetas._embedded){                
                    this.setState({
                        etiquetas: jsonEtiquetas._embedded.etiquetas
                    });
                }
            }
        ).catch(error => toast.error(`Erro ao tentar consultar os domínios: ${error}`));

        TjdocsApi.buscarPasta(this.props.idPasta).then( response =>
            {
                if(response != null && response != "undefined") {
                    this.setState({nomePasta : response.nome});
                    if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
                }
            }
         );

        const idNovoArquivo = Math.random() + "novo";
        this.setState({
            libreofficeURL: `${TjdocsApi.urlLibreOffice()}/browser/fc0de5b/cool.html?WOPISrc=${TjdocsApi.urlWOPI()}/v1/wopi/files/${idNovoArquivo}&NotWOPIButIframe=true&lang=pt-br`
        });

        this.handleSelectChangeVisibilidade();        
    }
    
    // Função para enviar a imagem via postmessage

    receiveMessage(event) {
        let jsonData = '';
        if(typeof event.data === 'object') {
            jsonData = event.data;
        }
        else {
            jsonData = JSON.parse(event.data);
        }
        console.log("Message received from the child: ", jsonData); // Message received from child
        const protocol = window.location.protocol;
        const host = window.location.host;
        const port = window.location.port ? `:${window.location.port}` : '';

        
        // Formar a URL base
        const baseUrl = `${protocol}//${host}`;

        if(jsonData.MessageId == "Clicked_Button") {
            if(jsonData.Values.Id == "brasao__") {
                document.getElementById('edicaoTextoDescricao').contentWindow.postMessage(
                    JSON.stringify({'MessageId': 'Send_UNO_Command',
                    'Values': {
                        'Command': '.uno:DefaultFormat',
                        'Args': {}
                    }
                    }), '*');

                document.getElementById('edicaoTextoDescricao').contentWindow.postMessage(
                    JSON.stringify({
                        MessageId: 'Send_UNO_Command',
                        'Values': {
                          'Command': '.uno:InsertHeader',
                          'Args': {}
                        }
                      }), '*');    

                    

                document.getElementById('edicaoTextoDescricao').contentWindow.postMessage(
                    JSON.stringify({'MessageId': 'Send_UNO_Command',
                        'Values': {
                        'Command': '.uno:CenterPara',
                        'Args': {}
                        }
                    }), '*'); 

                 var img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = function () {
                    var canvas = document.createElement('canvas');
                    var ctx = canvas.getContext('2d');
                    canvas.height = this.height;
                    canvas.width = this.width;
                    ctx.drawImage(this, 0, 0);
                    var dataURL = canvas.toDataURL('image/png');

                    document.getElementById('edicaoTextoDescricao').contentWindow.postMessage(
                        JSON.stringify({
                            'MessageId': 'Action_Paste',
                            'Values': {
                                'Data': dataURL
                            }
                        }), '*');
                };
                img.src = baseUrl + '/ui/img/brasao.png';

                

                setTimeout(function() {

                    document.getElementById('edicaoTextoDescricao').contentWindow.postMessage(
                        
                        JSON.stringify({'MessageId': 'Send_UNO_Command',
                        'Values': {
                            'Command': '.uno:InsertText',
                            'Args': {
                                Text: {
                                    type: 'string',
                                    value: 'PODER JUDICIÁRIO\r\n'
                                    +' Tribunal de Justiça do Estado de Goiás\r\n'
                                    +' Gabinete da Presidência\r\n'
                                    },
                                ParaStyleName: {
                                    type: 'string',
                                    value: 'Heading 1' // Estilo de cabeçalho pré-definido
                                    }
                            }
                        }
                        }), '*');
                }, 1000);
            }

            if(jsonData.Values.Id == "abrir__") { 
                if(document.getElementById("formFile") != null && document.getElementById("formFile") != undefined) {
                    document.getElementById("formFile").click();
                }
            }
        }

        if(jsonData.Values!= undefined && jsonData.Values.Status == "Document_Loaded" && document.getElementById('edicaoTextoDescricao') != null && document.getElementById('edicaoTextoDescricao') != undefined) {
            document.getElementById('edicaoTextoDescricao').contentWindow.postMessage(JSON.stringify({
                "MessageId" : "Insert_Button",
                "Values" : {
                  "id" : "abrir__",
                  "imgurl" : baseUrl + "/ui/img/folder.svg",
                  "hint" : "Abrir",
                  "insertBefore" : 'save',
                  "label" : "Abrir"
                }
              }), '*');

              document.getElementById('edicaoTextoDescricao').contentWindow.postMessage(JSON.stringify({
                "MessageId" : "Insert_Button",
                "Values" : {
                  "id" : "brasao__",
                  "imgurl" : baseUrl + "/ui/img/brasao.png",
                  "hint" : "Adicionar Brasão",
                  "insertBefore" : 'save',
                  "label" : "Brasão"
                }
              }), '*');
        }
    }

    

    exibeModalLoading() {
        this.setState({exibeModalLoading : true});
    }

    fecharModalLoading() {
        this.setState({exibeModalLoading : false});
    }

    resetState(){
        this.state = {modal:  false, etiquetas: [], arquivo: "", descricao: "", ano_filtro: "", etiquetasSelecionadas:[], arquivosVinculados:[], tipoVinculo: "", idVisibilidade: "", numDecreto: "", numLei: "", descricaoDocumento: "", validated: false, dados: [], modalArquivoRepetido: false, nomePasta: "", nomeArquivo: "", idDocumento: 0, urlUpload: "", mensagemRepetida: false, exibeModalLoading: false };

        this.setState({ arquivo: "" });
        this.setState({ descricao: "" });
        this.setState({ ano_filtro: "" });
        this.setState({ etiquetasSelecionadas: [] });
        this.setState({ arquivosVinculados:[] });
        this.setState({ tipoVinculo: "" });
        this.setState({ idVisibilidade: "" });
        this.setState({ numDecreto: "" });
        this.setState({ numLei: "" });
        this.setState({ descricaoDocumento: "" });
        this.setState({ nomeArquivo: ""});
        this.setState({ idDocumento: 0});
        this.setState({ urlUpload: ""});

    }
       
    handleClose(){
        if(this.state.idDocumento != null && this.state.idDocumento != '' && this.state.idDocumento != 0) { 
            TjdocsApi.excluiDocumento(this.state.idDocumento);
            this.props.loadComponent(this.props.idPasta);
        }

        const idNovoArquivo = Math.random() + "novo";
        this.setState({
            libreofficeURL: `${TjdocsApi.urlLibreOffice()}/browser/fc0de5b/cool.html?WOPISrc=${TjdocsApi.urlWOPI()}/v1/wopi/files/${idNovoArquivo}&NotWOPIButIframe=true&lang=pt-br`
        });

        this.setState({ modal: false });
    }

    handleShow(e){        
        
        this.setState({ validated: false });
        this.resetState();


        e.preventDefault();
        TjdocsApi.listaEtiquetas()
        .then(jsonEtiquetas => {
                if(jsonEtiquetas){
                    this.setState({
                        etiquetas: jsonEtiquetas._embedded.etiquetas
                    });
                }

                return true;
        })
        .then(response => {
            this.setState({ modal: response });
            if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
        })
        .catch(error => toast.error(`Erro ao tentar consultar os domínios: ${error}`));

    }

    atualizarFakeFile() {
        const fakeField = document.getElementById("fakeFileField");
        const realField = document.getElementById("formFile");
        if (realField.files.length > 0) {
        fakeField.value = realField.files[0].name;
        } else {
            fakeField.value = "";
        }
    }

    handleFile(e) {
        e.preventDefault();

        let file = e.target.files[0];
        if(file == null || file == undefined) return;

        this.setState({ arquivo: file});
        this.handleSubmit(e, file.name);
    }

    editarComArquivo() {
        this.setState({modalArquivoRepetido : false});
        var arquivosVinculadosAux = this.state.arquivosVinculados.map((i) => ({ id: null, documentoVinculado: i.documentoVinculado, tipo:this.state.tipoVinculo }));
        TjdocsApi.editarComArquivo(this.state.idDocumento, this.state.arquivo, this.state.descricao, 
            this.state.idVisibilidade, this.state.urlUpload, arquivosVinculadosAux, this.state.numDecreto, 
            this.state.etiquetasSelecionadas, this.state.numLei, this.state.descricaoDocumento, this.state.idPasta).then(response => {

            this.props.loadComponent(this.props.idPasta);
            this.setState({arquivosVinculados:[], dados:[]});
            if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
            toast.success('Sucesso!');

        }).catch((e) => {
            if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";  
            toast.error('Editando Arquivo: ' + e);              
            });

        const idNovoArquivo = Math.random() + "novo";    
        this.setState({
            libreofficeURL: `${TjdocsApi.urlLibreOffice()}/browser/fc0de5b/cool.html?WOPISrc=${TjdocsApi.urlWOPI()}/v1/wopi/files/${idNovoArquivo}&NotWOPIButIframe=true&lang=pt-br`
        });    
        this.setState({ modal: false });
    }

    editarSemArquivo(e) {
        e.preventDefault();
        const form = e.currentTarget; 
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ validated: true });

        } else {
            this.setState({modalArquivoRepetido : false});
            var arquivosVinculadosAux = this.state.arquivosVinculados.map((i) => ({ id: null, documentoVinculado: i.documentoVinculado, tipo:this.state.tipoVinculo }));
            TjdocsApi.editarArquivo(this.state.idDocumento, this.state.nomeArquivo, this.state.descricao, 
                this.state.idVisibilidade, this.state.numDecreto, this.state.numLei, this.state.descricaoDocumento, this.state.ano_filtro, arquivosVinculadosAux).then(response => {

                this.props.loadComponent(this.props.idPasta);
                this.setState({arquivosVinculados:[], dados:[]});
                if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
                toast.success('Sucesso!');

            }).catch((e) => {
                if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";  
                toast.error('Editando Arquivo: ' + e);              
                });
            
            const idNovoArquivo = Math.random() + "novo";    
            this.setState({
                libreofficeURL: `${TjdocsApi.urlLibreOffice()}/browser/fc0de5b/cool.html?WOPISrc=${TjdocsApi.urlWOPI()}/v1/wopi/files/${idNovoArquivo}&NotWOPIButIframe=true&lang=pt-br`
            });    
            this.setState({ modal: false });
        }
    }

    // handleSubmit(e, nomeArquivo) {
    //     e.preventDefault();
    //     this.exibeModalLoading();
    //     const timeOut = setTimeout(() => {
    //         var arquivosVinculadosAux = []; //this.state.arquivosVinculados.map((i) => ({ id: null, documentoVinculado: i.documentoVinculado, tipo:this.state.tipoVinculo }));
    //         const fileName = this.state.arquivo.name;
    //         const fileExtension = fileName.split('.').pop().toLowerCase();
    //
    //         if(this.state.idDocumento != null && this.state.idDocumento != '' && this.state.idDocumento != 0) {
    //
    //             console.log("editarComArquivo");
    //             console.log(this.state.arquivo);
    //             TjdocsApi.editarComArquivo(this.state.idDocumento, this.state.arquivo, this.state.descricao,
    //                 this.state.idVisibilidade, this.state.urlUpload, arquivosVinculadosAux, this.state.numDecreto,
    //                 this.state.etiquetasSelecionadas, this.state.numLei, this.state.descricaoDocumento, this.state.idPasta).then(response => {
    //                 this.fecharModalLoading();
    //                 this.props.loadComponent(this.props.idPasta);
    //                 this.setState({arquivosVinculados:[], dados:[]});
    //                 if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
    //                 this.setState({idDocumento: response.id});
    //                 this.setState({nomeArquivo: this.state.arquivo.name });
    //                 this.setState({
    //                     libreofficeURL: ``
    //                 });
    //                 this.setState({
    //                     libreofficeURL: `${TjdocsApi.urlLibreOffice()}/browser/fc0de5b/cool.html?WOPISrc=${TjdocsApi.urlWOPI()}/v1/wopi/files/${response.id}&NotWOPIButIframe=true&lang=pt-br`
    //                 });
    //
    //             }).catch((e) => {
    //                 this.fecharModalLoading();
    //                 if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
    //                 toast.error('Editando Arquivo: ' + e);
    //             });
    //         } else {
    //
    //             console.log("criaDocumento");
    //             TjdocsApi.criaDocumento(this.state.etiquetasSelecionadas, this.state.descricao, this.props.idPasta, this.state.arquivo, arquivosVinculadosAux, this.state.idVisibilidade, this.state.numDecreto, this.state.numLei, this.state.descricaoDocumento, this.state.nomeArquivo, this.state.ano_filtro).then( response =>{
    //                     this.fecharModalLoading();
    //                     this.props.loadComponent(this.props.idPasta);
    //                     this.setState({arquivosVinculados:[], dados:[]});
    //                     if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
    //                     this.setState({urlUpload: response.urlUpload});
    //                     this.setState({idDocumento: response.id});
    //                     this.setState({nomeArquivo:  this.state.nomeArquivo == "" ? this.state.arquivo.name : this.state.nomeArquivo});
    //                     if (fileExtension === 'pdf') {
    //                         this.setState({
    //                             libreofficeURL: `${TjdocsApi.urlLibreOffice()}/browser/fc0de5b/cool.html?WOPISrc=${TjdocsApi.urlWOPI()}/v1/wopi/files/${response.id}&NotWOPIButIframe=true&lang=pt-br&permission=readonly`
    //                         });
    //                     }else{
    //                         this.setState({
    //                             libreofficeURL: `${TjdocsApi.urlLibreOffice()}/browser/fc0de5b/cool.html?WOPISrc=${TjdocsApi.urlWOPI()}/v1/wopi/files/${response.id}&NotWOPIButIframe=true&lang=pt-br`
    //                         });
    //                     }
    //                 }
    //             ).catch((e) => {
    //                 this.fecharModalLoading();
    //                 if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
    //                 toast.error('Criando Arquivo: ' + e);
    //             });
    //
    //         }
    //     }, 1000);
    //     this.timeOutInputPesquisaNav = timeOut;
    //
    // }

    handleSubmit(e, nomeArquivo) {
        e.preventDefault();
        this.exibeModalLoading();
        const timeOut = setTimeout(() => {
            var arquivosVinculadosAux = []; //this.state.arquivosVinculados.map((i) => ({ id: null, documentoVinculado: i.documentoVinculado, tipo:this.state.tipoVinculo }));
            const fileName = this.state.arquivo.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();

            if(this.state.idDocumento != null && this.state.idDocumento != '' && this.state.idDocumento != 0) {
                this.setState({ nomeArquivo: this.state.arquivo.name});
                TjdocsApi.excluiDocumento(this.state.idDocumento);
            }

            TjdocsApi.criaDocumento(this.state.etiquetasSelecionadas, this.state.descricao, this.props.idPasta, this.state.arquivo, arquivosVinculadosAux, this.state.idVisibilidade, this.state.numDecreto, this.state.numLei, this.state.descricaoDocumento, this.state.arquivo.name, this.state.ano_filtro).then( response =>{
                    this.fecharModalLoading();
                    this.props.loadComponent(this.props.idPasta);
                    this.setState({arquivosVinculados:[], dados:[]});
                    if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
                    this.setState({urlUpload: response.urlUpload});
                    this.setState({idDocumento: response.id});
                    this.setState({nomeArquivo:  this.state.nomeArquivo == "" ? this.state.arquivo.name : this.state.nomeArquivo});
                    if (fileExtension === 'pdf') {
                        this.setState({
                            libreofficeURL: `${TjdocsApi.urlLibreOffice()}/browser/fc0de5b/cool.html?WOPISrc=${TjdocsApi.urlWOPI()}/v1/wopi/files/${response.id}&NotWOPIButIframe=true&lang=pt-br&permission=readonly`
                        });
                    }else{
                        this.setState({
                            libreofficeURL: `${TjdocsApi.urlLibreOffice()}/browser/fc0de5b/cool.html?WOPISrc=${TjdocsApi.urlWOPI()}/v1/wopi/files/${response.id}&NotWOPIButIframe=true&lang=pt-br`
                        });
                    }
                }
            ).catch((e) => {
                this.fecharModalLoading();
                if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
                toast.error('Criando Arquivo: ' + e);
            });

        }, 1000);
        this.timeOutInputPesquisaNav = timeOut;
    
    }
 
   /* handleSubmit(e, nomeArquivo) {   
       e.preventDefault();

       const form = e.currentTarget; 
       this.setState({modalArquivoRepetido : false});
       this.exibeModalLoading(); 
       TjdocsApi.existeDuplicidade(this.state.arquivo, this.state.nomeArquivo == "" || this.state.nomeArquivo == undefined || this.state.nomeArquivo == null ? nomeArquivo : this.state.nomeArquivo, this.props.idPasta).then(documento => {
            this.fecharModalLoading();
            if(documento) {
                this.setState({idDocumento: documento.id});
                this.setState({urlUpload: documento.urlUpload});
                this.setState({modalArquivoRepetido : true});
                return; 
            }
            else {
                var arquivosVinculadosAux = this.state.arquivosVinculados.map((i) => ({ id: null, documentoVinculado: i.documentoVinculado, tipo:this.state.tipoVinculo }));
                
                    
                if (form.checkValidity() === false) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ validated: true });

                } else{        
                        if(this.state.arquivosVinculados.length != 0 && (this.state.tipoVinculo == "" || this.state.tipoVinculo == "Escolha...")) { 
                            toast.error(`Selecione o tipo de vinculo antes de prosseguir!`);
                            return;
                        } 

                    this.exibeModalLoading(); 
                    TjdocsApi.criaDocumento(this.state.etiquetasSelecionadas, this.state.descricao, this.props.idPasta, this.state.arquivo, arquivosVinculadosAux, this.state.idVisibilidade, this.state.numDecreto, this.state.numLei, this.state.descricaoDocumento, this.state.nomeArquivo, this.state.ano_filtro).then( response =>{
                            this.fecharModalLoading();
                            this.props.loadComponent(this.props.idPasta);
                            this.setState({arquivosVinculados:[], dados:[]});
                            if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";

                            this.setState({idDocumento: response.id});
                            this.setState({nomeArquivo:  this.state.nomeArquivo == "" ? this.state.arquivo.name : this.state.nomeArquivo});
                            this.setState({
                                libreofficeURL: `${TjdocsApi.urlLibreOffice()}/browser/fc0de5b/cool.html?WOPISrc=${TjdocsApi.urlWOPI()}/v1/wopi/files/${response.id}&NotWOPIButIframe=true&lang=pt-br`
                            });
                            //toast.success('Sucesso!');
                        }
                    ).catch((e) => {
                        this.fecharModalLoading();
                        if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";  
                        toast.error('Criando Arquivo: ' + e);              
                        });
                    //this.setState({ modal: false });           
                }
            }
       });   
   }*/

   reenviar() {
    this.setState({modalArquivoRepetido : false}); 
    this.exibeModalLoading();    
    TjdocsApi.existeDuplicidade(this.state.arquivo, this.state.nomeArquivo, this.props.idPasta).then(duplicado => {
         this.fecharModalLoading();
         if(duplicado) {
             this.setState({modalArquivoRepetido : true});
             this.setState({mensagemRepetida : true});
             return false; 
         }
         else {
             var arquivosVinculadosAux = this.state.arquivosVinculados.map((i) => ({ id: null, documentoVinculado: i.documentoVinculado, tipo:this.state.tipoVinculo }));
                   
                if(this.state.arquivosVinculados.length != 0 && (this.state.tipoVinculo == "" || this.state.tipoVinculo == "Escolha...")) { 
                    toast.error(`Selecione o tipo de vinculo antes de prosseguir!`);
                    return;
                } 
                 TjdocsApi.criaDocumento(this.state.etiquetasSelecionadas, this.state.descricao, this.props.idPasta, this.state.arquivo, arquivosVinculadosAux, this.state.idVisibilidade, this.state.numDecreto, this.state.numLei, this.state.descricaoDocumento, this.state.nomeArquivo, this.state.ano_filtro).then( response =>{
                     this.props.loadComponent(this.props.idPasta);
                     this.setState({arquivosVinculados:[], dados:[]});
                     if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
                     
                     //this.setState({idDocumento: response.id});
                     
                     //this.setState({
                        //libreofficeURL: `${TjdocsApi.urlLibreOffice()}/browser/fc0de5b/cool.html?WOPISrc=${TjdocsApi.urlWOPI()}/v1/wopi/files/${response.id}&NotWOPIButIframe=true&lang=pt-br`
                    //});
                     toast.success('Sucesso!');
                    }
                 ).catch((e) => {
                     if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";  
                     toast.error('Criando Arquivo: ' + e);              
                     });
                 this.setState({ modal: false });           
         }
    });   
}

   handleChange (event){
        this.setState({tipoVinculo: event.target.value});
       /*var arquivos = this.state.arquivosVinculados.map((i) => ({ id: null, documentoVinculado: i.documentoVinculado, tipo:event.target.value }))
       this.setState({ arquivosVinculados: arquivos }); */
   }
 
   handleSelectChangeArquivos = (data) => {
        if(data[0] != null && data[0] != "undefined") {
            var arquivos = [...this.state.arquivosVinculados, { documentoVinculado: {id: data[0].value.split('/')[2]}}];
            this.setState({ arquivosVinculados: arquivos });
        }
    /*var arquivos = data.map((i) => ({ documentoVinculado: {id: i.value.split('/')[2]}}))
       this.setState({ arquivosVinculados: arquivos });
       console.log(arquivos);*/
   }
  
   handleSelectChangeEtiquetas (data){
       var etiquetasSelecionadas = data.map((i) => ({ id: i.value}))
       this.setState({ etiquetasSelecionadas: etiquetasSelecionadas });
   }

   handleSelectChangeVisibilidade (event){
    
    if (event){
        let visibilidade = event.target.value;
        this.setState({ idVisibilidade: visibilidade });
    }
    else{
        this.setState({ idVisibilidade: 1 });
    }

   }   
  
   handleDescricaoArquivo (event){    
    let descricaoArquivo = "";
    
    if(event.editor){        
        descricaoArquivo = event.editor.getData();        
    }else{        
        descricaoArquivo = event.target.value;
    }  
    
    this.setState({ descricao: descricaoArquivo });
   }   

   handleNumLei (event){    
    let numLei = "";    
    numLei = event.target.value;
    
    this.setState({ numLei: numLei });
   }

   handleNumDecreto (event){    
    let numDecreto = "";    
    numDecreto = event.target.value;
    
    this.setState({ numDecreto: numDecreto });
   }

   handleDescricaoDocumento (event){    
    let descricaoDocumento = "";    
    descricaoDocumento = event.target.value;
    
    this.setState({ descricaoDocumento: descricaoDocumento });
   }
   
   handleFiltroAno (event){
    
    let ano = event.target.value;
    this.setState({ ano_filtro: ano });
   } 
   
   handleNomeArquivo (event){
        let nome = event.target.value;
        this.setState({ nomeArquivo: nome });
   }  

   timeOutInputPesquisaArquivosUpload = setTimeout(() => {}, 100);

   loadOptions = (inputPesquisaNav, callback) => {
        if (inputPesquisaNav.length >= 0){
                callback(this.state.etiquetas.map((i) => ({label: i.titulo, value: i.id, color: i.cor})));
        }
   }

   loadOptionsConteudo = (inputPesquisaNav, callback) => {
    if (inputPesquisaNav.length >= 3) {
      clearTimeout(this.timeOutInputPesquisaNav);
      const timeOut = setTimeout(() => {
        TjdocsApi.pesquisa(inputPesquisaNav)
          .then((jsonPesquisa) => {
            var retorno = jsonPesquisa.map((i, index) => ({
              label:
                '<span class="optionPesquisaPrincipal">' +
                (i.fonte == "pasta"
                  ? '<i class="fa fa-folder"></i> '
                  : '<i class="fa fa-file"></i> ') +
                i.texto + ' - (Enviado em: ' + i.dataCadastro  +  ')' +
                "</span>" +
                (i.highlight == null
                  ? ""
                  : "<br/>" +
                    i.highlight.substring(11, i.highlight.length - 2)),
              value: "/" + i.fonte + "s/" + i.id,
            }));
            retorno.push({
              label: "+ Mais Resultados",
              value: "/busca/" + inputPesquisaNav,
            });

            if (inputPesquisaNav == this.state.textoDigitadoPesquisa)
              callback(retorno);
          })
          .catch((error) =>
            toast.error(`Erro ao tentar pesquisar termos: ${error}`)
          );
      }, 2000);
      this.timeOutInputPesquisaNav = timeOut;
    } else {
      this.timeOutInputPesquisaNav = setTimeout(() => {}, 100);
      callback([]);
    }
  };
 
 
   loadOptionsPesquisaArquivos = (inputPesquisaNav, callback) => {
       if (inputPesquisaNav.length >= 3){
        clearTimeout(this.timeOutInputPesquisaArquivosUpload);
        const timeOut = setTimeout(() => {
           TjdocsApi.pesquisaArquivos(inputPesquisaNav, this.state.ano_filtro).then(jsonPesquisa => {
               callback(jsonPesquisa.map((i, index) => ({ label: i.texto, value: i.id})));
           }).catch(error => toast.error(`Erro ao tentar pesquisar termos: ${error}`));
        }, 2000);
        this.timeOutInputPesquisaArquivosUpload = timeOut;
       }
   };

   onChangeFunc = (optionSelected) => {
        if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "block";
        window.location.href = optionSelected.value;
    }

    selecionarArquivo() {
        if(document.getElementById("formFile") != null && document.getElementById("formFile") != undefined) {
            document.getElementById("formFile").click();
        }
    }

    removeItemSelecionado = (id) => {
        const dadosAux = [...this.state.dados];
        dadosAux.splice(id, 1);
        this.setState({dados : dadosAux});

        const arquivosVinculadosAux = [...this.state.arquivosVinculados];
        arquivosVinculadosAux.splice(id, 1);
        this.setState({arquivosVinculados : arquivosVinculadosAux});
      }
    
    handleItensSelecionados = (data) => {
        if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "block";
        if(data.length > 0) { 
          document.getElementById('buscaAvancadaButtonUpload').click()
        }
        const dadosAux = [...this.state.dados, data];
        this.setState({dados : dadosAux});
        this.handleSelectChangeArquivos(data);
      }

    closeModalArquivoRepetido() {
        this.setState({modalArquivoRepetido: false});
    }  

  render() {
   const { modal } = this.state;
   const unidade_corporativo = this.usuario.corporativo_unidade_nome;

   
      
   {/*Configurações de estilo do select-react das etiquetas*/}
   const colourStyles: StylesConfig<ColourOption, true> = {
     control: (styles) => ({ ...styles, backgroundColor: 'white' }),
     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
       const color = chroma(data.color);
       return {
         ...styles,
         backgroundColor: isDisabled
           ? undefined
           : isSelected
           ? data.color
           : isFocused
           ? color.alpha(0.1).css()
           : undefined,
         color: isDisabled
           ? '#ccc'
           : isSelected
           ? chroma.contrast(color, 'white') > 2
             ? 'white'
             : 'black'
           : data.color,
         cursor: isDisabled ? 'not-allowed' : 'default',
 
         ':active': {
           ...styles[':active'],
           backgroundColor: !isDisabled
             ? isSelected
               ? data.color
               : color.alpha(0.3).css()
             : undefined,
         },
       };
     },
     multiValue: (styles, { data }) => {
       const color = chroma(data.color);
       return {
         ...styles,
         backgroundColor: color.alpha(0.1).css(),
       };
     },
     multiValueLabel: (styles, { data }) => ({
       ...styles,
       color: data.color,
     }),
     multiValueRemove: (styles, { data }) => ({
       ...styles,
       color: data.color,
       ':hover': {
         backgroundColor: data.color,
         color: 'white',
       },
     }),
   };


 
   const { validated } = this.state;
 
      return (
          <>

                
				
                  <a  className={this.props.podeAdicionarRemoverArquivo || this.props.podeAdicionarArquivo  ? "icones" : "d-none"} title="Upload Arquivo" onClick={this.handleShow} ><i className="fas fa-upload"></i></a>
                  <Modal show={modal} onHide={this.handleClose} backdrop="static"  autoFocus={false} dialogClassName={"largeModal"} enforceFocus={false} >
                      <Modal.Header closeButton>
                          <Modal.Title> <h3 className="span12 centered-text"> Upload Arquivo </h3> </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
 
                      <Form id="create-course-form" name = "addForm"
                               noValidate
                               validated={validated}
                               onSubmit={e => this.editarSemArquivo(e)} >
 

                           <div className="mb-3">
                                
                                <input  required className="form-control" type="file" id="formFile" onChange={this.handleFile} accept-language="pt-BR" />
                           </div>
 
                          
                          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Conteúdo do Arquivo</Form.Label>
                            {/*<CKEditor

                                onBeforeLoad={(CKEDITOR) => {                                                                        
                                    CKEDITOR.plugins.addExternal("textindent","/ckeditor4/plugins/textindent/","plugin.js");
                                    CKEDITOR.plugins.addExternal("abnt","/ckeditor4/plugins/abnt/","plugin.js");                                    
                                    CKEDITOR.plugins.addExternal("brasao","/ckeditor4/plugins/brasao/","plugin.js");                                    
                                    CKEDITOR.plugins.addExternal("openlink","/ckeditor4/plugins/openlink/","plugin.js");                                    
                                }}
                                
                                onDialogShow={(CKEDITOR) => {                                                                                                                     
                                        
                                    let modalBootstrap = document.querySelector("div.fade.modal.show");  
                                    if(modalBootstrap){
                                        modalBootstrap.setAttribute("tabindex","");
                                    }                                    
                                                                          
                                }}                                  

                                config={{
                                    toolbar:[
                                    { name: 'document', items: [ 'textindent','abnt','brasao','lineheight', 'PasteFromWord', 'Print' ] },
                                    { name: 'clipboard', items: [ 'Undo', 'Redo' ] },
                                    { name: 'styles', items: [ 'Format', 'Font', 'FontSize' ] },
                                    { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting' ] },
                                    { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                                    { name: 'align', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                                    { name: 'links', items: [ 'Link', 'Unlink' ] },
                                    { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote' ] },
                                    { name: 'insert', items: [ 'Image', 'Table' ] },
                                    { name: 'tools', items: [ 'Maximize' ] }	
                                    ],                                                                                          
                                    extraPlugins:[ 'textindent', 'abnt', 'print', 'justify', 'brasao', 'font', 'openlink'],
                                    customValues: {unidade: unidade_corporativo}
                                }}

                                onChange={e => this.handleDescricaoArquivo(e)}


                            />*/}
                            <iframe id="edicaoTextoDescricao" name="edicaoTextoDescricao" style={{height: "500px", width: "100%"}} class="vbox" src={this.state.libreofficeURL}  />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Descrição</Form.Label>
                            <CKEditor

                                onBeforeLoad={(CKEDITOR) => {                                                                        
                                    CKEDITOR.plugins.addExternal("textindent","/ckeditor4/plugins/textindent/","plugin.js");
                                    CKEDITOR.plugins.addExternal("abnt","/ckeditor4/plugins/abnt/","plugin.js");                                    
                                    CKEDITOR.plugins.addExternal("brasao","/ckeditor4/plugins/brasao/","plugin.js");                                    
                                    CKEDITOR.plugins.addExternal("openlink","/ckeditor4/plugins/openlink/","plugin.js");   
                                }}

                                onDataReady={(CKEDITOR) => {         
                                    setTimeout(() => {
                                        if(document.querySelector(".cke_notification_close")) document.querySelector(".cke_notification_close").click();
                                    }, 500);                                                               
                                }}
                                
                                onDialogShow={(CKEDITOR) => {                                                                                                                     
                                        
                                    let modalBootstrap = document.querySelector("div.fade.modal.show");  
                                    if(modalBootstrap){
                                        modalBootstrap.setAttribute("tabindex","");
                                    }                                    
                                                                          
                                }}                                  

                                config={{
                                    toolbar:[
                                    { name: 'document', items: [ 'textindent','abnt','brasao','lineheight', 'PasteFromWord', 'Print' ] },
                                    { name: 'clipboard', items: [ 'Undo', 'Redo' ] },
                                    { name: 'styles', items: [ 'Format', 'Font', 'FontSize' ] },
                                    { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting' ] },
                                    { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                                    { name: 'align', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                                    { name: 'links', items: [ 'Link', 'Unlink' ] },
                                    { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote' ] },
                                    { name: 'insert', items: [ 'Image', 'Table' ] },
                                    { name: 'tools', items: [ 'Maximize' ] }	
                                    ],                                                                                          
                                    extraPlugins:[ 'textindent', 'abnt', 'print', 'justify', 'brasao', 'font', 'openlink'],
                                    customValues: {unidade: unidade_corporativo}
                                }}

                                onChange={e => this.handleDescricaoArquivo(e)}


                            />
                          </Form.Group>
                            <Form.Row>
                                <Form.Group as={Col} md="2">
                                <Form.Label>Ano</Form.Label>
                                <div className="mb-7">
                                    <input  className="form-control" type="text" id="ano"  placeholder="Ano" onChange={e => this.handleFiltroAno(e)} />
                                </div>
                                </Form.Group>
                            </Form.Row> 
                          <Form.Row>
                                <Form.Group as={Col} md="6">
                                <Form.Label>N° Lei</Form.Label>
                                <div className="mb-7">
                                    <input maxLength={"15"}  className="form-control" type="text" id="leiDecreto"  placeholder="N° da lei" onChange={e => this.handleNumLei(e)} />
                                </div>
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                <Form.Label>N° decreto</Form.Label>
                                <div className="mb-7">
                                    <input maxLength={"15"}  className="form-control" type="text" id="leiDecreto"  placeholder="N° do decreto" onChange={e => this.handleNumDecreto(e)} />
                                </div>
                                </Form.Group>
                            </Form.Row>      
                            <Form.Row>
                                <Form.Group as={Col} md="12">
                                    <Form.Label>Descrição do Arquivo</Form.Label>
                                    <div className="mb-12">
                                        <textarea maxLength={"255"}  className="form-control" id="descricaoarquivo" onChange={e => this.handleDescricaoDocumento(e)}  />
                                    </div>
                                </Form.Group>
                            </Form.Row>                 

                          <fieldset>
                            <legend>Vínculos de arquivos</legend>
                            

                          <Form.Row>                              
                              <Form.Group as={Col}   md="12" >
                                    
                                <BuscaAvancadaContainer state={this.props.state} idPasta={this.props.idPasta} loadComponent={this.props.loadComponent} adicionaArquivo={this.handleSelectChangeArquivos} removeItemSelecionado={this.removeItemSelecionado} handleItensSelecionados={this.handleItensSelecionados} dados={this.state.dados} />       
                              </Form.Group>
                                            
                          </Form.Row>

                          <Form.Row> 
                                <Form.Group as={Col}   md="6" controlId="3">
                                    <Form.Label>Escolha o tipo de vínculo</Form.Label>
                                            <div key="natureza">
                                                <select      
                                                    onChange={(data) => {
                                                            this.handleChange(data)      
                                                            }
                                                    }
                                                    
                                                    className="form-control" 
                                                    aria-label="Natureza do upload de arquivo">
                                                    <option>Escolha...</option>
                                                    <option>Altera</option>
                                                    <option>Retifica</option>   
                                                    <option>Revoga</option>   
                                                    <option>Altera e Revoga</option> 
                                                    <option>Torna sem Efeito</option>  
                                                    <option>Suspende</option> 
                                                    <option>Prorroga</option> 
                                                </select>
            
                                            </div>
                                </Form.Group>           
                            </Form.Row>
                          </fieldset>
                          <Form.Row>
                              <Form.Group as={Col}   md="12" controlId="4">
                                  <Form.Label>Visibilidade</Form.Label>
                                  <div key="natureza">
                                   <select className="form-control mb-3" aria-label="Visibilidade" onChange={e => this.handleSelectChangeVisibilidade(e)}>
                                   <option value="1">Público</option>
                                   <option value="2">Intranet</option>     
                                   </select>

                                  </div>
                              </Form.Group>
                              <Form.Group as={Col}  md="12" controlId="5">
                                  <Form.Label>Etiquetas</Form.Label>
                                  <div key="seguimento">
                                      <div className="row">
                                          <div className="col-md-12">
                                              
                                              {/*select-react etiquetas*/}
                                              <AsyncSelect className="w-100" placeholder="Etiquetas"
                                              
                                               isMulti
                                               cacheOptions
                                               loadOptions={this.loadOptions}
                                               defaultOptions
                                               onChange={(data) => {
                                                   this.handleSelectChangeEtiquetas(data)      
                                                   }
                                               }
                                        
                                               closeMenuOnSelect={false}
                                               styles={colourStyles}
                                               />
                                            
                                          </div>
                                      </div>
                                  </div>
                              </Form.Group>
                             
                          </Form.Row>
                          <ValidadorArquivoRepetido idPasta={this.props.idPasta} nomePasta={this.state.nomePasta} state={this.props.state} nomeArquivo={this.state.arquivo} modal={this.state.modalArquivoRepetido} close={this.closeModalArquivoRepetido} handleNomeArquivo={this.handleNomeArquivo} handleSubmit={this.reenviar} editarComArquivo={this.editarComArquivo} mensagemRepetida={this.state.mensagemRepetida}  />                     
                          <ModalLoading exibeModal={this.state.exibeModalLoading}   />                      
                          <Modal.Footer>
                          <Button variant="secondary" onClick={this.handleClose}>
                              Fechar
                          </Button>
                          <Button variant="primary"  type="submit">
                               Salvar
                           </Button>
                       </Modal.Footer>
 
 
                          </Form>
 
                                    
                      </Modal.Body>
 
                  </Modal>
            
          </>
      )
  }
};

 
class NovaPasta extends React.Component {
   constructor(props) {
       super(props);
       this.state = {modal:  false, nomePasta: "", publica: false, modalArquivoRepetido: false, mensagemRepetida: false, nomePastaAtual: "" };
       this.handleClose = this.handleClose.bind(this);
       this.handleShow = this.handleShow.bind(this);
       this.handleSubmit = this.handleSubmit.bind(this); 
       this.closeModalArquivoRepetido = this.closeModalArquivoRepetido.bind(this); 
       this.handleNomePasta = this.handleNomePasta.bind(this);	
       this.criarPasta = this.criarPasta.bind(this);      
    }
 
    handleClose(){
        this.setState({ modal: false });
    }
 
    handleShow(e){
        e.preventDefault();
        this.setState({ modal: true });
    }

    handleSubmit(e) {
        e.preventDefault();
        TjdocsApi.existeDuplicidadePasta(this.state.nomePasta, this.props.idPasta).then(duplicado => {

            if(duplicado) {
                this.setState({ nomePastaAtual: this.state.nomePasta });
                this.setState({modalArquivoRepetido : true});
                return false; 
            }
            else {
                TjdocsApi.criaPasta(this.props.idPasta, this.state.nomePasta, this.state.publica).then( response =>
                    
                    this.props.loadComponent(this.props.idPasta)
                );
                this.setState({ modal: false });
                toast.success('Sucesso!');
            }
        });

    }

    handleChange (event){ 		
        if(event.target.id == "nome_pasta"){        	
        	let nome = event.target.value;
        	this.setState({ nomePasta: nome });
        }

        if(event.target.id == "pasta_publica"){        	
        	let publica = event.target.checked;        	
        	this.setState({ publica: publica });
       }
        
        
    }

    closeModalArquivoRepetido() {
        this.setState({modalArquivoRepetido: false});
    }
        
    handleNomePasta (event){
        let nome = event.target.value;
        this.setState({ nomePasta: nome });
    }	
    
    criarPasta() {     
        TjdocsApi.existeDuplicidadePasta(this.state.nomePasta, this.props.idPasta).then(duplicado => {
             if(duplicado) {
                this.setState({ nomePastaAtual: this.state.nomePasta });
                 this.setState({modalArquivoRepetido : true});
                 this.setState({mensagemRepetida : true});
                 return false; 
             }
             else {
                TjdocsApi.criaPasta(this.props.idPasta, this.state.nomePasta, this.state.publica).then( response =>
            
                    this.props.loadComponent(this.props.idPasta)
                );
                this.setState({ modal: false });
                this.setState({modalArquivoRepetido : false});
                this.setState({mensagemRepetida : false});
                toast.success('Sucesso!');
                      
             }
        });   
    }   

    render() {
       const { modal } = this.state;
     
       return (
           <>
 
                  

                   <a  className={this.props.podeAdicionarRemoverPasta  ? "icones" : "d-none "} title="Nova Pasta" onClick={this.handleShow} > <i className="fas fa-folder-plus"></i> </a>
 
                    <Modal show={modal} onHide={this.handleClose}  size="sm">
                       <Modal.Header closeButton>
                           <Modal.Title>Nova Pasta</Modal.Title>
                       </Modal.Header>
                        <Modal.Body>
                         
                           <Form.Group controlId="1">
                               <div key="nome">
                                   <Form.Control required type="text" id="nome_pasta"  placeholder="Nome Nova pasta" ref="nome"  onChange={e => this.handleChange(e)} />
                               </div>
                               
                               {this.props.podeSerPublica && (
                                <Form.Check
                                    type="checkbox"
                                    id="pasta_publica"
                                    label="&nbsp;&nbsp;Pasta Pública"
                                    onChange={e => this.handleChange(e)}
                                />
                                )}
                               
                           </Form.Group>
                        	
                        
                        </Modal.Body>
 
                       <Modal.Footer>
                           <Button variant="secondary" onClick={this.handleClose}>
                               Fechar
                           </Button>
                           <Button variant="primary" onClick={this.handleSubmit}>
                               Salvar
                           </Button>
                       </Modal.Footer>
                   </Modal>
                   <ValidadorPastaRepetida idPasta={this.props.idPasta} nomePasta={this.state.nomePastaAtual} state={this.props.state} nomeArquivo={this.state.arquivo} modal={this.state.modalArquivoRepetido} close={this.closeModalArquivoRepetido} handleNomePasta={this.handleNomePasta} editarPasta={this.criarPasta} mensagemRepetida={this.state.mensagemRepetida}  />          
            </>
       )
   }
 };
 

class Permissoes extends React.Component {
   constructor(props) {   	   
       super(props);       
       this.state = {modal:  false, usuario: "", validated: false};     
       
       this.handleClose = this.handleClose.bind(this);
       this.handleShow = this.handleShow.bind(this);
       this.handleSubmit = this.handleSubmit.bind(this);       
       
    }
 
   handleClose(){
        this.setState({ modal: false });
   }
 
   handleShow(e){
       e.preventDefault();
       this.setState({ modal: true });
       this.atualizaPermissoes();         
   }
 
   handleSubmit(e) {
       e.preventDefault();
       const form = e.currentTarget;
          
       if (form.checkValidity() === false) {
           e.preventDefault();
           e.stopPropagation();
           this.setState({ validated: true });
 
       } else{
           
 	       let add_del_arquivos = document.querySelector('#add_del_arquivos:checked')?true:false;
 	       let add_del_pastas = document.querySelector('#add_del_pastas:checked')?true:false;
 	       let add_del_usuarios = document.querySelector('#add_del_usuarios:checked')?true:false;
 	       let del_all_arquivo = document.querySelector('#del_all_arquivo:checked')?true:false;
 	       let del_all_pasta = document.querySelector('#del_all_pasta:checked')?true:false;
           let add_arquivos = document.querySelector('#add_arquivos:checked')?true:false;
 	       
 	       let valido = true 
	       if(!(add_del_arquivos || add_del_pastas || add_del_usuarios || del_all_arquivo || del_all_pasta || add_arquivos))
	       {
	           valido = false
	       }
	       if(!(this.state.usuario)){
	       	   valido = false;
	       }
	       if(!valido){
	           e.preventDefault();
    	       e.stopPropagation();	
    	       toast.error('Informe o usuário e selecione pelo menos uma opção');
    	       return;       
	       }	       
	       
           TjdocsApi.adicionarPermissao(this.state.usuario, this.props.idPasta, add_del_pastas, add_del_arquivos, add_del_usuarios, del_all_arquivo, del_all_pasta, add_arquivos).then( response =>{
               toast.success('Sucesso!');
               this.atualizaPermissoes();
               document.getElementById("form_permissoes").reset();
               this.props.loadComponent(this.props.idPasta)               
               }
           ).catch((e) => {
            if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";  
            toast.error('Erro ao adicionar permissões: ' + e);              
            });          
       }
      
 
   }
   
   alterarPermissao(idPermissao){
   
   	let add_del_arquivo = document.querySelector('#add_del_arquivo_'+idPermissao+':checked')?true:false;
   	let add_del_pasta = document.querySelector('#add_del_pasta_'+idPermissao+':checked')?true:false;
   	let add_del_usuario = document.querySelector('#add_del_usuario_'+idPermissao+':checked')?true:false;
   	let del_all_pasta = document.querySelector('#del_all_pasta_'+idPermissao+':checked')?true:false;
   	let del_all_arquivo = document.querySelector('#del_all_arquivo_'+idPermissao+':checked')?true:false;
    let add_arquivos = document.querySelector('#add_arquivos_'+idPermissao+':checked')?true:false;
   	let usuario = document.querySelector('#usuario_permissao_'+idPermissao).value;
   	
   	
   	TjdocsApi.atualizarPermissao(usuario, this.props.idPasta, add_del_pasta, add_del_arquivo, add_del_usuario, del_all_arquivo, del_all_pasta, idPermissao, add_arquivos).then( response =>{
               toast.success('Sucesso!');
               this.atualizaPermissoes();
               this.props.loadComponent(this.props.idPasta)
               }
    );
   	
   }
   
    
   	atualizaPermissoes = () => {
   	
		TjdocsApi.listaPermissoes(this.props.idPasta).then(jsonPermissoes => {
                
					let tabela = document.getElementById('tb_permissoes');
					let numeroLinhas = tabela.rows.length;
					if(numeroLinhas > 2){
					
						for (let i= numeroLinhas-1; i>=2; i--){
							tabela.deleteRow(i);
						}
						numeroLinhas = tabela.rows.length;
					}
					
					jsonPermissoes._embedded.dominioUsuarioOutputList.map((permissao, index) => {
						let linha = tabela.insertRow(numeroLinhas);
                		let celula1 = linha.insertCell(0);
                		let celula2 = linha.insertCell(1);   
                		let celula3 = linha.insertCell(2); 
                		let celula4 = linha.insertCell(3);
                		let celula5 = linha.insertCell(4); 
                		let celula6 = linha.insertCell(5);
                		let celula7 = linha.insertCell(6);
                        let celula8 = linha.insertCell(7);
                		celula2.align="center";
                		celula3.align="center";
                		celula4.align="center";
                		celula5.align="center";
                		celula6.align="center";
                		celula7.align="center";
                		celula1.innerHTML = permissao.usuarioNome + "<input id='usuario_permissao_"+permissao.id+"' type='hidden' value="+permissao.usuarioId+" >"; 
                		celula2.innerHTML = permissao.adicionarRemoverArquivo == true ? "<input id='add_del_arquivo_"+permissao.id+"' type='checkbox' checked >":"<input id='add_del_arquivo_"+permissao.id+"' type='checkbox' >";
                		celula3.innerHTML = permissao.adicionarRemoverPasta == true ? "<input id='add_del_pasta_"+permissao.id+"' type='checkbox' checked >":"<input id='add_del_pasta_"+permissao.id+"' type='checkbox' >";
              			celula4.innerHTML = permissao.adicionarRemoverUsuarios == true ? "<input id='add_del_usuario_"+permissao.id+"' type='checkbox' checked >":"<input id='add_del_usuario_"+permissao.id+"' type='checkbox' >";
              			celula5.innerHTML = permissao.apagarTodosArquivos == true ? "<input id='del_all_arquivo_"+permissao.id+"' type='checkbox' checked >":"<input id='del_all_arquivo_"+permissao.id+"' type='checkbox' >";
              			celula6.innerHTML = permissao.apagarTodasPastas == true ? "<input id='del_all_pasta_"+permissao.id+"' type='checkbox' checked >":"<input id='del_all_pasta_"+permissao.id+"' type='checkbox' >";
                        celula7.innerHTML = permissao.adicionarArquivo == true ? "<input id='add_arquivos_"+permissao.id+"' type='checkbox' checked >":"<input id='add_arquivos_"+permissao.id+"' type='checkbox' >";
                		//celula7.innerHTML = "<button class='excluirPermissao'>Excluir</button><button class='atualizarPermissao'>Atualizar</button>";
                		celula8.innerHTML = "<a class='excluirPermissao' href='#'><i class='fas fa-trash'></i></a> &nbsp;<a class='atualizarPermissao' href='#'><i class='fas fa-save'></i></a>";
                		numeroLinhas++;
                		
                		             			
						
					});
					
					var aExcluirPermissao = document.getElementsByClassName('excluirPermissao');
					var aAtualizarPermissao = document.getElementsByClassName('atualizarPermissao');					
					jsonPermissoes._embedded.dominioUsuarioOutputList.map((permissao, index) => {											
					
							aExcluirPermissao[index].onclick = (e) => {
							e.preventDefault();
							
							var confirmacao = window.confirm("Deseja realmente excluir esta permissão?");
							if (confirmacao == true) {
								//Exclui
								TjdocsApi.excluirPermissao(permissao.id).then(jsonPermissao => {
									toast.success("Permissão excluída com sucesso!");									
									this.atualizaPermissoes()
									this.props.loadComponent(this.props.idPasta);
									}
								).catch(error => toast.error(`Erro ao excluir permissão: ${error}`));
							}
						}
						
							aAtualizarPermissao[index].onclick = (e) => {
							e.preventDefault();
							this.alterarPermissao(permissao.id);
						}
						



					});

                    if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
				
            }
        ).catch(error => console.log(`Erro ao tentar atualizar permissões ${error}`));
	}
   
	
   loadOptionsPesquisaUsuario = (inputPesquisaUsuarioNav, callback) => {
       if (inputPesquisaUsuarioNav.length >= 3){
           TjdocsApi.pesquisaUsuario(inputPesquisaUsuarioNav).then(jsonPesquisa => {
               if(jsonPesquisa._embedded != undefined){
                callback(jsonPesquisa._embedded.usuarios.map((i, index) => ({ label: i.nome, value: i.idCorporativo})));
               }

           }).catch(error => toast.error(`Erro ao tentar pesquisar termos: ${error}`));
       }
   };	
   
   handleSelectChangeUsuarios (data){   
       let id_usuario = data.value;        
       this.state.usuario = id_usuario;        
   }   
 
    render() {
       const { modal } = this.state;
       const { validated } = this.state;
       return (
           <>
 
 
                   <a  className={this.props.podeAdicionarRemoverUsuario  ? "icones" : "d-none "} title="Permissões" onClick={this.handleShow} > <i className="fas fa-user-circle"></i> </a>
 
                    <Modal show={modal} onHide={this.handleClose} size="lg">
                       <Modal.Header closeButton>
                           <Modal.Title class="modalTitle">Permissões {this.props.caminhoCompleto}</Modal.Title>
                       </Modal.Header>
                        <Modal.Body>
                        <br></br>                        
                        <Form id="form_permissoes" name = "formPermissoes"
                               noValidate
                               validated={validated}
                               onSubmit={e => this.handleSubmit(e)} >
						    <legend>Usuário:</legend>
                            <Form.Group  md="12" controlId="1">
                                   <AsyncSelect id="inputPesquisaUsuarioNav" className="w-100" placeholder="Digiter para pesquisar o usuário  (pessoa deve ser um usuario com login no corporativo)"
                                      cacheOptions                                       
                                      loadOptions={this.loadOptionsPesquisaUsuario}
                                      onChange={(data) => {
                                          this.handleSelectChangeUsuarios(data)      
                                          }
                                      }
                                      defaultOptions
                                      />          							
                           </Form.Group>
                           <br></br>
                           <Form.Group controlId="2">
								<legend>Selecione abaixo as permissões para este usuário:</legend>
                                <br></br>
                               <div>
                               	    <Form.Check type="checkbox" id="add_del_pastas"  placeholder="" label = "&nbsp;Adicionar/Remover pastas" />
                               </div>
                               <div>
                               	    <Form.Check type="checkbox" id="add_del_arquivos"  placeholder="" label = "&nbsp;Adicionar/Remover arquivos" />
                               </div>

                               <div>
                               	    <Form.Check type="checkbox" id="add_del_usuarios"  placeholder="" label = "&nbsp;Adicionar/Remover usuários" />
                               </div>
                               <div>
                               	    <Form.Check type="checkbox" id="del_all_arquivo"  placeholder="" label = "&nbsp;Remover qualquer arquivo" />
                               </div>

                               <div>
                               	    <Form.Check type="checkbox" id="del_all_pasta"  placeholder="" label = "&nbsp;Remover qualquer pasta" />
                               </div>

                               <div>
                               	    <Form.Check type="checkbox" id="add_arquivos"  placeholder="" label = "&nbsp;Adicionar arquivos" />
                               </div>
                            
                           </Form.Group>
                           <Form.Group controlId="2">
                           <Button variant="secondary" onClick={this.handleClose}>
                               Fechar
                           </Button>&nbsp;
                           <Button variant="primary" type="Submit">
                               Salvar
                           </Button>               
                           
                           </Form.Group>
                           <div class="formDivider"></div>
                       <Form.Group controlId="2">
                           <div>
                               <br></br>
                               <legend>Permissões para Adicionar e Remover:</legend>
                               <br></br>
                           <table width="100%" id="tb_permissoes" class="table table-striped table-hover">
                           	<thead>
                           		<tr>
                           			<th>Usuário</th>
                           			<th align="center">Adicionar/Remover arquivos</th>
                           			<th align="center">Adicionar/Remover pastas</th>
                           			<th align="center">Adicionar/Remover usuários</th>
                           			<th align="center">Remover qualquer arquivo</th>
                           			<th align="center">Remover qualquer pasta</th>
                                    <th align="center">Adicionar arquivos</th>
                           			<th align="center">Excluir/Salvar</th>                           		
                           		</tr>                           		
                           	</thead>                           	
                           	
                           	<tbody>
                           	<tr class="invisible"><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                           	</tbody>                           	
                           </table>          
							</div>
                     </Form.Group>
                     </Form>
                        </Modal.Body>
                       <Modal.Footer>
                       </Modal.Footer>

                   </Modal>
 
                             
            </>
       )
   }
 };


                    
 
  class ExcluirPasta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {modal:  false, pastaPai: "" };
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
     }
  
     handleClose(){
         this.setState({ modal: false });
     }
  
     handleShow(e){
         e.preventDefault();
         this.setState({ modal: true });
         
         TjdocsApi.buscarPasta(this.props.idPasta).then( response =>
            {
                
                document.getElementById("nome_pasta_excluir").innerHTML = "<strong>"+response.nome+"</strong>";
                this.loadState(response);
                if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
            }
         );
         
         TjdocsApi.buscarQuantidadeItem(this.props.idPasta).then( response =>
            {
                document.getElementById("quatidade_itens_selecionado_excluir").innerHTML="Quantidade de itens que serão excluídos:<ul><li> <strong>"+response.quantidadePastas+"</strong> pasta(s)</li><li><strong>"+response.quantidadeDocumentos+"</strong> documento(s)</li></ul>";
                this.loadState(response);
                if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
            }
         );

    }
 
     handleSubmit(e) {
         e.preventDefault();	
         TjdocsApi.excluiPasta(this.props.idPasta).then( response =>{            
            //console.log(response.id)
            this.props.loadComponent(this.state.pastaPai);
         });
         this.setState({ modal: false });
         toast.success('Sucesso!');
     }
     
     loadState (response){           
            
            this.setState({ pastaPai: response.pastaPaiId })                        
     }        
 
  
     render() {
        const { modal } = this.state;
      
        return (
            <>

                    
                    <a  className={this.props.podeAdicionarRemoverPasta  ? "icones" : "d-none "} title="Excluir Pasta" onClick={this.handleShow} > <i className="fas fa-folder-minus"></i></a>
  
                     <Modal show={modal} onHide={this.handleClose}  size="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>Excluir Pasta</Modal.Title>
                        </Modal.Header>
                         <Modal.Body>
                          
                            <Form.Group controlId="1">
                                <div key="nome">
                                    Deseja realmente excluir a pasta <span id="nome_pasta_excluir"></span>?
                                </div>
                                <div key="nome" id="quatidade_itens_selecionado_excluir">
                                    
                                </div>
                            </Form.Group>
                                      
                         </Modal.Body>
  
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Não
                            </Button>
                            <Button variant="primary" onClick={this.handleSubmit}>
                                Sim
                            </Button>
                        </Modal.Footer>
                    </Modal>
  
                                               
             </>
        )
    }
  };

  class ValidadorArquivoRepetido extends React.Component {
    constructor(props) {
        super(props);
        this.state = {modal:  false, nomePasta: "" , pastaPai: "", publica: false, renomearArquivo: false};
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
     }
  
     handleClose(){
         this.props.close();
     }
  
     handleShow(e){
        e.preventDefault();
     }

     loadState (response){
        if(this.props.nomeArquivo == null) {
            toast.error(`Erro ao validar duplicidade.`);
            this.props.close();
        }
        this.setState({ nomePasta: response.nome })
     }
 
     handleSubmit(e) {
         e.preventDefault();
 
         TjdocsApi.editarPasta(this.props.idPasta, this.state.nomePasta, this.state.pastaPai, this.state.publica).then( response =>
             
             this.props.loadComponent(this.props.idPasta)
         );
         this.setState({ modal: false });
         toast.success('Sucesso!');
 
     }
 
     handleChange (event){
  
          if(event.target.id == "nome_pasta"){        	
        	let nome = event.target.value;
        	this.setState({ nomePasta: nome });
        }

        if(event.target.id == "pasta_publica"){        	
        	let publica = event.target.checked;        	
        	this.setState({ publica: publica });
       }  
  
     }


     renomearArquivo() {
        this.setState({renomearArquivo : !this.state.renomearArquivo});
     }

     
  
     render() {
        const { modal } = this.props;
        const { nomePasta } = this.props;
        const nomeArquivo = this.props.nomeArquivo != null && this.props.nomeArquivo != "undefined" ? this.props.nomeArquivo.name : "";
        
	
      
        return (
            <>
  
                <Modal show={modal} onHide={this.handleClose}  size="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>Arquivos Repetidos!</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                        {this.props.mensagemRepetida &&   <div style={{color: "#ff0000"}} >O destino {nomePasta} já possui um arquivo chamado {nomeArquivo}.</div> }
                        {!this.props.mensagemRepetida && `O destino ${nomePasta} já possui um arquivo chamado ${nomeArquivo}.`} <br />Como deseja prosseguir?
                        <br /><br />
                        <Button variant="secondary" style={{width: "280px", marginBottom: "5px"}} onClick={() => {this.props.editarComArquivo() }} >
                            Substituir arquivo no destino
                        </Button><br />
                        <Button variant="secondary" style={{width: "280px", marginBottom: "5px"}} >
                            <span onClick={() => { this.renomearArquivo()  }} >Renomear arquivo e enviar&nbsp;▼</span>
                            { this.state.renomearArquivo &&
                            <div >
                                <Form.Control required type="text" id="nome_pasta"  placeholder="Nome Novo Arquivo" ref="nome" style={{ width: "230px", float: "left" }} onChange={this.props.handleNomeArquivo}  /><i className="fas fa-save" onClick={this.props.handleSubmit} ></i>
                            </div> }
                        </Button><br />
                        <Button variant="secondary" style={{color: "red", width: "280px", marginBottom: "5px"}} onClick={this.handleClose} >
                            Cancelar operação
                        </Button>            
                        </Modal.Body>

                    <Modal.Footer>
                        
                    </Modal.Footer>
                </Modal>
  
                              
             </>
        )
    }
  };


  class ValidadorPastaRepetida extends React.Component {
    constructor(props) {
        super(props);
        this.state = {modal:  false, nomePasta: "" , pastaPai: "", publica: false, renomearPasta: false};
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
     }
  
     handleClose(){
         this.props.close();
     }
  
     handleShow(e){
        e.preventDefault();
     }

     loadState (response){
        this.setState({ nomePasta: response.nome });
     }
 
     handleSubmit(e) {
         e.preventDefault();
 
         TjdocsApi.editarPasta(this.props.idPasta, this.state.nomePasta, this.state.pastaPai, this.state.publica).then( response =>
             
             this.props.loadComponent(this.props.idPasta)
         );
         this.setState({ modal: false });
         toast.success('Sucesso!');
 
     }
 
     handleChange (event){
  
          if(event.target.id == "nome_pasta"){        	
        	let nome = event.target.value;
        	this.setState({ nomePasta: nome });
        }

        if(event.target.id == "pasta_publica"){        	
        	let publica = event.target.checked;        	
        	this.setState({ publica: publica });
       }  
  
     }


     renomearPasta() {
        this.setState({renomearPasta : !this.state.renomearPasta});
     }

     
  
     render() {
        const { modal } = this.props;
        const { nomePasta } = this.props;
        
	
      
        return (
            <>
  
                <Modal show={modal} onHide={this.handleClose}  size="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>Pasta Repetida!</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                        {this.props.mensagemRepetida &&   <div style={{color: "#ff0000"}} >O destino {nomePasta} já existe.</div> }
                        {!this.props.mensagemRepetida && `O destino ${nomePasta} já existe.`} <br />Como deseja prosseguir?
                        <br /><br />
                        {/*<Button variant="secondary" style={{width: "280px", marginBottom: "5px"}} onClick={() => {this.props.editarPasta() }} >
                            Substituir pasta no destino
                        </Button><br />*/}
                        <Button variant="secondary" style={{width: "280px", marginBottom: "5px"}} >
                            <span onClick={() => { this.renomearPasta()  }} >Renomear pasta e enviar&nbsp;▼</span>
                            { this.state.renomearPasta &&
                            <div >
                                <Form.Control required type="text" id="nome_pasta"  placeholder="Nome Nova Pasta" ref="nome" style={{ width: "230px", float: "left" }} onChange={this.props.handleNomePasta}  /><i className="fas fa-save" onClick={this.props.editarPasta} ></i>
                            </div> }
                        </Button><br />
                        <Button variant="secondary" style={{color: "red", width: "280px", marginBottom: "5px"}} onClick={this.handleClose} >
                            Cancelar operação
                        </Button>            
                        </Modal.Body>

                    <Modal.Footer>
                        
                    </Modal.Footer>
                </Modal>
  
                              
             </>
        )
    }
  };
 


  class EditarPasta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {modal:  false, nomePasta: "" , nomePastaAtual: "", pastaPai: "", publica: false, arquivo: "", modalArquivoRepetido: false, mensagemRepetida: false};
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModalArquivoRepetido = this.closeModalArquivoRepetido.bind(this); 
        this.handleNomePasta = this.handleNomePasta.bind(this);	
        this.editarPasta = this.editarPasta.bind(this);
     }
  
     handleClose(){
         this.setState({ modal: false });
     }
  
     handleShow(e){
        e.preventDefault();
        this.setState({ modal: true });

        TjdocsApi.buscarPasta(this.props.idPasta).then( response =>
            {
                this.loadState(response);
                if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
            }
         );

       

     }

     loadState (response){

            this.setState({ nomePasta: response.nome })
            this.setState({ pastaPai: response.pastaPaiId })
            this.setState({ publica: response.publica })
            this.setState({ nomePastaAtual: response.nome})            
     }
 
     handleSubmit(e) {
         e.preventDefault();
         
         TjdocsApi.existeDuplicidadePasta(this.state.nomePasta, this.state.pastaPai).then(duplicado => {

            if(duplicado) {
                this.setState({modalArquivoRepetido : true});
                return false; 
            }
            else {

                TjdocsApi.editarPasta(this.props.idPasta, this.state.nomePasta, this.state.pastaPai, this.state.publica).then( response =>
                    
                    this.props.loadComponent(this.props.idPasta)
                );
                this.setState({ modal: false });
                toast.success('Sucesso!');
            }
        });
 
     }
 
     handleChange (event){
  
          if(event.target.id == "nome_pasta"){        	
        	let nome = event.target.value;
        	this.setState({ nomePasta: nome });
        }

        if(event.target.id == "pasta_publica"){        	
        	let publica = event.target.checked;        	
        	this.setState({ publica: publica });
       }  
  
     }

     closeModalArquivoRepetido() {
        this.setState({modalArquivoRepetido: false});
    }
        
    handleNomePasta (event){
        let nome = event.target.value;
        this.setState({ nomePasta: nome });
    }	
    
    editarPasta() {     
        TjdocsApi.existeDuplicidadePasta(this.state.nomePasta, this.state.pastaPai).then(duplicado => {
             if(duplicado) {
                 this.setState({modalArquivoRepetido : true});
                 this.setState({mensagemRepetida : true});
                 return false; 
             }
             else {
                TjdocsApi.editarPasta(this.props.idPasta, this.state.nomePasta, this.state.pastaPai, this.state.publica).then( response =>
             
                    this.props.loadComponent(this.props.idPasta)
                );
                this.setState({ modal: false });
                this.setState({modalArquivoRepetido : false});
                 this.setState({mensagemRepetida : false});
                toast.success('Sucesso!');        
             }
        });   
    }     
  
     render() {
        const { modal } = this.state;
        const { nomePasta } = this.state;
        
	
      
        return (
            <>
  
  
                    <a  className={this.props.podeAdicionarRemoverPasta  ? "icones" : "d-none "} title="Renomear" onClick={this.handleShow}  style={{marginRight: "4px"}}> <i className="fas fa-edit"></i> </a>
  
                     <Modal show={modal} onHide={this.handleClose}  size="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>Renomear Pasta</Modal.Title>
                        </Modal.Header>
                         <Modal.Body>
                          
                            <Form.Group controlId="1">
                                <div key="nome">
                                    <Form.Control required type="text" id="nome_pasta"  value={nomePasta} placeholder="Nome Nova pasta" ref="nome"  onChange={e => this.handleChange(e)} />
                                </div>
                                	<Form.Check  checked = {this.state.publica ? "checked": ""} type="checkbox" id="pasta_publica"  label="&nbsp;&nbsp;Pasta Pública" onChange={e => this.handleChange(e)}/>
                            </Form.Group>
                                      
                         </Modal.Body>
  
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Fechar
                            </Button>
                            <Button variant="primary" onClick={this.handleSubmit}>
                                Salvar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <ValidadorPastaRepetida idPasta={this.props.idPasta} nomePasta={this.state.nomePastaAtual} state={this.props.state} nomeArquivo={this.state.arquivo} modal={this.state.modalArquivoRepetido} close={this.closeModalArquivoRepetido} handleNomePasta={this.handleNomePasta} editarPasta={this.editarPasta} mensagemRepetida={this.state.mensagemRepetida}  />
                              
             </>
        )
    }
  };

  class ModalLoading extends React.Component {
    constructor(props) {
        super(props);
        this.state = {modal:  false };
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);     
     }
  
     handleClose(){
         this.setState({ modal: false });
     }
  
     handleShow(e){
         e.preventDefault();
         this.setState({ modal: true });
     }

     render() {
        const { modal } = this.state;
      
        return (
            <>              
  
                     <Modal show={this.props.exibeModal} onHide={this.handleClose}  size="sm">
                        <Modal.Header >
                            <Modal.Title>Aguarde..</Modal.Title>
                        </Modal.Header>
                         <Modal.Body>
                            <div className="loading-ico"></div>
                         </Modal.Body>
  
                        <Modal.Footer>
                            
                        </Modal.Footer>
                    </Modal>
                              
             </>
        )
    }
  };
 