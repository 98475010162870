import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import TjdocsApi from "../api/API";
 
import Form from "react-bootstrap/Form";

export class EditarVisibilidade extends Component {
    constructor(props) {
        super(props);
        this.state = {modal:  this.props.modalVisibilidade, idVisibilidade : ""};
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectChangeVisibilidade = this.handleSelectChangeVisibilidade.bind(this);
     }
  
     handleClose(){
        this.props.close();
     }

     loadState (response){    
        console.log("resposta", response)           
        this.setState({ idVisibilidade: response.visibilidadeId })                        
     } 
  
     handleSubmit(e) {
         e.preventDefault();
  
         TjdocsApi.editarVisibilidade(this.props.idPasta, this.state.idVisibilidade).then( response =>
             console.log(response)
             //this.props.loadComponent(this.props.idPasta)
         );
         this.setState({ modal: false });
         toast.success('Sucesso!');
  
     }
  
     handleSelectChangeVisibilidade (event){
      
      if (event){
          let visibilidade = event.target.value;
          this.setState({ idVisibilidade: visibilidade });
      }
      else{
          this.setState({ idVisibilidade: 1 });
      }
  
     } 
  
     
  
     render() {
        const { modal } = this.state;
        
  
      
        return (
            <>
                    
  
                     <Modal show={this.props.modalVisibilidade} onHide={this.handleClose}  size="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>Editar Visibilidade</Modal.Title>
                        </Modal.Header>
                         <Modal.Body>
                          
                            <Form.Group controlId="1">
                              <Form.Label>Visibilidade</Form.Label>
                              <div key="natureza">
                                <select className="form-control mb-3" aria-label="Visibilidade" onChange={e => this.handleSelectChangeVisibilidade(e)}>
                                  <option value="1"  selected={this.props.visibilidadeId == 1 ? "selected" : ""} >Público</option>
                                  <option value="2"  selected={this.props.visibilidadeId == 2 ? "selected" : ""}  >Intranet</option>     
                                </select>
  
                              </div>
                            </Form.Group>
                                      
                         </Modal.Body>
  
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Fechar
                            </Button>
                            <Button variant="primary" onClick={this.handleSubmit}>
                                Salvar
                            </Button>
                        </Modal.Footer>
                    </Modal>
  
                              
             </>
        )
    }
  };