import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import TjdocsApi from '../api/API';
import Authentication from '../Authentication';
import ExcluirArquivoIcone from "./ExcluirArquivoIcone";

import Form from "react-bootstrap/Form";

export class FileIconLista extends Component {
  
  constructor(props) {
	  super(props);
	  this.state = {
		modalEditarArquivo: false
	};
	  this.showLoading = this.showLoading.bind(this);
	  this.usuario = Authentication.getUsuario();
  }

  isLogin(){
    
	return  Authentication.getUsuario();
  }
  
  showLoading(){
	if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "block";
  }

  setNodo=()=>{
	window.sessionStorage.setItem("nodo",this.props.id);
	window.sessionStorage.setItem("tipo_nodo","documento");  	
  }

  copiar = () =>{
	if (document.querySelector('div[role="tooltip"]') != undefined) //Fecha o menu aberto
			document.querySelector('div[role="tooltip"]').style.display = "none";
	this.setNodo();
	toast.success("Copiado!");
  }

  setModalEditarArquivo = (valor) => {
	  this.setState({modalEditarArquivo: valor});
  }
  
  render() {
	const tooltipOptionsFileIcon = (props) => (
		<Tooltip {...props}>
		  <div className="box box-primary" style={{margin: 0, minWidth: "135px"}}>
			  <div className="box-body no-padding"
				  style={{
					  color: "#333",
					  fontSize: "180%",
				  }}
			  >
				  <ul className="padding-0 " style={{textAlign: "left"}}>
					  
					  {/*<button onClick={(e) => this.setNodo()}> Copiar </button>*/}
					  <li 
					  	  className={(this.props.permissoesUsuarios != null && this.props.permissoesUsuarios.adicionarRemoverArquivo) || this.isLogin().authorities == 'ADMINISTRADOR' ? "box-body no-padding" : "d-none "}	
						  style={{listStyle: "none", padding: "7px 10px"}}
					  >
						  <a className="nav-link" href="#" onClick={(e) => {document.body.click(); this.copiar()}}>
							  <i className="fas fa-cut"></i> Recortar
						  </a>
					  </li>
				  		  
					  <ExcluirArquivoIcone pastaPai={this.props.idPastaAtual} loadComponent={this.props.loadComponent} documento={this.props.documento} logado={this.isLogin} podeAdicionarRemoverArquivo={this.props.permissoesUsuarios != null ? this.props.permissoesUsuarios.adicionarRemoverArquivo : false}  /> 	
  
					  <li className={(this.props.permissoesUsuarios != null && this.props.permissoesUsuarios.adicionarRemoverArquivo) || this.isLogin().authorities == 'ADMINISTRADOR' ? "box-body no-padding" : "d-none "}
						  style={{listStyle: "none", padding: "7px 10px"}}
					  >
						  <a className="nav-link" href="#" onClick={() => {document.body.click(); this.setModalEditarArquivo(true);}}>
							  <i className="fas fa-edit"></i> Editar
						  </a>
					  </li>

					  <li className={(this.props.permissoesUsuarios != null && this.props.permissoesUsuarios.adicionarRemoverArquivo) || this.isLogin().authorities == 'ADMINISTRADOR' || this.isLogin().authorities == "ARQUIVISTA" ? "box-body no-padding" : "d-none "}
						  style={{listStyle: "none", padding: "7px 10px"}}
					  >
						  <a className="nav-link" href="#" onClick={() => {document.body.click(); enviarArchivematica(this.props.id);}}>
							  <i className="fas fa-file-upload"></i> Archivematica
						  </a>
					  </li>


					  {/*
					  O nodo é : {this.props.id} <br/>
					  O pai e : {this.props.idPastaAtual} 
					  o tipo é: {window.sessionStorage.getItem("tipo_nodo")}
					   */}
				  </ul>
			  </div>
		  </div>
		</Tooltip>
	  );
	  
	  const tooltipArquivosVinculados = (props) => (
		<Tooltip {...props}>
		  <div className="box box-primary" style={{margin: 0}}>
			  <div className="box-header with-border box-defaultClaro">
				 <h3 className="box-title">
				  <a role="button" href="#" onClick={(e) => e.preventDefault()}>
					  Arquivos Vinculados <i style={{"float": "right"}} className="fas fa-times" onClick={() => document.body.click()}/>
				  </a>
				 </h3>
			  </div>
			  <div className="box-body no-padding" style={{color: "#333", fontSize: "180%"}}>
				  <ul className="padding-0" style={{textAlign: "left"}} >
					<li style={{listStyle: "none", padding: "2px 10px 0"}} >
						<span class="fas" >Nome do Gestor: </span><br /><div style={{height: "40px", overflow:"auto" }} >{this.props.listaGestores != null && <>{this.props.listaGestores._embedded.path.map((gestor) => (
							<>{gestor.username}<br /></> 
						))}</>}</div> 
					</li>
					<li style={{listStyle: "none", padding: "2px 10px 0"}} >
						<span class="fas" >Contato do Gestor: </span><br /><div style={{height: "40px", overflow:"auto" }} >{this.props.listaGestores != null && <>{this.props.listaGestores._embedded.path.map((gestor) => (
							<>{gestor.email}<br /></> 
						))}</>}</div> 
					</li>
					<li style={{listStyle: "none", padding: "2px 10px 0"}} >
						<span class="fas" >Visibilidade: </span>&nbsp;{this.props.documento.nomeVisibilidade}
					</li>
					<li style={{listStyle: "none", padding: "2px 10px 0"}} >
						<span class="fas" >Data de criação: </span>&nbsp;{this.props.dataCadastro}
					</li>
					<li style={{listStyle: "none", padding: "2px 10px 0"}} >
						<span class="fas" >Data de modificação: </span>&nbsp;{this.props.documento.dataAlteracao}
					</li>
				  </ul>

				  <ul className="padding-0" style={{textAlign: "left"}} >
				  {this.usuario.nome_completo != null &&<li style={{listStyle: "none", padding: "2px 10px 0"}} >
						<span class="fas" >Usuário: </span> &nbsp;{this.usuario.nome_completo}
					</li> }
					{this.props.permissoesUsuarios != null && <li style={{listStyle: "none", padding: "2px 10px 0" }} >
						<span class="fas" >Permissões: </span> 
					</li> }
					{(this.props.permissoesUsuarios != null && this.props.permissoesUsuarios.adicionarRemoverPasta != null && this.props.permissoesUsuarios.adicionarRemoverPasta) && <li style={{listStyle: "none", padding: "2px 30px 0", fontSize: "12px" }} >
						Adicionar/Remover pastas
					</li> }
					{(this.props.permissoesUsuarios != null && this.props.permissoesUsuarios.adicionarRemoverArquivo != null && this.props.permissoesUsuarios.adicionarRemoverArquivo) && <li style={{listStyle: "none", padding: "2px 30px 0", fontSize: "12px" }} >
						Adicionar/Remover arquivos
					</li> }
					{(this.props.permissoesUsuarios != null && this.props.permissoesUsuarios.adicionarRemoverUsuarios != null && this.props.permissoesUsuarios.adicionarRemoverUsuarios) && <li style={{listStyle: "none", padding: "2px 30px 0", fontSize: "12px" }} >
						Adicionar/Remover usuários
					</li>}
					{(this.props.permissoesUsuarios != null && this.props.permissoesUsuarios.apagarTodosArquivos != null && this.props.permissoesUsuarios.apagarTodosArquivos) && <li style={{listStyle: "none", padding: "2px 30px 0", fontSize: "12px" }} >
						Remover qualquer arquivo
					</li>}
					{(this.props.permissoesUsuarios != null && this.props.permissoesUsuarios.apagarTodasPastas != null && this.props.permissoesUsuarios.apagarTodasPastas) && <li style={{listStyle: "none", padding: "2px 30px 0", fontSize: "12px" }} >
						Remover qualquer pasta
					</li>}
				</ul>	

				  <ul className="padding-0" style={{textAlign: "left"}}>
					  {this.props.vinculadores.map((vinculador, i) => {
						  return (<li style={{listStyle: "none", padding: "7px 10px"}}>
							  <span className="etiquetas-ico" style={{backgroundColor: `${vinculador.tipo == "Revoga"  ? "#ff7369" : "#7CFC00"}`, padding: "1px 6px"}}>
								  <i className="far fa-file-alt" />
							  </span>
							  <span className="fa" style={{fontSize : "19px" }} >{vinculador.tipo == null ? "" : vinculador.tipo+"do por "} <Link style={{color: "#009abf"}} to={{pathname: `/documentos/${vinculador.documentoNovoId}`}} onClick={this.showLoading}>{vinculador.documentoNovoNome}</Link></span><br />
							  <span className="fa" style={{fontSize : "19px" }} >Descrição</span>&nbsp;{vinculador.documentoNovoDescricaoDocumento}<br />
							  <span className="fa" style={{fontSize : "19px" }} >N° Lei / Decreto </span>&nbsp;{vinculador.documentoNovoNumDecreto} {vinculador.documentoNovoNumLei}
						  </li>)
					  })}
				  </ul>
				  <ul className="padding-0" style={{textAlign: "left"}}>
					{this.props.documento.vinculos.map((vinculos, i) => {
						return (<li style={{listStyle: "none", padding: "7px 10px"}}>
							<span className="etiquetas-ico" style={{backgroundColor: `${vinculos.tipo == "Revoga"  ? "#ff7369" : "#7CFC00"}`, padding: "1px 6px"}}>
								<i className="far fa-file-alt" />
							</span>
							<span className="fa" style={{fontSize : "19px" }} >{vinculos.tipo} <Link style={{color: "#009abf"}} to={{pathname: `/documentos/${vinculos.documentoVinculadoId}`}} onClick={this.showLoading}>{vinculos.documentoVinculadoNome}</Link></span><br />
							<span className="fa" style={{fontSize : "19px" }} >Descrição</span>&nbsp;{vinculos.documentoVinculadoDescricaoDocumento}<br />
							<span className="fa" style={{fontSize : "19px" }} >N° Lei / Decreto </span>&nbsp;{vinculos.documentoVinculadoNumDecreto} {vinculos.documentoVinculadoNumLei}
						</li>)
					})}
				</ul>
			  </div>
		  </div>
		</Tooltip>
	  );

    return <tr className="tabByArrow" tabIndex="0">
		<td>

			<Link to={{pathname: `/documentos/${this.props.id}`}} onClick={this.showLoading}>
				<svg className="imagem_Cards icones-arquivo svg-cinza-claro" x="0px" y="0px" viewBox="0 0 60 60" width="35px" height="35px">
				<path d="M38.914,0H6.5v60h47V14.586L38.914,0z M17.5,14h10c0.552,0,1,0.447,1,1s-0.448,1-1,1h-10c-0.552,0-1-0.447-1-1  S16.948,14,17.5,14z M42.5,48h-25c-0.552,0-1-0.447-1-1s0.448-1,1-1h25c0.552,0,1,0.447,1,1S43.052,48,42.5,48z M42.5,40h-25  c-0.552,0-1-0.447-1-1s0.448-1,1-1h25c0.552,0,1,0.447,1,1S43.052,40,42.5,40z M42.5,32h-25c-0.552,0-1-0.447-1-1s0.448-1,1-1h25  c0.552,0,1,0.447,1,1S43.052,32,42.5,32z M42.5,24h-25c-0.552,0-1-0.447-1-1s0.448-1,1-1h25c0.552,0,1,0.447,1,1S43.052,24,42.5,24z   M37.5,16V2l14,14H37.5z" fill="#333333"/>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				</svg>
				<span className="sr-only"> Arquivo: </span> &nbsp; {this.props.nome}
			</Link>
			<EditarArquivo path={this.props.path} documento={this.props.documento} nome={this.props.documento.nome} idPasta={this.props.idPastaAtual} loadComponent={this.props.loadComponent} loadArquivo={this.props.loadArquivo} modalEditarArquivo={this.state.modalEditarArquivo} close={() => this.setModalEditarArquivo(false)}/>		  	
			{this.isLogin() && ((this.props.permissoesUsuarios != null && this.props.permissoesUsuarios.adicionarRemoverArquivo != null && this.props.permissoesUsuarios.adicionarRemoverArquivo) || this.isLogin().authorities == 'ADMINISTRADOR' || this.isLogin().authorities == 'ARQUIVISTA') ?
			<OverlayTrigger
				trigger="click"
				placement="bottom"
				overlay={tooltipOptionsFileIcon}
				rootClose
			>
				<div style={{width: '30px', height: '25px', cursor: 'help', display: 'inline', right: 0, marginLeft: '5px'}}>
					<span className="etiquetas-ico" title="Mais opções" style={{backgroundColor: '#6dcda0', padding: '1px 7px', color: 'var(--paleta_branco)'}}>
						<i className="fas fa-ellipsis-v" />
					</span>
				</div>
			</OverlayTrigger>
			:''}
			
			<OverlayTrigger
				trigger="click"
				placement="top"
				overlay={tooltipArquivosVinculados}
				rootClose={true}
			>
				<div style={{width: '30px', height: '25px', cursor: 'help', display: 'inline', right: "25px", marginLeft: '5px'}}>
					<span className="etiquetas-ico" title="Arquivos vinculados" style={{backgroundColor: '#009abf', padding: "1px 7px"}}>
						<i className="fas fa-info"></i>
					</span>
				</div>
			</OverlayTrigger>		  	

		</td>
		<td>{(this.props.documento.numLei != null ? this.props.documento.numLei : '')}</td>
		<td>{(this.props.documento.numDecreto != null ? this.props.documento.numDecreto : '')}</td>
		<td></td>
		<td>{(this.props.documento.tamanho != null ? (this.props.documento.tamanho /1000/1000 ).toFixed(3) : '')}</td>
		<td>{this.props.documento.nomeVisibilidade}</td>
		<td><div style={{height: "40px", overflow:"auto" }} >{this.props.listaGestores != null && <>{this.props.listaGestores._embedded.path.map((gestor) => (
			<>{gestor.username} / {gestor.email} <br /></> 
		))}</>}</div></td>
		<td>{this.props.dataCadastro}</td>
		<td>{this.props.documento.dataAlteracao}</td>
	</tr>;
  }
}

FileIconLista.propTypes = {
  nome: PropTypes.string
};

class EditarArquivo extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {modal:  this.props.modalEditarArquivo, nomeArquivo: "", descricao: ""};
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
		//this.loadState(this.props.documento)
     }
  
     handleClose(){
		this.setState({ nomeArquivo: "" });
         this.props.close();
     }

     loadState (documento){
		this.setState({ nomeArquivo: documento.nome })
		this.setState({ descricao: documento.descricao })
     }
 
     handleSubmit(e) {
         e.preventDefault();
		 if(this.state.nomeArquivo != null && this.state.nomeArquivo != "") {
			TjdocsApi.editarArquivo(this.props.documento.id, this.state.nomeArquivo, this.state.descricao).then( response =>
			this.props.loadComponent(this.props.idPasta)
			);
			toast.success('Sucesso!');
			this.props.close();
		}
		else {
			toast.error(`O nome do arquivo deve ser preenchido!`)
		}
     }
 
     handleChange (event){
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
     }
	 
	 setStateNomeArquivo() {
		if(this.state.nomeArquivo == "") {
			this.setState({ nomeArquivo: this.props.documento.nome });
		}
	  }


     render() {

        return (
            <>
                     <Modal show={this.props.modalEditarArquivo} onHide={this.handleClose}  size="sm" onMouseEnter={(e) => this.setStateNomeArquivo()} >
                        <Modal.Header closeButton>
                            <Modal.Title class="modalTitle">Editar Arquivo</Modal.Title>
                        </Modal.Header>
                         <Modal.Body>
							<br></br>
                            <Form.Group controlId="1">
                                <div key="nome">
                                    <Form.Control required type="text"  value={this.state.nomeArquivo} placeholder="Nome Arquivo" name="nomeArquivo"  onClick={(e)=> e.stopPropagation()} onChange={e => this.handleChange(e)} />
                                </div>
                            </Form.Group>

                            <Form.Group controlId="1">
                                <div key="descricao">
                                    <Form.Control required type="text"  value={this.state.descricao} placeholder="Descrição Arquivo" name="descricao" onClick={(e)=> e.stopPropagation()} onChange={e => this.handleChange(e)} />
                                </div>
                            </Form.Group>
                            
                            
                            
                                      
                         </Modal.Body>
  
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Fechar
                            </Button>
                            <Button variant="primary" onClick={this.handleSubmit}>
                                Salvar
                            </Button>
                        </Modal.Footer>
                    </Modal>
  
                              
             </>
        )
    }
  };


  class ExcluirArquivo extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {modal:  false, nomeArquivo: ""  ,descricao: "", permissao: false};
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
     }

	checkPermissao(){
        TjdocsApi.buscarPasta(this.props.id).then( response =>
            this.setState({ permissao: response.adicionarRemoverPasta })
        );
    }

    componentWillMount(){
        this.checkPermissao();
    }
  
     handleClose(){
         this.setState({ modal: false });
     }
  
     handleShow(e){
        e.preventDefault();
		
		if (document.querySelector('div[role="tooltip"]') != undefined) //Fecha o menu aberto
			document.querySelector('div[role="tooltip"]').style.display = "none";

        this.setState({ modal: true });
        this.loadState(e, this.props.documento)

       

     }

     loadState (e, documento){
        e.preventDefault();

            this.setState({ nomeArquivo: documento.nome })
            this.setState({ descricao: documento.descricao })

     }
 
     handleSubmit(e) {
         e.preventDefault();
 
        TjdocsApi.excluiDocumento(this.props.documento.id,).then( response =>
          this.props.loadComponent(this.props.idPasta)
        );
        this.setState({ modal: false });
        toast.success('Sucesso!');

 
     }
 
     handleChange (event){
        event.preventDefault();

        this.setState({[event.target.name]: event.target.value});
     }
  


     render() {
        const { modal } = this.state;


      
        return (
            <>
  
					<li className={this.permissao  ? "box-body no-padding" : "d-none "}
						style={{
							listStyle: "none",
							padding: "7px 10px",
						}}	
					>
						<a className="nav-link" onClick={this.handleShow}>
							<i className="fas fa-trash-alt"></i> Excluir
						</a>
					</li>

  
                     <Modal show={modal} onHide={this.handleClose}  size="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>Excluir Arquivo</Modal.Title>
                        </Modal.Header>
                         <Modal.Body>
                          
                            <Form.Group controlId="1">
                                <div key="nome">
                                    Deseja realmente excluir o arquivo?
                                </div>
                            </Form.Group>
                                      
                         </Modal.Body>
  
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Não
                            </Button>
                            <Button variant="primary" onClick={this.handleSubmit}>
                                Sim
                            </Button>
                        </Modal.Footer>
                    </Modal>

  
                              
             </>
        )
    }
  };

  const enviarArchivematica = async (id) => {
	try {
	  const token = await TjdocsApi.getAccessTokenArchivematica();

	  await TjdocsApi.conectarArchivematicaAPI(id);
	} catch (error) {
	  console.error("Erro ao autenticar ou enviar o documento:", error);
	}
  };