import chroma from 'chroma-js';
import React, { Component } from 'react';
import toast from 'react-hot-toast';
import TjdocsApi from '../api/API';

import Authentication from '../Authentication';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

export class Manual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.exibeCadastraManual,
      titulo: '',
      arquivo: '',
      validated: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleTitulo = this.handleTitulo.bind(this);
    this.usuario = Authentication.getUsuario();
    this.isLogin = Authentication.isLogin();
  }

  componentDidMount() {}

  resetState() {
    this.state = { modal: false, titulo: '', arquivo: '', validated: false };

    this.setState({ titulo: '' });
  }

  handleClose() {
    this.props.handleCadastraManual();
  }

  handleShow(e) {
    this.setState({ validated: false });
    this.resetState();

    e.preventDefault();
    TjdocsApi.listaEtiquetas()
      .then((jsonEtiquetas) => {
        if (jsonEtiquetas) {
          this.setState({
            etiquetas: jsonEtiquetas._embedded.etiquetas,
          });
        }

        return true;
      })
      .then((response) => {
        this.setState({ modal: response });
        if (document.querySelector('.loading'))
          document.querySelector('.loading').style.display = 'none';
      })
      .catch((error) =>
        toast.error(`Erro ao tentar consultar os domínios: ${error}`),
      );
  }

  handleFile(e) {
    e.preventDefault();
    let file = e.target.files[0];
    console.log(file);

    this.setState({ arquivo: file });
  }

  handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ validated: true });
    } else {
      TjdocsApi.criaManualDeInstrucao(this.state.titulo, this.state.arquivo)
        .then((response) => {
          if (document.querySelector('.loading'))
            document.querySelector('.loading').style.display = 'none';
          toast.success('Sucesso!');
        })
        .catch((e) => {
          if (document.querySelector('.loading'))
            document.querySelector('.loading').style.display = 'none';
          toast.error('Criando Arquivo: ' + e);
        });
      this.setState({ modal: false });
    }
  }

  handleChange(event) {
    var arquivos = this.state.arquivosVinculados.map((i) => ({
      id: null,
      documentoVinculado: i.documentoVinculado,
      tipo: event.target.value,
    }));
    this.setState({ arquivosVinculados: arquivos });
  }

  handleSelectChangeArquivos = (data) => {
    var arquivos = data.map((i) => ({
      documentoVinculado: { id: i.value.split('/')[2] },
    }));
    this.setState({ arquivosVinculados: arquivos });
    console.log(arquivos);
  };

  handleTitulo(event) {
    let titulo = '';
    titulo = event.target.value;

    this.setState({ titulo: titulo });
  }

  timeOutInputPesquisaArquivosUpload = setTimeout(() => {}, 100);

  loadOptions = (inputPesquisaNav, callback) => {
    if (inputPesquisaNav.length >= 0) {
      callback(
        this.state.etiquetas.map((i) => ({
          label: i.titulo,
          value: i.id,
          color: i.cor,
        })),
      );
    }
  };

  loadOptionsConteudo = (inputPesquisaNav, callback) => {
    if (inputPesquisaNav.length >= 3) {
      clearTimeout(this.timeOutInputPesquisaNav);
      const timeOut = setTimeout(() => {
        TjdocsApi.pesquisa(inputPesquisaNav)
          .then((jsonPesquisa) => {
            var retorno = jsonPesquisa.map((i, index) => ({
              label:
                '<span class="optionPesquisaPrincipal">' +
                (i.fonte === 'pasta'
                  ? '<i class="fa fa-folder"></i> '
                  : '<i class="fa fa-file"></i> ') +
                i.texto +
                ' - (Enviado em: ' +
                i.dataCadastro +
                ')' +
                '</span>' +
                (i.highlight === null
                  ? ''
                  : '<br/>' +
                    i.highlight.substring(11, i.highlight.length - 2)),
              value: '/' + i.fonte + 's/' + i.id,
            }));
            retorno.push({
              label: '+ Mais Resultados',
              value: '/busca/' + inputPesquisaNav,
            });

            if (inputPesquisaNav === this.state.textoDigitadoPesquisa)
              callback(retorno);
          })
          .catch((error) =>
            toast.error(`Erro ao tentar pesquisar termos: ${error}`),
          );
      }, 2000);
      this.timeOutInputPesquisaNav = timeOut;
    } else {
      this.timeOutInputPesquisaNav = setTimeout(() => {}, 100);
      callback([]);
    }
  };

  loadOptionsPesquisaArquivos = (inputPesquisaNav, callback) => {
    if (inputPesquisaNav.length >= 3) {
      clearTimeout(this.timeOutInputPesquisaArquivosUpload);
      const timeOut = setTimeout(() => {
        TjdocsApi.pesquisaArquivos(inputPesquisaNav, this.state.ano_filtro)
          .then((jsonPesquisa) => {
            callback(
              jsonPesquisa.map((i, index) => ({ label: i.texto, value: i.id })),
            );
          })
          .catch((error) =>
            toast.error(`Erro ao tentar pesquisar termos: ${error}`),
          );
      }, 2000);
      this.timeOutInputPesquisaArquivosUpload = timeOut;
    }
  };

  onChangeFunc = (optionSelected) => {
    if (document.querySelector('.loading'))
      document.querySelector('.loading').style.display = 'block';
    window.location.href = optionSelected.value;
  };

  render() {
    const { modal } = this.state;
    const unidade_corporativo = this.usuario.corporativo_unidade_nome;

    {
      /*Configurações de estilo do select-react das etiquetas*/
    }
    const colourStyles: StylesConfig<ColourOption, true> = {
      control: (styles) => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? data.color
            : isFocused
            ? color.alpha(0.1).css()
            : undefined,
          color: isDisabled
            ? '#ccc'
            : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',

          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
              ? isSelected
                ? data.color
                : color.alpha(0.3).css()
              : undefined,
          },
        };
      },
      multiValue: (styles, { data }) => {
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: color.alpha(0.1).css(),
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
          backgroundColor: data.color,
          color: 'white',
        },
      }),
    };

    const { validated } = this.state;

    return (
      <>
        <Modal
          show={this.props.exibeCadastraManual}
          onHide={this.handleClose}
          autoFocus={false}
          dialogClassName={'largeModal'}
          enforceFocus={false}
        >
          <Modal.Header style={{ backgroundColor: '#004A80' }}>
            <Modal.Title
              style={{ color: '#ffffff', height: '36px', width: '90%' }}
            >
              <div
                style={{ fontWeight: 'bold', fontSize: '20px', float: 'left' }}
                className='span12 centered-text'
              >
                {' '}
                Releases - TJ
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    color: '#0FBDD2',
                  }}
                >
                  DOCS
                </span>{' '}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              id='create-course-form'
              name='addForm'
              noValidate
              validated={validated}
              onSubmit={(e) => this.handleSubmit(e)}
            >
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlTextarea1'
              >
                <div
                  style={{
                    marginBottom: '40px',
                    borderBottom: 'solid 1px #ccc',
                  }}
                >
                  <h3 className='span12 centered-text'>Cadastrar Manual</h3>
                </div>

                <Form.Row>
                  <Form.Group as={Col} md='4'>
                    <Form.Label>Titulo:</Form.Label>
                    <div className='mb-7'>
                      <input
                        className='form-control'
                        type='text'
                        id='leiDecreto'
                        onChange={(e) => this.handleTitulo(e)}
                      />
                    </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md='6'>
                    <div className='mb-3'>
                      <input
                        required
                        className='form-control'
                        type='file'
                        id='formFile'
                        onChange={this.handleFile}
                      />
                    </div>
                  </Form.Group>
                </Form.Row>
              </Form.Group>
              <div></div>
              <Modal.Footer>
                <div>
                  <Button
                    style={{
                      padding: '10px',
                      color: '#fff',
                      backgroundColor: '#1C7A67',
                      border: 'solid 1px #1C7A67',
                      fontWeight: 'bold',
                      width: '80px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      float: 'left',
                      marginRight: '5px',
                    }}
                    onClick={(e) => {
                      this.handleClose();
                    }}
                    type='submit'
                  >
                    Salvar
                  </Button>
                  <div
                    style={{
                      padding: '10px',
                      color: '#ff0000',
                      border: 'solid 1px #ff0000',
                      fontWeight: 'bold',
                      width: '80px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      float: 'left',
                    }}
                    onClick={(e) => {
                      this.handleClose();
                    }}
                  >
                    Cancelar
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
